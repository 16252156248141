import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as Yup from 'yup';
import moment from 'moment';

import Button from '../../../../../shared/Button';
import Form from '../../../../../shared/Form';
import Page from '../../../../../shared/Page';
import TextField from '../../../../../shared/TextField';
import Styled from '../../../UserAccountPage/UserAccountPage.styles';
import SelectField from '../../../../../shared/SelectField';
import { insuranceCompanies as insuranceCompaniesConstant } from '../../../../../../constants/InsuranceCompanies';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { range } from 'lodash';
import { getMonth, getYear } from 'date-fns';
import { months as monthsConstant } from '../../../../../../constants/Months';

const InsuranceCardEdit = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isFormSubmitting, setFormSubmitting] = useState(false);

  const { post: createUserInsurance, response: createUserInsuranceResponse } =
    useFetch(`/UserInsurance`);
  const { put: updateUserInsurance, response: createUpdateUserInsurance } =
    useFetch(`/UserInsurance`);

  const insuranceCard = history.location.state?.data;
  const allInsurances = history.location.state?.allInsurances || [];
  const insuranceCompanies = insuranceCompaniesConstant;

  const [validFrom, setValidFrom] = useState();

  const years = range(1990, getYear(new Date()) + 1, 1);
  const months = monthsConstant;

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  const validationSchema = Yup.object().shape({
    insuranceCompanyName: Yup.string().required('Laukelis privalomas'),
    insuranceCardNumber: Yup.string()
      .trim()
      .test(
        'insurance-number-validation-tests',
        (val, { createError, parent }) => {
          if (!val) {
            return createError({
              message: t('validation.requiredField'),
              path: 'insuranceCardNumber',
            });
          }

          const wrongNumberError = createError({
            message: 'Klaidingas kortelės numeris',
            path: 'insuranceCardNumber',
          });

          switch (parent.insuranceCompanyName) {
            case 'Ergo':
              if (!/^[A-Za-z]{3}\d{6}$/.test(val)) {
                // 3 letters following 6 numbers
                return wrongNumberError;
              }
              break;

            case 'Gjensidige':
              if (!/^\d{10}$/.test(val)) {
                // 10 numbers
                return wrongNumberError;
              }
              break;

            default: // for the remaining companies
              if (!/^\d{19}$/.test(val)) {
                // 19 numbers
                return wrongNumberError;
              }
              break;
          }

          if (allInsurances?.length > 0) {
            const matchingCard = allInsurances.find(
              (card) =>
                card.insuranceCompanyName === parent?.insuranceCompanyName &&
                card.insuranceCardNumber === val,
            );

            if (
              !!matchingCard &&
              (!insuranceCard?.id || insuranceCard.id != matchingCard.id)
            ) {
              return createError({
                message: 'Tokia draudimo kortelė jau egzistuoja',
                path: 'insuranceCardNumber',
              });
            }
          }

          return true;
        },
      ),
    validFrom: Yup.string()
      .trim()
      .test('valid-from-validation-tests', (val, { createError, parent }) => {
        if (!val) {
          return true;
        }

        if (!isValidDate(val)) {
          return createError({
            message: 'Klaidingas datos formatas',
            path: 'validFrom',
          });
        }

        if (!!parent.validUntil) {
          const validFromDate = new Date(val);
          const validUntil = new Date(parent.validUntil);

          validFromDate.setHours(0, 0, 0, 0);
          validUntil.setHours(0, 0, 0, 0);

          if (validFromDate >= validUntil) {
            return createError({
              message: 'Turi būti mažesnė nei "Galioja iki" data',
              path: 'validFrom',
            });
          }
        }

        return true;
      }),
    validUntil: Yup.string()
      .trim()
      .test('valid-until-validation-tests', (val, { createError, parent }) => {
        if (!val) {
          return true;
        }

        if (!isValidDate(val)) {
          return createError({
            message: 'Klaidingas datos formatas',
            path: 'validUntil',
          });
        }

        if (!!parent.validFrom) {
          const validFromDate = new Date(parent.validFrom);
          const validUntil = new Date(val);

          validFromDate.setHours(0, 0, 0, 0);
          validUntil.setHours(0, 0, 0, 0);

          if (validFromDate >= validUntil) {
            return createError({
              message: 'Turi būti didesne nei "Galioja nuo" data',
              path: 'validUntil',
            });
          }
        }

        return true;
      }),
  });

  const initialValues = {
    id: insuranceCard?.id || '',
    insuranceCompanyName: insuranceCard?.insuranceCompanyName || '',
    insuranceCardNumber: insuranceCard?.insuranceCardNumber || '',
    validFrom: insuranceCard?.validFrom?.substring(0, 10) || '',
    validUntil: insuranceCard?.validUntil?.substring(0, 10) || '',
  };

  const sendForm = async (data, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    setFormSubmitting(true);

    var model = {
      id: insuranceCard?.id || null,
      insuranceCompanyName: data.insuranceCompanyName,
      insuranceCardNumber: data.insuranceCardNumber,
      validFrom: data.validFrom || null,
      validUntil: data.validUntil || null,
    };
    let response;
    if (!!insuranceCard && !!insuranceCard.id) {
      response = await updateUserInsurance(model);
    } else {
      response = await createUserInsurance(model);
    }

    if (response > 0) {
      history.push('/account/insurance-cards');
    } else {
      setSubmitting(false);
      setFormSubmitting(false);
      alert('Įvyko nenumatyta klaida');
    }
  };

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.TabTitle style={{ display: 'flex', justifyContent: 'center' }}>
          Asmeninio draudimo kortelė
        </Styled.TabTitle>

        <Styled.EditForm
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            color: '#52585F',
          }}
        >
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={sendForm}
          >
            {({
              setFieldValue,
              errors,
              isSubmitting,
              values,
              handleSubmit,
              handleChange,
              setFieldError,
            }) => (
              <FormikForm>
                <Styled.EditFormCenter>
                  <Form>
                    <Form.Group>
                      <Form.Row>
                        <SelectField
                          error={errors.insuranceCompanyName}
                          defaultValue={
                            insuranceCompanies.find(
                              (company) =>
                                company.value === values.insuranceCompanyName,
                            ) || {
                              value: values.insuranceCompanyName,
                              label: values.insuranceCompanyName,
                            }
                          }
                          name="insuranceCompanyName"
                          label="Draudimo įmonė*"
                          options={insuranceCompanies}
                          onChange={(value) => {
                            setFieldValue('insuranceCompanyName', value);
                          }}
                        />
                      </Form.Row>
                      <Form.Row>
                        <Field
                          error={errors.insuranceCardNumber}
                          name="insuranceCardNumber"
                          label={'Kortelės numeris*'}
                          component={TextField}
                        />
                      </Form.Row>

                      <Form.Row>
                        <Field
                          error={errors.validFrom}
                          name="validFrom"
                          label={'Galioja nuo'}
                          component={TextField}
                        />
                      </Form.Row>
                      <Form.Row>
                        <Field
                          error={errors.validUntil}
                          name="validUntil"
                          label={'Galioja iki'}
                          component={TextField}
                        />
                      </Form.Row>
                      {/* <Form.Row>
                        <DatePicker
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                              >
                                {'<'}
                              </button>
                              <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <select
                                value={months[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>

                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                              >
                                {'>'}
                              </button>
                            </div>
                          )}
                          selectsRange={false}
                          dateFormat="yyyy-MM-dd"
                          //  locale={lt}
                          selected={validFrom}
                          //  maxDate={maxDate}
                          placeholderText="Data nuo"
                          calendarStartDay={1}
                          onChange={(date) => {
                            setValidFrom(date);
                          }}
                          // onChange={(date) => {
                          //   setMinDate(update);
                          //   setDateRange([update, maxDate]);
                          //   props.dateChanged([update, maxDate]);
                          // }}
                          isClearable={true}
                        />
                      </Form.Row> */}
                    </Form.Group>
                  </Form>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: '10px',
                    }}
                  >
                    <Button
                      size="large"
                      variant="secondary"
                      onClick={() => {
                        history.push(history.goBack());
                      }}
                      style={{ width: '50px' }}
                    >
                      Atgal
                    </Button>
                    <Button
                      loading={isFormSubmitting}
                      disabled={isFormSubmitting}
                      size="large"
                      onClick={handleSubmit}
                      type="submit"
                      style={{ width: '50px' }}
                    >
                      Išsaugoti
                    </Button>
                  </div>
                </Styled.EditFormCenter>
              </FormikForm>
            )}
          </Formik>
        </Styled.EditForm>
      </Styled.DataContainer>
    </Page>
  );
};

export default InsuranceCardEdit;
