import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const TitleBlock = styled.div`
  p {
    display: flex;
    flex-direction: row;
    margin: 0;
    svg {
      margin-right: 4px;
      margin-top: 3.5px;
      color: rgb(95, 157, 244);
      ${mediaDown('md')`
       margin-top:0px;      
       height:18px;
       margin-right: -4px;
  `}
    }
  }
`;

const Styled = {
  TitleBlock,
};

export default Styled;
