import { addHours, addDays } from 'date-fns';

const TimeSlot = [
  {
    startDate: new Date(addHours(addDays(new Date(), 1), 1)).toISOString(),
    endDate: new Date(addHours(addDays(new Date(), 1), 2)).toISOString(),
    timeSlotId: 1,
    statusName: 'Available',
  },
  {
    startDate: new Date(addHours(addDays(new Date(), 1), 2)).toISOString(),
    endDate: new Date(addHours(addDays(new Date(), 1), 3)).toISOString(),
    timeSlotId: 2,
    statusName: 'Available',
  },
  {
    startDate: new Date(addHours(addDays(new Date(), 1), 3)).toISOString(),
    endDate: new Date(addHours(addDays(new Date(), 1), 4)).toISOString(),
    timeSlotId: 3,
    statusName: 'Available',
  },
];

export default TimeSlot;
