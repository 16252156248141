import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

export const Box = styled.div`
  position: absolute;
  top: 0; 
  left: 0;
  width: 26px;
  height: 26px;
  border-radius: 7px;
  box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid 'transparent';

  ${mediaDown('md')`
    width: 23px;
    height: 23px;
  `}

  svg {
    display: block;
    height: 14px;
    opacity: 0;
    transition: all 0.15s;

    ${mediaDown('md')`
      width: 12px;
    `}
  }

  svg path {
    ${(props)=>props.isGrey && 'fill:#52585F;'}
  }
`;

export const Label = styled.div`
  position: relative;
  padding: 4px 0 4px 40px;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  display: inline-block;
  cursor: pointer;

  ${mediaDown('md')`
    font-size: 12px;
    line-height: 15px;
  `}

  small {
    display: block;
    font-size: 14px;
    font-weight: 800;
    margin-top: 8px;
  }
  span {
    ${(props) => props.boldParagraph &&  css`font-size: 16px;`}
  }
  
`;

const errorCss = css`
  ${Box} {
    border-color: #f35b69;
  }
`;

export const Checkbox = styled.div`
  user-select: none;
 
  ${(props) => props.error && errorCss}

  ${Box} {
    border: 1px solid ${(props) => props.showBorder ? '#cce1e5' : 'transparent'};
  }
  input {
    display: none;

    &:checked + ${Label} {
      ${Box} {
        svg {
          opacity: 1;
        }
      }
    }
  }

  a {
    color: inherit;
  }
`;

const Styled = {
  Checkbox,
  Label,
  Box,
};

export default Styled;
