import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';
const DatePicker = styled.div` 
  span {
    font-weight: normal;
    margin-right: 5px;
    font-size: 18px;
  }
  .breaker {
    margin-right: 10px;
  }
  display: flex;
  width: 350px;
  float: right;
  font-size: 18px;
  .react-datepicker-wrapper {
    div {
      button {
        margin-right: 10px;
      }
    }
    width: 330px;
    input {
      width: 135px;
      color: #52585F;
      ::-webkit-input-placeholder { 
        color: #52585f;
        }
      ::-moz-placeholder {
          color: #52585f;
        }
      :-ms-input-placeholder {
          color: #52585f;
        }
      :-moz-placeholder { 
          color: #52585f;
        }      
      ::placeholder {
          color:#52585f;
        }
    }   
  }
    
  .react-datepicker {
    .react-datepicker__month-container {
      .react-datepicker__header {
        div {
          color: #52585f;
          select {
            color: #52585f;
          }
        }
      }
      .react-datepicker__month {
        div {
          color: #52585f;
        }
      }
    }
    color: #52585f;
  }

  ${mediaDown('md')`
    flex-grow: wrap;
    font-size: 18px;
    .react-datepicker-wrapper {
      width: 275px;
    }
`}
  @media (max-width: 585px) {
    flex-direction: column;
    width: 255px;
    font-size: 18px;
    .react-datepicker-wrapper {
      div {
        button {
          margin-right: 20px;
        }
      }
      input {
        width: 255px;
      }
    }
  }
`;
const Styled = {
  DatePicker,
};

export default Styled;
