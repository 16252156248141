/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import Page from '../../../../../shared/Page';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Button from '../../../../../shared/Button';
import Modal from '../../../../../shared/Modal/Modal';
import { useUserState } from '../../../../../context/UserContext';

const RegistrationTab = (props) => {
  const { t } = useTranslation();
  const [spin, setSpin] = useState(false);
  const [VisitDetails, setVisitDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [showResultModalTextContent, setShowResultModalTextContent] =
    useState('');
  const [showCancelRegistrationModal, setShowCancelRegistrationModal] =
    useState(false);
  const [
    showCancelRegistrationResultModal,
    setShowCancelRegistrationResultModal,
  ] = useState(false);
  const history = useHistory();
  const userState = useUserState();
  const registration = history.location.state?.data;
  const mrt3tPriceChangeDate = new Date(
    process.env.REACT_APP_3T_PRICE_CHANGE_DATE,
  );
  const [isPsdf, setIsPsdf] = useState(
    registration.services?.length > 0 &&
      registration.services.every((service) => {
        return (
          service.payments?.length > 0 &&
          service.payments.every(
            (payment) => payment.paymentType === 'VLK (PSDF)',
          )
        );
      }),
  );
  const [is3T, setIs3T] = useState(() => {
    if (
      registration?.registrationService?.includes('3T') &&
      isPsdf &&
      new Date(registration.date).getTime() < mrt3tPriceChangeDate.getTime() &&
      registration.departmentAddress?.includes('Šiauliai')
    ) {
      return true;
    } else if (
      registration?.registrationService?.includes('3T') &&
      isPsdf &&
      new Date(registration.date).getTime() >= mrt3tPriceChangeDate.getTime()
    ) {
      return true;
    } else return false;
  });
  const { get: getVisitDetails } = useFetch('/UserAccount/VisitDetails');

  const {
    post: cancelRegistrationRequestSend,
    response: cancelRegistrationResponse,
  } = useFetch(`/RegistrationManagement/Registrations/CancelPsdf`);

  const handleSubmit = async (visitId) => {
    setSpin((prev) => !prev);

    const response = await cancelRegistrationRequestSend('', {
      visitId: visitId,
      espbiEsi: userState.espbiEsi,
    });

    if (cancelRegistrationResponse.status >= 400) {
      setShowResultModalTextContent(
        t('userAccount.registrationsTab.registrationFailedCancelledSecondary'),
      );
      setSpin((prev) => !prev);
      setShowCancelRegistrationResultModal(true);
    } else {
      if (!response.registrationIsCancelled) {
        setShowResultModalTextContent(
          t('userAccount.registrationsTab.cancelRegistrationFailed'),
        );
        setSpin((prev) => !prev);
        setShowCancelRegistrationResultModal(true);
      } else {
        setShowResultModalTextContent(
          t('userAccount.registrationsTab.cancelRegistrationSuccess'),
        );
        setSpin((prev) => !prev);
        setShowCancelRegistrationResultModal(true);
      }
    }
  };

  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');
  const getUserVisitDetailsInformation = async (visitId) => {
    setLoading(true);
    let query = userState.espbiEsi
      ? `?visitId=${visitId}&espbiEsi=${userState.espbiEsi}`
      : `?visitId=${visitId}`;

    getVisitDetails(query).then((res) => {
      setVisitDetails(res);
      setLoading(false);
    });
  };

  useEffectOnce(() => {
    getUserVisitDetailsInformation(registration.visitId);
  });

  const handleFileDownload = async () => {
    if (VisitDetails.files?.length > 0 && VisitDetails.files?.length === 1) {
      var file = VisitDetails.files[0];
      await getFile(
        `?fileType=${file.fileType}&fileGuid=${
          file.fileGuid
        }&category=${'AttachedRegistrationFiles'}`,
      ).then((res) => {
        saveAs(res, 'Registracijos failas');
      });
    } else if (VisitDetails.files?.length > 1) {
      var JSZip = require('jszip');
      var zip = new JSZip();
      for (let index = 0; index < VisitDetails.files?.length; index++) {
        const file = VisitDetails.files[index];
        await getFile(
          `?fileType=${file.fileType}&fileGuid=${
            file.fileGuid
          }&category=${'AttachedRegistrationFiles'}`,
        ).then(async (res) => {
          const newRes = res.substring(28);
          let mimeType2 = res.match(/[^:/]\w+(?=;|,)/)[0];
          zip.file('Failas nr ' + index + 1 + '.' + mimeType2, newRes, {
            base64: true,
          });
        });
      }
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'Registracijos failai.zip');
      });
    }
  };

  const {
    get: calendarInviteDownload,
    response: calendarInviteDownloadResponse,
  } = useFetch('UserAccount/createCalendar');

  const downloadCalendarInvite = async () => {
    await calendarInviteDownload(`?id=${registration.visitId}`).then((res) => {
      // window.open(res, '_blank');
      var a = document.createElement('a'); //Create <a>
      a.href = res;
      a.download = 'Affidea_Registracija.ics';
      setTimeout(() => {
        a.click();
      }, 120);
    });
  };

  const getCombinedTitles = () => {
    const conmbinedNames = registration.services
      .reduce((accumulator, service) => {
        if (service.name) {
          accumulator.push(service.name);
        }
        return accumulator;
      }, [])
      .join(', ');
    return conmbinedNames;
  };

  const getSumOfPrices = () => {
    const totalPrices = registration.services.reduce((sum, service) => {
      return sum + (service.price || 0);
    }, 0);
    return totalPrices.toFixed(2);
  };

  return (
    <Page loading={loading}>
      <Styled.DataContainer>
        <Styled.BackArrow>
          <RightArrow onClick={() => history.goBack()}></RightArrow>
          <Styled.VisitTitle>
            {t('userAccount.registrationsList.title')}
          </Styled.VisitTitle>
        </Styled.BackArrow>
        <Styled.TabTitle>
          {t('userAccount.registrationsTab.title')}
        </Styled.TabTitle>
        <Styled.InfoDetails>
          {registration.department && (
            <Styled.InfoDetailsRow>
              <span> {t('userAccount.registrationsTab.department')}</span>
              <div>{registration.department}</div>
            </Styled.InfoDetailsRow>
          )}

          {registration.departmentAddress && (
            <Styled.InfoDetailsRow>
              <span>
                {' '}
                {t('userAccount.registrationsTab.departmentAddress')}
              </span>
              <div>{registration.departmentAddress}</div>
            </Styled.InfoDetailsRow>
          )}

          {!!registration.services?.length ? (
            <Styled.InfoDetailsRow>
              <span> {t('userAccount.registrationsTab.serviceTitle')}</span>
              <div>{getCombinedTitles()}</div>
            </Styled.InfoDetailsRow>
          ) : (
            registration.registrationService && (
              <Styled.InfoDetailsRow>
                <span> {t('userAccount.registrationsTab.serviceTitle')}</span>
                <div>{registration.registrationService}</div>
              </Styled.InfoDetailsRow>
            )
          )}

          {registration &&
            registration.services &&
            registration.services.length > 0 && (
              <Styled.InfoDetailsRow>
                <span> Kaina: </span>
                {is3T &&
                  new Date(registration.date).getTime() <
                    mrt3tPriceChangeDate.getTime() && (
                    <div>{`${process.env.REACT_APP_3TPRICE}`}</div>
                  )}
                {is3T &&
                  new Date(registration.date).getTime() >=
                    mrt3tPriceChangeDate.getTime() && (
                    <div>{`${process.env.REACT_APP_3T_PRICE_AFTER_CHANGE}`}</div>
                  )}

                {!is3T && <div>{getSumOfPrices()}</div>}

                {Array.isArray(registration.services) &&
                  registration.services[0] &&
                  Array.isArray(registration.services[0].payments) &&
                  registration.services[0].payments.map((payment, index) => {
                    return (
                      <div key={index}>
                        {payment.paymentType !== null && (
                          <Styled.PaymentDetails>
                            {payment.paymentType === 'VLK (PSDF)' && (
                              <span> (PSDF kompensuojama paslauga)</span>
                            )}
                          </Styled.PaymentDetails>
                        )}
                      </div>
                    );
                  })}
              </Styled.InfoDetailsRow>
            )}

          <Styled.InfoDetailsRow>
            <span> {t('userAccount.registrationsTab.registrationStatus')}</span>
            <div>{registration.paymentStatus}</div>
          </Styled.InfoDetailsRow>

          {registration.doctorName &&
            registration.doctorSpeciality &&
            registration.doctorSurname && (
              <Styled.InfoDetailsRow>
                <span> {t('userAccount.registrationsTab.doctor')}</span>
                <div>
                  {registration.doctorSpeciality} - {registration.doctorName}{' '}
                  {registration.doctorSurname}
                </div>
              </Styled.InfoDetailsRow>
            )}
          <Styled.InfoDetailsRow>
            <span> {t('userAccount.registrationsTab.visitDate')}</span>
            <div>
              <Moment className="time" utc={true} format="YYYY-MM-DD HH:mm">
                {registration.date}
              </Moment>
            </div>
            <span style={{ marginLeft: '10px' }}>
              {t('userAccount.registrationsTab.visitReminder')}
            </span>
          </Styled.InfoDetailsRow>

          {registration.isPayseraUser && (
            <Styled.InfoDetailsRow>
              <span> {t('userAccount.registrationsTab.payment')}</span>
              <div>{`${registration.Payment} ${registration.Price} €`}</div>
            </Styled.InfoDetailsRow>
          )}
        </Styled.InfoDetails>
        {registration.referral && (
          <div>
            <Styled.TabTitle>
              {t('userAccount.registrationsTab.siuntimas')}
            </Styled.TabTitle>
            <Styled.InfoDetails>
              <Styled.InfoDetailsRow>
                <span>
                  {t('userAccount.registrationsTab.siuntimoData.date')}
                </span>
                <Moment className="time" format="YYYY-MM-DD">
                  {registration.referral.date}
                </Moment>
              </Styled.InfoDetailsRow>
              <Styled.InfoDetailsRow>
                <span>
                  {t('userAccount.registrationsTab.siuntimoData.aspi')}
                </span>
                <div>{registration.referral.institution}</div>
              </Styled.InfoDetailsRow>
              {registration.referral.specialist && (
                <Styled.InfoDetailsRow>
                  <span>
                    {t('userAccount.registrationsTab.referralData.specialist')}
                  </span>
                  <div>{registration.referral.specialist}</div>
                </Styled.InfoDetailsRow>
              )}
            </Styled.InfoDetails>
          </div>
        )}

        {registration.registrationServicePreparations &&
          registration.registrationServicePreparations?.length > 0 && (
            <div>
              <Styled.TabTitle>
                {t('userAccount.registrationsTab.servicePreparations')}
              </Styled.TabTitle>

              <Styled.Preparations>
                {registration.registrationServicePreparations.map((prep) => {
                  return <li key={prep}>{prep}</li>;
                })}
              </Styled.Preparations>
            </div>
          )}
        {VisitDetails && VisitDetails.files.length > 0 && (
          <Styled.InfoDetailsRow>
            <a
              onClick={() => {
                handleFileDownload();
              }}
            >
              ATSISIŲSTI REGISTRACIJOS METU PRISEGTĄ FAILĄ
            </a>
          </Styled.InfoDetailsRow>
        )}

        <Styled.InfoDetails>
          <Styled.InfoDetailsRow>
            <div
              onClick={downloadCalendarInvite}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Pridėti kalendoriuje
            </div>
          </Styled.InfoDetailsRow>
        </Styled.InfoDetails>

        {isPsdf && (
          <Styled.InfoDetails>
            <Styled.InfoDetailsRow>
              <p
                onClick={() => setShowCancelRegistrationModal(true)}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                Atšaukti registraciją
              </p>
            </Styled.InfoDetailsRow>
          </Styled.InfoDetails>
        )}
        <Styled.InfoDetails>
          <Styled.InfoDetailsRow>
            <Styled.Reminder>
              {t('userAccount.registrationsTab.reminder')}
            </Styled.Reminder>
          </Styled.InfoDetailsRow>
        </Styled.InfoDetails>
      </Styled.DataContainer>
      <Modal
        visible={showCancelRegistrationModal}
        showClose={false}
        onClose={() => {
          setShowCancelRegistrationModal(false);
        }}
      >
        <Modal.TextContent isGrey={true}>
          Ar tikrai norite atšaukti registracija?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowCancelRegistrationModal(false);
            }}
            isGrey={true}
          >
            Grįžti
          </Button>
          <Button
            size="large"
            onClick={() => {
              handleSubmit(registration.visitId);
            }}
            loading={spin}
          >
            Taip
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        visible={showCancelRegistrationResultModal}
        showClose={false}
        onClose={() => {
          setShowCancelRegistrationResultModal(false);
        }}
      >
        <Modal.TextContent isGrey={true}>
          {showResultModalTextContent}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowCancelRegistrationResultModal(false);
              history.push('/account/registrations');
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};
export default RegistrationTab;
