import React, { useEffect, useState } from 'react';
import Styled from '../../UserAccountPage.styles';
import Page from '../../../../../shared/Page';
import FileUpload from '../../../../../shared/FileUpload';
import { Formik, Form as FormikForm, Field } from 'formik';
import useFetch from 'use-http';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';
import Checkbox from '../../../../../shared/Checkbox';
import TextField from '../../../../../shared/TextField';
import Button from '../../../../../shared/Button';
import Modal from '../../../../../shared/Modal/Modal';
import Loader from '../../../../../shared/Loader';

const PopupWindow = () => {
  const { request: fileRequest } = useFetch(`/Files/SavePopup`);
  const { post: updatePopupRequest, response: updatePopupResponse } = useFetch(
    `/UserAccount/UpdatePopup`,
  );
  const { get: getPopupRequest, response: getPopupResponse } = useFetch(
    `/UserAccount/GetPopup`,
  );
  const { get: getPopupImageRequest, response: getPopupImageResponse } =
    useFetch(`/UserAccount/GetPopupImage`);
  const { get: deletePopupRequest, response: deletePopupResponse } = useFetch(
    `/UserAccount/DeletePopup`,
  );

  const { t } = useTranslation();
  const [popup, setPopup] = useState(null);
  const [isPopupSaved, setIsPopupSaved] = useState(false);
  const [saveModalVisible, setSaveModalVisible] = useState(false);
  const [saveModalContent, setSaveModalContent] = useState('');
  const [initialValues, setInitialValues] = useState({
    files: [],
    buttonTitle: '',
    link: '',
    isVisible: false,
    validForHours: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPopup = async () => {
      await getPopupRequest();
      if (getPopupResponse.ok) {
        setInitialValues((prev) => ({
          files: [],
          link: getPopupResponse.data.link,
          isVisible: getPopupResponse.data.isVisible,
          validForHours: getPopupResponse.data.validForHours,
          buttonTitle: getPopupResponse.data.buttonTitle,
        }));
      }
    };

    const fetchPopupImage = async () => {
      setLoading(true);
      await getPopupImageRequest();
      if (!getPopupImageResponse.data.popupImage) {
        setIsPopupSaved(false);
        setLoading(false);
      } else {
        setIsPopupSaved(true);
        setLoading(false);
        setPopup(getPopupImageResponse.data.popupImage);
      }
    };

    fetchPopup();
    fetchPopupImage();
  }, []);

  const validationSchema = () => {
    const schema = {
      link: Yup.string().url(t('validation.invalidUrl')),
      validForHours: Yup.number('Privalo būti skaičius').required(
        'Privalomas laukas',
      ),
      buttonTitle: Yup.string().test(
        'len',
        'Teksto ilgis negali būti ilgesnis nei 36 simboliai',
        (val) => val.length <= 36,
      ),
    };
    return Yup.object().shape(schema);
  };

  const handlePopupDelete = async () => {
    await deletePopupRequest();
    if (deletePopupResponse.ok) {
      setIsPopupSaved(false);
      setPopup(null);
    }
  };

  const handlePopupEdit = async (data, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await updatePopupRequest('', {
      buttonTitle: data.buttonTitle,
      link: data.link,
      showPopup: data.isVisible,
      validForHours: data.validForHours,
    });

    if (updatePopupResponse.ok) {
      setSaveModalVisible(true);
      setSaveModalContent(t('validation.successfullySaved'));
    } else if (
      updatePopupResponse.status === 400 &&
      updatePopupResponse.data.message === 'Please upload an image'
    ) {
      setSaveModalVisible(true);
      setSaveModalContent(t('validation.noPicture'));
    }
    setSubmitting(false);
  };

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Popup lango parametrai</Styled.TabTitle>
        </Styled.TabTitleWrapper>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema()}
          onSubmit={handlePopupEdit}
        >
          {({
            values,
            handleChange,
            isSubmitting,
            handleSubmit,
            errors,
            setFieldError,
            setFieldValue,
          }) => (
            <>
              <FormikForm>
                {loading ? (
                  <Page>
                    <Loader />
                  </Page>
                ) : (
                  <>
                    <Styled.ImageContainer>
                      {!isPopupSaved && (
                        <Styled.GreyLabel>
                          Paveikslėlis dar neįkeltas
                        </Styled.GreyLabel>
                      )}
                      {popup && (
                        <>
                          <img
                            src={popup}
                            width="auto"
                            height="auto"
                            style={{
                              objectFit: 'contain',
                              objectPosition: 'left',
                              height: '100%',
                              maxHeight: '600px',
                              width: '100%',
                              outline: 'none',
                              borderColor: '#fff',
                            }}
                          ></img>
                        </>
                      )}
                    </Styled.ImageContainer>
                    {popup && (
                      <Button
                        size="large"
                        variant="secondary"
                        style={{ border: '1px solid #cce1e5', color:'#52585F'}}
                        onClick={() => handlePopupDelete()}
                      >
                        {t('userAccount.popupAdministration.deleteFile')}
                      </Button>
                    )}
                  </>
                )}
                <Styled.RowDiv>
                  <FileUpload
                    name="fileUpload"
                    request={fileRequest}
                    error={errors.files}
                    onChange={(value) => {
                      setFieldValue('files', value);
                      setPopup(
                        value[value.length - 1] &&
                          value[value.length - 1].fileImage,
                      );
                      setIsPopupSaved(true);
                    }}
                    value={values.files}
                    label={t('userAccount.popupAdministration.fileUploadLabel')}
                    thumbs={true}
                    isPopup={true}
                    isGrey={true}
                  />
                </Styled.RowDiv>
                <Styled.RowDiv>
                  <Field
                    focus={true}
                    name="buttonTitle"
                    value={values.buttonTitle || ''}
                    label={'Mygtuko pavadinimas'}
                    component={TextField}
                    hint={'Teksto ilgis negali būti ilgesnis nei 36 simboliai'}
                    style={{ width: '100%', border: '1px solid #cce1e5',color:'#52585F' }}
                    onChange={handleChange}
                    isGrey={true}
                  />
                </Styled.RowDiv>
                <Styled.RowDiv>
                  <Field
                    focus={true}
                    name="link"
                    value={values.link || ''}
                    label={'Nuoroda'}
                    component={TextField}
                    style={{ width: '100%', border: '1px solid #cce1e5' }}
                    onChange={handleChange}
                    isGrey={true}
                  />
                </Styled.RowDiv>
                <Styled.RowDiv>
                  <Field
                    focus={true}
                    name="validForHours"
                    value={values.validForHours || ''}
                    label={'Popup rodymo intervalas valandomis'}
                    component={TextField}
                    style={{ width: '100%', border: '1px solid #cce1e5' }}
                    onChange={handleChange}
                    isGrey={true}
                  />
                </Styled.RowDiv>
                <Styled.RowDiv>
                  <Checkbox
                    name="isVisible"
                    value={values.isVisible}
                    checked={values.isVisible}
                    onChange={(event) => {
                      setFieldValue('isVisible', !values.isVisible);
                    }}
                    showBorder={true}
                    isGrey={true}
                  >
                    {' '}
                    {t('userAccount.popupAdministration.showPopup')}
                  </Checkbox>
                </Styled.RowDiv>
                <Styled.RowDiv>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    onClick={() => handlePopupEdit}
                  >
                    Išsaugoti
                  </Button>
                </Styled.RowDiv>
              </FormikForm>
            </>
          )}
        </Formik>
      </Styled.DataContainer>
      <Modal
        visible={saveModalVisible}        
        onClose={() => setSaveModalVisible(false)}
      >
        <Modal.TextContent isGrey={true}>{saveModalContent}</Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setSaveModalVisible(false);
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default PopupWindow;
