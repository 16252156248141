/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../../../../shared/DateRangePicker/DateRangePicker';
import Loader from '../../../../../shared/Loader';
import Page from '../../../../../shared/Page';
import { useHistory } from 'react-router-dom';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import { getQueryString } from '../../../../../constants/visitsQueryStringBuilder';
import Button from '../../../../../shared/Button/Button';
import SelectField from '../../../../../shared/SelectField/SelectField';
import { useEffectOnce } from 'react-use';
import moment from 'moment';
import LineChart from '../../../../../shared/LineChart';
import { AiOutlineLineChart } from 'react-icons/ai';
import CheckboxList from './CheckboxList';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
const LabaratoryResearches = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [Researches, setResearches] = useState();
  const [loading, setLoading] = useState(false);
  const sessionDispatch = useUserDispatch();
  const [Filters, setFilters] = useState();
  const [SelectedFilter, setSelectedFilter] = useState();
  const [search, setSearch] = useState();
  const userState = useUserState();
  const [dateRange, setDateRange] = useState(userState.dateFilter);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [data, setData] = useState();
  const [showResultChart, setShowResultChart] = useState(false);
  const [isCHECKED, setIsCHECKED] = useState(false);

  const [selectedChartNames, setSelectedChartNames] = useState([]);
  const [
    showResultChartAnalytesSelections,
    setShowResultChartAnalytesSelections,
  ] = useState(false);
  const [results, setResults] = useState(null);
  const [resultsForChart, setResultsForChart] = useState(null);
  const [observations, setObservations] = useState();
  const [checkedObservations, setCheckedObservations] = useState({});
  const { get: getResearches, response: getResearchesResponse } = useFetch(
    '/UserAccount/LabaratoryResearchesList',
  );
  const { get: getObservations, response: getObservationsResponse } = useFetch(
    '/UserAccount/LabaratoryResearchesObservations',
  );

  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');
  const [expandedGroupIds, setExpandedGroupIds] = useState([]);
  const [subGroupLoading, setSubGroupLoading] = useState(false);
  const { get: getResearch } = useFetch('/UserAccount/LabaratoryResearch');

  const toggleGroup = (groupName, researchIds) => {
    setResults(null);
    setShowResultChart(false);
    setShowResultChartAnalytesSelections(false);
    setSelectedChartNames([]);
    if (expandedGroup === groupName) {
      setExpandedGroup(null);
      setExpandedGroupIds([]);
    } else {
      setExpandedGroup(groupName);
      setExpandedGroupIds(researchIds);
      setResults(null);
      setCheckedObservations({});
      if (researchIds.length > 0) {
        handleSelection(researchIds);
      }
    }
  };

  const applyChanges = async (date, search, filter) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getResearches(
      getQueryString(date, search, null, filter, userState.espbiEsi),
    );
    if (getResearchesResponse.status >= 400) {
    } else {
      setData(response);
      const tmpFilterArray = [];
      // eslint-disable-next-line array-callback-return
      response?.filters?.map((filter) => {
        tmpFilterArray.push({ value: filter, label: filter });
      });
      setFilters(tmpFilterArray);
    }
    setLoading(false);
  };

  useEffectOnce(() => {
    applyChanges(dateRange, search, SelectedFilter);
  });

  useEffect(() => {
    if (!!results) {
      const groupedObservations = results
        .reduce((acc, curr) => {
          const { observations, orderDate } = curr;
          const observedNames = new Set();

          observations.forEach((observation) => {
            const { name, value, unit } = observation;
            const observationKey = `${name}`;

            if (!observedNames.has(name)) {
              observedNames.add(name);

              const existingEntry = acc.find(
                (entry) => entry.name === observationKey,
              );
              if (!existingEntry) {
                acc.push({
                  name: observationKey,
                  unit,
                  data: [{ orderDate, value }],
                });
              } else {
                existingEntry.data.push({ orderDate, value });
              }
            }
          });

          return acc;
        }, [])
        .filter((observationGroup) =>
          observationGroup.data.every(
            (observation) =>
              !isNaN(parseFloat(observation.value)) &&
              isFinite(observation.value),
          ),
        );

      groupedObservations.forEach((entry) => {
        entry.data.sort(
          (a, b) => new Date(a.orderDate) - new Date(b.orderDate),
        );
      });
      setResultsForChart(groupedObservations);
    }
  }, [results]);

  useEffect(() => {
    if (!!results) {
      async function getLaboratoryReaserchesObservations() {
        const researchIds = results.map((result) => result.externalId);
        const query = userState.espbiEsi
          ? `?researchesExternalIds=${researchIds.join(
              '&researchesExternalIds=',
            )}&espbiEsi=${userState.espbiEsi}`
          : `?researchesExternalIds=${researchIds.join(
              '&researchesExternalIds=',
            )}`;

        await getObservations(query).then((obs) => {
          setObservations(obs);
          setCheckedObservations({});
        });
      }

      getLaboratoryReaserchesObservations();
    }
  }, [getObservations, results, userState.espbiEsi]);

  const setSelectedChartsCallback = (charts) => {
    setSelectedChartNames(charts);
    setShowResultChart(!!charts.length);
  };

  const shouldShowGraphButton = () => {
    return !!results && !!resultsForChart?.length;
  };

  const getSelectedResultsForCharts = () => {
    if (!selectedChartNames || !selectedChartNames.length)
      return resultsForChart;
    return resultsForChart.filter((obj) =>
      selectedChartNames.includes(obj.name),
    );
  };

  const handleSelection = async (researchIds) => {
    setSubGroupLoading(true);
    const query = userState.espbiEsi
      ? `?ids=${researchIds.join('&ids=')}&espbiEsi=${userState.espbiEsi}`
      : `?ids=${researchIds.join('&ids=')}`;

    await getResearch(query).then((res) => {
      setSubGroupLoading(false);
      setResults(res);
    });
  };

  const handleFileDownload = async (filename, fileType, fileGuid) => {
    await getFile(
      `?fileType=${fileType}&fileGuid=${fileGuid}&category=${'LabaratoryResearch'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${trimFileName(filename)}.pdf`;
      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  const trimFileName = (fileName) => {
    if (fileName.length > 190) {
      return fileName.substring(0, fileName.length - 18) + '...';
    } else return fileName;
  };

  function isOutsideRange(value, referenceRange) {
    const rangeMatch = referenceRange.match(
      /^\((\d+(\.\d+)?) - (\d+(\.\d+)?)\)$/,
    );

    if (!rangeMatch) {
      // Reference range does not match the specified format, consider it as outside range
      return true;
    }

    const minValue = parseFloat(rangeMatch[1]);
    const maxValue = parseFloat(rangeMatch[3]);

    return value < minValue || value > maxValue;
  }

  const selectResultChart = () => {
    if (resultsForChart?.length > 1) {
      setShowResultChartAnalytesSelections(true);
      setShowResultChart(false);
      setCheckedObservations({});
      return;
    }
    setShowResultChart(true);
  };

  useEffect(() => {
    if (showResultChart) {
      setShowResultChartAnalytesSelections(false);
    }
  }, [showResultChart]);

  const handleChangeObservations = (event) => {
    const { name, checked } = event.target;
    setCheckedObservations((prevCheckedStates) => ({
      ...prevCheckedStates,
      [name]: checked,
    }));
  };

  const isObservationsFromOtherResearches = (observationName) => {
    const researchName = results[0]?.name;
    if (!!researchName) {
      return observations?.find(
        (observation) =>
          observation.observationName === observationName &&
          observation.laboratoryResearchName !== researchName,
      );
    }

    return false;
  };

  return (
    <Styled.DataContainer>
      <Styled.TabTitleWrapper select={true}>
        <Styled.TabTitle>Laboratoriniai tyrimai </Styled.TabTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target.search.value);
            applyChanges(dateRange, e.target.search.value, SelectedFilter);
          }}
          onChange={(e) => {
            if (
              e.target.value === undefined ||
              e.target.value === null ||
              e.target.value === ''
            ) {
              setSearch(e.target.value);
              applyChanges(dateRange, e.target.value, SelectedFilter);
            }
          }}
          style={{ display: 'flex' }}
        >
          <input type="text" id="search" placeholder="Paieška" name="search" />
          <Button
            style={{ marginRight: '15px', fontSize: '16px' }}
            size="small"
            type="submit"
          >
            Ieškoti
          </Button>
        </form>
        <Styled.SelectField>
          <SelectField
            options={Filters}
            isClearable={true}
            placeHolder={'Būsena'}
            onChange={(val) => {
              setSelectedFilter(val);
              applyChanges(dateRange, search, val);
            }}
          ></SelectField>
        </Styled.SelectField>
        <DateRangePicker
          dateChanged={(date) => {
            setDateRange(date);
            applyChanges(date, search, SelectedFilter);
          }}
          dateRange={dateRange}
        />
      </Styled.TabTitleWrapper>
      {loading && (
        <Page>
          <Loader />
        </Page>
      )}
      {!loading &&
        data &&
        data.groups !== 0 &&
        _.orderBy(data.groups, ['asc']).map((group) => {
          return (
            <div>
              <div
                onClick={() =>
                  toggleGroup(
                    group.groupName,
                    group.researches
                      .filter(
                        (research) => research.statusDisplay === 'Atliktas',
                      )
                      .map((research) => research.id),
                  )
                }
                key={group.groupName}
                style={{
                  backgroundColor: '#0087f7',
                  color: 'white',
                  padding: '10px',
                  marginTop: '5px',
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
              >
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {group.groupName}{' '}
                </span>
                <span>{expandedGroup === group.groupName ? '' : '+'}</span>
                {expandedGroup === group.groupName && (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleGroup(group.groupName)}
                  >
                    {expandedGroup === group.groupName ? '-' : '+'}
                  </span>
                )}
              </div>
              {expandedGroup === group.groupName && (
                <div
                  style={{
                    padding: '10px',
                    marginTop: '5px',
                    borderRadius: '10px',
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  {subGroupLoading && (
                    <div style={{ position: 'relative', height: '50px' }}>
                      <Loader></Loader>
                    </div>
                  )}
                  {!subGroupLoading && (
                    <>
                      {shouldShowGraphButton() && (
                        <AiOutlineLineChart
                          size={40}
                          color={'#2196F3'}
                          onClick={() => {
                            selectResultChart();
                          }}
                          title="Spauskite, kad parodyti grafiką"
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                      {showResultChartAnalytesSelections && results && (
                        <>
                          <CheckboxList
                            title={
                              'Pasirinkite analites, kurių rezultatai bus atvaizduoti grafike:'
                            }
                            results={resultsForChart}
                            callback={setSelectedChartsCallback}
                          />
                        </>
                      )}
                      {showResultChart && results && (
                        <>
                          {getSelectedResultsForCharts().map((obj) => (
                            <>
                              {!checkedObservations[obj.name] && (
                                <LineChart
                                  dataName={`${obj.name} (${obj.unit})`}
                                  dataSet={obj.data.map((x) => x.value)}
                                  xValues={obj.data.map((x) =>
                                    x.orderDate.toString().substring(0, 10),
                                  )}
                                />
                              )}

                              {!!checkedObservations[obj.name] && (
                                <LineChart
                                  dataName={`${obj.name} (${obj.unit})`}
                                  dataSet={observations
                                    .filter(
                                      (o) => o.observationName === obj.name,
                                    )
                                    .sort(
                                      (a, b) =>
                                        new Date(a.researchOrderDate) -
                                        new Date(b.researchOrderDate),
                                    )
                                    .map((ob) => ob.observationValue)}
                                  xValues={observations
                                    .filter(
                                      (o) => o.observationName === obj.name,
                                    )
                                    .sort(
                                      (a, b) =>
                                        new Date(a.researchOrderDate) -
                                        new Date(b.researchOrderDate),
                                    )
                                    .map((ob) =>
                                      ob.researchOrderDate
                                        .toString()
                                        .substring(0, 10),
                                    )}
                                />
                              )}

                              {isObservationsFromOtherResearches(obj.name) && (
                                <Checkbox
                                  children={`Įtraukti kitų tyrimų ${obj.name} rezultatus`}
                                  onChange={(e) => {
                                    handleChangeObservations(e);
                                  }}
                                  name={obj.name}
                                  checked={
                                    checkedObservations[obj.name] || false
                                  }
                                />
                              )}
                            </>
                          ))}
                        </>
                      )}
                      <ul>
                        {group.researches.map((research) => (
                          <div style={{ marginTop: '40px' }}>
                            <li key={research.id}>
                              {
                                new Date(research.orderDate)
                                  .toISOString()
                                  .split('T')[0]
                              }{' '}
                              ({research.statusDisplay})
                            </li>
                            {results &&
                              Array.isArray(results) &&
                              results?.find(
                                (item) => item.externalId === research.id,
                              ) && (
                                <div>
                                  <div style={{ fontWeight: 'bold' }}>
                                    Rezultatai:
                                  </div>
                                  {Array.isArray(results) &&
                                    results
                                      ?.find(
                                        (item) =>
                                          item.externalId === research.id,
                                      )
                                      .observations.map((obj, idx) => {
                                        const valueToCheck = obj.value;
                                        const referenceRange =
                                          obj.referenceRange;
                                        const isValueWithinRange =
                                          isOutsideRange(
                                            valueToCheck,
                                            referenceRange,
                                          );

                                        const spanStyle = {
                                          color: 'red',
                                          padding: '1px',
                                          fontWeight: 'bold',
                                        };

                                        return (
                                          <div key={idx}>
                                            {obj.name}:{' '}
                                            <span
                                              style={
                                                isValueWithinRange &&
                                                referenceRange
                                                  ? spanStyle
                                                  : undefined
                                              }
                                            >
                                              {obj.value ? obj.value : '-'}{' '}
                                            </span>
                                            {obj.unit}, Norma:{' '}
                                            {referenceRange
                                              ? referenceRange
                                              : '-'}
                                            ,
                                          </div>
                                        );
                                      })}

                                  {Array.isArray(results) &&
                                    results?.find(
                                      (item) => item.externalId === research.id,
                                    ).fileGuid && (
                                      <Styled.InfoDetailsRow>
                                        <a
                                          onClick={() => {
                                            const fileName =
                                              research.name +
                                              ' ' +
                                              moment(
                                                new Date(research.orderDate),
                                              ).format('YYYY-MM-DD');
                                            handleFileDownload(
                                              fileName,
                                              results?.find(
                                                (item) =>
                                                  item.externalId ===
                                                  research.id,
                                              ).fileType,
                                              results?.find(
                                                (item) =>
                                                  item.externalId ===
                                                  research.id,
                                              ).fileGuid,
                                            );
                                          }}
                                        >
                                          ATSISIŲSTI PDF
                                        </a>
                                      </Styled.InfoDetailsRow>
                                    )}
                                </div>
                              )}
                          </div>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              )}
            </div>
          );
        })}
      {Researches && Researches.length !== 0 && !loading && (
        <div style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}>
          {t('userAccount.visitHistoryTab.dateInfo')}
        </div>
      )}
      {Researches && Researches.length === 0 && !loading && (
        <>
          <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
          <div
            style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}
          >
            {t('userAccount.visitHistoryTab.dateInfo')}
          </div>
        </>
      )}
    </Styled.DataContainer>
  );
};
export default LabaratoryResearches;
