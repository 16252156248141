import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format as dateFnsFormat } from 'date-fns';
import { lt as locale } from 'date-fns/locale';
import Button from '../Button';
import { Money } from '../Money';
import Styled from './Card.styles';
import { useSettingsState } from '../../context/SettingsContext';
import { ReactComponent as TlkIcon } from '../../../assets/icons/tlk.svg';

const format = (date, formatStr = 'PP') =>
  dateFnsFormat(date, formatStr, { locale });

const Card = ({
  service,
  categoryId,
  serviceTypeId,
  backUrl,
  noRegistrationService,
  isComplex,
  complexPrices,
  selectedFilterDepartment,
  addToMultipleServicesCallback,
  isDisabled,
  visitPaymentType,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { getServiceImage, shortPhoneNumber } = useSettingsState();

  const isService = !service.specialistId;
  const link = `/order/${service.workplaceId}/${service.serviceId}`;
  const badge = service.cardTitle;
  const [isHalfMrt, setIsHalfMrt] = useState(
    service.cityTitle === 'Šiauliai' &&
      service.serviceTitle.split(' ').includes('1.5T') &&
      service.serviceTypeExternalId == process.env.REACT_APP_MRT_CATEGORY &&
      !(service.serviceTitle.substring(0, 9) === 'Viso kūno'),
  );

  const isUltrasoundServiceType =
    service.serviceTypeExternalId == process.env.REACT_APP_ULTRASOUNDEXTERNALID;

  const SGSpecialtyExternalId = process.env.REACT_APP_SG_SPECIALTY_EXTERNAL_ID;

  const isRegistrationDisplay = !(
    isUltrasoundServiceType && !service.upcomingVisit
  );

  const getSortedLanguages = () => {
    const languagesOrder = ['LT', 'EN', 'RU', 'DE', 'PL'];
    const customSort = (a, b) => {
      const indexA = languagesOrder.indexOf(a.toUpperCase());
      const indexB = languagesOrder.indexOf(b.toUpperCase());

      // If one of the languages is not in the predefined order, move it to the end
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    };

    return service.languages.sort(customSort);
  };

  const getUpcomingVisitPsdfFilter = () => {
    if(visitPaymentType == 1) return service.nearestPaidDate;
    if(visitPaymentType == 2) return service.nearestPsdfDate;
    return service.upcomingVisit;
  };

  return (
    <Styled.Card isComplex={isComplex}>
      {!isService ? (
        <>
          <Styled.ImageContainer>
            <Styled.Image
              style={{
                backgroundImage: getServiceImage(service),
              }}
            />
            {/* {!!service.languages?.length && (
              <Styled.ImageText>
                {service.languages.map((language, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {language}
                  </React.Fragment>
                ))}
              </Styled.ImageText>
            )} */}
          </Styled.ImageContainer>
        </>
      ) : service.serviceParts ? (
        <Styled.ServiceTitleHolder>
          <Styled.Title greyTheme={true}>{service.title}</Styled.Title>
        </Styled.ServiceTitleHolder>
      ) : (
        <Styled.ServiceTitleHolder>
          <Styled.Title greyTheme={true}>{service.serviceTitle}</Styled.Title>
        </Styled.ServiceTitleHolder>
      )}

      <Styled.Content
        isService={isService}
        isPsdf={service.psdf}
        isPsdfDate={service.nearestPsdfDate}
      >
        {service.serviceParts ? (
          <Styled.Badge isService={true} isUpcomingVisit={true}>
            {'Paslaugų paketai'}
          </Styled.Badge>
        ) : badge ? (
          <Styled.Badge
            isService={isService}
            isUpcomingVisit={service.upcomingVisit}
          >
            {badge}
          </Styled.Badge>
        ) : (
          <></>
        )}

        {!isService && (
          <Styled.Title greyTheme={true}>{service.specialistName}</Styled.Title>
        )}
        <Styled.Description greyTheme={true}>
          {!isService && service.serviceTitle}
        </Styled.Description>
        <Styled.Description greyTheme={true}>
          {!service.serviceParts &&
            service.departmentTitle +
              (service.isServiceRemote
                ? `, ${t('common.remoteService')}`
                : '')}{' '}
        </Styled.Description>
        <Styled.BottomText greyTheme={true}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {isComplex && (
              <>
                {complexPrices.length === 1 && (
                  <span style={{ width: 'auto' }}>
                    <Money amount={complexPrices[0].price} />
                    {complexPrices[0].originalPrice !== null && (
                      <span style={{ textDecoration: 'line-through' }}>
                        <Money amount={complexPrices[0].originalPrice} />
                      </span>
                    )}
                  </span>
                )}

                {complexPrices.length > 1 && (
                  <span>
                    {t('common.price')}:
                    {Math.min(...complexPrices.map((item) => item.price)) ===
                    Math.max(...complexPrices.map((item) => item.price)) ? (
                      <Money
                        amount={Math.min(
                          ...complexPrices.map((item) => item.price),
                        )}
                      ></Money>
                    ) : (
                      <>
                        <Money
                          amount={Math.min(
                            ...complexPrices.map((item) => item.price),
                          )}
                        />
                        -
                        <Money
                          amount={Math.max(
                            ...complexPrices.map((item) => item.price),
                          )}
                        />
                      </>
                    )}
                  </span>
                )}
              </>
            )}

            {!isHalfMrt && !isComplex && (
              <span style={{ marginRight: 'auto' }}>
                {t('common.price')}:
                {(service.priceWithDiscount !== service.price &&
                  service.price !== 0 && (
                    <>
                      <Money amount={service.priceWithDiscount} />
                      {/* <span style={{ textDecoration: 'line-through' }}>
                        <Money amount={service.price} />
                      </span> */}
                    </>
                  )) || <Money amount={service.priceWithDiscount} />}
              </span>
            )}

            {!!service.languages?.length && (
              <div style={{ marginLeft: 'auto' }}>
                Kalbos: {getSortedLanguages().join(' ')}
              </div>
            )}
          </div>
          {service.priceWithDiscount !== service.price &&
            service.price !== 0 && (
              <>
                {/* <Money amount={service.priceWithDiscount} /> */}
                <Styled.DiscountPriceTag
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  <Money amount={service.price} />
                </Styled.DiscountPriceTag>
              </>
            )}
          {!noRegistrationService && !isComplex && (
            <>
              {!isHalfMrt && !service.serviceParts && isRegistrationDisplay && (
                <span>
                  {t('common.nearestRegistration')}:{' '}
                  {getUpcomingVisitPsdfFilter()
                    ? format(new Date(getUpcomingVisitPsdfFilter()))
                    : t('common.noFreeVisits')}
                </span>
              )}
              {isHalfMrt &&
                service.psdf &&
                service.nearestPsdfDate &&
                !service.serviceParts &&
                visitPaymentType === 2 && (
                  <span>
                    {t('common.nearestRegistration')}:{' '}
                    {service.nearestPsdfDate
                      ? format(new Date(service.nearestPsdfDate))
                      : t('common.noFreeVisits')}
                  </span>
                )}
            </>
          )}

          {isComplex && (
            <span>Galioja: {service?.validationTimeInDays} d.</span>
          )}
        </Styled.BottomText>
        {!noRegistrationService && isRegistrationDisplay && (
          <Styled.Actions>
            {(!!addToMultipleServicesCallback && (
              <Button
                disabled={
                  isDisabled ||
                  (service.serviceParts ? false : !getUpcomingVisitPsdfFilter())
                }
                color="disabled"
                onClick={() => {
                  addToMultipleServicesCallback();
                }}
              >
                {'Pasirinkti'}
              </Button>
            )) || (
              <Button
                disabled={service.serviceParts ? false : !getUpcomingVisitPsdfFilter()}
                color="disabled"
                onClick={() => {
                  if (!service.serviceParts) {
                    history.push(link, {
                      categoryId: categoryId,
                      serviceTypeId: serviceTypeId,
                      visitPaymentType: visitPaymentType,
                      backUrl: backUrl,
                    });
                  } else {
                    history.push('/kompleksines-paslaugos/checkout', {
                      service: service,
                      prices: complexPrices,
                      selectedDepartment: selectedFilterDepartment,
                    });
                  }
                }}
              >
                {service.serviceParts ? 'Užsakyti' : 'Registruotis'}
              </Button>
            )}
          </Styled.Actions>
        )}

        {(noRegistrationService || !isRegistrationDisplay) && (
          <Styled.Description
            greyTheme={true}
            style={{ fontWeight: 'bold', marginTop: '10px', fontSize: '14px' }}
          >
            {isUltrasoundServiceType
              ? service.workplaceTypeId === 'Tyrimas'
                ? t('common.noFreeVisits')
                : `Tyrimas pagal poreikį gali būti atliekamas konsultacijos metu`
              : `Dėl datos ir laiko teirautis telefonu ${shortPhoneNumber}`}
          </Styled.Description>
        )}
      </Styled.Content>
      {!noRegistrationService && (
        <>
          {!isHalfMrt && service.psdf && service.nearestPsdfDate && (
            <Styled.TlkService hasVisits={service.upcomingVisit}>
              {service.specialtyExternalId == SGSpecialtyExternalId
                ? 'Prisirašiusiems apmokama iš PSDF lėšų'
                : 'Galima registruotis ir su TLK siuntimu'}
            </Styled.TlkService>
          )}
          {isHalfMrt && (
            <Styled.TlkService hasVisits={service.upcomingVisit}>
              Galima registruotis tik su TLK siuntimu
            </Styled.TlkService>
          )}
        </>
      )}
    </Styled.Card>
  );
};

const List = ({ children, noTopMargin }) => {
  return <Styled.List noTopMargin={noTopMargin}>{children}</Styled.List>;
};

Card.List = List;

export default Card;
