import styled from 'styled-components/macro';
import { mediaDown } from '../../../../../../utils/styles/media';
import { mediaUp } from '../../../../../../utils/styles/media';

const BackArrow = styled.div`
  display: flex;
  margin-bottom: 25px;
  padding-top: 50px;
  svg {
    cursor: pointer;
    min-height: 30px;
    min-width: 30px;
    transform: rotate(180deg);
  }
  span {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  ${mediaDown('md')`
  padding-top: 30px;
`}
`;

const InfoTitle = styled.div`
  padding: 30px 0 30px 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  a {
    color: black;
  }
  span {
    cursor: pointer;
    text-decoration: underline;
  }

  ${({ noTopBorder }) =>
    noTopBorder &&
    `
  border-top:0px !important ;
`}
`;

const DataContainer = styled.div`
  margin: 0 25px 0 350px;
  ${InfoTitle}:first-child {
    border-bottom: 1px solid black;
    border-top: 0px solid black;
  }
  ${InfoTitle}:not(:first-child):not(:last-child) {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  ${mediaDown('md')`
    margin: 0 25px 0 100px;
    display: flex;
    flex-direction: column;
  `}
`;

const EditForm = styled.div`
  width: 650px;
  ${mediaDown('md')`
  width: 100%;
`}
  button {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap:20px;
  
`;

const Styled = {
  EditForm,
  InfoTitle,
  DataContainer,
  BackArrow,
  ButtonContainer,
};

export default Styled;
