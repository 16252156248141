import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useUserState } from '../context/UserContext/UserContext';
import Page from '../../components/shared/Page';

const PrivateRoute = (props) => {
  const isTestEnv = process.env.REACT_APP_ISTESTENV === 'true';
  const userState = useUserState();
  function isUserLoggedIn() {
    if (!userState.isLoggedIn) {
      return false;
    }
    return true;
  }

  function heightSize() {
    if (
      userState.role.toLowerCase() === 'affidea' &&
      (isTestEnv || userState.subscriptionInfo?.isSubscriptionInstitutionLocal)
    ) {
      return '1150px';
    } else if (
      userState.role.toLowerCase() !== 'affidea' &&
      (isTestEnv || userState.subscriptionInfo?.isSubscriptionInstitutionLocal)
    ) {
      return '900px';
    } else if (
      userState.role.toLowerCase() !== 'affidea' &&
      !isTestEnv &&
      !userState.subscriptionInfo?.isSubscriptionInstitutionLocal
    ) {
      return '900px';
    }
  }

  const condition = isUserLoggedIn();
  return condition ? (
    <Route
      path={props.path}
      exact={props.exact}
      render={() => (
        <Page loading={false} styles={{ minHeight: heightSize() }}>
          {props.components &&
            props.components.length > 0 &&
            props.components.map((ComponentToRender, index) => {
              return <ComponentToRender key={index} />;
            })}
        </Page>
      )}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/selfservice',
        state: { redirectedFromUrl: props.location },
      }}
    />
  );
};
export default PrivateRoute;
