import { useCallback } from 'react';
import useFetch from 'use-http';
import jwtDecode from 'jwt-decode';
import SessionStorage from '../constants/sessionStorage';

function useToken() {
  const { get, post } = useFetch(process.env.REACT_APP_API_BASE, {
    cachePolicy: 'no-cache',
  });

  // Get system token.
  const getSystemToken = useCallback(async () => {
    return await get(`/Login/clientToken/${process.env.REACT_APP_CLIENT_ID}`);
  }, [get]);

  // Get refreshed tokens.
  const getRefreshedTokens = useCallback(
    async (token, refreshToken) => {
      try {
        const res = await post('/UserAccount/RefreshToken', {
          token,
          refreshToken,
        });
        return { token: res.token, refreshToken: res.refreshToken };
      } catch (error) {
        // Just in case get default token.
        console.error('>>> getRefreshedToken error', error);
        const res = await getSystemToken();
        return { token: res.token, refreshToken: null };
      }
    },
    [post, getSystemToken],
  );

  // Get or refresh token.
  const handleToken = useCallback(async () => {
    let content = SessionStorage.getContent();
    let token = content.token;
    let refreshToken = content.refreshToken;

    if (!token) {
      token = await getSystemToken();
      refreshToken = null;
    } else {
      if (token && refreshToken) {
        const expired =
          refreshToken && new Date() > new Date(jwtDecode(token).exp * 1000);

        if (expired) {
          const refreshedTokens = await getRefreshedTokens(token, refreshToken);
          token = refreshedTokens.token;
          refreshToken = refreshedTokens.refreshToken;
        }
      }
    }

    SessionStorage.setContent({
      ...content,
      ...{ token: token, refreshToken: refreshToken },
    });

    return token;
  }, [getRefreshedTokens, getSystemToken]);

  return {
    handleToken,
  };
}

export default useToken;
