import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Hero = styled.div`
  position: relative;
  padding: 180px 0 60px;
  background-size: cover;
  background-position: center center;
  z-index: 1;

  ${mediaDown('md')`
    padding: 40px 0 30px;
  `}

  mark {
    color: ${(props) => props.primaryColor};
    background: transparent;
  }
`;

const Title = styled.h1`
  font-size: 74px;
  font-weight: 900;
  line-height: 76px;
  margin: 0 0 24px;

  ${mediaDown('md')`
      font-size: 29px;
      line-height: 33px;
  `}
`;

const Description = styled.div`
  margin: 24px 0 28px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;

  ${mediaDown('md')`
      font-size: 12px;
      line-height: 17px;
  `}
`;

const ContentContainer = styled.div`
  max-width: 825px;
`;


const Styled = {
  Hero,
  Title,
  Description,
  ContentContainer
};

export default Styled;
