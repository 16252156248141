import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${mediaDown('md')`
    min-width: 180px;
  `}
`;
const Buttons = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 25px;
  width: 100%;
  ${mediaDown('md')`
  width: 100%;
  `}
`;
const Title = styled.h1`
  margin-top: 55px;
  text-align: center;
  font-size: 48px;
  ${mediaDown('md')`
    font-size:24px;
  `}
`;

const AdditionalInfo = styled.div`
  margin-top: 25px;
  display: flex;
  text-align: center;
  justify-content: center;
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  width: 65%;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
  ${mediaDown('md')`
    
  `}
  span {
    margin: 20px 0 20px 0;
  }
`;

const SubTitleInForm = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
  ${mediaDown('md')`
    
  `}
  span {
    margin: 20px 0 20px 0;
  }
`;

const FormGroup = styled.div`
  background-color: red;
`;
const Styled = {
  Buttons,
  SubTitle,
  Form,
  Title,
  AdditionalInfo,
  FormGroup,
  SubTitleInForm,
};

export default Styled;
