import React, { useCallback, useState } from 'react';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/file.svg';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Form from '../Form';
import Loader from '../Loader';
import Styled from './FileUpload.styles';
import Resizer from "react-image-file-resizer";

const MAX_FILES = 10;

const FileUpload = ({ label, onChange, request, value, error, thumbs, isPopup, isGrey}) => {
  const { t } = useTranslation();

  const [tooManyFiles, setTooManyFilesError] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const formData = new FormData();     
      if (
        acceptedFiles.length > 0 &&
        acceptedFiles.length <= MAX_FILES &&
        acceptedFiles.length + value.length <= MAX_FILES
      ) {
        if(isPopup){
          for(const file of acceptedFiles){        
            if(file.size >= 500000){
              const resizedFile = await resizeFile(file);            
              formData.append('files', resizedFile);      
            } else {
              formData.append('files', file)         
            }           
          }
        } else {
          acceptedFiles.forEach((file) => {
            formData.append('files', file);
          }); 
        }        
        request.post(formData).then((files) => {      
          onChange([...value, ...files]);        
        });
      } else {
        setTooManyFilesError(true);
      }
    },
   [onChange, request, value]  
  );

  function remove(id) {
    request.delete(`/${id}`);
    setTooManyFilesError(false);

    onChange(value.filter((v) => v.fileUid !== id));
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    multiple: true,
    onDrop,
    accept: ['.png', '.jpg', '.jpeg', '.pdf'],
    maxSize: 10 * 1000000,
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });


  return (
    <Styled.FileUpload error={fileRejections.length > 0 || error} >
      {label && <Form.Label>{label}</Form.Label>}
      <Styled.Dropzone
        multiple={true}
        {...getRootProps()}
        error={fileRejections.length > 0 || error}
        focused={isDragActive}
      >
        <input {...getInputProps()} />

        {request.loading ? (
          <Loader size={20} />
        ) : (
          <Styled.Message isGrey={isGrey}>
            Tempti failą čia arba <strong>pasirinkti</strong>.
          </Styled.Message>
        )}
      </Styled.Dropzone>
      {fileRejections.map((file) => {
        return file.errors.map((error) => (
          <Styled.ErrorItem>{t(`fileUpload.${error.code}`)}</Styled.ErrorItem>
        ));
      })}
      {error && <Styled.ErrorItem>{error}</Styled.ErrorItem>}
      {tooManyFiles && (
        <Styled.ErrorItem>{t(`fileUpload.too-may-files`)}</Styled.ErrorItem>
      )}
      {!thumbs && <Styled.Thumbs>
        {value.map((file, i) => (
          <div key={i}>
            <Styled.Thumb>
              <Styled.FileIcon>
                <FileIcon />
              </Styled.FileIcon>
              <Styled.FileName>{file.fileName}</Styled.FileName>
              <Styled.RemoveButton onClick={() => remove(file.fileUid)}>
                <CrossIcon />
              </Styled.RemoveButton>
            </Styled.Thumb>
          </div>
        ))}
      </Styled.Thumbs>}
    </Styled.FileUpload>
  );
};

export default FileUpload;
