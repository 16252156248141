import Styled from '../../pages/SelfServicePage/UserAccountPage/UserAccountPage.styles';
import {
  useUserState,
  useUserDispatch,
} from '../../context/UserContext/UserContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { ReactComponent as RightArrow } from '../../../assets/icons/arrow.svg';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import { ReactComponent as DropDownArrowIcon } from '../../../assets/icons/user-settings-arrow-down.svg';
import personalCodeValidation from '../../hooks/personalCodeValidation';

const UserSidebar = () => {
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const { t } = useTranslation();
  const isTestEnv = process.env.REACT_APP_ISTESTENV === 'true';
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const [accountDropdown, setAccountDropdown] = useState();
  const [isChildData, setIsChildData] = useState(true);
  const [childDataOpen, setChildDataOpen] = useState(false);
  const [children, setChildren] = useState([]);
  const showLocalRegistrationTab =
    process.env.REACT_HIDE_REGISTRATION_PROD === 'true';
  const history = useHistory();
  const handleLogout = async () => {
    sessionDispatch({ type: 'LOGOUT' });
  };

  const [maxAgeFromPersonalCode] = personalCodeValidation();

  const closeMenu = () => {
    window.scrollTo(0, 0);
  };

  const userSettingsMenu = () => {
    setUserSettingsOpen(false);
  };

  useEffect(() => {
    let children = userState?.contacts?.filter(
      (ch) =>
        ch.relationship === 'Dukra' ||
        ch.relationship === 'Sunus' ||
        ch.relationship === 'Isunis' ||
        ch.relationship === 'Idukra',
    );
    setChildren(
      children?.map((ch) => ({ name: ch.name, surname: ch.surname })),
    );
  }, []);
  useEffect(() => {
    const kids = [];
    if (userState?.contacts?.length > 0) {
      userState.contacts.forEach((k) => {
        if (k.personalCode) {
          var testRes = maxAgeFromPersonalCode(k.personalCode, 18);
          if (testRes) {
            kids.push(k);
          }
        } else {
          kids.push(k);
        }
      });
    }
    let children = kids.filter(
      (ch) =>
        ch.relationship === 'Dukra' ||
        ch.relationship === 'Sunus' ||
        ch.relationship === 'Isunis' ||
        ch.relationship === 'Idukra',
    );

    let childrens = children?.map((ch) => ({
      name: ch.name,
      surname: ch.surname,
      espbiEsi: ch.espbiEsi,
    }));

    if (userState.espbiEsi) {
      childrens.unshift({
        name: userState.firstName,
        surname: userState.lastName,
        espbiEsi: null,
      });
    }
    setChildren(childrens);
  }, [
    userState.contacts,
    userState.espbiEsi,
    userState.firstName,
    userState.lastName,
  ]);

  const onChildSelect = (ch) => {
    if (ch.espbiEsi) {
      sessionDispatch({
        type: 'SET_CHILD',
        data: {
          espbiEsi: ch.espbiEsi,
        },
      });
    } else {
      sessionDispatch({
        type: 'SET_CHILD',
        data: {
          espbiEsi: null,
        },
      });
    }
  };

  useEffect(() => {
    document.body.style.zoom = '100%';
  }, []);

  function renderSubMenu() {
    return (
      <div style={{ color: '#52585F' }}>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/E025');
            }}
          >
            Gydytojų konsultacijos
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/prescriptions');
            }}
          >
            Receptai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/referrals');
            }}
          >
            Siuntimai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/instrumental-researches');
            }}
          >
            Instrumentiniai tyrimai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/vaccinations');
            }}
          >
            Skiepai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/labaratory-researches');
            }}
          >
            Laboratoriniai tyrimai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/complex-services');
            }}
          >
            Abonementai ir paslaugų paketai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/pathology-researches');
            }}
          >
            Patologiniai tyrimai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/other-visits');
            }}
          >
            Kitos paslaugos
          </span>
        </Styled.SideBarSubMeniu>
      </div>
    );
  }

  function renderAdminSubMenu() {
    return (
      <div style={{ color: '#52585F' }}>
        {(userState.role.toLowerCase() === 'popupadmin' ||
          userState.role.toLowerCase() === 'affidea') && (
          <>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/popup');
                }}
              >
                Popup lango parametrai
              </span>
            </Styled.SideBarSubMeniu>

            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/doctorInformation');
                }}
              >
                Gydytojų informacija
              </span>
            </Styled.SideBarSubMeniu>

            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/serviceInformation');
                }}
              >
                Paslaugų informacija
              </span>
            </Styled.SideBarSubMeniu>

            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/admin/restrictions');
                }}
              >
                Vaikų gydytojų nustatymai
              </span>
            </Styled.SideBarSubMeniu>
          </>
        )}

        {userState.role.toLowerCase() === 'affidea' && (
          <>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/patientInfo');
                }}
              >
                "Tapk pacientu" informacija
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/logs');
                }}
              >
                AMIS kreipinių žurnalas
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/sync');
                }}
              >
                AMIS sinchronizacija
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/syncLogs');
                }}
              >
                Įvykių žurnalas
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/consentLogs');
                }}
              >
                Sutikimų pakeitimai
              </span>
            </Styled.SideBarSubMeniu>

            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/amis/requests');
                }}
              >
                Amis užklausų klaidos
              </span>
            </Styled.SideBarSubMeniu>
          </>
        )}
      </div>
    );
  }

  function renderMobileSideBar() {
    return (
      <div style={{ color: '#52585F' }}>
        <Styled.MobileViewTitle
          onClick={() => {
            history.push('/account');
            closeMenu();
          }}
        >
          {children.length === 0 ? (
            <div>
              <span>{t('userAccount.sidebar.myAccountSingle')}</span>
              <h3>
                {userState.firstName} {userState.lastName}
              </h3>
            </div>
          ) : (
            <div>
              <span
                style={{
                  paddingLeft: '30px',
                  display: 'inline-block',
                  marginBottom: '5px',
                  marginTop: '10px',
                }}
              >
                {t('userAccount.sidebar.myAccountMultiple')}
              </span>
              <ul>
                <li style={{ display: 'flex', justifyContent: 'start' }}>
                  {userState?.contacts?.length > 0 ? (
                    <Styled.SelfService
                      isMobile={true}
                      onClick={() => setChildDataOpen(!childDataOpen)}
                    >
                      <Styled.ButtonContainer>
                        <Button
                          size="medium"
                          style={{
                            fontSize: '16px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                          }}
                          isOpened={childDataOpen}
                          isWide={!childDataOpen}
                        >
                          {userState.espbiEsi ? (
                            <span>
                              {
                                userState.contacts?.find(
                                  (x) => x.espbiEsi === userState.espbiEsi,
                                ).name
                              }{' '}
                              {
                                userState.contacts?.find(
                                  (x) => x.espbiEsi === userState.espbiEsi,
                                ).surname
                              }
                            </span>
                          ) : (
                            <span>
                              {' '}
                              {userState.firstName} {userState.lastName}
                            </span>
                          )}

                          {!childDataOpen && (
                            <Styled.SelfServiceIcon isMobile={true}>
                              <DropDownArrowIcon
                                className={false ? 'upsideDown' : {}}
                              />
                            </Styled.SelfServiceIcon>
                          )}
                        </Button>
                      </Styled.ButtonContainer>
                      {childDataOpen && (
                        <Styled.UserSettingsMenu onClick={userSettingsMenu}>
                          {children?.map((ch, index) => {
                            return (
                              <Styled.Option
                                key={index}
                                isFirst={index === 0}
                                onClick={() => onChildSelect(ch)}
                                selectedChild={
                                  userState.espbiEsi !== null &&
                                  userState.espbiEsi !== undefined &&
                                  userState.espbiEsi === ch.espbiEsi
                                }
                              >
                                <p>
                                  {ch.name} {ch.surname}
                                </p>
                              </Styled.Option>
                            );
                          })}
                        </Styled.UserSettingsMenu>
                      )}
                      {childDataOpen && (
                        <Styled.Backdrop
                          onClick={() => setChildDataOpen(false)}
                        ></Styled.Backdrop>
                      )}
                    </Styled.SelfService>
                  ) : (
                    <Styled.SelfService>
                      <Styled.ButtonContainer>
                        <Button
                          size="medium"
                          style={{
                            fontSize: '16px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                          }}
                        >
                          {userState.firstName} {userState.lastName}
                          <Styled.SelfServiceIcon isMobile={true}>
                            <DropDownArrowIcon
                              className={false ? 'upsideDown' : {}}
                            />
                          </Styled.SelfServiceIcon>
                        </Button>
                      </Styled.ButtonContainer>
                    </Styled.SelfService>
                  )}
                </li>
              </ul>
            </div>
          )}
        </Styled.MobileViewTitle>

        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              history.push('/account/visits');
              closeMenu();
            }}
          >
            {t('userAccount.sidebar.visits')}
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        {renderSubMenu()}
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/registrations');
            }}
          >
            Suplanuotos registracijos
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/failed-registrations');
            }}
          >
            Neįvykę vizitai
            <RightArrow />
          </span>
        </Styled.SidebarOptions>

        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/user-coupons');
            }}
          >
            Kuponai
            <RightArrow />
          </span>
        </Styled.SidebarOptions>

        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/');
            }}
          >
            Registracija
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        {userState.role !== null &&
          userState.role !== undefined &&
          (userState.role.toLowerCase() === 'affidea' ||
            userState.role.toLowerCase() === 'popupadmin') && (
            <Styled.SidebarOptions>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  if (userState.role.toLowerCase() === 'affidea') {
                    history.push('/logs');
                  }
                }}
              >
                Administravimas
                <RightArrow />
              </span>
              {renderAdminSubMenu()}
            </Styled.SidebarOptions>
          )}
        <Link
          to={'/'}
          className={'logout'}
          onClick={() => handleLogout()}
          style={{ color: '#52585F' }}
        >
          {t('userAccount.sidebar.logout')}
        </Link>
      </div>
    );
  }

  return (
    <div style={{ color: '#52585F' }}>
      <Styled.Sidebar>
        <Styled.SidebarUserInfo
          onClick={() => {
            history.push('/account');
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {children?.length === 0 ? (
              <React.Fragment>
                <span
                  style={{
                    paddingLeft: '30px',
                    display: 'inline-block',
                    marginTop: '25px',
                  }}
                >
                  {t('userAccount.sidebar.myAccountSingle')}
                </span>
                <h3
                  style={{
                    paddingLeft: '30px',
                    display: 'inline-block',
                  }}
                >
                  {userState.firstName} {userState.lastName}
                </h3>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <span
                  style={{
                    paddingLeft: '30px',
                    display: 'inline-block',
                    marginBottom: '5px',
                    marginTop: '10px',
                  }}
                >
                  {t('userAccount.sidebar.myAccountMultiple')}
                </span>
                <ul>
                  <li style={{ display: 'flex', justifyContent: 'start' }}>
                    {children?.length > 0 ? (
                      <Styled.SelfService
                        isMobile={true}
                        onClick={() => setChildDataOpen(!childDataOpen)}
                      >
                        <Styled.ButtonContainer>
                          <Button
                            size="medium"
                            style={{
                              fontSize: '16px',
                              paddingTop: '0px',
                              paddingBottom: '0px',
                              paddingLeft: '0px',
                              paddingRight: '0px',
                            }}
                            isOpened={childDataOpen}
                            isWide={!childDataOpen}
                          >
                            {userState.espbiEsi ? (
                              <span>
                                {
                                  userState.contacts?.find(
                                    (x) => x.espbiEsi === userState.espbiEsi,
                                  ).name
                                }{' '}
                                {
                                  userState.contacts?.find(
                                    (x) => x.espbiEsi === userState.espbiEsi,
                                  ).surname
                                }
                              </span>
                            ) : (
                              <span>
                                {' '}
                                {userState.firstName} {userState.lastName}
                              </span>
                            )}

                            {!childDataOpen && (
                              <Styled.SelfServiceIcon isMobile={true}>
                                <DropDownArrowIcon
                                  className={false ? 'upsideDown' : {}}
                                />
                              </Styled.SelfServiceIcon>
                            )}
                          </Button>
                        </Styled.ButtonContainer>
                        {childDataOpen && (
                          <Styled.UserSettingsMenu onClick={userSettingsMenu}>
                            {children?.map((ch, index) => {
                              return (
                                <Styled.Option
                                  key={index}
                                  isFirst={index === 0}
                                  onClick={() => onChildSelect(ch)}
                                  selectedChild={
                                    userState.espbiEsi !== null &&
                                    userState.espbiEsi !== undefined &&
                                    userState.espbiEsi === ch.espbiEsi
                                  }
                                >
                                  <p>
                                    {ch.name} {ch.surname}
                                  </p>
                                </Styled.Option>
                              );
                            })}
                          </Styled.UserSettingsMenu>
                        )}
                        {childDataOpen && (
                          <Styled.Backdrop
                            onClick={() => setChildDataOpen(false)}
                          ></Styled.Backdrop>
                        )}
                      </Styled.SelfService>
                    ) : (
                      <Styled.SelfService>
                        <Styled.ButtonContainer>
                          <Button
                            size="medium"
                            style={{
                              fontSize: '16px',
                              paddingTop: '0px',
                              paddingBottom: '0px',
                            }}
                          >
                            {userState.firstName} {userState.lastName}
                            <Styled.SelfServiceIcon isMobile={true}>
                              <DropDownArrowIcon
                                className={false ? 'upsideDown' : {}}
                              />
                            </Styled.SelfServiceIcon>
                          </Button>
                        </Styled.ButtonContainer>
                      </Styled.SelfService>
                    )}
                  </li>
                </ul>
              </React.Fragment>
            )}
          </div>
        </Styled.SidebarUserInfo>
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              history.push('/account/visits');
            }}
          >
            {t('userAccount.sidebar.visits')}
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        {renderSubMenu()}
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              history.push('/account/registrations');
            }}
          >
            Suplanuotos registracijos
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/failed-registrations');
            }}
          >
            Neįvykę vizitai
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/user-coupons');
            }}
          >
            Kuponai
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/');
            }}
          >
            Registracija
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        <Styled.SidebarOptions>
          <span
            className={'option'}
            style={{ height: '45px' }}
            onClick={() => {
              closeMenu();
              history.push('/registration/local');
            }}
          >
            Susisiekti su budinčiu gydytoju
            <RightArrow />
          </span>

          <Styled.SideBarSubMeniu>
            <span
              className={'option'}
              onClick={() => {
                closeMenu();
                history.push('/registration/local');
              }}
            >
              Kreipimosi forma
            </span>
          </Styled.SideBarSubMeniu>

          <Styled.SideBarSubMeniu>
            <span
              className={'option'}
              onClick={() => {
                closeMenu();
                history.push('/registration/local/list');
              }}
            >
              Kreipimųsi istorija
            </span>
          </Styled.SideBarSubMeniu>
        </Styled.SidebarOptions>

        {userState.role !== null &&
          userState.role !== undefined &&
          (userState.role.toLowerCase() === 'affidea' ||
            userState.role.toLowerCase() === 'popupadmin') && (
            <Styled.SidebarOptions>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  if (userState.role.toLowerCase() === 'affidea') {
                    history.push('/logs');
                  }
                }}
              >
                Administravimas
                <RightArrow />
              </span>
              {renderAdminSubMenu()}
            </Styled.SidebarOptions>
          )}

        <Styled.SidebarLogoutUser>
          <Link
            to={'/'}
            className="logout"
            onClick={() => handleLogout()}
            style={{ color: '#52585F' }}
          >
            {t('userAccount.sidebar.logout')}
          </Link>
        </Styled.SidebarLogoutUser>
      </Styled.Sidebar>
    </div>
  );
};
export default UserSidebar;
