import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import lt from './lt.json';

i18next.use(initReactI18next).init({
  lng: localStorage.getItem('language') || 'lt',
  resources: {
    en,
    lt,
  },
  interpolation: { escapeValue: false },
});

i18next.on('languageChanged', (language) => {
  localStorage.setItem('language', language);
});

export default i18next;
