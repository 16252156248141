import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const UlElement = styled.ul`
  position: absolute;
  z-index: 999;
  background-color: white;
  margintop: 5px;
  padding: 5px;
  cursor: pointer;
`;

const Styled = {
  UlElement,
};

export default Styled;
