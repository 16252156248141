import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Form = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  ${mediaDown('md')`
    min-width: 200px;
  `}
  color: #52585F;
`;
const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: inherit;
  color: #52585F;
`;
const Title = styled.h1`
  margin-top: 55px;
  text-align: center;
  ${mediaDown('md')`
    font-size:24px;
  `}
`;
const Information = styled.span`
  text-align: center;
`;
const ControlCode = styled.span`
  text-align: center;
`;
const Error = styled.span`
  text-align: center;
  display: inline-block;
  background-color: #f35b69;
  font-size: 16px;
  color: #fff;
  padding: 4px;
  margin-top: 14px;
  border-radius: 0 0 4px 4px;
  font-weight: normal;
`;

const Styled = {
  Form,
  Title,
  Buttons,
  Information,
  ControlCode,
  Error,
};

export default Styled;
