/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import moment from 'moment';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
const InstrumentalResearchesTab = () => {
  const history = useHistory();
  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');

  const research = history.location.state?.data;

  const handleFileDownload = async (filename, fileType, fileGuid) => {
    await getFile(
      `?fileType=${fileType}&fileGuid=${fileGuid}&category=${'InstrumentalResearch'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${filename}.pdf`;
      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>Instrumentiniai tyrimai</Styled.VisitTitle>
      </Styled.BackArrow>
      <Styled.InfoDetailsRow>
        <span>Data:</span>
        <div>
          {(new Date(Date.parse(research.orderDate)).getUTCHours() === 0 && (
            <Moment utc={true} className="time" format="YYYY-MM-DD">
              {Date.parse(research.orderDate)}
            </Moment>
          )) || (
            <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
              {Date.parse(research.orderDate)}
            </Moment>
          )}
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Pavadinimas:</span>
        <div>{research.name}</div>
      </Styled.InfoDetailsRow>
      {research.equipment && (
        <Styled.InfoDetailsRow>
          <span>Įranga:</span>
          <div>{research.equipment}</div>
        </Styled.InfoDetailsRow>
      )}
      {research.statusDisplay && (
        <Styled.InfoDetailsRow>
          <span>Būsena:</span>
          <div>{research.statusDisplay}</div>
        </Styled.InfoDetailsRow>
      )}

      {research.statusDisplay !== 'Užbaigtas' &&
        research.statusDisplay !== 'Neatliktas' && (
          <Styled.InfoDetailsRow>
            <span>LAUKIAMA REZULTATŲ</span>
          </Styled.InfoDetailsRow>
        )}

      {research.contrast && (
        <div>
          <Styled.List style={{ marginTop: '10px' }}>
            <span>Instrumentinio tyrimo kontrastas:</span>
          </Styled.List>
          {(research.contrast && (
            <div>
              <Styled.ListItemRow>
                <span>Pavadinimas:</span>
                <div>{research.contrast.name}</div>
              </Styled.ListItemRow>
              <Styled.ListItemRow>
                <span>Tipas:</span>
                <div>{research.contrast.type}</div>
              </Styled.ListItemRow>
              <Styled.ListItemRow>
                <span>Serijinis numeris:</span>
                <div>{research.contrast.serialNumber}</div>
              </Styled.ListItemRow>

              <Styled.ListItemRow>
                {research.contrast.quantity && (
                  <>
                    <span>Kiekis:</span>
                    <div>{research.contrast.value}</div>
                  </>
                )}
              </Styled.ListItemRow>
              <Styled.ListItemRow>
                {research.contrast.unitOfMeasurement && (
                  <>
                    <span>Matavimo vienetas:</span>

                    <div>{research.contrast.unit}</div>
                  </>
                )}
              </Styled.ListItemRow>
            </div>
          )) || (
            <Styled.ListItemRow>
              <div>Netaikytas</div>
            </Styled.ListItemRow>
          )}
        </div>
      )}

      {research.observations?.length > 0 &&
        research.observations.map((result, index) => {
          return (
            <div key={index}>
              <Styled.ServiceTitle style={{ marginTop: '25px' }}>
                Instrumentinių tyrimu vertinimai:
              </Styled.ServiceTitle>
              {result.date && (
                <Styled.InfoDetailsRow>
                  <span>Data:</span>
                  {(new Date(Date.parse(result.date)).getUTCHours() === 0 && (
                    <Moment utc={true} className="time" format="YYYY-MM-DD">
                      {Date.parse(result.date)}
                    </Moment>
                  )) || (
                    <Moment
                      utc={true}
                      className="time"
                      format="YYYY-MM-DD HH:mm"
                    >
                      {Date.parse(result.date)}
                    </Moment>
                  )}{' '}
                </Styled.InfoDetailsRow>
              )}

              <Styled.InfoDetailsRow>
                <span>Dokumento numeris:</span>
                <div>{result.documentNumber}</div>
              </Styled.InfoDetailsRow>
              {result.radiologist?.name && (
                <Styled.InfoDetailsRow>
                  <span>Radiologo vardas:</span>
                  <div>{result.radiologist?.name}</div>
                </Styled.InfoDetailsRow>
              )}
              {result.radiologist?.surname && (
                <Styled.InfoDetailsRow>
                  <span>Radiologo pavardė:</span>
                  <div>{result.radiologist?.surname}</div>
                </Styled.InfoDetailsRow>
              )}
              {result.radiologyTechnologist?.name && (
                <Styled.InfoDetailsRow>
                  <span>Radiologo tecchnologo vardas:</span>
                  <div>{result.radiologyTechnologist?.name}</div>
                </Styled.InfoDetailsRow>
              )}
              {result.radiologyTechnologist?.surname && (
                <Styled.InfoDetailsRow>
                  <span>Radiologo tecchnologo pavardė:</span>
                  <div>{result.radiologyTechnologist?.surname}</div>
                </Styled.InfoDetailsRow>
              )}

              <Styled.InfoDetailsRow>
                <span>Išvados:</span>
                <div>{result.value}</div>
              </Styled.InfoDetailsRow>
              <Styled.InfoDetailsRow>
                <span>Aprašymas:</span>
                <div>{result.description}</div>
              </Styled.InfoDetailsRow>
              {result.file && result.file.guid && (
                <Styled.InfoDetailsRow>
                  <a
                    onClick={() => {
                      const fileName =
                        'Instrumentinis tyrimas ' +
                        moment(new Date(result.date)).format('YYYY-MM-DD');
                      handleFileDownload(
                        fileName,
                        result.file.type,
                        result.file.guid,
                      );
                    }}
                  >
                    ATSISIŲSTI PDF
                  </a>
                </Styled.InfoDetailsRow>
              )}
            </div>
          );
        })}
    </Styled.DataContainer>
  );
};
export default InstrumentalResearchesTab;
