import styled, { css } from 'styled-components/macro';
import StyledContainer from '../../../shared/Container/Container.styles';
import { mediaDown } from '../../../../utils/styles/media';

const Categories = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  flex-direction: row;
  padding-bottom: 10px;
  ${mediaDown('sm')`
    margin: 0 0 54px;
    grid-template-columns: 1fr;
    padding: 0 10px;
    grid-gap:0px;
  `}
`;

const Title = styled.div`
  color: #000;
  font-size: 53px;
  font-weight: 900;
  line-height: 50px;
  margin-bottom: 75px;
  padding-top: 20px;

  ${mediaDown('md')`
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 35px;
    padding-top: 0;
  `}
`;

const ItemTitle = styled.div`
  color: #fff;
  font-size: 28px;
  font-weight: 800;
  padding-right: 8px;
  padding-left: 25px;
  background-color: ${(props) => props.color};
  border-radius: 6px 6px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${mediaDown('md')`
  font-size: 26px;
  line-height: 30px;
  justify-content:center;
  padding:0 25px;
  `}

  ${mediaDown('sm')`
  font-size: 24px;
  padding:10px;
  padding-left:55px;
  `}

  ${mediaDown('xxs')`
  padding:20px;
  `}
`;

const Container = styled(StyledContainer.Container)`
  display: flex;
  flex-direction: column;

  ${mediaDown('md')`
    flex-direction: column;
  `}
`;

const ItemImage = styled.div`
  min-height: 278px;
  width: 100%;
  border-radius: 15px;
  position: relative;
  top: -20%;

  ${mediaDown('sm')`
     display: none;
  `}
`;

const ItemText = styled.div`
  width: 100%;
  flex-grow: 1;

  ${mediaDown('md')`
    width: 100%;
    padding-right: 0;
  `}
`;

const invertCss = css`
  &:before {
    border-radius: 20px 0 0 20px;
    left: auto;
    right: 0;

    ${mediaDown('md')`
      border-radius: 12px 0 0 12px;
      right: auto;
      left: 0;
    `}
  }

  ${ItemImage} {
    order: 1;
  }
  ${ItemText} {
    order: 2;
    padding-right: 0;
    padding-left: 65px;

    ${mediaDown('md')`
      padding-left: 0;
    `}
  }
`;

const Item = styled.div`
  position: relative;
  padding: 25px 0 0;
  display: grid;
  grid-template-rows: 0.5fr 3fr 2.4fr;
  flex-direction: column;

  @media (max-width: 1032px) {
    grid-template-rows: 0.8fr 3fr 2.4fr;
  }
  ${mediaDown('md')`
    padding: 0;
    grid-template-rows: 0.30fr 1.35fr 1.1fr;
  `}
  ${mediaDown('sm')`
    padding: 0;
    grid-template-rows: 0.75fr max-content;    
  `}   
  

  ${(props) => props.invert && invertCss};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  margin-bottom: 75px;
  background-color: #f1f5f7;
  border-radius: 0 0 6px 6px;
  padding: 25px;
  padding-top: 15px;

  ul {
    list-style-type: none;
    padding: 0;

    ${mediaDown('md')` 
   margin-bottom:1.5em;
   
  `}

    li {
      font-size: 16px;

      letter-spacing: 0;
      line-height: 22px;
      margin-bottom: 16px;

      @media (max-width: 979px) {
        font-size: 16px;
        line-height: 18px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #000;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      svg {
        position: relative;
        display: inline-block;
        transform: rotate(-90deg) translate(-1px, 0px);
        margin-left: 8px;

        ${mediaDown('md')`
        height: 14px;
        width: auto;
      `}

        path {
          fill: #52585f;
        }
      }
    }
  }
  ${mediaDown('md')`
margin-bottom:75px;
padding-bottom:0;
`}

  ${mediaDown('sm')`
padding-left:10px;
padding-bottom:0;
padding-right:10px;
padding-top:10px;
margin-bottom:15px;
`}



${mediaDown('xxs')`
padding:20px;
padding-top:0;
`}
`;

const LoaderBox = styled.div`
  position: relative;
  width: 100%;
  height: 600px;
`;

const Styled = {
  Categories,
  Title,
  Item,
  ItemTitle,
  ItemImage,
  Container,
  ItemText,
  LoaderBox,
  Content,
};

export default Styled;
