import React from 'react';

const validateEmail = (email) => {
  if (!email) {
    return false;
  }

  let domain = email.slice(email.lastIndexOf('@') + 1);
  let trimmedDomain;
  if (domain.substring(0, 6).toUpperCase() === 'G.MAIL') {
    trimmedDomain = 'G.MAIL';
  } else {
    trimmedDomain = domain.slice(0, domain.indexOf('.'));
  }

  let notAllowedDomains = [
    'G.MAIL',
    'GAIL',
    'GAMAI',
    'GAMAIL',
    'GAMIL',
    'GEMAIL',
    'GIMAIL',
    'GMAAIL',
    'GMAI',
    'GMAIK',
    'GMAL',
    'GMALIL',
    'GMAOL',
    'GMIAL',
    'GMIL',
    'GMSAIL',
    'GMSIL',
    'GMZAIL',
  ];

  if (
    trimmedDomain.toUpperCase().includes('GMAIL') &&
    trimmedDomain.length > 5
  ) {
    return false;
  }

  if (notAllowedDomains.includes(trimmedDomain.toUpperCase())) {
    return false;
  }

  return true;
};

export default validateEmail;
