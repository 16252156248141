/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import useFetch from 'use-http';
import Styled from '../UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../../../shared/DateRangePicker/DateRangePicker';
import Loader from '../../../../shared/Loader';
import Page from '../../../../shared/Page';
import { useHistory } from 'react-router-dom';
import { useUserState, useUserDispatch } from '../../../../context/UserContext';
import { getQueryString } from '../../../../constants/visitsQueryStringBuilder';
import Button from '../../../../shared/Button/Button';
import SelectField from '../../../../shared/SelectField/SelectField';
import { useEffectOnce } from 'react-use';
const CouponHistory = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const [dateRange, setDateRange] = useState(userState.dateFilter);
  const [Filters, setFilters] = useState();
  const [SelectedFilter, setSelectedFilter] = useState();
  const [search, setSearch] = useState();
  const { get: getData, response: getDataResponse } = useFetch(
    '/UserAccount/UserCoupons',
  );

  const { get: getDetails } = useFetch('/UserAccount/UserCouponDetails');
  const { get: getUsageDetails } = useFetch('/UserAccount/UserCouponUsage');

  const applyChanges = async (date, search, filter) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getData(
      getQueryString(date, search, null, filter, userState.espbiEsi),
    );
    if (getDataResponse.status >= 400) {
    } else {
      setData(response);
      if (!Filters && response) {
        const tmpFilterArray = [];
        const uniqueStatusIds = [
          ...new Set(response?.map((item) => item.statusId)),
        ];

        const order = [
          'Galioja',
          'Panaudotas',
          'Rezervuotas',
          'Nebegalioja',
          'Patvirtintas',
          'Darbinis',
          'Anuliuotas',
        ];

        const sortedStatuses = uniqueStatusIds.sort((a, b) => {
          const indexA = order.indexOf(a);
          const indexB = order.indexOf(b);
          return indexA - indexB;
        });

        const otherStatuses = uniqueStatusIds.filter(
          (status) => !order.includes(status),
        );

        const finalStatuses = sortedStatuses.concat(otherStatuses);

        finalStatuses?.map((filter) => {
          tmpFilterArray.push({ value: filter, label: filter });
        });
        setFilters(tmpFilterArray);
      }
    }
    setLoading(false);
  };

  useEffectOnce(() => {
    applyChanges(dateRange, search, SelectedFilter);
  });

  const handleSelection = async (obj) => {
    setLoading(true);

    let data = obj;
    let query = userState.espbiEsi
      ? `?id=${obj.id}&espbiEsi=${userState.espbiEsi}`
      : `?id=${obj.id}`;
    await getDetails(query).then((res) => {
      data = { ...data, ...res };
    });
    await getUsageDetails(query).then((res) => {
      data = { ...data, ...res };
    });
    setLoading(false);
    history.push('/account/user-coupon/details', { data: data });
  };

  return (
    <Styled.DataContainer>
      <Styled.TabTitleWrapper select={true}>
        <Styled.TabTitle>Kuponų sąrašas</Styled.TabTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target.search.value);
            applyChanges(dateRange, e.target.search.value, SelectedFilter);
          }}
          onChange={(e) => {
            if (
              e.target.value === undefined ||
              e.target.value === null ||
              e.target.value === ''
            ) {
              setSearch(e.target.value);
              applyChanges(dateRange, e.target.value, SelectedFilter);
            }
          }}
          style={{ display: 'flex' }}
        >
          <input type="text" id="search" placeholder="Paieška" name="search" />
          <Button
            style={{ marginRight: '15px', fontSize: '16px' }}
            size="small"
            type="submit"
          >
            Ieškoti
          </Button>
        </form>
        <Styled.SelectField>
          <SelectField
            options={Filters}
            isClearable={true}
            placeHolder={'Būsena'}
            onChange={(val) => {
              setSelectedFilter(val);
              applyChanges(dateRange, search, val);
            }}
          ></SelectField>
        </Styled.SelectField>
      </Styled.TabTitleWrapper>
      {loading && (
        <Page>
          <Loader />
        </Page>
      )}
      {!loading &&
        data &&
        data.length !== 0 &&
        _.orderBy(data, ['id'], ['desc']).map((obj) => {
          return (
            <div key={obj.id}>
              <Styled.Visit>
                <Styled.VisitTitleDateTime style={{ textDecoration: 'none' }}>
                  <a
                    onClick={() => {
                      handleSelection(obj);
                    }}
                  >
                    {obj.name} - {obj.number}
                  </a>
                  <Styled.Badge validityStatus={obj.statusId}>
                    {obj.statusId}
                  </Styled.Badge>
                </Styled.VisitTitleDateTime>
              </Styled.Visit>
            </div>
          );
        })}

      {(!data || data?.length === 0) && !loading && (
        <>
          <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
        </>
      )}
    </Styled.DataContainer>
  );
};
export default CouponHistory;
