import React from 'react';
import PropTypes from 'prop-types';
import { useSettingsState } from '../../context/SettingsContext';
import Loader from '../Loader';
import Styled from './Button.styles';

const Button = ({
  children,
  size,
  variant,
  loading = false,
  type = 'button',
  disabled,
  icon,
  isCenter,
  isGrey,
  isOpened,
  isWide,
  ...rest
}) => {
  const { theme } = useSettingsState();

  return (
    <Styled.Button
      {...rest}
      type={type}
      size={size}
      variant={variant}
      isLoading={loading}
      primaryColor={theme.brandColor}
      disabled={disabled}
      isGrey={isGrey}
      isOpened={isOpened}
      isWide={isWide}
    >
      {loading && <Loader light={true} color="white" />}
      {children}
      {icon && <Styled.IconHolder>{icon}</Styled.IconHolder>}
    </Styled.Button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  icon: PropTypes.node,
  size: PropTypes.oneOf(['large', 'small', 'huge', 'mega', 'medium']),
  variant: PropTypes.oneOf(['secondary']),
};

export default Button;
