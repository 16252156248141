/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import useFetch from 'use-http';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../../../../shared/DateRangePicker/DateRangePicker';
import Loader from '../../../../../shared/Loader';
import Page from '../../../../../shared/Page';
import { useHistory } from 'react-router-dom';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import { getQueryString } from '../../../../../constants/visitsQueryStringBuilder';
import { useEffectOnce } from 'react-use';
import Button from '../../../../../shared/Button/Button';
const ReferralsComponent = () => {
  const { t } = useTranslation();
  const [Data, setData] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const [search, setSearch] = useState();
  const [dateRange, setDateRange] = useState(userState.dateFilter);
  const { get: getData, response: getDataResponse } = useFetch(
    '/UserAccount/ReferralsList',
  );

  const { get: getReferral } = useFetch('/UserAccount/Referral');

  const applyChanges = async (date, search) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getData(
      getQueryString(date, search, undefined, undefined, userState.espbiEsi),
    );
    if (getDataResponse.status >= 400) {
    } else {
      setData(response);
    }
    setLoading(false);
  };

  useEffectOnce(() => {
    applyChanges(dateRange);
  });

  const handleSelection = async (id) => {
    setLoading(true);
    let query = userState.espbiEsi ?
    `?id=${id}&espbiEsi=${userState.espbiEsi}`
    :
    `?id=${id}`
    await getReferral(query).then(
      (res) => {
        setLoading(false);
        history.push('/account/referral', { data: res });
      },
    );
  };

  return (
    <Styled.DataContainer>
      <Styled.TabTitleWrapper>
        <Styled.TabTitle>
          Siuntimai
          <br />
          <span>Specialistas, kuriam siunčiama</span>
        </Styled.TabTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target.search.value);
            applyChanges(dateRange, e.target.search.value);
          }}
          onChange={(e) => {
            if (
              e.target.value === undefined ||
              e.target.value === null ||
              e.target.value === ''
            ) {
              setSearch(e.target.value);
              applyChanges(dateRange, e.target.value);
            }
          }}
          style={{ display: 'flex' }}
        >
          <input type="text" id="search" placeholder="Paieška" name="search" />
          <Button
            style={{ marginRight: '15px', fontSize: '16px' }}
            size="small"
            type="submit"
          >
            Ieškoti
          </Button>
        </form>
        <DateRangePicker
          dateChanged={(date) => {
            setDateRange(date);
            applyChanges(date, search);
          }}
          dateRange={dateRange}
        />
      </Styled.TabTitleWrapper>

      {loading && (
        <Page>
          <Loader />
        </Page>
      )}
      {!loading &&
        Data &&
        Data.length !== 0 &&
        _.orderBy(Data, ['occurrenceDateTime'], ['desc']).map((obj) => {
          return (
            <div key={obj.id}>
              <Styled.Visit>
                <Styled.VisitTitleDateTime>
                  <a
                    onClick={() => {
                      handleSelection(obj.id);
                    }}
                  >
                    {obj.specialty}
                  </a>
                  {(new Date(
                    Date.parse(obj.occurrenceDateTime),
                  ).getUTCHours() === 0 && (
                    <Moment
                      utc={true}
                      onClick={() => {
                        handleSelection(obj.id);
                      }}
                      className="time"
                      format="YYYY-MM-DD"
                    >
                      {Date.parse(obj.occurrenceDateTime)}
                    </Moment>
                  )) || (
                    <Moment
                      utc={true}
                      onClick={() => {
                        handleSelection(obj.id);
                      }}
                      className="time"
                      format="YYYY-MM-DD HH:mm"
                    >
                      {Date.parse(obj.occurrenceDateTime)}
                    </Moment>
                  )}
                </Styled.VisitTitleDateTime>
              </Styled.Visit>
            </div>
          );
        })}
      {Data && Data.length !== 0 && !loading && (
        <div style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}>
          {t('userAccount.visitHistoryTab.dateInfo')}
        </div>
      )}
      {Data && Data.length === 0 && !loading && (
        <>
          <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
          <div
            style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}
          >
            {t('userAccount.visitHistoryTab.dateInfo')}
          </div>
        </>
      )}
    </Styled.DataContainer>
  );
};
export default ReferralsComponent;
