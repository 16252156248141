import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import useFetch from 'use-http';
import { Trans, useTranslation } from 'react-i18next';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import Button from '../../../shared/Button';
import Styled from './FinishRegistrationPage.styles';
import Form from '../../../shared/Form';
import TextField from '../../../shared/TextField';
import ErrorModal from '../../../shared/ErrorModal';
import Modal from '../../../shared/Modal';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Danger } from '../../../../assets/icons/danger.svg';
import { useUserState, useUserDispatch } from '../../../context/UserContext';
import useRedirection from '../../../hooks/useRedirection';
import { useEffectOnce } from 'react-use';
import personalCodeValidation from '../../../hooks/personalCodeValidation';
import validateEmail from '../../../hooks/validateEmail';
import { PhoneNumberType } from 'google-libphonenumber';
import { countBy } from 'lodash';

const FinishRegistrationPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [emailCheckValue, setEmailCheckValue] = useState('');
  const [emailConfirmCheckValue, setEmailConfirmCheckValue] = useState('');
  const [emailGlobalCheckValue, setEmailGlobalCheckValue] = useState(false);
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const [validatePersonalCode] = personalCodeValidation();
  const [clearCartState] = useRedirection();
  const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
  useEffectOnce(async () => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);
  const phoneNumberCountryCode =
    userState.countryCode === 'LV' ?
      "+3712" : userState.countryCode === 'ES' ?
        "+372" : "+3706"
  const initialValues = {
    email: userState.email ? userState.email : '',
    confirmEmail: userState.emailConfirmed ? userState.email : '',
    phoneNumber: userState.phoneNumber ? userState.phoneNumber : phoneNumberCountryCode,
    personalCode: userState.personalCode ? userState.personalCode : '',
    password: userState.password ? userState.password : '',
    confirmPassword: userState.passwordConfirmed ? userState.password : '',
  };

  const validationSchema = Yup.object().shape({
    personalCode: Yup.number()
      .typeError(t('validation.personalCodeNumbers'))
      .required(t('validation.requiredField'))
      .test('len', t('validation.personalCode'), (val) => {
        return validatePersonalCode(val);
      }),
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.requiredField'))
      .trim()
      .test('email', t('validation.checkEmail'), function (value) {
        let validEmail = validateEmail(value);
        if (value === emailCheckValue && !validEmail) {
          return true;
        }
        if (value !== emailCheckValue && !validEmail) {
          setEmailCheckValue(value);
          setEmailGlobalCheckValue(true);
          return false;
        }
        return true;
      }),
    confirmEmail: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.requiredField'))
      .trim()
      .test(
        'confirmEmail',
        t('validation.checkEmail'),
        function (value, { createError }) {
          let validEmail = validateEmail(value);
          if (value !== this.parent.email) {
            return createError({
              message: t('validation.notMatchingEmail'),
              path: 'confirmEmail',
            });
          }
          if (emailGlobalCheckValue && !validEmail) {
            return true;
          }
          if (value === emailConfirmCheckValue && !validEmail) {
            return true;
          }
          if (value !== emailConfirmCheckValue && !validEmail) {
            setEmailConfirmCheckValue(value);
            return false;
          }
          return true;
        },
      ),

    phoneNumber: Yup.string()
      .required(t('validation.requiredField'))
      .test('checkFormat', function (val) {
        if (!val) {
          throw new Yup.ValidationError(t('validation.requiredField'), val, 'phoneNumber');
        }
        const standardPhoneRegex = /^\+(?:\d+)?$/;
        if (!standardPhoneRegex.test(val)) {
          throw new Yup.ValidationError(`Netinkami simboliai. Naudokite tik '+' ženklą ir skaičius po jo.`, val, 'phoneNumber');
        }
        if (!val.startsWith(phoneNumberCountryCode)) {
          throw new Yup.ValidationError(`Netinkamas valstybės kodas, turi prasidėti ${phoneNumberCountryCode}`, val, 'phoneNumber');
        }
        try {
          const number = phoneUtil.parseAndKeepRawInput(val, userState.countryCode);
          const isNumberValid = phoneUtil.isValidNumber(number);
          if (!isNumberValid) {
            throw new Yup.ValidationError(`Netinkamas numerio formatas arba ilgis`, val, 'phoneNumber');
          }
          const isNumberMobile =
            phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE ||
            phoneUtil.getNumberType(number) === PhoneNumberType.FIXED_LINE_OR_MOBILE;

          if (!isNumberMobile) {
            throw new Yup.ValidationError(`Turi būti mobilaus telefono numeris`, val, 'phoneNumber');
          }
        } catch (error) {
          throw new Yup.ValidationError(`Netinkamas numerio formatas arba ilgis`, val, 'phoneNumber');
        }
        return true;
      }),
    password: Yup.string()
      .required(t('validation.requiredField'))
      .matches(
        /(?=[A-Za-z0-9@$!%*#?&^`~+-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&^`~+-])(?=.{8,}).*$/,
        t('validation.password'),
      ),
    confirmPassword: Yup.string()
      .required(t('validation.requiredField'))
      .trim()
      .oneOf([Yup.ref('password'), null], t('validation.notMatchingPassword'))
      .matches(
        /(?=[A-Za-z0-9@$!%*#?&^`~+-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&^`~+-])(?=.{8,}).*$/,
        t('validation.password'),
      ),
  });

  const { post: formPost, response: formResponse } = useFetch(
    `/UserAccount/MarkID/Login/${userState.authenticationType}`,
  );

  const { post: createUserPost } = useFetch(`/UserAccount/Create`);

  const { post: checkUserEmail, response: checkedEmailResponse } = useFetch(
    '/UserAccount/CheckEmail',
  );

  const handleRegistration = async (data, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    if (!userState.initializedFromLogin) {
      await checkUserEmail('', {
        personalCode: data.personalCode,
        email: data.email,
      });
      if (checkedEmailResponse.status >= 400) {
        const response = await checkedEmailResponse.json();
        setErrors(response.errors);
      } else {
        const response = await checkedEmailResponse.json();
        if (!response) {
          sessionDispatch({
            type: 'UPDATE',
            data: {
              personalCode: data.personalCode?.replace(/\s/g, ''),
              phoneNumber: data.phoneNumber,
              email: data.email,
              password: data.password,
              countryCode: userState.countryCode,
            },
          });
          if (userState.authenticationType === 'smartid') {
            await formPost('', {
              personalCode: data.personalCode?.replace(/\s/g, ''),
              countryCode: userState.countryCode,
            });
          } else {
            await formPost('', {
              personalCode: data.personalCode?.replace(/\s/g, ''),
              phoneNumber: data.phoneNumber,
            });
          }

          if (formResponse.status >= 400) {
            const response = await formResponse.json();
            if (
              response &&
              response.exceptionDetails[0] &&
              response.exceptionDetails[0].message
            ) {
              const errors = {
                personalCode: response.exceptionDetails[0].message,
              };
              setErrors(errors);
            } else {
              history.push('/confirmIdentity', {
                error: true,
                mobileid: userState.authenticationType === 'mobileid',
              });
            }
          } else {
            if (response.status === 'error') {
            } else {
              const response = await formResponse.data;
              sessionDispatch({
                type: 'UPDATE',
                data: {
                  phoneNumberVerifyOptions: {
                    token: response.token,
                    controlCode: response.controlCode,
                  },
                },
              });
              //temp
              if (response.status !== 'error') {
                if (!userState.initializedFromLogin) {
                  history.push('/confirmIdentity');
                } else {
                  history.push('/verify/phone');
                }
              }
            }
          }
        } else {
          const errors = {
            email: t('validation.takenEmail'),
          };
          setErrors(errors);
        }
      }
    } else {
      await checkUserEmail('', {
        personalCode: data.personalCode,
        email: data.email,
      });
      if (checkedEmailResponse.status >= 400) {
        const response = await checkedEmailResponse.json();
        setErrors(response.errors);
      } else {
        const response = await checkedEmailResponse.json();
        if (response) {
          const errors = {
            email: t('validation.takenEmail'),
          };
          setErrors(errors);
        } else {
          createUserPost('', {
            email: data.email,
            password: data.password,
            firstName: userState.firstName,
            lastName: userState.lastName,
            personalCode: data.personalCode?.replace(/\s/g, ''),
            phoneNumber: data.phoneNumber,
          }).then((response) => {
            if (response.status === 'error') {
              const errors = {
                email: t('validation.takenEmail'),
              };
              setErrors(errors);
            } else {
              sessionDispatch({
                type: 'UPDATE',
                data: {
                  id: response.user.id,
                  password: data.password,
                  email: response.user.email,
                  phoneNumber: response.user.phoneNumber,
                  personalCode: userState.personalCode,
                  firstName: response.user.firstName,
                  lastName: response.user.lastName,
                  countryCode: userState.countryCode,
                  phoneNumberConfirmed: response.user.phoneNumberConfirmed,
                  role: response.user.role,
                  contacts: response.user.contacts,
                },
              });
              sessionDispatch({
                type: 'UPDATE_TOKENS',
                data: {
                  token: response.token,
                  refreshToken: response.refreshToken,
                },
              });
              if (response.user.phoneNumberConfirmed) {
                history.push('/account');
              } else {
                history.push('/verify/phone');
              }
            }
          });
        }
        // identity already verified
      }
    }

    setSubmitting(false);
  };
  return (
    <Page loading={false}>
      <Container>
        <Styled.Title>{t('userRegistration.title')}</Styled.Title>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegistration}
        >
          {({ setFieldValue, errors, isSubmitting, values }) => (
            <FormikForm>
              <Form>
                <Form.Group>
                  <Styled.SubTitle>
                    {t('userRegistration.personalData.title')}
                  </Styled.SubTitle>
                  <Form.Row>
                    <Field
                      labelStyle={{ fontSize: 20 }}
                      disabled={userState.initializedFromLogin ? true : false}
                      errorStyle={{ fontSize: 18 }}
                      name="personalCode"
                      value={values.personalCode?.replace(/\s/g, '')}
                      focus={true}
                      label={t('userRegistration.personalData.personalCode')}
                      component={TextField}
                    />
                    <Field
                      labelStyle={{ fontSize: 20 }}
                      errorStyle={{ fontSize: 18 }}
                      disabled={
                        userState.initializedFromLogin &&
                          userState.authenticationType === 'mobileid'
                          ? true
                          : false
                      }
                      name="phoneNumber"
                      label={t('userRegistration.contactData.phone')}
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Field
                      labelStyle={{ fontSize: 20 }}
                      errorStyle={{ fontSize: 18 }}
                      name="password"
                      password
                      label={t('userRegistration.personalData.password')}
                      component={TextField}
                    />
                    <Field
                      labelStyle={{ fontSize: 20 }}
                      errorStyle={{ fontSize: 18 }}
                      name="confirmPassword"
                      password
                      label={t('userRegistration.personalData.confirmPassword')}
                      component={TextField}
                    />
                  </Form.Row>
                  <Form.Row>
                    <Styled.PasswordExplanation>
                      <div>
                        <Danger></Danger>
                      </div>
                      <div>
                        <Trans
                          i18nKey="validation.passwordStrongSymbols"
                        />
                      </div>
                    </Styled.PasswordExplanation>
                  </Form.Row>

                  <Form.Row>
                    <Field
                      labelStyle={{ fontSize: 20 }}
                      errorStyle={{ fontSize: 18 }}
                      name="email"
                      label={t('userRegistration.contactData.email')}
                      component={TextField}
                    />
                    <Field
                      labelStyle={{ fontSize: 20 }}
                      errorStyle={{ fontSize: 18 }}
                      name="confirmEmail"
                      label={t('userRegistration.personalData.confirmEmail')}
                      component={TextField}
                    />
                  </Form.Row>
                </Form.Group>
              </Form>
              <Styled.Actions>
                <Form.Actions>
                  <Button
                    size="huge"
                    variant="secondary"
                    onClick={async () => {
                      sessionDispatch({ type: 'RESET' });
                      history.goBack();
                    }}
                  >
                    {t('userRegistration.cancel')}
                  </Button>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="huge"
                    type="submit"
                    onClick={() => handleRegistration}
                  >
                    {t('userRegistration.continue')}
                  </Button>
                </Form.Actions>
              </Styled.Actions>
            </FormikForm>
          )}
        </Formik>
        {/* <div>
          <pre>{JSON.stringify(userAccountState, null, 2)}</pre>
        </div> */}
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
        }}
        showError={errorModalVisible}
      />
      <Modal visible={formPost.error} onClose={() => window.location.reload()}>
        <Modal.Alert type="error">Atsiprašome, įvyko klaida</Modal.Alert>
        <Modal.TextContent>
          {formResponse.status >= 400
            ? 'Atsiprašome įvyko nenumatyta klaida, bandykite dar kartą.'
            : 'Apgailestaujame, tačiau šį laiką jau rezervavo kitas klientas. Pasirinkite kitą laiką.'}
        </Modal.TextContent>
        <Modal.Actions>
          <Button size="large" onClick={() => window.location.reload()}>
            Uždaryti
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};
export default FinishRegistrationPage;
