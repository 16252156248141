import styled, { css } from 'styled-components/macro';
import ButtonStyles from '../Button/Button.styles';
import { mediaDown } from '../../../utils/styles/media';

const formIndentCss = css`
  padding: 15px 20px 20px;
  background-color: #e7edf0;

  ${mediaDown('md')`
    margin: 0;
    padding: 12px 15px;
    border-radius: 8px;
  `}
`;

const positionRelative = css`
  position: relative;
`;

const marginTop = css`
  margin-top: 25px;
`;

const Form = styled.div`
  padding: 24px 30px;
  background-color: #f1f5f7;
  border-radius: 15px
    ${(props) => props.noBorderRadiusTop && 'border-top-left-radius: unset'};
  ${(props) => props.noBorderRadiusTop && 'border-top-right-radius: unset'};
  ${mediaDown('md')`
    margin: 0 -20px;
    padding: 24px 20px;
    border-radius: 0;
  `}

  ${(props) => props.indent && formIndentCss}
`;

const Label = styled.label`
  color: ${(props) => props.greyTheme && '#52585F;'};
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
  margin-top: 6px;
  display: block;
  ${mediaDown('md')`
    font-size: 11px;
    line-height: 11px;
  `}
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;

  ${ButtonStyles.Button} {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Row = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-gap: 20px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  flex-grow: 1;
  ${(props) => props.positionRelative && positionRelative}
  ${(props) => props.marginTop && marginTop}
  ${mediaDown('md')`
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    grid-gap: 0;

    > * {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const absolutePosition = css`
  position: absolute;
`;

const errorStandAloneCss = css`
  margin-top: 10px;

  > div {
    border-radius: 4px;
  }
`;

const Error = styled.div`
  max-width: 480px;
  margin-top: -1px;
  > div {
    display: inline-block;
    background-color: #f35b69;
    font-size: 12px;
    color: #fff;
    padding: 4px 12px;
    border-radius: 0 0 4px 4px;
    font-weight: normal;
  }
  ${(props) => props.positionAbs && absolutePosition}
  ${(props) => props.standAlone && errorStandAloneCss}
`;

const Title = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 20px;
  margin-top: 6px;
`;

const Group = styled.div`
  &:not(:first-child) {
    margin-top: 26px;
  }

  ${Row} {
    margin-bottom: 17px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Styled = {
  Label,
  Form,
  Actions,
  Row,
  Error,
  Title,
  Group,
};

export default Styled;
