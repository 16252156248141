/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../assets/icons/arrow.svg';
import moment from 'moment';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';

const CouponHistoryDetails = () => {
  const history = useHistory();
  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');

  const data = history.location.state?.data;

  const handleFileDownload = async (filename, fileType, fileGuid) => {
    await getFile(
      `?fileType=${fileType}&fileGuid=${fileGuid}&category=${'InstrumentalResearch'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${filename}.pdf`;
      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>Kuponų sąrašas</Styled.VisitTitle>
      </Styled.BackArrow>

      <Styled.InfoDetailsRow>
        <span>Pavadinimas:</span>
        <div>{data.name}</div>
      </Styled.InfoDetailsRow>

      {data.value && (
        <Styled.InfoDetailsRow>
          <span>Vertė:</span>
          <div>{data.value}€</div>
        </Styled.InfoDetailsRow>
      )}

      <Styled.InfoDetailsRow>
        <span>Numeris:</span>
        <div>{data.number}</div>
      </Styled.InfoDetailsRow>

      {data.balance && (
        <Styled.InfoDetailsRow>
          <span>Balansas:</span>
          <div>{data.balance}€</div>
        </Styled.InfoDetailsRow>
      )}

      {data.validTo && (
        <Styled.InfoDetailsRow>
          <span>Galioja iki:</span>
          <div>
            {(new Date(Date.parse(data.validTo)).getUTCHours() === 0 && (
              <Moment utc={true} className="time" format="YYYY-MM-DD">
                {Date.parse(data.validTo)}
              </Moment>
            )) || (
              <Moment utc={true} className="time" format="YYYY-MM-DD">
                {Date.parse(data.validTo)}
              </Moment>
            )}
          </div>
        </Styled.InfoDetailsRow>
      )}

      {data.company && (
        <Styled.InfoDetailsRow>
          <span>Galioja:</span>
          <div>{data.company}</div>
        </Styled.InfoDetailsRow>
      )}

      <Styled.InfoDetailsRow>
        <span>Būsena:</span>
        <div>{data.statusId}</div>
      </Styled.InfoDetailsRow>

      {data.file && data.file.guid && (
        <Styled.InfoDetailsRow>
          <a
            onClick={() => {
              const fileName = `Dovanu kuponas - ${data.number}`;
              handleFileDownload(fileName, data.file.type, data.file.guid);
            }}
          >
            ATSISIŲSTI PDF
          </a>
        </Styled.InfoDetailsRow>
      )}
      {data.usings?.length > 0 && (
        <Styled.ServiceTitle style={{ marginTop: '25px' }}>
          Kupono panaudojimo istorija:
        </Styled.ServiceTitle>
      )}

      {data.usings?.length > 0 &&
        data.usings.map((result, index) => {
          return (
            <div style={{ marginLeft: '40px' }} key={index}>
              {result.dateOfUse && (
                <Styled.InfoDetailsRow style={{ marginTop: '25px' }}>
                  <span>Data:</span>
                  {(new Date(Date.parse(result.dateOfUse)).getUTCHours() ===
                    0 && (
                    <Moment
                      utc={true}
                      className="time"
                      format="YYYY-MM-DD hh:mm"
                    >
                      {Date.parse(result.dateOfUse)}
                    </Moment>
                  )) || (
                    <Moment
                      utc={true}
                      className="time"
                      format="YYYY-MM-DD HH:mm"
                    >
                      {Date.parse(result.dateOfUse)}
                    </Moment>
                  )}{' '}
                </Styled.InfoDetailsRow>
              )}

              <Styled.InfoDetailsRow>
                <span>Panaudota suma:</span>
                <div>{result.usedSum}€</div>
              </Styled.InfoDetailsRow>
            </div>
          );
        })}
    </Styled.DataContainer>
  );
};
export default CouponHistoryDetails;
