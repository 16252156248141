import React, { useState } from 'react';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import Button from '../../../../../shared/Button/Button';

function CheckboxList({ title, results, callback }) {
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'selectAll') {
      const newCheckedItems = results.reduce((current, result) => ({
        ...current,
        [result.name]: checked,
      }), {});
      setCheckedItems(newCheckedItems);
      setSelectAll(checked);
    } else {
      setCheckedItems({ ...checkedItems, [name]: checked });
      if (!checked) {
        setSelectAll(false);
      } else {
        setSelectAll(Object.keys(checkedItems).length === results.length && Object.values({ ...checkedItems, [name]: checked }).every(v => v));
      }
    }
  };

  const handleOkClick = () => {
    const selectedItems = Object.keys(checkedItems).filter(key => checkedItems[key]);
    callback(selectedItems);
  };

  const items = results?.map((result, i) => ({
    id: i,
    name: result.name,
    info: result.name,
  }));

  return (
    <div>
      <h3>{title}</h3>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <Checkbox
          children={selectAll ? "Nužymėti viską" : "Pažymėti viską"}
          onChange={handleChange}
          name="selectAll"
          checked={selectAll}
        />
        <Button onClick={handleOkClick} size="medium" style={{ marginLeft: '10px' }}>
          Rodyti grafikus
        </Button>
      </div>
      {items.map((item) => (
        <React.Fragment key={item.id}>
          <Checkbox
            children={item.name}
            onChange={handleChange}
            name={item.name}
            checked={!!checkedItems[item.name]}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export default CheckboxList;
