import React, { useMemo, forwardRef } from 'react';
import { ReactComponent as TickIcon } from '../../../assets/icons/tick.svg';
import Styled from './Tabs.styles';

const TabsContext = React.createContext();

export function useTabsContext() {
  const context = React.useContext(TabsContext);
  if (!context) {
    throw new Error(
      `Dropdown compound components cannot be rendered outside the Dropdown component`,
    );
  }
  return context;
}

const Tabs = forwardRef(
  ({ children: _children, active, actions, marginTop }, ref) => {
    const value = useMemo(() => ({ active }), [active]);
    const children = React.Children.toArray(_children).filter(Boolean);
    return !marginTop ? (
      <TabsContext.Provider value={value}>
        <Styled.Tabs ref={ref}>
          {React.Children.map(children, (child, i) =>
            React.cloneElement(
              child,
              i > 0 && {
                active: i - 1 === value.active,
                actions,
              },
            ),
          )}
        </Styled.Tabs>
      </TabsContext.Provider>
    ) : (
      <TabsContext.Provider value={value}>
        <Styled.TabsMarginTopSmaller ref={ref}>
          {React.Children.map(children, (child, i) =>
            React.cloneElement(
              child,
              i > 0 && {
                active: i - 1 === value.active,
                actions,
              },
            ),
          )}
        </Styled.TabsMarginTopSmaller>
      </TabsContext.Provider>
    );
  },
);

const Tab = ({ children, active, previous, next }) => {
  return (
    <Styled.Tab active={active} previous={previous} next={next}>
      {!active && (
        <div>
          <TickIcon />
        </div>
      )}
      {children}
    </Styled.Tab>
  );
};

const List = ({ children }) => {
  const { active } = useTabsContext();
  return (
    <Styled.List>
      {React.Children.map(children, (child, i) => {
        if (child) {
          return React.cloneElement(child, {
            active: i === active,
            previous: i < active,
            next: i > active,
          });
        }
      })}
    </Styled.List>
  );
};

const Content = ({ children, active, actions }) => {
  return (
    <Styled.Content active={active}>
      {children}
      {actions}
    </Styled.Content>
  );
};

Tabs.Tab = Tab;
Tabs.List = List;
Tabs.Content = Content;

export default Tabs;
