import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import Form from '../../../../../shared/Form';
import { useTranslation } from 'react-i18next';
import Page from '../../../../../shared/Page';
import Styled from './Agreements.styles';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../shared/Button';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import useFetch from 'use-http';
import Modal from '../../../../../shared/Modal/Modal';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import _ from 'lodash';
import TextField from '../../../../../shared/TextField/TextField';

const Agreements = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [Agreements, setAgreements] = useState([]);
  const [authorizedPersonConsentId, setAuthorizedPersonConsentId] = useState();
  const [authorizedPersonsVisible, setAuthorizedPersonsVisible] =
    useState(true);
  const [consentsVisible, setConsentsVisible] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [currentData, setCurrentData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const agreementId = process.env.REACT_APP_AGREEMENTID;
  const { get: getAgreementsRequest, response: getAgreementsRequestResponse } =
    useFetch(`/UserAccount/Agreements`);

  const { post: postAgreementsRequest, response: postAgreementsResponse } =
    useFetch(`/UserAccount/UpdateAgreement`);

  const [
    isAuthorizedPersonsUpdateSuccess,
    setIsAuthorizedPersonsUpdateSuccess,
  ] = useState(history.location.state?.success);
  const [AMISErrorMessage, setAMISErrorMessage] = useState(
    history.location.state?.errorMessage,
  );

  const [
    isAuthorizedPersonsUpdateSuccessModalVisible,
    setIsAuthorizedPersonsUpdateSuccessModalVisible,
  ] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [AuthorizedPersons, setAuthorizedPersons] = useState([]);
  const [authorizedPersonsUpdate, setAuthorizedPersonsUpdate] = useState('');
  const [authorizedPersonsPassId, setAuthorizedPersonsPassId] = useState({});
  const userState = useUserState();
  const [loading, setLoading] = useState(false);
  const [idData, setIdData] = useState([]);
  const [stringConsentIds, setStringConsentIds] = useState();
  const [failedFetch, setFailedFetch] = useState(false);
  const handleSubmit = async (data) => {
    let changes = [];
    currentData.forEach((x, idx) => {
      if (data[idx][x.consentId] !== undefined) {
        const value = data[idx][x.consentId];

        if (x.value !== value) {
          x.value = value;
          changes.push(x);
        }
      }
    });
    let objTrimmedChangesArray = changes.map((consent) => ({
      medConsultPersonGuid: userState.id,
      patientConsentId: consent.id,
      value: consent.value,
      consentId: consent.consentId,
      espbiEsi: userState.espbiEsi,
    }));
    const response = await postAgreementsRequest(' ', objTrimmedChangesArray);
    if (
      postAgreementsResponse.status > 199 &&
      postAgreementsResponse.status < 300
    ) {
      if (response.error) {
        setModalMessage(`Įvyko klaida: ${response.error}`);
        setIsAuthorizedPersonsUpdateSuccessModalVisible(true);
      } else {
        setModalMessage(`Duomenys pakeisti sėkmingai`);
        setIsAuthorizedPersonsUpdateSuccessModalVisible(true);
        setRefresh((prev) => !prev);
      }
    } else {
      setModalMessage(`Įvyko klaida: Negalime atnaujinti sutikimo.`);
      setIsAuthorizedPersonsUpdateSuccessModalVisible(true);
    }
  };

  useEffect(() => {
    if (isAuthorizedPersonsUpdateSuccess) {
      setModalMessage('Duomenys pakeisti sėkmingai');
      history.replace({ state: {} });
      setIsAuthorizedPersonsUpdateSuccessModalVisible(true);
    } else if (isAuthorizedPersonsUpdateSuccess === false) {
      setModalMessage(`Įvyko klaida: ${AMISErrorMessage}`);
      history.replace({ state: {} });
      setIsAuthorizedPersonsUpdateSuccessModalVisible(true);
    }
  }, []);

  useEffect(() => {
    (async function () {
      setLoading(true);
      let response = null;
      await getAgreementsRequest(
        userState.espbiEsi ? `?espbiEsi=${userState.espbiEsi}` : '',
      );
      if (getAgreementsRequestResponse.status === 200) {
        response = await getAgreementsRequestResponse;
      } else {
        setConsentsVisible(false);
        setLoading(false);
      }
      if (
        response.data?.consents.filter((arr) => arr.valueType === 'String')
          .length === 0
      ) {
        setAuthorizedPersonsVisible(false);
      }
      if (
        response.data?.consents === null ||
        response.data?.consents.length === 0
      ) {
        setConsentsVisible(false);
      }
      let consents = response.data?.consents.filter(
        (arr) =>
          arr.valueType === 'Boolean' ||
          (arr.valueType === 'String' && arr.amisConsentId != agreementId),
      );

      let filteredAuthorizedPersons = response.data?.consents
        .filter(
          (arr) =>
            arr.valueType === 'String' && arr.amisConsentId == agreementId,
        )
        .reduce((total, currentItem) => {
          total.push(currentItem.value);
          return total;
        }, []);
      if (filteredAuthorizedPersons.length > 0) {
        setAuthorizedPersons(filteredAuthorizedPersons.join(','));
      }

      let checkboxConsentCount = 0;

      response.data?.consents
        .filter(
          (arr) =>
            arr.valueType === 'String' && arr.amisConsentId == agreementId,
        )
        .forEach((ag, idx) => {
          if (ag.value === null) {
            ag.value = '';
          }

          setAuthorizedPersonsUpdate(`${ag.valueType}${checkboxConsentCount}`);
          setAuthorizedPersonsPassId(ag.id);
          setAuthorizedPersonConsentId(ag.consentId);
          checkboxConsentCount++;
        });

      let initialValues = [];
      let consentIdsForStrings = [];
      consents = _.orderBy(consents, ['quequenumber', 'name'], ['asc']);
      consents.forEach((x) => {
        if (x.valueType === 'String' && x.consentId != agreementId) {
          consentIdsForStrings.push(x.consentId);
        }
        initialValues.push({ [`${x.consentId}`]: x.value });
      });
      setStringConsentIds(consentIdsForStrings);
      setInitialValues(initialValues);
      setCurrentData(consents);

      setLoading(false);
    })();
  }, [refresh]);

  return (
    <Page loading={loading}>
      <Styled.DataContainer>
        <Styled.BackArrow>
          <RightArrow onClick={() => history.goBack()}></RightArrow>
          <span>{t('userAccount.userInfoTab.agreementsTitle')}</span>
        </Styled.BackArrow>

        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            setFieldValue,
            errors,
            isSubmitting,
            handleChange,
            values,
            onChange,
          }) => (
            <FormikForm>
              {!consentsVisible && (
                <Form.Row>
                  <h3> Nėra sutikimų</h3>
                </Form.Row>
              )}
              {consentsVisible && (
                <Styled.FormContainer>
                  <Form mw>
                    <Form.Group>
                      {currentData &&
                        currentData.map((ag, idx) => (
                          <Form.Row key={idx}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {ag.valueType === 'Boolean' && (
                                <Checkbox
                                  value={values[idx][`${ag.consentId}`]}
                                  checked={values[idx][`${ag.consentId}`]}
                                  onChange={(e) => {
                                    setFieldValue(
                                      values[idx][`${ag.consentId}`],
                                      e.target.checked,
                                    );
                                    values[idx][`${ag.consentId}`] =
                                      e.target.checked;
                                  }}
                                  name={`${ag.consentId}`}
                                >
                                  {ag.name}{' '}
                                  {values[idx][`${ag.consentId}`] &&
                                    ag.validTo &&
                                    `(Galioja iki ${ag.validTo.substring(
                                      0,
                                      10,
                                    )})`}
                                </Checkbox>
                              )}
                              {ag.valueType === 'String' && (
                                <>
                                  <TextField
                                    name={`${ag.consentId}`}
                                    defaultValue={
                                      values[idx][`${ag.consentId}`]
                                    }
                                    label={ag.name}
                                    onChange={(e) => {
                                      values[idx][`${ag.consentId}`] =
                                        e.target.value;
                                    }}
                                    style={{
                                      maxWidth: '450px',
                                      color: '#52585F',
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </Form.Row>
                        ))}
                      {authorizedPersonsVisible && (
                        <Form.Row>
                          <Styled.AuthorizedPersons>
                            <div>
                              <span>Įgalioti asmenys</span>{' '}
                              <Styled.RightSpan
                                onClick={() => {
                                  history.push(
                                    '/account/manage-authorized-persons',
                                    {
                                      data: {
                                        id: authorizedPersonsPassId,
                                        value: AuthorizedPersons,
                                        consentId: authorizedPersonConsentId,
                                      },
                                    },
                                  );
                                }}
                              >
                                Keisti įgaliotus asmenis
                              </Styled.RightSpan>
                            </div>
                            <div>
                              <p style={{ maxWidth: '900px' }}>
                                {AuthorizedPersons.length !== 0
                                  ? AuthorizedPersons
                                  : 'Nenurodyta'}
                              </p>
                            </div>
                          </Styled.AuthorizedPersons>
                        </Form.Row>
                      )}
                    </Form.Group>
                  </Form>

                  <Button
                    loading={false}
                    disabled={false}
                    size="large"
                    type="submit"
                    onClick={() => handleSubmit}
                  >
                    {t('userAccount.userInfoTab.updateData')}
                  </Button>
                </Styled.FormContainer>
              )}
            </FormikForm>
          )}
        </Formik>
      </Styled.DataContainer>

      <Modal
        visible={isAuthorizedPersonsUpdateSuccessModalVisible}
        onClose={() => {
          setIsAuthorizedPersonsUpdateSuccessModalVisible(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>{modalMessage}</Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setIsAuthorizedPersonsUpdateSuccessModalVisible(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default Agreements;
