import styled from 'styled-components/macro';
import { mediaDown } from '../../../../../../utils/styles/media';
import { mediaUp } from '../../../../../../utils/styles/media';

const FormContainer = styled.div`
    display:inline-flex;
    flex-direction:column;
    align-items:center;
    gap:15px;
`;

const BackArrow = styled.div`
  display: flex;
  margin-bottom: 25px;
  padding-top: 50px;
  svg {
    cursor: pointer;
    min-height: 30px;
    min-width: 30px;
    transform: rotate(180deg);
  }
  svg path {
    fill: #52585F;
  }  
  span {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  ${mediaDown('md')`
  padding-top: 30px;
`}
`;

const InfoTitle = styled.div`
  padding: 30px 0 30px 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  a {
    color: black;
  }
  span {
    cursor: pointer;
    text-decoration: underline;
  }

  ${({ noTopBorder }) => noTopBorder && `
  border-top:0px !important ;
`}
`;

const AuthorizedPersons = styled.div`  
  align-self:flex-start;      
  padding-top:10px;  
  font-size:0.9rem;
  width:inherit;  
  div:first-child {
    display: flex;
    justify-content: space-between;
    margin-bottom:10px;    
 }
 div:first-child > span:first-child {
    color:#a0a0a0;
 }
 div:first-child > span:nth-child(2) {
    font-weight:bold;
    text-decoration:underline;
    cursor:pointer;
 }
${mediaDown('md')`
      width:100% !important;
      font-size:0.8rem;
`}

${mediaDown('xs')`
    div:first-child{
      display:flex;
      flex-direction:column;
      justify-content:center;
    }
    > div {
      text-align:center;
    }
`}
`;

const LabelInput = styled.input`
    border:0;
    background-color:#F1F5F7;   
    outline: 0;
    width:auto;
    text-align:left;
    ${mediaDown('xs')`
    text-align:center;
    `}
`;

const RightSpan = styled.span`
  display: inline-block;
  margin-left:45px;
  ${mediaDown('xs')`
  display: inline;
  margin-left:0;
  `}
`;


const DataContainer = styled.div`
  color:#52585F;
  margin: 0 25px 0 350px;
  ${InfoTitle}:first-child {
    border-bottom: 1px solid black;
    border-top: 0px solid black;
  }
  ${InfoTitle}:not(:first-child):not(:last-child) {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  ${mediaDown('md')`
    margin: 0 25px 0 100px;
    display: flex;
    flex-direction: column;
  `}
`;

const Styled = {
    FormContainer,
    DataContainer,
    BackArrow,
    AuthorizedPersons,
    RightSpan,
    LabelInput
}

export default Styled;