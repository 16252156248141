import React from 'react';
import { useEffect } from 'react';

const TransferStateContext = React.createContext();
const TransferDispatchContext = React.createContext();

const initialState = {};

function transferReducer(state, action) {
  switch (action.type) {
    case 'SET_DATA': {
      return {
        ...state,
        ...action.data,
      };
    }
    case 'RESET': {
      return {
        ...initialState,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function TransferProvider({ children }) {
  const [state, dispatch] = React.useReducer(transferReducer, initialState);

  useEffect(() => {
    return () => {
      dispatch({ type: 'RESET' });
    };
  }, []);

  return (
    <TransferStateContext.Provider value={state}>
      <TransferDispatchContext.Provider value={dispatch}>
        {children}
      </TransferDispatchContext.Provider>
    </TransferStateContext.Provider>
  );
}

function useTransferState() {
  const context = React.useContext(TransferStateContext);

  if (context === undefined) {
    throw new Error('useTransferState must be used within a TransferProvider');
  }

  return context;
}

function useTransferDispatch() {
  const context = React.useContext(TransferDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useTransferDispatch must be used within a TransferProvider'
    );
  }

  return context;
}

export { TransferProvider, useTransferState, useTransferDispatch };
