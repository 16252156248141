import affideaLogo from './assets/images/affidea-logo.svg';

import cat1Img from './assets/images/cat-1.png';
import cat2Img from './assets/images/cat-2.png';
import cat3Img from './assets/images/cat-3.png';

function env(original, fallback, parse = false) {
  if (original) {
    return parse ? JSON.parse(original) : original;
  }
  return fallback;
}

const clients = [
  {
    id: env(
      process.env.REACT_APP_CLIENT_ID,
      '01e91ca5-09d8-441e-ba70-df04326a591b',
    ),
    phoneNumber: env(process.env.REACT_APP_PHONE_NUMBER, '+370 5 244 1188'),
    shortPhoneNumber: env(process.env.REACT_APP_SHORT_PHONE_NUMBER, '1811'),
    theme: {
      primaryColor: env(process.env.REACT_APP_PRIMARY_COLOR, '#0087F7'),
      brandColor: '#4181D0',
      logo: env(process.env.REACT_APP_LOGO, affideaLogo),
      categories: env(
        process.env.REACT_APP_CATEGORIES_IMAGES,
        {
          1: cat1Img,
          2: cat2Img,
          3: cat3Img,
        },
        true,
      ),
    },
    header: {
      about: {
        url: env(process.env.REACT_APP_HEADER_URL, 'https://affidea.lt/apie/'),
      },
      services: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/paslaugos/',
        ),
      },
      findUs: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/vietos/',
        ),
      },
      ourDoctors: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/musu-gydytojai/',
        ),
      },
      prices: {
        url: env(
          process.env.REACT_APP_HEADER_URL,
          'https://affidea.lt/kainynai/',
        ),
      },
    },
    footer: {
      cookiePolicy: {
        url: env(
          process.env.REACT_APP_FOOTER_URL,
          'https://affidea.lt/slapuku-politika/',
        ),
      },
      privacyPolicy: {
        url: env(
          process.env.REACT_APP_FOOTER_URL,
          'https://affidea.lt/privatumo-politika/',
        ),
      },
      termsOfUse: {
        url: env(
          process.env.REACT_APP_FOOTER_URL,
          'https://affidea.lt/naudojimosi-salygos/',
        ),
      },
    },
    agreements: {
      rules: {
        url: env(
          process.env.REACT_APP_RULES_URL,
          'https://affidea.lt/wp-content/uploads/2020/12/sop-lc-lt-018-nuotoliniu-sveikatos-prieziuros-paslaugu-teikimo-taisykles-v4.pdf',
        ),
      },
      personalData: {
        url: env(
          process.env.REACT_APP_PERSONLA_DATA_URL,
          'https://affidea.lt/wp-content/uploads/2023/10/Duomenu-privatumo-pranesimas-PACIENTAMS-isplestinis-LT-V9.pdf',
        ),
      },
      termsOfUse: {
        url: env(
          process.env.REACT_APP_PERSONLA_DATA_URL,
          'https://affidea.lt/wp-content/uploads/2022/11/SOP-LC-LT-042-Portalo-mano.affidea.lt-naudojimo-taisykles-v1.1-1.pdf',
        ),
      },
      couponRules: {
        url: env(
          process.env.REACT_APP_COUPON_RULES_URL,
          'https://affidea.lt/dovanu-kuponai/',
        ),
      },
    },
    cookies: {
      enabled: env(
        process.env.REACT_APP_COOKIES_ENABLED,
        ['mandatory', 'functional', 'analysis', 'marketing'],
        true,
      ),
      url: env(
        process.env.REACT_APP_COOKIES_URL,
        'https://affidea.lt/wp-content/uploads/2020/12/Duomenu-apsaugos-politika.pdf',
      ),
    },
  },
];

export default clients;
