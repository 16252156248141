import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Description = styled.div`
  margin-top: 50px;
  position: relative;
  display: flex;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 60px);
    height: 100%;
    border-radius: 15px;
    z-index: -1;

    ${mediaDown('md')`
      width: 100%;
    `}
  }
`;

const Preparation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 2px solid lightblue;
  border-radius: 5px;
  padding-top: 30px;

  @media (max-width: 308px) {
    margin-top: 15px;
  }
  li {
    margin-left: 70px;
    ${mediaDown('md')`
      margin-left: 25px;
      font-size: 12px;
    `}
    padding-top: 5px;
    &:last-child {
      padding-bottom: 25px;
    }
  }
`;

const PreparationTitle = styled.div`
  display: flex;
  border-radius: 15px;
  color: white;
  background-color: #4181d0;
  position: absolute;
  width: 750px;
  font-size: 24px;
  margin: 0 0 25px 60px;
  top: -20px;
  span {
    margin-left: 15px;
    padding: 7px;
  }
  ${mediaDown('md')`
    width: 100%;
    margin: 0 0 25px 0;
    font-size: 16px;
  `}
`;
const ServiceContraImportant = styled.div`
  display: block;
`;

const TlkIconSvg = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  height: 28px;
  z-index: 0;
  svg {
    height: 40px;
    width: 40px;
    fill: rgba(0, 0, 0, 0.1);
  }
`;

const TimeSlotsFilter = styled.div`
  display: flex;
  height: auto;
  justify-items: center;
  justify-content: space-evenly;
  margin-bottom: 25px;
    ${mediaDown('md')`
    flex-direction: column;
    align-items: start;
  `}
`;

const FilterButton = styled.div`
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 40px;
  height: 40px;
  align-items: center;
  span {
    margin: 0 15px 0 15px;
  }
`;

const Radio = styled.div`
  color: red;
`;

const slotBase = css`
  background-color: #f5f9fa;
  border-radius: 5px;
  height: 40px;
`;
const Slot = styled.div`
  ${slotBase}
  background-color: #CCF9E5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.selected
      ? '#000'
      : props.isTaken
        ? '#c7c7c7'
        : props.isPaid
          ? '#519c77'
          : '#3655d7'};
  background-color: ${(props) =>
    props.isTaken
      ? '#f5f9fa'
      : props.isPaid
        ? '#CCF9E5'
        : props.isExtra
          ? '#7447E6'
          : '#a2cbff'};
  font-size: 13px;
  cursor: ${(props) =>
    props.legend ? 'default' : props.isTaken ? 'default' : 'pointer'};

  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
  border: 1px solid black;
  transition: opacity 0.15s;
  position: relative;
  overflow: hidden;
  ${mediaDown('md')`
    font-size: 12px;
  `}
`;

const TimeSlotInfo = styled.div`
  display: flex;
`;
const TimeSlotLegend = styled.div`
  width: 100%;
  ${TimeSlotInfo} {
    margin-top: 15px;
  }
`;

const TimeSlot = styled.div`
  width: 90px;
`;

const LegendTitle = styled.div`
  width: 400px;
  margin-left: 5px;
  align-self: center;
  color: ${(props) => (props.isPaid ? '#519c77' : 'black')};
`;

const AddMoreLaboratoryServicesButton = styled.div`
  margin-top: 30px;
`;

const Table = styled.table`
  border: 0px;
  tr:nth-child(odd) {
  }

  tr:nth-child(even) {
    background-color: #f8f7f6;
    color: #4884d4;
  }

  td {
    padding: 10px;
    color: #4884d4;
    font-size: 18px;
  }

  button {
    float: right;
  }
`;

const Styled = {
  TimeSlotInfo,
  Description,
  Preparation,
  PreparationTitle,
  ServiceContraImportant,
  TimeSlotLegend,
  TlkIconSvg,
  LegendTitle,
  TimeSlot,
  TimeSlotsFilter,
  FilterButton,
  Radio,
  Slot,
  AddMoreLaboratoryServicesButton,
  Table,
};

export default Styled;
