import React from 'react';
import Button from '../Button';
import Modal from '../Modal';
import './IdleModal.scss';

const IdleModal = ({
  onYes,
  onNo,

  onYesHandler = () => onYes(),
  onNoHandler = () => onNo(),
}) => {
  return (
    <Modal visible={true} onClose={onYesHandler}>
      {/* <Modal.Alert type="error">Naktyvus...</Modal.Alert> */}
      <Modal.TextContent>
        <p>Ar dar vis norite likti prisijungę?</p>
      </Modal.TextContent>
      <Modal.Actions>
        <Button size="large" onClick={onYesHandler}>
          Taip
        </Button>
        <Button size="large" onClick={onNoHandler}>
          Ne
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default IdleModal;
