import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { omitBy, isEmpty } from 'lodash';
import useFetch from 'use-http';
import Container from '../../shared/Container';
import Loader from '../../shared/Loader';
import Card from '../../shared/Card';
import Page from '../../shared/Page';
import Filter from '../ServicesPage/Filter/Filter';
import Styled from '../ServicesPage/Services.styles';
import Coupon from '../../shared/Coupons/Coupon';
import NoResults from '../../shared/NoResults/NoResults';
import _ from 'lodash';
import { exponeaCapture } from '../../hooks/exponeaCapture';

import WorkplaceServices from '../../../__mock__/WorkplaceServices';
import WorkplaceServices_coupons from '../../../__mock__/WorkplaceServices_coupons';
const USE_MOCKS = false;

const Coupons = () => {
  const { id, categoryId } = useParams();
  const { search: urlParams } = useLocation();
  const options = queryString.parse(urlParams);

  const history = useHistory();

  const { get, loading: loadingCoupons } = useFetch(
    '/CouponManagement/GetCoupons',
  );
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [fetchRemaining, setFetchRemaining] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingData(true);
    get().then((res) => {
      setData(res);
      setLoadingData(false);
    });
  }, [get]);

  useEffect(() => {
    let exponea = JSON.parse(sessionStorage.getItem('exponeaTrack') || '[]');
    if (exponea.length > 0) {
      exponea.push({ name: 'Registration status', value: 'Cancelled' });
      exponea.push({ name: 'booking_stage', value: 'Cancelled' });
      exponeaCapture(exponea, 'booking');
      sessionStorage.removeItem('exponeaTrack');
    }

    exponeaCapture(
      [
        { name: 'category_1', value: 'Dovanų kuponai' },
        { name: 'category_id', value: '3' },
      ],
      'view_item',
    );
  }, []);

  function removePriceDuplicatesForEachCoupon(coupons) {
    const seenCombinations = {};
    return coupons.filter((coupon) => {
      const key = `${coupon.couponId}-${coupon.price}`;
      if (seenCombinations[key]) {
        return false;
      }
      seenCombinations[key] = true;
      return true;
    });
  }

  return (
    <Page error={!loadingData && !data} loading={loadingCoupons}>
      <Container>
        {!loadingData && (
          <Page.Title backUrl={`/`} greyTheme={true}>
            {'Dovanų kuponai'}
          </Page.Title>
        )}
      </Container>

      <Container>
        {loadingData ? (
          <Styled.ServicesLoader>
            <Loader />
          </Styled.ServicesLoader>
        ) : (
          <>
            {data && data?.length !== 0 ? (
              <Coupon.List>
                {removePriceDuplicatesForEachCoupon(data).map(
                  (coupon, index) => {
                    return <Coupon key={index} coupon={coupon} />;
                  },
                )}
              </Coupon.List>
            ) : (
              <NoResults>Paieškos rezultatų nėra</NoResults>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};
export default Coupons;
