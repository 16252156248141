/* eslint-disable jsx-a11y/anchor-is-valid */
import useFetch from 'use-http';
import React, { useEffect, useState } from 'react';
import { useEffectOnce, useStateWithHistory } from 'react-use';
import Page from '../../../../../shared/Page';
import Styled from '../../UserAccountPage.styles';
import { useHistory } from 'react-router-dom';
import {
  useUserDispatch,
  useUserState,
} from '../../../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../shared/Loader';
import Button from '../../../../../shared/Button/Button';
import Moment from 'react-moment';

const AmisSync = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [syncing, setSyncing] = useState(false);
  const [startFetching, setStartFetching] = useState(false);
  const [syncStartedAt, setSyncStartedAt] = useState();
  const [syncFinishedAt, setSyncFinishedAt] = useState();
  const { t } = useTranslation();
  const [retry, setRetry] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const syncFunctionHttpRequest =
    process.env.REACT_APP_API_BASE + '/ExecuteSyncJob';

  const { get: callSyncFunction, response: callSyncFunctionResponse } =
    useFetch('Administration/ExecuteSyncJob');

  const { get: checkSyncStatus, response: checkSyncStatusResponse } = useFetch(
    'Administration/checkSyncStatus',
  );

  useEffectOnce(() => {
    checkSyncStatus().then((res) => {
      setSyncStartedAt(res.syncStartedAt);
      setMessageCount(res.messageCount);
      if (!res.currentlySyncing) {
        setSyncing(false);
        setSyncFinishedAt(res.syncFinishedAt);
      } else {
        setStartFetching(true);
        setSyncing(true);
      }
    });
  }, [checkSyncStatus, syncStartedAt, syncFinishedAt, syncing, messageCount]);

  useEffect(() => {
    startFetching &&
      syncing &&
      setTimeout(() => {
        checkSyncStatus()
          .then((res) => {
            setSyncStartedAt(res.syncStartedAt);
            setMessageCount(res.messageCount);
            if (!res.currentlySyncing) {
              setSyncing(false);
              setSyncFinishedAt(res.syncFinishedAt);
            } else {
              setSyncing(true);
            }
          })
          .finally(() => setRetry(retry + 1));
      }, 15000);
  }, [checkSyncStatus, retry, startFetching, syncing, messageCount]);

  const handleSync = async () => {
    var moment = require('moment-timezone');
    const convertedTime = moment(new Date())
      .tz('Europe/Vilnius')
      .format('YYYY-MM-DD HH:mm:ss');
    setSyncStartedAt(convertedTime);
    setSyncFinishedAt(undefined);
    setSyncing(true);
    callSyncFunction();
    setStartFetching(true);
  };

  return (
    <Page>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>AMIS sinchronizacija</Styled.TabTitle>
        </Styled.TabTitleWrapper>
        {loading && (
          <Page>
            <Loader />
          </Page>
        )}

        {syncStartedAt && (
          <div>
            Amis sinchronizacija pradėta:{' '}
            <Moment format="YYYY-MM-DD HH:mm:ss" utc={true}>
              {syncStartedAt}
            </Moment>
          </div>
        )}

        {syncFinishedAt && (
          <div>
            Amis sinchronizacija baigta:{' '}
            <Moment format="YYYY-MM-DD HH:mm:ss" utc={true}>
              {syncFinishedAt}
            </Moment>
          </div>
        )}
        {messageCount > 0 && (
          <div>Likusių žinučių skaičius Service Bus: {messageCount}</div>
        )}

        <Button loading={syncing} disabled={syncing} onClick={handleSync}>
          Pradėti AMIS sinchronizaciją
        </Button>
      </Styled.DataContainer>
    </Page>
  );
};
export default AmisSync;
