import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  margin-top: 14px;

  ${mediaDown('md')`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    grid-gap: 0;
  `}

  ${mediaDown('sm')`
    margin-top:15px;
  `}

  @media (max-width: 979px) {
    ${(props) => props.noTopMargin && 'margin: 14px 0;'}
  }
`;

const Card = styled.div`
  position: relative;
  border-radius: 15px;
  background-color: #f1f5f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => props.isComplex && 'width:270px;'}
  ${mediaDown('md')`
    border-radius: 8px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  `}

  ${mediaDown('sm')`
  &:not(:last-child) {
    margin-bottom: 15px;
  }  
  `}

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

const Image = styled.div`
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  ${mediaDown('md')`
    width: 130px;
    height: 83px;
    border-radius: 0 0 8px 0;
    position: absolute;
    left: 0;
    top: 0;
  `}
`;

const ImageContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
`;

const ImageText = styled.div`
  position: absolute;
  top: 8px;
  left: 16px;
  color: #19b656;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  ${mediaDown('md')`
    font-size:10px;
  }
`}
`;

const ServiceTitleHolder = styled.div`
  min-height: 180px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  padding: 0 20px;

  ${mediaDown('md')`

    > div {
      padding-left: 0;
      min-height: auto;
      margin-bottom: 0;
    }
  `}
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0 6px 6px 0;
  background-color: ${(props) =>
    props.isUpcomingVisit
      ? props.isService
        ? '#6A32E6'
        : '#0535AD'
      : '#909090'};
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
  padding: 5px 7px 5px 20px;
  display: inline-block;
  transform: translate(0, -50%);

  ${mediaDown('md')`
    font-size: 10px;
    padding: 4px 8px 4px 15px;
    top: 82px;
  `}

  ${(props) => props.isService && mediaDown('md')`top: 0px;`}
`;

const Content = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.isPsdf && props.isPsdfDate ? '0' : '25px'};
  padding: ${(props) => (props.isService ? '30px 20px 20px' : '20px')};

  ${mediaDown('sm')`
  margin-bottom: 0;
  margin-top:0;
  padding-bottom:10px;
`}
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 900;
  line-height: 29px;
  margin-bottom: 12px;
  ${mediaDown('md')`
    font-size: 16px;
    padding-left: 125px;
    min-height: 53px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  `};
  ${(props) => props.greyTheme && 'color: #52585F;'}
`;

const Description = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 10px;
  ${(props) => props.greyTheme && 'color: #52585F;'};

  ${mediaDown('sm')`
  font-size: 14px;
`}
`;

const BottomText = styled.div`
  color: #9395a9;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  span:first-child {
    margin-right: 14px;
    // width: 100%;
  }
  ${(props) => props.greyTheme && 'color: #52585F;'}

  ${mediaDown('sm')`
  font-size: 14px;
`}
`;

const Actions = styled.div`
  margin-top: 18px;
  ${mediaDown('md')`
    display: flex;
    justify-content: center;
  `}

  ${mediaDown('sm')`
  margin-top:10px;
`}
`;

const TlkService = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${(props) => (props.hasVisits ? '#01c65b' : '#909090')};
  font-size: 12px;
  text-align: center;
  color: white;
`;

const TlkIcon = styled.div`
  position: absolute;
  bottom: 0;
  left: 10px;
  height: 20px;
  z-index: 0;
  svg {
    height: 15px;
    width: 15px;
    fill: rgba(255, 255, 255, 0.8);
  }
`;

const DiscountPriceTag = styled.span`
  padding-left: 33px;
  ${mediaDown('md')`
    padding-left: 34px;
  `}
  ${mediaDown('sm')`
  padding-left: 42px;
`}
`;

const Styled = {
  Card,
  Image,
  ImageContainer,
  ImageText,
  List,
  Badge,
  Title,
  Description,
  BottomText,
  Content,
  Actions,
  ServiceTitleHolder,
  TlkService,
  TlkIcon,
  DiscountPriceTag,
};

export default Styled;
