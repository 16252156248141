import React, { useEffect } from 'react';
import { useSettingsState } from '../../context/SettingsContext';
import Form from '../Form';
import Styled from './TextField.styles';

function findByPath(result, pathItem) {
  if (result && result[pathItem]) {
    return result[pathItem];
  }
  return false;
}
export const IsError = (field, form) => {
  const touched = form.touched;
  const errors = form.errors;
  const name = field.name;
  // For Formik FieldArray
  if (name.indexOf('.') > 0) {
    const path = name.split('.');

    const isTouched = path.reduce(findByPath, touched);
    const hasErrors = path.reduce(findByPath, errors);

    return isTouched && hasErrors;
  }

  return touched[name] && errors[name];
};

const TextField = ({
  label,
  labelStyle,
  errorStyle,
  onChange,
  error,
  textarea,
  password,
  field,
  form,
  primaryColor,
  disabled,
  defaultValue,
  focus,
  fontSize,
  hint,
  isGrey,
  ...rest
}) => {
  const { theme } = useSettingsState();
  const inputRef = React.useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }

  }, []);
  const hasError = (form && IsError(field, form, errorStyle)) || error;

  return (
    <Styled.TextField error={!!hasError}>
      {label && <Form.Label styleFormat={labelStyle}>{label}</Form.Label>}
      {textarea && !password && (
        <Styled.Textarea
          rows={6}
          value={defaultValue}
          onChange={onChange}
          ref={focus ? inputRef : undefined}
          primaryColor={'theme.primaryColor'}     
          isGrey={isGrey}
          {...field}
          {...rest}
        />
      )}
      {!textarea && !password && fontSize === 'larger' && (
        <Styled.InputLargerFont
          type="text"
          ref={focus ? inputRef : undefined}
          disabled={disabled}
          value={defaultValue}
          onChange={onChange}
          primaryColor={theme.primaryColor}          
          fontSize={fontSize}    
          isGrey={isGrey}        
          {...field}
          {...rest}
        />
      )}
      {!textarea && !password && !fontSize && (
        <Styled.Input
          type="text"
          ref={focus ? inputRef : undefined}
          disabled={disabled}
          defaultValue={defaultValue}
          onChange={onChange}
          primaryColor={theme.primaryColor}          
          fontSize={fontSize} 
          isGrey={isGrey}                  
          {...field}
          {...rest}
        />
      )}
      {password && (
        <Styled.Input
          type="password"
          ref={focus ? inputRef : undefined}
          primaryColor={theme.primaryColor}
          onChange={onChange}         
          {...field}
          {...rest}
        />
      )}
      {hint && <Styled.Hint isGrey={isGrey} >{hint}</Styled.Hint>}

      <Form.Error styleFormat={errorStyle}>{hasError}</Form.Error>
    </Styled.TextField>
  );
};

export default TextField;
