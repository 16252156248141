import React from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CaretIcon } from '../../../assets/icons/caret.svg';
import { useSettingsState } from '../../context/SettingsContext';
import Form from '../Form';
import Styled from './SelectField.styles';

const SelectField = ({
  innerRef,
  disabled,
  defaultValue,
  value,
  error,
  onChange,
  options,
  placeHolder,
  isClearable,
  label,
  greyTheme,
}) => {
  const { theme } = useSettingsState();
  const { t } = useTranslation();

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <Styled.Caret greyTheme={greyTheme}>
          <CaretIcon />
        </Styled.Caret>
      </components.DropdownIndicator>
    );
  };

  return (
    <div>
      <Form.Label greyTheme={greyTheme}>{label}</Form.Label>

      <Styled.SelectHolder>
        <Styled.Select
          defaultValue={defaultValue}
          placeholder={placeHolder ? placeHolder : t('select')}
          ref={innerRef}
          isDisabled={disabled}
          error={!!error}
          value={value}
          options={options}
          isSearchable
          isClearable={isClearable}
          classNamePrefix="rs"
          primaryColor={greyTheme ? '#52585F' : theme.primaryColor}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              neutral80: greyTheme ? '#52585F' : '#000000',
              neutral50: greyTheme ? '#52585F' : '#000000',
            },            
          })}
          components={{ DropdownIndicator }}
          onChange={(result) => {
            if (result && !isClearable) {
              onChange(result.value);
            } else {
              onChange(result?.value ? result.value : result);
            }
          }}
        />

        <Form.Error>{error}</Form.Error>
      </Styled.SelectHolder>
    </div>
  );
};

export default SelectField;
