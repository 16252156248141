import styled, { css } from 'styled-components/macro';

const FileUpload = styled.div`
  max-width: 390px;
  line-height: 1;
`;

const Message = styled.div`
  color: ${(props)=>props.isGrey ? '#52585F' : '#9395a9'};
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;

  strong {
    text-decoration: underline;
    font-weight: normal;
    color: ${(props)=>props.isGrey ? '#52585F' : '#000'};
  }
`;

const ErrorItem = styled.div`
  line-height: 16px;
  background-color: #f35b69;
  font-size: 12px;
  color: #fff;
  padding: 4px 12px;
  border-radius: 0 0 4px 4px;
  font-weight: normal;
  display: inline-block;
  max-width: calc(100% - 10px);
`;

const Dropzone = styled.div`
  position: relative;
  height: 70px;
  width: 100%;
  border: 1px dashed #9395a9;
  border-radius: 10px;
  background-color: ${(props) => (props.focused ? '#e7edf0' : '#f4f9fa')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: background-color 0.15s;
  ${(props) =>
    props.error &&
    css`
      border: 1px solid #f35b69;
      border-radius: 10px 10px 10px 0;
    `};
`;

const Thumbs = styled.div`
  margin-top: 12px;

  > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const Thumb = styled.div`
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  color: #000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);
  padding: 4px;
`;

const FileIcon = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #f0f4f6;
  box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);
  font-size: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FileName = styled.div`
  width: calc(100% - 33px - 32px);
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const RemoveButton = styled.div`
  width: 33px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    svg {
      opacity: 1;
    }
  }

  svg {
    display: block;
    height: 8px;
    width: 8px;
    opacity: 0.7;
    transition: all 0.15s;
  }
`;

const Styled = {
  ErrorItem,
  FileUpload,
  Dropzone,
  Message,
  Thumbs,
  Thumb,
  FileName,
  RemoveButton,
  FileIcon,
};

export default Styled;
