import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-circle.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-circle.svg';
import Styled from './Alert.styles.js';

const Alert = ({ type, children }) => {
  return (
    <Styled.Alert type={type}>
      <>
        {type === 'success' && <SuccessIcon />}
        {type === 'error' && <ErrorIcon />}
        {children}
      </>
    </Styled.Alert>
  );
};

Alert.defaultProps = {
  type: 'info',
};

Alert.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['info', 'success', 'error']),
};

export default Alert;
