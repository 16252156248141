import React, { useState, useRef, useEffect } from 'react';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import { Formik, Field, FieldArray } from 'formik';
import Tabs from '../../../shared/Tabs';
import Form from '../../../shared/Form';
import TextField from '../../../shared/TextField';
import { useTranslation, Trans } from 'react-i18next';
import { useUserState } from '../../../context/UserContext';
import { useCartState, useCartDispatch } from '../../../context/CartContext';
import { cloneDeep } from 'lodash';
import Summary from './Summary/CombinedServicesSummary';
import Checkbox from '../../../shared/Checkbox';
import NoResults from '../../../shared/NoResults';
import { useSettingsState } from '../../../context/SettingsContext';
import usePhoneValidation from '../../../hooks/usePhoneValidator';
import * as Yup from 'yup';
import useFetch from 'use-http';
import Modal from '../../../shared/Modal';
import Button from '../../../shared/Button';
import ErrorModal from '../../../shared/ErrorModal';
import { Redirect, useHistory } from 'react-router-dom';
import SelectField from '../../../shared/SelectField';
import personalCodeValidation from '../../../hooks/personalCodeValidation';
import ChildDropdown from '../../../shared/ChildDropdown/ChildDropdown';
import Styled from '../CombinedPageStyles/CombinedPage.styles';
import { exponeaCapture } from '../../../hooks/exponeaCapture';

const CombinedServicesCheckout = () => {
  const { coupon: coupon } = useCartState();
  const userState = useUserState();
  const { t } = useTranslation();
  const { agreements } = useSettingsState();
  const [couponCount, setCouponCount] = useState(1);
  const formRefCoupons = useRef();

  const validatePhoneNumber = usePhoneValidation();

  const [showGiftCoupon, setShowGiftCoupon] = useState(false);
  const [appliedCouponNumbers, setAppliedCouponNumbers] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { post: checkCoupon, response: checkCouponResponse } = useFetch(
    '/CouponManagement/CheckCoupon',
  );
  const [changeCount, setChangeCount] = useState(0);
  const [totalCouponsApplied, setTotalCouponsApplied] = useState(0);
  const [initialTotalPrice, setInitialTotalPrice] = useState();

  const [validPhoneNumber, setValidPhoneNumber] = useState();
  const [redirectToPaysera, setRedirectToPaysera] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState();
  const [showPhoneValidationModal, setShowPhoneValidationModal] =
    useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const { get: saveValidationLog, response: saveValidationLogResponse } =
    useFetch('/Logs/saveValidationLog');
  const formRef = useRef();
  const formRefDiscount = useRef();
  const checkBoxRef = useRef();
  const history = useHistory();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const dispatch = useCartDispatch();
  const [service, setService] = useState(null);
  const [prices, setPrices] = useState(null);
  const [changedFilter, setChangedFilter] = useState(false);
  const [departments, setDepartments] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [selectedDepartmentObj, setselectedDepartmentObj] = useState();
  const [childSelected, setChildSelected] = useState(false);
  const [underAge, setUnderageModalVisibile] = useState(false);
  const [
    validatePersonalCode,
    maxAgeFromPersonalCode,
    birthDateFromPersonalCode,
  ] = personalCodeValidation();
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedChild, setSelectedChild] = useState('');
  const [kids, setKids] = useState();
  const [showDiscountCoupon, setShowDiscountCoupon] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [totalInitialSum, setTotalInitialSum] = useState(0);
  const [discountCodePrice, setDiscountCodePrice] = useState(false);
  const [discountCodeNumber, setDiscountCodeNumber] = useState('');
  const [serviceLink, setServiceLink] = useState('');

  // const { step: step, registrationServices: products } = useCartState();
  const { request: registrationRequest, response: registrationResponse } =
    useFetch('/Registrations/ComplexServiceRegistration');
  const { post: checkDiscountCode, response: checkDiscountCodeResponse } =
    useFetch('/DiscountManagement/GetDiscount');

  const requiredMessage = t('validation.requiredField');

  const { get: serviceLinkRequest, response: serviceLinkResponse } = useFetch(
    `/Services/ServiceLink`,
  );

  useEffect(async () => {
    async function fetchData() {
      if (!!service && !!service.amisServiceId) {
        const response = await serviceLinkRequest(`/${service.amisServiceId}`);
        return response;
      }

      return null;
    }

    const link = await fetchData();

    setServiceLink(link ?? '');
  }, [service, serviceLinkRequest]);

  useEffect(() => {
    setService(history.location.state?.service);
    const pricesList = history.location.state?.prices;
    const selectedDepartmentFilter = history.location.state?.selectedDepartment;
    setPrices(pricesList);
    let departmentsArr = [];
    if (pricesList.length === 1) {
      setSelectedDepartment(pricesList[0].departmentId);

      departmentsArr.push({
        value: pricesList[0].departmentId,
        label: pricesList[0].departmentName,
      });
      setDepartments(departmentsArr);
      setselectedDepartmentObj(departmentsArr[0]);
      if (formRef?.current) {
        formRef.current.values.department = pricesList[0].departmentId;
      }
    } else {
      pricesList.forEach((p) => {
        departmentsArr.push({
          value: p.departmentId,
          label: p.departmentName,
        });
      });

      if (selectedDepartmentFilter && !changedFilter) {
        pricesList.forEach((p) => {
          if (p.medConsultDepartmentId == selectedDepartmentFilter) {
            const foundDepartment = departmentsArr.find(
              (x) => x.value == p.departmentId,
            );
            setSelectedDepartment(foundDepartment.value);
            if (formRef?.current) {
              formRef.current.values.department = foundDepartment.value;
            }
            setselectedDepartmentObj(foundDepartment);
          }
        });
      }
      setDepartments(departmentsArr);
    }
  }, [
    history.location.state?.prices,
    history.location.state?.selectedDepartment,
    history.location.state?.service,
    formRef?.current?.values?.department,
  ]);

  useEffect(() => {
    if (userState?.contacts) {
      const kids = [];
      userState?.contacts.forEach((k) => {
        if (k.personalCode) {
          var testRes = maxAgeFromPersonalCode(k.personalCode, 18);
          if (!testRes) {
            kids.push(k);
          }
        } else {
          kids.push(k);
        }
      });
      setKids(kids);
    }
  }, [userState?.contacts]);

  useEffect(() => {
    if (selectedDepartment) {
      setSelectedPrice(
        prices?.find((x) => x.departmentId === selectedDepartment),
      );
      setTotalPrice(
        prices?.find((x) => x.departmentId === selectedDepartment).price,
      );
      setShowGiftCoupon(false);
    }
  }, [prices, selectedDepartment]);

  const handleResetCart = async () => {
    dispatch({
      type: 'RESET_CART',
    });
  };

  const validationSchemaForNotLoggedInUser = () => {
    const schema = {
      patientName: Yup.string().required(t('validation.requiredField')).trim(),
      department: Yup.string()
        .nullable()
        .required(t('validation.requiredField'))
        .trim(),
      patientSurname: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      patientEmail: Yup.string()
        .email(t('validation.invalidFormat'))
        .required(t('validation.requiredField')),
      patientPhoneNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim()
        .test(
          'phone',
          'Telefono numerio formatas netinkamas',
          (val, { createError }) => {
            var phone = validatePhoneNumber(val);
            if (phone.valid && phone.format && phone.changed) {
              formRef.current.values.patientPhoneNumber = phone.newValue;
              saveValidationLog(
                `?changedFrom=${phone.oldVal}&changedTo=${phone.newValue}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal &&
              !phone.showModal
            ) {
              formRef.current.values.patientPhoneNumber = phone.newVal;
              // formRef.current.validateField('patientPhoneNumber');
              saveValidationLog(
                `?changedFrom=${phone.orgVal}&changedTo=${phone.newVal}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal
            ) {
              setValidPhoneNumber(phone.newVal);
              setInvalidPhoneNumber(phone.orgVal);
              setShowPhoneValidationModal(true);
            }
          },
        ),
      patientIdNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim()
        .test('len', (val, { createError }) => {
          const validatedPersonalCode = validatePersonalCode(
            val?.replace(/\s/g, ''),
          );
          const testResult = maxAgeFromPersonalCode(
            val?.replace(/\s/g, ''),
            18,
          );
          if (!validatedPersonalCode) {
            return createError({
              message: t('validation.personalCode'),
              path: 'patientIdNumber',
            });
          }
          if (!testResult) {
            setUnderageModalVisibile(true);
            return createError({
              message: t('Asmuo nepilnametis'),
              path: 'patientIdNumber',
            });
          }
          return true;
        }),
      agreement2: Yup.bool().oneOf([true], t('validation.requiredField')),
      agreement3: Yup.bool().oneOf([true], t('validation.requiredField')),
      childName: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childSurname: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childIdNumber: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string()
          .required(t('validation.requiredField'))
          .trim()
          .test('len', (val, { createError }) => {
            const validatedPersonalCode = validatePersonalCode(val);
            const testResult = maxAgeFromPersonalCode(val, 18);
            if (!validatedPersonalCode) {
              return createError({
                message: t('validation.personalCode'),
                path: 'childIdNumber',
              });
            }
            if (testResult) {
              return createError({
                message: t('Įveskite vaiko iki 18 metų asmens kodą'),
                path: 'childIdNumber',
              });
            }
            return true;
          }),
      }),
    };

    return Yup.object().shape(schema);
  };

  const validationSchemaForLoggedInUser = () => {
    const schema = {
      patientName: Yup.string().required(t('validation.requiredField')).trim(),
      department: Yup.string()
        .nullable()
        .required(t('validation.requiredField'))
        .trim(),
      patientSurname: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),

      patientEmail: Yup.string()
        .email(t('validation.invalidFormat'))
        .required(t('validation.requiredField')),
      patientPhoneNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      patientIdNumber: Yup.string()
        .trim()
        .test('len', (val, { createError }) => {
          if (userState.containsPersonalCode) {
            return true;
          }
          const validatedPersonalCode = validatePersonalCode(
            val?.replace(/\s/g, ''),
          );
          const testResult = maxAgeFromPersonalCode(
            val?.replace(/\s/g, ''),
            18,
          );
          if (!validatedPersonalCode) {
            return createError({
              message: t('validation.personalCode'),
              path: 'patientIdNumber',
            });
          }
          if (!testResult) {
            setUnderageModalVisibile(true);
            return createError({
              message: t('Asmuo nepilnametis'),
              path: 'patientIdNumber',
            });
          }

          return true;
        }),
      agreement2: Yup.bool().oneOf([true], t('validation.requiredField')),
      agreement3: Yup.bool().oneOf([true], t('validation.requiredField')),
      otherPerson: Yup.boolean(),
      childName: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childSurname: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string().required(requiredMessage),
      }),
      childIdNumber: Yup.string().when('otherPerson', {
        is: true,
        then: Yup.string()
          .nullable()
          .trim()
          .test('len', (val, { createError }) => {
            if (childSelected) {
              return true;
            }

            if (!val) {
              return createError({
                message: t('validation.requiredField'),
                path: 'childIdNumber',
              });
            }

            const validatedPersonalCode = validatePersonalCode(val);
            const testResult = maxAgeFromPersonalCode(val, 18);
            if (!validatedPersonalCode) {
              return createError({
                message: t('validation.personalCode'),
                path: 'childIdNumber',
              });
            }
            if (testResult) {
              return createError({
                message: t('Įveskite vaiko iki 18 metų asmens kodą'),
                path: 'childIdNumber',
              });
            }
            return true;
          }),
      }),
    };

    return Yup.object().shape(schema);
  };

  const validationSchema = () => {
    return userState.isLoggedIn && userState.isLoggedIn !== null
      ? validationSchemaForLoggedInUser()
      : validationSchemaForNotLoggedInUser();
  };

  function showPaymentOptions(formData, { setErrors, setSubmitting }) {
    setSubmitting(true);
    setFormSubmitting(true);
    let paymentType = totalPrice > 0 ? 'Paysera' : null;

    const form = cloneDeep({
      patientEmail: formData.patientEmail,
      patientName: formData.patientName,
      patientPhoneNumber: formData.patientPhoneNumber,
      patientSurname: formData.patientSurname,
      patientIdNumber: userState.isLoggedIn
        ? userState.personalCode
        : formData.patientIdNumber,
      amount: selectedPrice.price,
      complexServiceId: service.serviceId,
      departmentId: selectedDepartment,
      price: !!discountCodePrice ? discountCodePrice : selectedPrice.price,
      giftCouponNumbers: appliedCouponNumbers,
      discountCode: discountCodeNumber,
      priceId: selectedPrice.priceId,
      childIdNumber: formData.childIdNumber,
      childName: formData.childName,
      childSurname: formData.childSurname,
      childEspbiEsi: formData.childEspbiEsi,
      childBirthDate: formData.childIdNumber
        ? birthDateFromPersonalCode(formData.childIdNumber)
        : '',
      frontUrls: {
        cancelFrontURL: '/kompleksines-paslaugos',
        acceptFrontUrl: '/kompleksines-paslaugos/completed',
        errorFrontUrl: '/complex-payment/error',
      },
    });

    let baseObj = [
      { name: 'category_1', value: 'Paslaugų paketai' },
      { name: 'category_2', value: service.title },
      {
        name: 'Price',
        value: !!discountCodePrice ? discountCodePrice : selectedPrice.price,
      },
      {
        name: 'Deparment',
        value: departments.find((x) => x.value == selectedDepartment)?.label,
      },
      { name: 'Firstname', value: formData.patientName },
      { name: 'Lastname', value: formData.patientSurname },
      { name: 'email', value: formData.patientEmail },
      { name: 'Payment type', value: 'Paysera' },
      { name: 'product_id', value: service.serviceId },
      { name: 'category_id', value: 3 },
      { name: 'mobile_phone', value: formData.patientPhoneNumber },
    ];

    if (formData.childIdNumber) {
      baseObj.push({ name: 'Child name', value: formData.childName });
      baseObj.push({ name: 'Child surname', value: formData.childSurname });
    }

    delete form.agreement2;
    delete form.agreement3;
    registrationRequest.post('', form).then(async () => {
      const response = await registrationResponse.json();

      baseObj.push({
        name: 'booking_booking_id',
        value: response.couponOrderId,
      });

      baseObj.push({
        name: 'booking_payment_gateway_id',
        value: response.couponOrderId,
      });

      baseObj.push({
        name: 'purchase_total_cost',
        value: !!discountCodePrice ? discountCodePrice : selectedPrice.price,
      });
      if (registrationResponse.status >= 500) {
        setSubmitting(false);
        setFormSubmitting(false);
        baseObj.push({ name: 'Status', value: 'Failed' });
        baseObj.push({ name: 'booking_stage', value: 'Cancelled' });
        exponeaCapture(baseObj, 'booking');

        setErrorModalVisible(true);
      } else if (registrationResponse.status >= 400) {
        const { errors } = response;
        baseObj.push({ name: 'Status', value: 'Failed' });
        baseObj.push({ name: 'booking_stage', value: 'Cancelled' });
        exponeaCapture(baseObj, 'booking');
        setErrors(errors);
      } else {
        sessionStorage.setItem('exponeaTrack', JSON.stringify(baseObj));
        exponeaCapture(baseObj, 'booking_item');
        const payseraUrl = response.paymentUrl;
        dispatch({
          type: 'RESET_CART',
        });
        if (!!payseraUrl) {
          window.location.replace(payseraUrl);
        } else {
          history.push(
            `/kompleksines-paslaugos/completed/${response.couponOrderId}`,
          );
        }
      }
    });
  }

  const handleChildSelect = (child) => {
    const kid = userState?.contacts.find((x) => x.espbiEsi === child.espbiEsi);
    if (kid) {
      setSelectedChild(kid.name);
      formRef.current.values.childName = kid.name;
      formRef.current.values.childSurname = kid.surname;
      formRef.current.values.childIdNumber = kid.personalCode;
      formRef.current.values.childEspbiEsi = kid.espbiEsi;
      setChildSelected(true);
    } else {
      setChildSelected(false);
      setSelectedChild(child);
      formRef.current.values.childName = child;
    }
  };

  const handleDiscountCodeCheckboxChange = (checked) => {
    setShowDiscountCoupon(checked);
    if (!checked) {
      setDiscountCodePrice(0);
      setDiscountCodeNumber('');
    }
  };

  function onCountChange(val) {
    setCouponCount(val);
  }

  useEffect(() => {
    if (showGiftCoupon) {
      handleTotalCoupons(null);
    }
  }, [changeCount, showGiftCoupon]);

  const handleTotalCoupons = (coupons) => {
    let sum = 0;
    formRefCoupons.current.values.coupons.forEach((coupon) => {
      if (coupon.couponBalance) {
        sum += coupon.couponBalance;
      }
    });
    setTotalCouponsApplied(sum);
  };

  useEffect(() => {
    if (totalCouponsApplied > 0) {
      setTotalPrice(
        selectedPrice?.price - totalCouponsApplied < 0
          ? 0
          : selectedPrice?.price - totalCouponsApplied,
      );

      return;
    }

    setTotalPrice(selectedPrice?.price);
  }, [totalCouponsApplied]);

  useEffect(() => {
    if (showGiftCoupon) {
      formRefCoupons.current.values.coupons = [
        { couponNumber: '', couponLabel: false, inputVisible: true },
      ];
    } else {
      setTotalCouponsApplied(0);
    }
  }, [showGiftCoupon]);

  useEffect(() => {
    if (formRefCoupons?.current?.values.coupons.length === 0) {
      setShowGiftCoupon(false);
    }
  }, [formRefCoupons?.current?.values.coupons]);

  return (
    <Page>
      <Container>
        <Page.Title></Page.Title>

        {service ? (
          <>
            <Summary
              item={service}
              onCountChange={onCountChange}
              price={prices}
              totalPrice={totalPrice}
              discountPrice={discountCodePrice}
              selectedDepartment={selectedDepartment}
              serviceLink={serviceLink}
              onRemove={async (id, timeSlotId) => {
                // await handleOneCartItemRemove(id, timeSlotId);
                // history.push('/');
              }}
            />
            {selectedDepartment && (
              <Styled.CouponFieldsParent>
                <Styled.CouponFields active={true}>
                  <Formik
                    innerRef={formRefCoupons}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      coupons: [
                        {
                          couponNumber: '',
                          couponSecondaryLabel: false,
                          couponBalance: 0,
                          couponLabel: false,
                          couponButton: false,
                          inputVisible: true,
                        },
                      ],
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      setFieldError,
                      setFieldValue,
                    }) => (
                      <>
                        <Form>
                          <Form.Group>
                            {selectedDepartment && (
                              <Styled.CouponCheckbox>
                                <Checkbox
                                  showBorder={true}
                                  value={showGiftCoupon}
                                  checked={showGiftCoupon}
                                  onChange={(e) => {
                                    setShowGiftCoupon(e.target.checked);
                                    if (!e.target.checked) {
                                      setAppliedCouponNumbers((prev) =>
                                        prev.filter((cn) => false),
                                      );
                                    }
                                  }}
                                  innerRef={checkBoxRef}
                                >
                                  <Styled.Trans>
                                    <Trans i18nKey="cart.couponCheckbox" />
                                  </Styled.Trans>
                                </Checkbox>
                              </Styled.CouponCheckbox>
                            )}
                            {showGiftCoupon && (
                              <FieldArray
                                name="coupons"
                                render={(arrayHelpers) => (
                                  <div>
                                    {values.coupons.map((coupon, index) => {
                                      return (
                                        <Styled.MainContainerDiv
                                          message={false}
                                        >
                                          {values.coupons[index]
                                            .inputVisible && (
                                            <Form.Row>
                                              <Styled.CouponFieldsContainer>
                                                <Field
                                                  focus={true}
                                                  type={'text'}
                                                  component={TextField}
                                                  label={t(
                                                    'cart.fieldCouponLabel',
                                                  )}
                                                  name={`coupons.${index}.couponNumber`}
                                                  value={
                                                    values.coupons[index]
                                                      .couponNumber || ''
                                                  }
                                                  onChange={handleChange}
                                                />
                                                <Styled.CouponButtonContainer>
                                                  <Button
                                                    size="large"
                                                    onClick={async (value) => {
                                                      let cp =
                                                        values.coupons[
                                                          index
                                                        ].couponNumber.toString();

                                                      setFieldValue(
                                                        `coupons[${index}].couponSecondaryLabel`,
                                                        false,
                                                      );

                                                      if (
                                                        appliedCouponNumbers.includes(
                                                          cp,
                                                        )
                                                      ) {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.couponUsed',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      if (totalPrice <= 0) {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.overPrice',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      const response =
                                                        await checkCoupon(' ', {
                                                          couponNumber:
                                                            cp.toString(),
                                                          serviceId:
                                                            service.serviceId,
                                                          departmentId:
                                                            selectedPrice.departmentId,
                                                        });

                                                      if (
                                                        checkCouponResponse.status ===
                                                        400
                                                      ) {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.couponNotFound',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      if (
                                                        checkCouponResponse.status >
                                                        199
                                                      ) {
                                                        if (
                                                          response.rejectReason ==
                                                          'Expired'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponExpired',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.rejectReason ==
                                                          'Empty'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponBalanceEmpty',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.rejectReason ==
                                                          'Restricted'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponOtherService',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.rejectReason ==
                                                          'CompanyMismatch'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponCompany',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.statusId ===
                                                          'Rezervuotas'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponReserved',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.statusId ===
                                                          'Darbinis'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponOther',
                                                            ),
                                                          );
                                                          return;
                                                        }

                                                        if (
                                                          response.statusId ===
                                                          'Nebegalioja'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponExpired',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                        if (
                                                          response.statusId ===
                                                          'Panaudotas'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponSingleUsed',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                        if (
                                                          response.statusId ===
                                                          'Anuliuotas'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponCancelled',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                        if (
                                                          response.statusId !==
                                                          'Galioja'
                                                        ) {
                                                          setFieldError(
                                                            `coupons[${index}].couponNumber`,
                                                            t(
                                                              'coupons.couponNonApplicable',
                                                            ),
                                                          );
                                                          return;
                                                        }
                                                      }

                                                      if (cp === '') {
                                                        setFieldError(
                                                          `coupons[${index}].couponNumber`,
                                                          t(
                                                            'coupons.couponEmpty',
                                                          ),
                                                        );
                                                        return;
                                                      }

                                                      setAppliedCouponNumbers(
                                                        (prev) => [...prev, cp],
                                                      );

                                                      setFieldError(
                                                        `coupons[${index}].couponNumber`,
                                                        '',
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].couponLabel`,
                                                        true,
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].couponBalance`,
                                                        response.balance,
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].couponButton`,
                                                        true,
                                                      );
                                                      setFieldValue(
                                                        `coupons[${index}].inputVisible`,
                                                        false,
                                                      );
                                                      setChangeCount(
                                                        (prev) => prev + 1,
                                                      );
                                                    }}
                                                  >
                                                    Pritaikyti
                                                  </Button>
                                                </Styled.CouponButtonContainer>
                                              </Styled.CouponFieldsContainer>
                                            </Form.Row>
                                          )}
                                          {values.coupons[index]
                                            .couponLabel && (
                                            <Form.Row>
                                              <Styled.CouponFieldsContainer>
                                                <Styled.CouponParagraphContainer>
                                                  <p>
                                                    {' '}
                                                    {`Dovanų kuponas Nr.${values.coupons[index].couponNumber}, vertė
                                                  ${values.coupons[index].couponBalance}€`}
                                                  </p>
                                                </Styled.CouponParagraphContainer>
                                                <Styled.CouponButtonContainer>
                                                  <Button
                                                    size="large"
                                                    type="secondary"
                                                    style={{
                                                      backgroundColor: '#fff',
                                                      color: '#000',
                                                    }}
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index,
                                                      );
                                                      setChangeCount(
                                                        (prev) => prev + 1,
                                                      );

                                                      setAppliedCouponNumbers(
                                                        (prev) =>
                                                          prev.filter(
                                                            (cp) =>
                                                              cp !==
                                                              values.coupons[
                                                                index
                                                              ].couponNumber,
                                                          ),
                                                      );
                                                    }}
                                                  >
                                                    Pašalinti
                                                  </Button>
                                                </Styled.CouponButtonContainer>
                                              </Styled.CouponFieldsContainer>
                                            </Form.Row>
                                          )}
                                        </Styled.MainContainerDiv>
                                        //end of main div
                                      );
                                    })}
                                    {values.coupons &&
                                      values.coupons[values.coupons.length - 1]
                                        ?.couponButton && (
                                        <Form.Row>
                                          <Button
                                            size="large"
                                            type="secondary"
                                            onClick={() => {
                                              arrayHelpers.insert(
                                                values.coupons.length + 1,
                                                {
                                                  couponNumber: '',
                                                  couponLabel: false,
                                                  inputVisible: true,
                                                },
                                              );
                                            }}
                                          >
                                            Pridėti dar vieną dovanų kuponą
                                          </Button>
                                        </Form.Row>
                                      )}
                                  </div>
                                )}
                              />
                            )}
                          </Form.Group>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Styled.CouponFields>
              </Styled.CouponFieldsParent>
            )}

            {false && selectedDepartment && (
              <Styled.CouponFieldsParent>
                <Styled.CouponFields active={true}>
                  <Formik
                    innerRef={formRefDiscount}
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{
                      discount: {
                        discountCode: '',
                        couponSecondaryLabel: false,
                        couponLabel: false,
                        inputVisible: true,
                      },
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      setFieldError,
                      setFieldValue,
                    }) => (
                      <>
                        <Form>
                          <Form.Group>
                            {selectedDepartment && (
                              <Styled.CouponCheckbox>
                                <Checkbox
                                  showBorder={true}
                                  value={showDiscountCoupon}
                                  checked={showDiscountCoupon}
                                  onChange={(e) => {
                                    if (!e.target.checked) {
                                      values.discount.discountCode = '';
                                      values.discount.couponSecondaryLabel =
                                        false;
                                      values.discount.couponLabel = false;
                                      values.discount.inputVisible = true;
                                    }
                                    handleDiscountCodeCheckboxChange(
                                      e.target.checked,
                                    );
                                  }}
                                  innerRef={checkBoxRef}
                                >
                                  <Styled.Trans>
                                    <Trans i18nKey="cart.discountCodeCheckbox" />
                                  </Styled.Trans>
                                </Checkbox>
                              </Styled.CouponCheckbox>
                            )}
                            <div>
                              {showDiscountCoupon && (
                                <Styled.MainContainerDiv message={false}>
                                  {values.discount.inputVisible && (
                                    <Form.Row>
                                      <Styled.CouponFieldsContainer>
                                        <Field
                                          focus={true}
                                          type={'text'}
                                          component={TextField}
                                          label={t(
                                            'cart.fieldDiscountCodeLabel',
                                          )}
                                          name={`discount.discountCode`}
                                          value={
                                            values.discount.discountCode || ''
                                          }
                                          onChange={handleChange}
                                        />
                                        <Styled.CouponButtonContainer>
                                          <Button
                                            size="large"
                                            onClick={async (value) => {
                                              let dc =
                                                values.discount.discountCode.toString();

                                              if (dc === '') {
                                                setFieldError(
                                                  `discount.discountCode`,
                                                  t('coupons.couponEmpty'),
                                                );
                                                return;
                                              }

                                              const response =
                                                await checkDiscountCode(' ', {
                                                  discountCode: dc.toString(),
                                                  serviceId: service.serviceId,
                                                  departmentId:
                                                    selectedPrice.departmentId,
                                                  visitType: 'Main',
                                                  currentPrice:
                                                    selectedPrice.price,
                                                });

                                              if (
                                                checkDiscountCodeResponse.status ===
                                                400
                                              ) {
                                                setFieldError(
                                                  `discount.discountCode`,
                                                  t(
                                                    'coupons.discountCodeNotFound',
                                                  ),
                                                );
                                                return;
                                              }

                                              if (
                                                checkDiscountCodeResponse.status >
                                                199
                                              ) {
                                                if (!response.isPass) {
                                                  setFieldError(
                                                    `discount.discountCode`,
                                                    `Jums jau pritaikyta didesnė nuolaida. Nuolaidos nesumuojamos.`,
                                                  );
                                                  setDiscountCodePrice(0);
                                                  return;
                                                } else {
                                                  setDiscountCodePrice(
                                                    response.price,
                                                  );
                                                  setFieldError(
                                                    `discount.discountCode`,
                                                    '',
                                                  );
                                                  setFieldValue(
                                                    `discount.couponLabel`,
                                                    true,
                                                  );
                                                  setFieldValue(
                                                    `discount.inputVisible`,
                                                    false,
                                                  );
                                                  setDiscountCodeNumber(dc);
                                                }
                                              }
                                            }}
                                          >
                                            Pritaikyti
                                          </Button>
                                        </Styled.CouponButtonContainer>
                                      </Styled.CouponFieldsContainer>
                                    </Form.Row>
                                  )}
                                  {values.discount.couponLabel && (
                                    <Form.Row>
                                      <Styled.CouponFieldsContainer>
                                        <Styled.CouponParagraphContainer>
                                          <p>{`Nuolaidos kodas "${discountCodeNumber}" priimtas`}</p>
                                        </Styled.CouponParagraphContainer>
                                      </Styled.CouponFieldsContainer>
                                    </Form.Row>
                                  )}
                                </Styled.MainContainerDiv>
                              )}
                            </div>
                          </Form.Group>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Styled.CouponFields>
              </Styled.CouponFieldsParent>
            )}

            <Tabs active={0}>
              <Tabs.List>
                <Tabs.Tab>{t('cart.tab1Name')}</Tabs.Tab>
                <Tabs.Tab>{t('cart.tab2Name')}</Tabs.Tab>
              </Tabs.List>

              <Tabs.Content>
                <Formik
                  innerRef={formRef}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    patientName: userState.firstName || '',
                    patientSurname: userState.lastName || '',
                    patientEmail: userState.email || '',
                    patientPhoneNumber: userState.phoneNumber || '',
                    patientIdNumber: userState.containsPersonalCode
                      ? userState.personalCode
                      : '',
                    department: null,
                    agreement2: false,
                    agreement3: false,
                    childName: userState.childName || '',
                    childSurname: userState.childSurname || '',
                    childIdNumber: userState.childIdNumber || '',
                    childEspbiEsi: userState.espbiEsi || '',
                  }}
                  validationSchema={validationSchema()}
                  onSubmit={showPaymentOptions}
                >
                  {({
                    values,
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    errors,
                    setFieldValue,
                  }) => (
                    <>
                      <Form>
                        <Form.Group>
                          <Form.Row>
                            <SelectField
                              options={departments}
                              defaultValue={selectedDepartmentObj}
                              error={errors.department}
                              label={'Padalinys'}
                              name="department"
                              onChange={(value) => {
                                setFieldValue('department', value);
                                setSelectedDepartment(value);
                                setChangedFilter(true);
                                handleDiscountCodeCheckboxChange(false);
                              }}
                            />
                          </Form.Row>

                          {selectedDepartment && (
                            <>
                              <Form.Row>
                                <Checkbox
                                  name="otherPerson"
                                  checked={values.otherPerson}
                                  onChange={() => {
                                    if (values.otherPerson) {
                                      [
                                        'childName',
                                        'childSurname',
                                        'childBirthDate',
                                        'childIdNumber',
                                        'childEspbiEsi',
                                      ].forEach((key) => {
                                        setFieldValue(key, '');
                                      });
                                    }
                                    setFieldValue(
                                      'otherPerson',
                                      !values.otherPerson,
                                    );
                                  }}
                                >
                                  {t('registration.form.fieldChildCheckbox')}
                                </Checkbox>
                              </Form.Row>
                              <Form.Title>
                                {t('cart.patientGroupLabel')}
                              </Form.Title>
                              {values.otherPerson && (
                                <Form indent>
                                  <Form.Row>
                                    {userState?.isLoggedIn && (
                                      <Field
                                        component={ChildDropdown}
                                        onChildSelect={handleChildSelect}
                                        name="childName"
                                        value={values.childName}
                                        availableKids={kids}
                                        label={t(
                                          'registration.form.fieldNameLabel',
                                        )}
                                        onChange={handleChange}
                                        error={errors.childName}
                                      />
                                    )}

                                    {!userState?.isLoggedIn && (
                                      <Field
                                        component={TextField}
                                        name="childName"
                                        value={values.childName}
                                        label={t(
                                          'registration.form.fieldNameLabel',
                                        )}
                                        onChange={handleChange}
                                        error={errors.childName}
                                      />
                                    )}

                                    <Field
                                      component={TextField}
                                      name="childSurname"
                                      value={values.childSurname}
                                      label={t(
                                        'registration.form.fieldSurnameLabel',
                                      )}
                                      onChange={handleChange}
                                      error={errors.childSurname}
                                    />

                                    <Field
                                      component={TextField}
                                      label={t('cart.fieldCodeLabel')}
                                      name="childIdNumber"
                                      value={values.childIdNumber}
                                      error={errors.childIdNumber}
                                      onChange={handleChange}
                                    />
                                  </Form.Row>
                                </Form>
                              )}

                              <Form.Group>
                                {values.otherPerson && (
                                  <Form.Title>
                                    {t('cart.buyerGroupLabel')}
                                  </Form.Title>
                                )}
                                <Form.Row>
                                  <Field
                                    focus={true}
                                    component={TextField}
                                    label={t('cart.fieldNameLabel')}
                                    name="patientName"
                                    error={errors.patientName}
                                    value={values.patientName}
                                    disabled={userState.isLoggedIn}
                                    onChange={handleChange}
                                  />
                                  <Field
                                    component={TextField}
                                    label={t('cart.fieldSurnameLabel')}
                                    name="patientSurname"
                                    error={errors.patientSurname}
                                    value={values.patientSurname}
                                    disabled={userState.isLoggedIn}
                                    onChange={handleChange}
                                  />
                                </Form.Row>
                              </Form.Group>
                              <Form.Row>
                                <Field
                                  component={TextField}
                                  label={t('cart.fieldCodeLabel')}
                                  name="patientIdNumber"
                                  error={errors.patientIdNumber}
                                  value={values.patientIdNumber?.replace(
                                    /\s/g,
                                    '',
                                  )}
                                  disabled={
                                    userState.isLoggedIn &&
                                    userState.containsPersonalCode
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                />
                                <div />
                              </Form.Row>
                              <Form.Group>
                                <Form.Title>
                                  {t('cart.contactGroupLabel')}
                                </Form.Title>
                                <Form.Row>
                                  <Form.Item>
                                    <Field
                                      component={TextField}
                                      label={t('cart.fieldPhoneLabel')}
                                      name="patientPhoneNumber"
                                      error={errors.patientPhoneNumber}
                                      value={values.patientPhoneNumber}
                                      disabled={userState.isLoggedIn}
                                      onChange={handleChange}
                                    />
                                  </Form.Item>
                                  <Form.Item>
                                    <Field
                                      component={TextField}
                                      label={t('cart.fielEmailLabel')}
                                      name="patientEmail"
                                      error={errors.patientEmail}
                                      value={values.patientEmail}
                                      disabled={userState.isLoggedIn}
                                      onChange={handleChange}
                                    />
                                  </Form.Item>
                                </Form.Row>
                              </Form.Group>

                              <Form.Group>
                                <Form.Title>
                                  {t('cart.agreementGroulLabel')}
                                </Form.Title>
                                <Form.Row>
                                  <Checkbox
                                    name="agreement2"
                                    value="true"
                                    onChange={handleChange}
                                    error={errors.agreement2}
                                  >
                                    <Trans
                                      i18nKey="cart.fieldAgreement2Label"
                                      components={{
                                        a: (
                                          //  eslint-disable-next-line jsx-a11y/anchor-has-content
                                          <a
                                            href={agreements.personalData.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          />
                                        ),
                                      }}
                                    />
                                  </Checkbox>
                                </Form.Row>
                                <Form.Row>
                                  <Checkbox
                                    name="agreement3"
                                    value="true"
                                    onChange={handleChange}
                                    error={errors.agreement3}
                                  >
                                    <Trans
                                      i18nKey="coupons.fieldAgreement3Label"
                                      components={{
                                        a: (
                                          //  eslint-disable-next-line jsx-a11y/anchor-has-content
                                          <a
                                            href={agreements.couponRules.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          />
                                        ),
                                      }}
                                    />
                                  </Checkbox>
                                </Form.Row>
                              </Form.Group>
                            </>
                          )}
                        </Form.Group>
                      </Form>
                      <Form.Actions>
                        <Button
                          size="large"
                          variant="secondary"
                          onClick={() => {
                            handleResetCart();
                            history.goBack();
                          }}
                        >
                          Atgal
                        </Button>
                        {selectedDepartment && (
                          <Button
                            size="large"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            onClick={() => {
                              setRedirectToPaysera(true);
                              handleSubmit();
                            }}
                          >
                            Eiti į apmokėjimą
                          </Button>
                        )}
                      </Form.Actions>
                    </>
                  )}
                </Formik>
              </Tabs.Content>
              <Tabs.Content></Tabs.Content>
            </Tabs>
          </>
        ) : (
          !redirectToPaysera && <NoResults>Prekių krepšelis tuščias</NoResults>
        )}
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
        }}
        showError={errorModalVisible}
      />

      <Modal visible={underAge} showClose={false} onClose={() => {}}>
        <Modal.TextContent>
          Nepilnamečius registruoti turi tėvai ar globėjai ir vaiko informaciją
          pateikti ankstesniame lange pažymėjus “Registruoju vaiką iki 18 metų.
          Pereiti į vaiko registracijos langą?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setUnderageModalVisibile(false);
            }}
          >
            Atšaukti
          </Button>
          <Button
            size="large"
            onClick={() => {
              const patientName = formRef.current.values.patientName;
              const patientSurname = formRef.current.values.patientSurname;
              const patientIdNumber = formRef.current.values.patientIdNumber;

              formRef.current.values.otherPerson = true;
              formRef.current.values.childName = patientName;
              formRef.current.values.childSurname = patientSurname;
              formRef.current.values.childIdNumber = patientIdNumber;
              formRef.current.values.patientName = '';
              formRef.current.values.patientSurname = '';
              formRef.current.values.patientIdNumber = '';
              formRef.current.errors.patientIdNumber = '';
              setUnderageModalVisibile(false);
            }}
          >
            Tęsti
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={showPhoneValidationModal}
        showClose={false}
        onClose={() => {
          setShowPhoneValidationModal(false);
        }}
      >
        <Modal.TextContent>
          Nurodytas telefono numeris {invalidPhoneNumber} yra klaidingo formato.
          Pakeisti numerį į {validPhoneNumber}?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowPhoneValidationModal(false);
            }}
          >
            Atsisakyti
          </Button>
          <Button
            size="large"
            onClick={() => {
              formRef.current.values.patientPhoneNumber = validPhoneNumber;
              formRef.current.validateField('patientPhoneNumber');
              saveValidationLog(
                `?changedFrom=${invalidPhoneNumber}&changedTo=${validPhoneNumber}`,
              );
              setShowPhoneValidationModal(false);
            }}
          >
            Pakeisti
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default CombinedServicesCheckout;
