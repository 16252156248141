/* eslint-disable jsx-a11y/anchor-is-valid */
import useFetch from 'use-http';
import React, { useEffect, useState } from 'react';
import { useEffectOnce, useStateWithHistory } from 'react-use';
import Page from '../../../../shared/Page';
import Styled from '../UserAccountPage.styles';
import { getQueryString } from '../../../../constants/visitsQueryStringBuilder';
import { useHistory } from 'react-router-dom';
import { useUserDispatch, useUserState } from '../../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../shared/Loader';
import Button from '../../../../shared/Button/Button';
import Moment from 'react-moment';
import DateRangePicker from '../../../../shared/DateRangePicker/DateRangePicker';
import { divide } from 'lodash';
import { string } from 'yup';

const ConsentLogs = () => {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState();
  const history = useHistory();
  const [search, setSearch] = useState();
  const userState = useUserState();
  const [dateRange, setDateRange] = useState(userState.logsFilter);
  const [skip, setSkip] = useState(0);
  const sessionDispatch = useUserDispatch();
  const { t } = useTranslation();
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);

  const { get: getConsentLogs, response: getAppLogsResponse } =
    useFetch(`/Logs/GetConsentLogs`);

  const applyChanges = async (date, search) => {
    setLoading(true);

    sessionDispatch({
      type: 'UPDATE',
      data: {
        logsFilter: date,
      },
    });
    const query = getQueryString(date, search)
      ? getQueryString(date, search) + `&skip=${skip}`
      : `?skip=${skip}`;
    const response = await getConsentLogs(query);
    if (getAppLogsResponse.status >= 400) {
    } else {
      if (search) {
        setSkip(0);
        setShowLoadMoreButton(false);
        setLogs(response);
      } else if (date && (date[0] || date[1])) {
        setSkip(0);
        setShowLoadMoreButton(false);
        setLogs(response);
      } else {
        if (response && response.length !== 50) {
          setShowLoadMoreButton(false);
        } else setShowLoadMoreButton(true);
        if (skip === 0) {
          setLogs(response);
          setSkip((skip) => skip + response.length);
        } else {
          setSkip((skip) => skip + response.length);
          setLogs((logs) => logs.concat(response));
        }
      }
    }
    setLoading(false);
  };
  useEffectOnce(() => {
    applyChanges(dateRange, search);
  });

  const handleSelection = async (obj) => {
    setLoading(true);
    history.push('/consentLog/details', {
      data: JSON.parse(obj),
    });
  };

  return (
    <Page>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Sutikimu keitimas</Styled.TabTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
              applyChanges(dateRange, e.target.search.value);
            }}
            onChange={(e) => {
              if (
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === ''
              ) {
                setSearch(e.target.value);
                applyChanges(dateRange, e.target.value);
              }
            }}
            style={{ display: 'flex' }}
          >
            <input
              type="text"
              id="search"
              placeholder="Paieška"
              name="search"
            />
            <Button
              style={{ marginRight: '15px', fontSize: '16px' }}
              size="small"
              type="submit"
            >
              Ieškoti
            </Button>
          </form>
          <DateRangePicker
            dateChanged={(date) => {
              setDateRange(date);
              applyChanges(date, search);
            }}
            dateRange={dateRange}
          />
        </Styled.TabTitleWrapper>

        {loading && !logs && (
          <Page>
            <Loader />
          </Page>
        )}

        {logs &&
          logs.length !== 0 &&
          logs.map((log, index) => {
            return (
              <div key={index}>
                <Styled.Visit>
                  <Styled.LogListRecord>
                    <a
                      onClick={() => {
                        handleSelection(log.request);
                      }}
                    >
                      <Moment
                        style={{ textDecoration: 'underline' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        utc={true}
                      >
                        {log.updatedAt}
                      </Moment>{' '}
                      <span>{log.userId}</span>
                    </a>
                    <span
                      style={{
                        display: 'inline-block',
                        marginTop: '5px',
                        textDecoration: 'none',
                      }}
                    >
                      {log.responseCode}
                    </span>
                  </Styled.LogListRecord>
                </Styled.Visit>
              </div>
            );
          })}
        {logs && logs.length !== 0 && showLoadMoreButton && (
          <Styled.LoadMoreBtn>
            <Button
              loading={loading}
              onClick={() => applyChanges(dateRange, search)}
            >
              Rodyti daugiau
            </Button>
          </Styled.LoadMoreBtn>
        )}

        {logs && logs.length === 0 && !loading && (
          <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
        )}
      </Styled.DataContainer>
    </Page>
  );
};
export default ConsentLogs;
