import React from 'react';
import Styled from './Container.styles';

const Container = ({ children, className, narrow = false, wide, marginTop }) => {
  return (
    <Styled.Container className={className} narrow={narrow} wide={wide} marginTop={marginTop}>
      {children}
    </Styled.Container>
  );
};

export default Container;
