import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import Button from '../../../shared/Button';
import Styled from './LoginOptions.styles';
import Form from '../../../shared/Form';
import TextField from '../../../shared/TextField';
import ErrorModal from '../../../shared/ErrorModal';
import SelectField from '../../../shared/SelectField';
import { useUserDispatch } from '../../../context/UserContext';
import useRedirection from '../../../hooks/useRedirection';
import personalCodeValidation from '../../../hooks/personalCodeValidation';
import AuthorizedPersons from '../UserAccountPage/AccountManagment/AuthorizedPersons/AuthorizedPersons';

const SmartIdLogin = () => {
  const { post: formPost, response: formResponse } = useFetch(
    `/UserAccount/MarkID/Login/SmartID`,
  );

  const [validatePersonalCode] = personalCodeValidation();

  const { t } = useTranslation();
  const history = useHistory();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const sessionDispatch = useUserDispatch();
  const [clearCartState] = useRedirection();
  const isFromAuthorizedPersons = history.location.state?.from;
  const [AuthorizedPersons, setAuthorizedPersons] = useState(
    history.location.state?.data,
  );

  const initialValues = {
    personalCode: '',
    countryCode: 'LT',
  };
  const countries = [
    { value: 'LT', label: 'Lietuva' },
    { value: 'LV', label: 'Latvija' },
    { value: 'ES', label: 'Estija' },
  ];

  useEffect(() => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);

  const validationSchema = Yup.object().shape({
    personalCode: Yup.number()
      .typeError(t('validation.personalCodeNumbers'))
      .required(t('validation.requiredField'))
      .test('len', t('validation.personalCode'), (val) => {
        return validatePersonalCode(val);
      }),
    countryCode: Yup.string().required(t('validation.requiredField')),
  });

  const handleSmartIdLogin = async (data, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    const response = await formPost('', {
      personalCode: data.personalCode?.replace(/\s/g, ''),
      countryCode: data.countryCode,
    });
    setSubmitting(false);

    if (formResponse.status >= 500) {
      setErrorModalVisible(true);
    } else if (formResponse.status >= 400) {
      const errors = {
        personalCode: response.exceptionDetails[0]?.message,
      };
      setErrors(errors);
    } else {
      sessionDispatch({
        type: 'UPDATE',
        data: {
          authenticationType: 'smartid',
          personalCode: data.personalCode?.replace(/\s/g, ''),
          countryCode: data.countryCode,
          initializedFromLogin: true,
          phoneNumberVerifyOptions: {
            token: response.token,
            controlCode: response.controlCode,
          },
        },
      });
      if (isFromAuthorizedPersons === 'AuthorizedPersonsConfirmation') {
        history.push('/confirmIdentity', {
          from: 'login',
          origin: 'authorizedPersons',
          data: AuthorizedPersons,
        });
        return;
      }

      history.push('/confirmIdentity', { from: 'login' });
    }
  };

  return (
    <Page loading={false}>
      <Container>
        <Styled.Form>
          {isFromAuthorizedPersons === 'AuthorizedPersonsConfirmation' ? (
            <Page.Title goBack={true}>{t('login.smartIdTab.title')}</Page.Title>
          ) : (
            <Page.Title backUrl={`/selfservice`} greyTheme={true}>
              {t('login.smartIdTab.title')}
            </Page.Title>
          )}

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSmartIdLogin}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Styled.SmartIdTitle greyTheme={true}>
                      {t('login.smartIdTab.tabName')}
                    </Styled.SmartIdTitle>
                    <Form.Row>
                      <Field
                        focus={true}
                        name="personalCode"
                        placeholder={t('login.smartIdTab.placeholder')}
                        fontSize={'larger'}
                        value={values.personalCode.replace(/\s/g, '')}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                    <Form.Row>
                      <SelectField
                        error={errors.companyId}
                        defaultValue={{ value: 'LT', label: 'Lietuva' }}
                        name="countryCode"
                        label={t('login.smartIdTab.country')}
                        options={countries}
                        onChange={(value) => {
                          setFieldValue('countryCode', value);
                        }}
                        greyTheme={true}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>
                <div className="mt-4 mt-md-2 d-flex align-center justify-center">
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    onClick={() => handleSmartIdLogin}
                  >
                    {t('login.smartIdTab.continue')}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Styled.Form>
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
        }}
        showError={errorModalVisible}
      />
    </Page>
  );
};

export default SmartIdLogin;
