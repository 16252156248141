import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Filter = styled.div`
  position: relative;
  background-color: #f1f5f7;
  padding: ${(props) => (props.greyTheme ? '14px 0 0' : '35px 0 10px;')};
  z-index: 8;
`;

const List = styled.div`
  display: flex;
  margin: 0 -15px;
  ${mediaDown('md')`
    flex-direction: column;
    margin: 0;
  `}
  ${(props) => props.isGrid && gridFilterDisplay}
  ${(props) => props.greyTheme && 'color: #52585F;'};
`;
const Item = styled.div`
  font-size: 16px;
  width: ${(props) => (props.isGrid ? 'width:100%;' : 'calc(100% / 2);')}
  margin: 0 7px;
  ${mediaDown('md')`
    width: 100%;
    margin: 0 0 7px 0;
  `}

  @media (max-width: 979px) {
    ${(props) => props.isLast && 'margin:0'}
 }

  ${(props) =>
    props.isGrid &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    `}
`;
const Actions = styled.div`
  text-align: right;
  margin-right: -10px;
  margin-top: 4px;
  order: 2;
`;

const ButtonClear = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  ${(props) => props.greyTheme && 'padding:14px 10px;'}
  cursor: pointer;
  transition: all 0.15s;
  &:hover {
    opacity: 0.5;
  }
  svg {
    display: block;
    margin-right: 5px;
  }
  svg path {
    ${(props) => props.greyTheme && 'fill: #52585F'}
  }
`;

const svgContainer = styled.div`
  svg {
    fill: red;
  }
`;

const Ordering = styled.div`
    font-size: 0.8rem;
    display: ${(props) => (props.isMain ? 'block;' : 'flex;')}
    cursor: pointer;
    user-select: none;
    position:relative;
    order:0;
    button {
      background-color:transparent;
      border:none;
      font-size: 14px;
      color:black;
      svg {
        fill: black;
        
      }
      position:relative;
      top:2px;
    }
    button:hover {
     
      color:black;
      svg {
        fill: black;
      }
    }
    :hover {
     
      color:black;
      svg {
        fill: black;
      }
    }
    svg {
      align-self: center;
      margin-right: 5px;
    }
    ${mediaDown('md')`
    
    button {
      line-height:0;
      font-size:14px; !important
      
    }
    svg {
      width:14px;      
    }
  `}
  `;

const OrderingIcon = styled.div`
  cursor: pointer;
  display: inline;
  margin: 2px 0 0 5px;
  user-select: none;
  z-index: 0;
  ${(props) => (props.isMobile ? 'padding-top: 5px;' : 'padding-top: 0px')}
  svg {
    fill: white;
  }
  svg:hover {
    fill: #28a745;
  }

  svg path {
    ${(props) => props.greyTheme && 'fill: #52585F'}
  }

  .upsideDown {
    svg:hover {
      fill: #28a745;
    }
    transform: rotate(180deg);
  }
  ${mediaDown('md')`
  margin-top: 0px;
  svg {
    width: 12px;
    height: 12px;
    fill: rgb(241, 239, 237);
    z-index:-1;
  }
`}
`;

const OrderingMenu = styled.div`
  position: absolute;
  top: ${(props) => (props.openDown ? '40px !important' : '-180px !important')};
  padding: 12px;
  border-radius: 6px;
  background-color: #fff;
  width: 170px;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.08),
    0px 2px 8px rgba(37, 26, 26, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
  z-index: 29;
  ${mediaDown('md')`
  top:auto;
  bottom: auto;  
  background-color:  #fff;
`}
`;

const Option = styled.div`
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  text-align: left;
  color: '#52585F';
  p {
    padding: 2px 2px;
    font-size: 0.8rem;
    color: '#52585F';
  }

  &:not(:first-child) {
  }
  :hover {
    // color: #28a745;
    color: black;
    background-color: ${(props) => props.hoverColor};
  }
  ${mediaDown('md')`
  color:  black;
  
`}
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  ${mediaDown('md')`
      position:  absolute;
      height: 100vh;
    `}
`;
const Select = styled.select`
  bacground-color: transparent;
  font-size: 14px;
  border: 0;
  box-shadow: 0;
`;

const gridFilterDisplay = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  ${mediaDown('md')`
  grid-template-columns:1fr;
`}
`;

const Styled = {
  Filter,
  List,
  Item,
  Actions,
  ButtonClear,
  Ordering,
  OrderingIcon,
  OrderingMenu,
  Option,
  Backdrop,
  Select,
  svgContainer,
};

export default Styled;
