import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useEffectOnce, useRafState } from 'react-use';
import useFetch from 'use-http';
import * as Yup from 'yup';
import {
  useUserDispatch,
  useUserState,
} from '../../../../../context/UserContext';
import Button from '../../../../../shared/Button';
import Container from '../../../../../shared/Container';
import Form from '../../../../../shared/Form';
import Page from '../../../../../shared/Page';
import TextField from '../../../../../shared/TextField';
import Styled from '../../../SelfServicePage.styles';
import SessionStorage from '../../../../../constants/sessionStorage';

const AuthorizedPersonsConfirmation = () => {
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const { t } = useTranslation();
  const history = useHistory();
  const { post: loginStep1Post, response: loginStep1Response } = useFetch(
    `/UserAccount/Login/Step1`,
  );

  const types = {
    smartid: 'smartid',
    msign: 'msignature',
    credentials: 'credentials',
  };

  const handleSelection = async (type) => {
    history.push(`/login/${type}`, {
      from: 'AuthorizedPersonsConfirmation',
      data: {
        value: history.location.state?.data.value,
        id: history.location.state.data.id,
        consentId: history.location.state.data.consentId,
      },
    });
  };

  return (
    <Page loading={false}>
      <Container>
        <Styled.FormWrapper isMediumWidth>
          <Styled.Form>
            <Page.Title goBack={true}>
              {t('login.confirmationTitle')}
            </Page.Title>
            <Styled.Buttons>
              <Button
                loading={false}
                disabled={false}
                size="mega"
                type="submit"
                style={{ width: 'inherit' }}
                onClick={() => handleSelection(types.smartid)}
              >
                {t('login.smartId')}
              </Button>
            </Styled.Buttons>
            <Styled.Buttons>
              <Button
                loading={false}
                disabled={false}
                size="mega"
                type="submit"
                style={{ width: 'inherit' }}
                onClick={() => handleSelection(types.msign)}
              >
                {t('login.msignature')}
              </Button>
            </Styled.Buttons>
          </Styled.Form>
        </Styled.FormWrapper>
      </Container>
    </Page>
  );
};

export default AuthorizedPersonsConfirmation;
