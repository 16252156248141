import React, { useState, useRef, useEffect } from 'react';
import Container from '../../shared/Container';
import Page from '../../shared/Page';
import { Formik, Field } from 'formik';
import Tabs from '../../shared/Tabs';
import Form from '../../shared/Form';
import TextField from '../../shared/TextField';
import { useTranslation, Trans } from 'react-i18next';
import { useUserState } from '../../context/UserContext';
import { useCartState, useCartDispatch } from '../../context/CartContext';
import { cloneDeep } from 'lodash';
import Summary from './Summary/Summary';
import Checkbox from '../../shared/Checkbox';
import NoResults from '../../shared/NoResults';
import { useSettingsState } from '../../context/SettingsContext';
import usePhoneValidation from '../../hooks/usePhoneValidator';
import * as Yup from 'yup';
import useFetch from 'use-http';
import Modal from '../../shared/Modal';
import Button from '../../shared/Button';
import ErrorModal from '../../shared/ErrorModal';
import { Redirect, useHistory } from 'react-router-dom';
import { exponeaCapture } from '../../hooks/exponeaCapture';
const CouponCartPage = () => {
  const { coupon: coupon } = useCartState();
  const userState = useUserState();
  const { t } = useTranslation();
  const [cartItems, setCartItem] = useState(coupon);
  const { agreements } = useSettingsState();
  const [couponCount, setCouponCount] = useState(1);

  const [underAge, setUnderageModalVisibile] = useState(false);
  const validatePhoneNumber = usePhoneValidation();
  const [validPhoneNumber, setValidPhoneNumber] = useState();
  const [redirectToPaysera, setRedirectToPaysera] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState();
  const [showPhoneValidationModal, setShowPhoneValidationModal] =
    useState(false);
  const [phoneValidated, setPhoneValidated] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const { get: saveValidationLog, response: saveValidationLogResponse } =
    useFetch('/Logs/saveValidationLog');
  const formRef = useRef();
  const history = useHistory();
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const dispatch = useCartDispatch();

  // const { step: step, registrationServices: products } = useCartState();
  const { request: registrationRequest, response: registrationResponse } =
    useFetch('/Registrations/Coupons');

  const handleResetCart = async () => {
    dispatch({
      type: 'RESET_CART',
    });
  };

  const validationSchemaForNotLoggedInUser = () => {
    const schema = {
      patientName: Yup.string().required(t('validation.requiredField')).trim(),
      patientSurname: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      patientEmail: Yup.string()
        .email(t('validation.invalidFormat'))
        .required(t('validation.requiredField')),
      patientPhoneNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim()
        .test(
          'phone',
          'Telefono numerio formatas netinkamas',
          (val, { createError }) => {
            var phone = validatePhoneNumber(val);
            if (phone.valid && phone.format && phone.changed) {
              formRef.current.values.patientPhoneNumber = phone.newValue;
              saveValidationLog(
                `?changedFrom=${phone.oldVal}&changedTo=${phone.newValue}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal &&
              !phone.showModal
            ) {
              formRef.current.values.patientPhoneNumber = phone.newVal;
              // formRef.current.validateField('patientPhoneNumber');
              saveValidationLog(
                `?changedFrom=${phone.orgVal}&changedTo=${phone.newVal}`,
              );
              return true;
            } else if (
              phone.valid &&
              phone.format &&
              phone.orgVal &&
              phone.newVal
            ) {
              setValidPhoneNumber(phone.newVal);
              setInvalidPhoneNumber(phone.orgVal);
              setShowPhoneValidationModal(true);
            }
          },
        ),
      agreement2: Yup.bool().oneOf([true], t('validation.requiredField')),
      agreement3: Yup.bool().oneOf([true], t('validation.requiredField')),
    };

    return Yup.object().shape(schema);
  };

  const validationSchemaForLoggedInUser = () => {
    const schema = {
      patientName: Yup.string().required(t('validation.requiredField')).trim(),
      patientSurname: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      patientEmail: Yup.string()
        .email(t('validation.invalidFormat'))
        .required(t('validation.requiredField')),
      patientPhoneNumber: Yup.string()
        .required(t('validation.requiredField'))
        .trim(),
      agreement2: Yup.bool().oneOf([true], t('validation.requiredField')),
      agreement3: Yup.bool().oneOf([true], t('validation.requiredField')),
    };

    return Yup.object().shape(schema);
  };

  const validationSchema = () => {
    return userState.isLoggedIn && userState.isLoggedIn !== null
      ? validationSchemaForLoggedInUser()
      : validationSchemaForNotLoggedInUser();
  };

  function showPaymentOptions(formData, { setErrors, setSubmitting }) {
    setSubmitting(true);
    setFormSubmitting(true);
    let paymentType = 'Paysera';
    // GET CCORRECCT PAYMENT TYPE
    const form = cloneDeep({
      patientEmail: formData.patientEmail,
      patientName: formData.patientName,
      patientPhoneNumber: formData.patientPhoneNumber,
      patientSurname: formData.patientSurname,
      patientPersonalCode: userState.isLoggedIn ? userState.personalCode : null,
      amount: coupon.price,
      couponId: coupon.couponId,
      couponPriceId: coupon.priceId,
      count: couponCount,
      frontUrls: {
        cancelFrontURL: '/dovanu-kuponai',
        acceptFrontUrl: '/coupon/checkout',
        errorFrontUrl: '/coupon/checkout',
      },
    });

    delete form.agreement2;
    delete form.agreement3;

    const baseObj = [
      { name: 'category_1', value: 'Dovanų kuponai' },
      { name: 'category_2', value: coupon.title },
      { name: 'Firstname', value: formData.patientName },
      { name: 'Lastname', value: formData.patientSurname },
      { name: 'email', value: formData.patientEmail },
      { name: 'Count', value: couponCount },
      { name: 'Price', value: coupon.price * couponCount },
      { name: 'Payment', value: 'Paysera' },
      { name: 'product_id', value: coupon.couponId },
      { name: 'category_id', value: 3 },
      { name: 'mobile_phone', value: formData.patientPhoneNumber },
    ];

    registrationRequest.post('', form).then(async () => {
      const response = await registrationResponse.json();

      baseObj.push({
        name: 'booking_booking_id',
        value: response.couponOrderId,
      });

      baseObj.push({
        name: 'booking_payment_gateway_id',
        value: response.couponOrderId,
      });

      baseObj.push({
        name: 'purchase_total_cost',
        value: coupon.price * couponCount,
      });
      if (registrationResponse.status >= 500) {
        setSubmitting(false);
        setFormSubmitting(false);
        baseObj.push({ name: 'Status', value: 'Failed' });
        baseObj.push({ name: 'booking_stage', value: 'Cancelled' });
        exponeaCapture(baseObj, 'booking');
        setErrorModalVisible(true);
      } else if (registrationResponse.status >= 400) {
        const { errors } = response;
        baseObj.push({ name: 'Status', value: 'Failed' });
        baseObj.push({ name: 'booking_stage', value: 'Cancelled' });
        exponeaCapture(baseObj, 'booking');
        setErrors(errors);
      } else {
        sessionStorage.setItem('exponeaTrack', JSON.stringify(baseObj));
        exponeaCapture(baseObj, 'booking_item');
        const payseraUrl = response.paymentUrl;
        dispatch({
          type: 'RESET_CART',
        });

        window.location.replace(payseraUrl);
      }
    });
  }
  function onCountChange(val) {
    setCouponCount(val);
  }
  return (
    <Page>
      <Container>
        <Page.Title></Page.Title>

        {coupon ? (
          <>
            <Summary
              item={cartItems}
              onCountChange={onCountChange}
              onRemove={async (id, timeSlotId) => {
                // await handleOneCartItemRemove(id, timeSlotId);
                // history.push('/');
              }}
            />

            <Tabs active={0}>
              <Tabs.List>
                <Tabs.Tab>{t('cart.tab1Name')}</Tabs.Tab>
                <Tabs.Tab>{t('cart.tab2Name')}</Tabs.Tab>
              </Tabs.List>

              <Tabs.Content>
                <Formik
                  innerRef={formRef}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{
                    patientName: userState.firstName || '',
                    patientSurname: userState.lastName || '',
                    patientEmail: userState.email || '',
                    patientPhoneNumber: userState.phoneNumber || '',
                    agreement2: false,
                    agreement3: false,
                  }}
                  validationSchema={validationSchema()}
                  onSubmit={showPaymentOptions}
                >
                  {({
                    values,
                    handleChange,
                    isSubmitting,
                    handleSubmit,
                    errors,
                  }) => (
                    <>
                      <Form>
                        <Form.Group>
                          <Form.Title>{t('cart.buyerGroupLabel')}</Form.Title>
                          <Form.Row>
                            <Field
                              focus={true}
                              component={TextField}
                              label={t('cart.fieldNameLabel')}
                              name="patientName"
                              error={errors.patientName}
                              value={values.patientName}
                              disabled={userState.isLoggedIn}
                              onChange={handleChange}
                            />
                            <Field
                              component={TextField}
                              label={t('cart.fieldSurnameLabel')}
                              name="patientSurname"
                              error={errors.patientSurname}
                              value={values.patientSurname}
                              disabled={userState.isLoggedIn}
                              onChange={handleChange}
                            />
                          </Form.Row>
                        </Form.Group>
                        <Form.Group>
                          <Form.Title>{t('cart.contactGroupLabel')}</Form.Title>
                          <Form.Row>
                            <Form.Item>
                              <Field
                                component={TextField}
                                label={t('cart.fieldPhoneLabel')}
                                name="patientPhoneNumber"
                                error={errors.patientPhoneNumber}
                                value={values.patientPhoneNumber}
                                disabled={userState.isLoggedIn}
                                onChange={handleChange}
                              />
                            </Form.Item>
                            <Form.Item>
                              <Field
                                component={TextField}
                                label={t('cart.fielEmailLabel')}
                                name="patientEmail"
                                error={errors.patientEmail}
                                value={values.patientEmail}
                                disabled={userState.isLoggedIn}
                                onChange={handleChange}
                              />
                            </Form.Item>
                          </Form.Row>
                        </Form.Group>
                        <Form.Group>
                          <Form.Title>
                            {t('cart.agreementGroulLabel')}
                          </Form.Title>
                          <Form.Row>
                            <Checkbox
                              name="agreement2"
                              value="true"
                              onChange={handleChange}
                              error={errors.agreement2}
                            >
                              <Trans
                                i18nKey="cart.fieldAgreement2Label"
                                components={{
                                  a: (
                                    //  eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a
                                      href={agreements.personalData.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    />
                                  ),
                                }}
                              />
                            </Checkbox>
                          </Form.Row>
                          <Form.Row>
                            <Checkbox
                              name="agreement3"
                              value="true"
                              onChange={handleChange}
                              error={errors.agreement3}
                            >
                              <Trans
                                i18nKey="coupons.fieldAgreement3Label"
                                components={{
                                  a: (
                                    //  eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a
                                      href={agreements.couponRules.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    />
                                  ),
                                }}
                              />
                            </Checkbox>
                          </Form.Row>
                        </Form.Group>
                      </Form>
                      <Form.Actions>
                        <Button
                          size="large"
                          variant="secondary"
                          onClick={() => {
                            handleResetCart();
                            history.push(
                              history.location.state.backPath.replace(
                                process.env.REACT_APP_BASE_ADDRESS,
                                '',
                              ),
                            );
                          }}
                        >
                          Atgal
                        </Button>
                        <Button
                          size="large"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          onClick={() => {
                            setRedirectToPaysera(true);
                            handleSubmit();
                          }}
                        >
                          Eiti į apmokėjimą
                        </Button>
                      </Form.Actions>
                    </>
                  )}
                </Formik>
              </Tabs.Content>
              <Tabs.Content></Tabs.Content>
            </Tabs>
          </>
        ) : (
          !redirectToPaysera && <NoResults>Prekių krepšelis tuščias</NoResults>
        )}
      </Container>
      <ErrorModal
        onClose={() => {
          setErrorModalVisible(false);
        }}
        showError={errorModalVisible}
      />

      <Modal
        visible={showPhoneValidationModal}
        showClose={false}
        onClose={() => {
          setShowPhoneValidationModal(false);
        }}
      >
        <Modal.TextContent>
          Nurodytas telefono numeris {invalidPhoneNumber} yra klaidingo formato.
          Pakeisti numerį į {validPhoneNumber}?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            variant="secondary"
            onClick={() => {
              setShowPhoneValidationModal(false);
            }}
          >
            Atsisakyti
          </Button>
          <Button
            size="large"
            onClick={() => {
              formRef.current.values.patientPhoneNumber = validPhoneNumber;
              formRef.current.validateField('patientPhoneNumber');
              saveValidationLog(
                `?changedFrom=${invalidPhoneNumber}&changedTo=${validPhoneNumber}`,
              );
              setShowPhoneValidationModal(false);
            }}
          >
            Pakeisti
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default CouponCartPage;
