import styled, { css } from 'styled-components/macro';
import { rgba } from 'polished';
import ReactSelect from 'react-select';
import { mediaDown } from '../../../utils/styles/media';

const SelectHolder = styled.div`
  position: relative;
  cursor: pointer;
`;

const Caret = styled.div`
  svg {
    width: 14px;
    height: auto;
  }

  svg path {
    ${(props) => props.greyTheme && 'fill:#52585F;'}
  }
`;

const errorCss = css`
  border-color: #f35b69;
  border-bottom-left-radius: 0;
`;

const Select = styled(ReactSelect)`
  .rs {
    &__control {
      height: 60px;
      border-color: transparent;
      border-radius: 10px;
      box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);

      ${(props) => props.error && errorCss}

      ${mediaDown('md')`
        height: 45px;
        font-size: 16px;
        line-height: 16px;
      `}

      &:hover {
        border-color: transparent;
      }

      &--is-focused {
        border-color: ${(props) => props.primaryColor};

        &:hover {
          border-color: ${(props) => props.primaryColor};
        }
      }
    }

    &__value-container {
      padding-left: 19px;

      ${mediaDown('md')`
        padding-left: 15px;
      `}
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      padding-right: 19px;

      ${mediaDown('md')`
        padding-right: 15px;
      `}
    }

    &__menu {
    }

    &__option {
      &:active,
      &--is-focused {
        background-color: ${(props) => rgba(props.primaryColor, 0.1)};
      }
      &--is-selected {
        background-color: ${(props) => props.primaryColor};
      }
    }
  }
`;

const Styled = {
  SelectHolder,
  Select,
  Caret,
};

export default Styled;
