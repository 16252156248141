/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import useFetch from 'use-http';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../../../../shared/DateRangePicker/DateRangePicker';
import Loader from '../../../../../shared/Loader';
import Page from '../../../../../shared/Page';
import { useHistory } from 'react-router-dom';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import { getQueryString } from '../../../../../constants/visitsQueryStringBuilder';
import Button from '../../../../../shared/Button/Button';
import SelectField from '../../../../../shared/SelectField/SelectField';
import { useEffectOnce } from 'react-use';
const PathologyResearches = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [Researches, setResearches] = useState();
  const [loading, setLoading] = useState(false);
  const sessionDispatch = useUserDispatch();
  const [Filters, setFilters] = useState();
  const [SelectedFilter, setSelectedFilter] = useState();
  const [search, setSearch] = useState();
  const userState = useUserState();
  const [dateRange, setDateRange] = useState(userState.dateFilter);

  const { get: getResearches, response: getResearchesResponse } = useFetch(
    '/UserAccount/PathologyResearches',
  );

  const { get: getResearch } = useFetch('/UserAccount/PathologyResearch');

  const applyChanges = async (date, search, filter) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getResearches(
      getQueryString(date, search, null, filter, userState.espbiEsi),
    );
    if (getResearchesResponse.status >= 400) {
    } else {
      setResearches(response.reaserches);
      const tmpFilterArray = [];
      // eslint-disable-next-line array-callback-return
      response?.filters?.map((filter) => {
        tmpFilterArray.push({ value: filter.id, label: filter.displayName });
      });
      setFilters(tmpFilterArray);
    }
    setLoading(false);
  };

  useEffectOnce(() => {
    applyChanges(dateRange, search, SelectedFilter);
  });

  const handleSelection = async (id) => {
    setLoading(true);
    let query = userState.espbiEsi
      ? `?id=${id}&espbiEsi=${userState.espbiEsi}`
      : `?id=${id}`;
    await getResearch(query).then((res) => {
      setLoading(false);
      history.push('/account/pathology-research', { data: res });
    });
  };

  return (
    <Styled.DataContainer>
      <Styled.TabTitleWrapper select={true}>
        <Styled.TabTitle>Patologiniai tyrimai </Styled.TabTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target.search.value);
            applyChanges(dateRange, e.target.search.value, SelectedFilter);
          }}
          onChange={(e) => {
            if (
              e.target.value === undefined ||
              e.target.value === null ||
              e.target.value === ''
            ) {
              setSearch(e.target.value);
              applyChanges(dateRange, e.target.value, SelectedFilter);
            }
          }}
          style={{ display: 'flex' }}
        >
          <input type="text" id="search" placeholder="Paieška" name="search" />
          <Button
            style={{ marginRight: '15px', fontSize: '16px' }}
            size="small"
            type="submit"
          >
            Ieškoti
          </Button>
        </form>
        <Styled.SelectField>
          <SelectField
            options={Filters}
            isClearable={true}
            placeHolder={'Būsena'}
            onChange={(val) => {
              setSelectedFilter(val);
              applyChanges(dateRange, search, val);
            }}
          ></SelectField>
        </Styled.SelectField>
        <DateRangePicker
          dateChanged={(date) => {
            setDateRange(date);
            applyChanges(date, search, SelectedFilter);
          }}
          dateRange={dateRange}
        />
      </Styled.TabTitleWrapper>
      {loading && (
        <Page>
          <Loader />
        </Page>
      )}
      {!loading &&
        Researches &&
        Researches.length !== 0 &&
        _.orderBy(Researches, ['orderDate'], ['desc']).map((obj) => {
          return (
            <div key={obj.id}>
              <Styled.Visit>
                <Styled.VisitTitleDateTime>
                  <a
                    onClick={() => {
                      handleSelection(obj.id);
                    }}
                  >
                    {obj.name}
                  </a>
                  {(new Date(Date.parse(obj.orderDate)).getUTCHours() === 0 && (
                    <Moment
                      utc={true}
                      onClick={() => {
                        handleSelection(obj.id);
                      }}
                      className="time"
                      format="YYYY-MM-DD"
                    >
                      {Date.parse(obj.orderDate)}
                    </Moment>
                  )) || (
                    <Moment
                      utc={true}
                      onClick={() => {
                        handleSelection(obj.id);
                      }}
                      className="time"
                      format="YYYY-MM-DD HH:mm"
                    >
                      {Date.parse(obj.orderDate)}
                    </Moment>
                  )}
                </Styled.VisitTitleDateTime>
                <div>
                  <span>{obj.status.displayName}</span>
                </div>
              </Styled.Visit>
            </div>
          );
        })}
      {Researches && Researches.length !== 0 && !loading && (
        <div style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}>
          {t('userAccount.visitHistoryTab.dateInfo')}
        </div>
      )}
      {Researches && Researches.length === 0 && !loading && (
        <>
          <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
          <div
            style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}
          >
            {t('userAccount.visitHistoryTab.dateInfo')}
          </div>
        </>
      )}
    </Styled.DataContainer>
  );
};
export default PathologyResearches;
