import React, { useState, useRef, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import { Formik, Field } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { cloneDeep } from 'lodash';
import Summary from './Summary/Summary';
import NoResults from '../../../shared/NoResults';
import * as Yup from 'yup';
import useFetch from 'use-http';
import { Redirect, useHistory } from 'react-router-dom';
import Styled from './CouponConfirmedPage.styles';
import Loader from '../../../shared/Loader';
import Modal from '../../../shared/Modal/Modal';
import Button from '../../../shared/Button/Button';
import { useSettingsState } from '../../../context/SettingsContext';
import { exponeaCapture } from '../../../hooks/exponeaCapture';

const CouponConfirmedPage = () => {
  const successMatchCoupon = useRouteMatch(
    '/coupon/checkout/:orderId',
  )?.isExact;

  const { phoneNumber } = useSettingsState();

  const successMatchCouponParams = useRouteMatch(
    '/coupon/checkout/:orderId',
  )?.params;

  const { request: couponDownloadRequest } = useFetch(
    `/CouponManagement/Download`,
  );

  const { request: couponRequest } = useFetch(`/CouponManagement/CouponStatus`);

  const [cartItem, setCartItem] = useState();
  const [fileGuids, setFileGuids] = useState();
  const [couponOrderId, setCouponOrderId] = useState();
  const [paymentPending, setPaymentPending] = useState();
  const [showEmptyFileModal, setShowEmptyFileModal] = useState(false);
  const history = useHistory();

  const logResultInExponea = (status) => {
    let exponea = JSON.parse(sessionStorage.getItem('exponeaTrack') || '[]');
    exponea.push({ name: 'Registration status', value: status });
    exponea.push({
      name: 'booking_stage',
      value: status === 'Success' ? 'Booked & Paid' : 'Cancelled',
    });
    exponeaCapture(exponea, 'booking');
    sessionStorage.removeItem('exponeaTrack');
  };

  useEffect(() => {
    setPaymentPending(true);
    if (successMatchCoupon && successMatchCouponParams?.orderId) {
      couponRequest
        .get(`?couponOrderId=${successMatchCouponParams?.orderId}`)
        .then((res) => {
          if (
            res.paymentStatus === 'Paid' &&
            res.succeeded &&
            !res.waitingForFiles
          ) {
            setPaymentPending(false);
            setCartItem({
              price: res.price,
              comment: res.comment,
              title: res.couponTitle,
              orderedCount: res.orderedCount,
              validTo: res.validTo,
            });
            setFileGuids(res.fileGuids);
            logResultInExponea('Success');
          } else if (
            res.paymentStatus === 'Pending' ||
            res.fileGuids == null ||
            res.fileGuids.length === 0
          ) {
            setPaymentPending(true);
            let interval = window.setInterval(() => {
              couponRequest
                .get(`?couponOrderId=${successMatchCouponParams?.orderId}`)
                .then((res) => {
                  if (!res.succeeded && res.fileGuids.length === 0) {
                    setShowEmptyFileModal(true);
                    logResultInExponea('Failed');
                  }
                  if (
                    res.paymentStatus === 'Paid' &&
                    res.fileGuids.length > 0
                  ) {
                    setCartItem({
                      price: res.price,
                      comment: res.comment,
                      title: res.couponTitle,
                      orderedCount: res.orderedCount,
                      validTo: res.validTo,
                    });
                    setFileGuids(res.fileGuids);
                    logResultInExponea('Success');
                    setPaymentPending(false);

                    clearInterval(interval);
                  }
                });
            }, 3000);
          }
        });
    }
  }, [couponRequest, successMatchCoupon, successMatchCouponParams?.orderId]);

  async function downloadCoupon(fileGuidsLength, file, idx) {
    await couponDownloadRequest.get(`?fileGuid=${file}`).then((res) => {
      // window.open(res, '_blank');
      var a = document.createElement('a');
      a.href = res;
      a.download =
        fileGuidsLength === 1
          ? 'Affidea dovanų kuponas.pdf'
          : `Affidea dovanų kuponas ${idx + 1}.pdf`;
      setTimeout(() => {
        a.click();
      }, 120);
    });
  }

  return (
    <>
      <Page>
        <Container style={{ marginTop: '50px' }}>
          {!paymentPending && fileGuids && fileGuids.length > 0 && (
            <h2 style={{ display: 'flex', justifyContent: 'center' }}>
              Jūs sėkmingai įsigijote dovanų kuponą
            </h2>
          )}

          {paymentPending && (
            <h2 style={{ display: 'flex', justifyContent: 'center' }}>
              Laukiama apmokėjimo informacijos
            </h2>
          )}

          {!paymentPending && fileGuids && fileGuids.length === 0 && (
            <h2 style={{ display: 'flex', justifyContent: 'center' }}>
              Jūsų mokėjimas už dovanų kuponą gautas
            </h2>
          )}

          {cartItem && !paymentPending && <Summary item={cartItem} />}

          {paymentPending && <Loader></Loader>}

          {!paymentPending &&
            fileGuids &&
            fileGuids.map((file, idx) => {
              return (
                <Styled.Download
                  onClick={() => downloadCoupon(fileGuids.length, file, idx)}
                >
                  {fileGuids.length === 1
                    ? `Atsisiųsti Affidea dovanų kuponą`
                    : `Atsisiųsti dovanų kuponą ${idx + 1}`}
                </Styled.Download>
              );
            })}
        </Container>

        <Modal
          visible={showEmptyFileModal}
          showClose={false}
          onClose={() => {
            setShowEmptyFileModal(false);
          }}
        >
          <Modal.Alert type="error">
            Įvyko klaidą generuojant dovanų kuponą
          </Modal.Alert>
          <Modal.TextContent>
            Prašome pranešti apie problemą paskambinę telefonu {phoneNumber}.
          </Modal.TextContent>
          <Modal.Actions>
            <Button
              size="large"
              onClick={() => {
                setShowEmptyFileModal(false);
              }}
            >
              Uždaryti
            </Button>
          </Modal.Actions>
        </Modal>
      </Page>
    </>
  );
};
export default CouponConfirmedPage;
