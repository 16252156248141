import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Styled = {
  Footer: styled.footer`
    height: 100px;
    background-color: #f5f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a0a0a0;
    font-size: 1.1rem;

    ${mediaDown('md')`
      height: 40px;
      font-size: 10px;
      line-height: 13px;
    `}
  `,

  FooterItem: styled.div`
    padding-right: 7px;
    padding-left: 7px;

    a {
      color: #a0a0a0;
      text-decoration: none;

      &:hover {
        color: #28a745;
      }
    }
  `,
};

export default Styled;
