import React, { useState, useEffect, useRef } from 'react';
import TextField from '../TextField/TextField';
import Styled from './ChildDropdown.styles';

const ChildDropdown = ({ availableKids, onChildSelect, value, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedChild, setSelectedChild] = useState(value);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelectedChild(value);
  }, [value]);

  const handleInputChange = (event) => {
    setSelectedChild(event.target.value);
    onChildSelect(event.target.value);
    if (availableKids && availableKids.length > 0) {
      setIsOpen(true);
    }
  };

  const onInputFocus = () => {
    if (availableKids && availableKids.length > 0) {
      setIsOpen(true);
    }
  };

  const handleChildSelect = (child) => {
    setSelectedChild(child.name);
    setIsOpen(false);
    onChildSelect(child);
  };

  return (
    <div ref={containerRef}>
      <TextField
        label={'Vardas'}
        type="text"
        value={selectedChild}
        onFocus={onInputFocus}
        onChange={handleInputChange}
        error={error}
        ref={inputRef}
      />
      {isOpen && (
        <Styled.UlElement>
          {availableKids?.map((child, idx) => (
            <li key={child + idx} onClick={() => handleChildSelect(child)}>
              {child.name} {child.surname}{' '}
              {child.personalCode != null ? `(${child.personalCode})` : ''}
            </li>
          ))}
        </Styled.UlElement>
      )}
    </div>
  );
};

export default ChildDropdown;
