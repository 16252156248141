import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Download = styled.span`
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-transform: uppercase;
`;

const Buttons = styled.div`
  margin-top: 25px;
  margin-right: 30px;
  height: 40px;
  display: flex;
  align-content: right;
  justify-content: right;
  width: inherit;
  margin-left: auto;
  align-self: flex-end;

  ${mediaDown('md')`
  margin-right: auto;
 `}
`;

const Inline = styled.div`
  display: inline-block;
`;

const Styled = { Download, Buttons, Inline };

export default Styled;
