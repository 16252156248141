import React, { useEffect, useState } from 'react';

import Styled from '../../UserAccountPage.styles';
import Button from '../../../../../shared/Button';
import Page from '../../../../../shared/Page';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../../shared/Loader';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import Moment from 'react-moment';
import moment from 'moment';
import DateRangePicker from '../../../../../shared/DateRangePicker/DateRangePicker';
import { getQueryString } from '../../../../../constants/visitsQueryStringBuilder';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import { useTranslation } from 'react-i18next';
const ConsentForms = () => {
  const { get: getConsents, response: getConsentsResponse } = useFetch(
    `/ConsentFrom/ConsentFroms`,
  );

  const {
    get: getConsentsFromDetails,
    response: getConsentsFromDetailsResponse,
  } = useFetch(`/ConsentFrom/ConsentFromsDetails`);

  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');

  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const history = useHistory();
  const [data, setData] = useState();
  const [signRequestUrl, setSignRequestUrl] = useState();
  const [consentDetails, setConsentDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(userState.dateFilter);
  const [search, setSearch] = useState();
  useEffect(() => {
    async function fetchData() {
      let query = '';
      if (userState?.espbiEsi) {
        query = `?espbiEsi=${userState?.espbiEsi}`;
      }
      await getConsents(query).then((res) => {
        setData(
          res.filter((x) => x.status !== 'LaukiamaIsankstinioPasirasymo'),
        );
      });
    }
    fetchData();
  }, [userState?.espbiEsi]);

  const { t } = useTranslation();

  const consentSelected = async (s) => {
    let query = `/${s.id}`;
    if (userState?.espbiEsi) {
      query = `/${s.id}?espbiEsi=${userState?.espbiEsi}`;
    }
    await getConsentsFromDetails(query).then((res) => {
      handleFileDownload(res);
    });
  };

  const handleFileDownload = async (s) => {
    await getFile(
      `?fileType=${s.file.type}&fileGuid=${
        s.file.guid
      }&category=${'AgreementsSoT'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download =
        s.name + ' ' + moment(new Date(s.date)).format('YYYY-MM-DD') + '.pdf';
      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const applyChanges = async (date, search, filter) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getConsents(
      getQueryString(date, search, null, filter, userState.espbiEsi),
    );
    if (getConsentsFromDetailsResponse.status >= 400) {
    } else {
      setData(
        response.filter((x) => x.status !== 'LaukiamaIsankstinioPasirasymo'),
      );
      // eslint-disable-next-line array-callback-return
    }
    setLoading(false);
  };

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper select={true}>
          <Styled.TabTitle
            style={{ cursor: 'pointer' }}
            onClick={() => history.goBack()}
          >
            Pasirašyti sutikimai{' '}
          </Styled.TabTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
              applyChanges(dateRange, e.target.search.value);
            }}
            onChange={(e) => {
              if (
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === ''
              ) {
                setSearch(e.target.value);
                applyChanges(dateRange, e.target.value);
              }
            }}
            style={{ display: 'flex' }}
          >
            <input
              type="text"
              id="search"
              placeholder="Paieška"
              name="search"
            />
            <Button
              style={{ marginRight: '15px', fontSize: '16px' }}
              size="small"
              type="submit"
            >
              Ieškoti
            </Button>
          </form>
          <DateRangePicker
            dateChanged={(date) => {
              setDateRange(date);
              applyChanges(date, search);
            }}
            dateRange={dateRange}
          />
        </Styled.TabTitleWrapper>
        {loading && (
          <Page>
            <Loader />
          </Page>
        )}

        {!loading &&
          data &&
          data.map((s) => {
            return (
              <div
                key={s.id}
                style={{
                  borderBottom: '1px solid black',
                  marginTop: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                }}
                onClick={async () => await consentSelected(s)}
              >
                {s.name}
                <span>
                  {s.status} ({' '}
                  <Moment utc={true} className="time" format="YYYY-MM-DD">
                    {Date.parse(s.date)}
                  </Moment>{' '}
                  )
                </span>{' '}
              </div>
            );
          })}

        {data && data.length === 0 && !loading && (
          <>
            <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
          </>
        )}
      </Styled.DataContainer>
    </Page>
  );
};
export default ConsentForms;
