import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import {
  useUserDispatch,
  useUserState,
} from '../context/UserContext/UserContext';
import IdleModal from '../shared/IdleModal';

const IdleTimerContainer = ({ children }) => {
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();

  const timeoutTime = process.env.REACT_APP_IDLE_TIMEOUT
    ? Number(process.env.REACT_APP_IDLE_TIMEOUT) * 60 * 1000
    : 60000;
  const idleTimerRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const sessionTimeoutRef = useRef(null);

  const onIdle = () => {
    setModalIsOpen(true);
    sessionTimeoutRef.current = setTimeout(logOut, 60000);
  };

  const stayActive = () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const logOut = () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    sessionDispatch({ type: 'LOGOUT' });
  };

  return userState.isLoggedIn ? (
    <IdleTimer ref={idleTimerRef} timeout={timeoutTime} onIdle={onIdle}>
      {modalIsOpen && (
        <IdleModal
          isVisible={modalIsOpen}
          onYes={stayActive}
          onNo={logOut}
        ></IdleModal>
      )}
      {children}
    </IdleTimer>
  ) : (
    children
  );
};
export default IdleTimerContainer;
