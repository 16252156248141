import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Form = styled.div`
  margin-top: 25px;
  display: grid;
  justify-content: center;
  width: 100%;
  ${mediaDown('md')`
    min-width: 200px;
  `}
`;

const Title = styled.h1`
  margin: 45px 0 45px 0;
  text-align: center;
  ${mediaDown('md')`
    font-size:24px;
  `}
`;
const SubTitle = styled.h3`
  margin-top: 30px;
  ${mediaDown('md')`
    
  `}
`;
const Buttons = styled.div`
  margin-top: 50px;
  display: inline-block;
  justify-content: center;
  margin: 25px;
`;
const Actions = styled.div`
  display: flex;
  justify-content: center;
`;
const PasswordExplanation = styled.div`
  display: flex;
  font-size: 16px;

  svg {
    width: 40px;
    margin-top: 10px;
  }
`;
const Styled = {
  Form,
  Title,
  SubTitle,
  Buttons,
  Actions,
  PasswordExplanation,
};

export default Styled;
