import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
const useCancelationToken = () => {
  const [
    previewRegistrationCancelationModalVisible,
    setPreviewRegistrationCancelationModalVisible,
  ] = useState(false);
  const [
    previewRegistrationCancelationFailureModalVisible,
    setPreviewRegistrationCancelationFailureModalVisible,
  ] = useState(false);

  const [
    manuallyConfirmedRegistrationVisible,
    setManuallyConfirmedRegistrationVisible,
  ] = useState(false);

  const [registrationCancelled, setRegistrationCancelled] = useState(false);

  const {
    post: cancelRegistrationRequestSend,
    response: cancelRegistrationResponse,
  } = useFetch(`/RegistrationManagement/Registrations/Cancel`);

  const cancelRegistration = async (cancelationToken) => {
    const response = await cancelRegistrationRequestSend('', {
      cancelationToken: cancelationToken,
    });

    if (cancelRegistrationResponse.status >= 400) {
      return;
    } else {
      if (response.registrationIsPaid) {
        setPreviewRegistrationCancelationFailureModalVisible(true);
      } else if (response.manuallyConfirmed) {
        setManuallyConfirmedRegistrationVisible(true);
      } else if (response.registrationIsCancelled) {
        setRegistrationCancelled(true);
      }
    }
  };

  return [
    previewRegistrationCancelationModalVisible,
    setPreviewRegistrationCancelationModalVisible,
    cancelRegistration,
    previewRegistrationCancelationFailureModalVisible,
    setPreviewRegistrationCancelationFailureModalVisible,
    manuallyConfirmedRegistrationVisible,
    setManuallyConfirmedRegistrationVisible,
    registrationCancelled,
    setRegistrationCancelled,
  ];
};

export default useCancelationToken;
