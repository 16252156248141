import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const List = styled.div`
  width: 285px;
  padding-right: 30px;

  ${mediaDown('md')`
    width: 100%;
    padding-right: 0;
  `}
`;

const activeTav = css`
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 39px;
  color: #000;

  ${mediaDown('md')`
    font-size: 16px;
    line-height: 20px;
    display: block;
  `}
`;

const Tab = styled.div`
  display: flex;
  margin: 24px 0;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.previous ? '#00B967' : '#9395A9')};
  ${mediaDown('md')`
    display: none;
    margin: 0 0 16px;
  `}

  ${(props) => props.active && activeTav}

  > div {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: ${(props) => (props.previous ? '#C0FBE4' : '#f0f5f7')};
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: #c8c9d9;
      display: ${(props) => (props.next ? 'block' : 'none')};
    }

    svg {
      display: ${(props) => (props.next ? 'none' : 'block')};
      height: 9px;

      path {
        fill: #00bd5d;
      }
    }
  }
`;

const Content = styled.div`
  width: calc(100% - 285px);
  display: ${(props) => (props.active ? 'block' : 'none')};

  ${mediaDown('md')`
    width: 100%;
  `}
`;

const Tabs = styled.div`
  display: flex;
  margin: 60px 0;
  ${mediaDown('md')`
    flex-direction: column;
    margin: 45px 0;
  `};
`;

const TabsMarginTopSmaller = styled.div`
  display: flex;
  margin: 60px 0;
  margin-top: 30px;
  ${mediaDown('md')`
    flex-direction: column;
    margin: 45px 0;
    margin-top: 30px;
  `};
`;

const Styled = {
  Tabs,
  Tab,
  List,
  Content,
  TabsMarginTopSmaller,
};

export default Styled;
