import styled from 'styled-components/macro';
import { mediaDown, mediaUp } from '../../../utils/styles/media';

const Styled = {
  Header: styled.header`
    background-color: #fff;
    padding: 42px 0 42px;
    box-shadow: 0 15px 64px 0 rgba(21, 22, 46, 0.05);
    color: #4181d0;
    padding-left: 7vw;
    padding-right: 7vw;
    ${mediaDown('md')`
      padding: 11px 0;
      box-shadow: 0 0 30px 0 rgba(23,46,64,0.06);
    `}
    @media (max-width: 1600px) {
      padding-left: 1vw;
      padding-right: 1vw;
    }
  `,

  Container: styled.div`
    padding: 0 0px;
    margin: 0 auto;
    ${mediaDown('md')`
      padding: 0 15px;
    `}
  `,

  Content: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  Logo: styled.div`
    background-color: #fff;
    position: relative;
    font-size: 0;
    Link {
      position: absolute;
      top: 0;
      left: 0;
    }
    a:visited {
      color: #4181d0;
    }
    img {
      height: 55px;
      width: auto;
      ${mediaDown('md')`
        height: 28px;
        width: auto;
      `}
    }
    span {
      position: absolute;
      display: block;
      top: 78%;
      left: 46%;
      font-size: 17px;
      height: auto;
      ${mediaDown('md')`
        visibility: hidden;
      `}
    }
  `,

  Middle: styled.div`
    // background-color: #fff;
    // display: flex;
    // align-items: center;
    // margin-top: -6px;
    // margin-left: 24px;
    // nav {
    //   ${mediaDown('md')`
    //     display: none;
    //   `}
    //   ul {
    //     display: flex;
    //     align-items: center;
    //     border-top: 1px solid white;
    //     li {
    //       margin-right: 40px;
    //       @media (max-width: 1250px) {
    //         margin-right: 20px;
    //       }
    //       &:last-child {
    //         margin-right: 0;
    //       }
    //       a {
    //         font-size: 1.7rem;
    //         white-space: nowrap;
    //         color: #4181d0;
    //         font-weight: 400;
    //         text-decoration: none;
    //         @media (max-width: 1500px) {
    //           font-size: 1.5vw;
    //         }
    //         &:hover {
    //           color: #28a745;
    //         }
    //       }
    //     }
    //   }
    // .login {
    //   font-size: 1rem;
    // }
    // }
    display: flex;
    flex-direction: row;

    width: 75%;
    @media (max-width: 1300px) {
      width: 100%;
    }
    nav {
      margin-left: 20px;
      ${mediaDown('md')`
        display: none;
      `}
      width: 100%;
      ul {
        font-weight: 400;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        li:last-child {
          margin-left: -20px;
        }
        li {
          a {
            white-space: nowrap;
            font-size: calc(12px + 0.6vw);
            @media (max-width: 1080px) {
              font-size: 15px;
            }
            color: #4181d0;
            text-decoration: none;
            &:hover {
              color: #28a745;
            }
          }
        }
      }
      .login {
        font-size: 1rem;
      }
    }
  `,

  RightSide: styled.div`
    background-color: #fff;
    display: flex;
    align-items: center;
    nav {
      ${mediaDown('md')`
        margin-right: 20px;
      `}
      ul {
        display: flex;
        align-items: center;
        li {
          margin-right: 40px;
          ${mediaDown('md')`
            margin-right: 20px;
          `}
          a {
            font-size: 14px;
            color: #000;
            text-decoration: none;
            ${mediaDown('md')`
              font-size: 12px;
            `}
            &:hover {
              color: #28a745;
            }
          }
        }
      }
    }
  `,

  Cart: styled.div`
    position: relative;
    margin-right: 45px;
    font-size: 0;
    display: none;
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `,

  CartCounter: styled.div`
    position: absolute;
    left: 17px;
    bottom: 15px;
    display: inline-block;
    background-color: #ff3838;
    color: #ffffff;
    font-size: 9px;
    font-weight: 900;
    border-radius: 30px;
    line-height: 14px;
    padding: 0 5px;
  `,

  SelfService: styled.div`
    font-size: 1rem;
    display: flex;
    cursor: pointer;
    user-select: none;
    button {
      svg {
        fill: white;
      }
    }
    button:hover {
      color: #28a745;
      svg {
        fill: #28a745;
      }
    }
    :hover {
      color: #28a745;
      svg {
        fill: #28a745;
      }
    }
    svg {
      align-self: center;
      margin-right: 5px;
    }
    ${mediaDown('md')`
    font-size: 24px;
  `}
  `,

  LangSelect: styled.div`
    padding: 0 1% 0 2%;
    @media (max-width: 1250px) {
      padding: 0 1% 0 5px;
    }
  `,

  MobileNavLangSelect: styled.div`
    display: none;
    ${mediaDown('md')`
      display: flex;
      padding-left: 15px;
    `}
  `,

  Burger: styled.div`
    display: none;
    ${mediaDown('md')`
      display: flex;
      padding-left: 16px;
    `}
    svg {
      transform: scale(2);
    }
  `,
  MobileNav: styled.div`
    position: fixed;
    color: rgb(241, 239, 237);
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background-color: #4181d0;
    overflow: auto;
    z-index: 9;
    ${mediaUp('md')`
      display: none;
    `}
    .mobileNavClose {
      position: absolute;
      top: 32px;
      right: 32px;
      svg {
        transform: scale(2);
      }
    }
    nav {
      padding-top: 48px;
      margin: 0;
      ul {
        flex-direction: column;
        .userMenuOption {
          padding-top: 10px;
          padding-bottom: 5px;
          border-bottom: 1px solid #fff;
        }
        li {
          padding-top: 1.4rem;
          padding-bottom: 0.8rem;
          width: 80%;
          &:not(:last-child) {
            border-bottom: 1px solid #fff;
          }
          a {
            font-size: 1.8rem;
            font-weight: 800;
            color: rgb(241, 239, 237);
            text-decoration: none;
            cursor: pointer;
            &:hover {
              color: #28a745;
            }
          }
        }
        .login {
          font-size: 1.5rem;
        }
      }
    }
  `,
  SelfServiceIcon: styled.div`
    cursor: pointer;
    display: inline;
    margin: 2px 0 0 5px;
    user-select: none;
    z-index: 29;
    ${(props) => (props.isMobile ? 'padding-top: 5px;' : 'padding-top: 0px')}
    svg {
      fill: white;
    }
    svg:hover {
      fill: #28a745;
    }
    .upsideDown {
      svg:hover {
        fill: #28a745;
      }
      transform: rotate(180deg);
    }
    ${mediaDown('md')`
    margin-top: 0px;
    svg {
      width: 20px;
      height: 20px;
      fill: rgb(241, 239, 237);
    }
  `}
  `,
  UserIcon: styled.div`
    display: flex;
    width: 24px;
    height: 24px;
  `,
  UserSettingsMenu: styled.div`
    position: absolute;
    top: 80px;
    padding: 12px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.08),
      0px 2px 8px rgba(37, 26, 26, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
    z-index: 29;
    ${mediaDown('md')`
      top:auto;
      bottom: auto;
      margin-top: 6vh;
      background-color: #72a1db;
    `}
    }
  `,
  Option: styled.div`
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    ${(props) => props.isFirst && 'border-top: 2px solid white;'}
    &:not(:first-child) {
      margin-top: 15px;
    }
    :hover {
      color: #28a745;
    }
    ${mediaDown('md')`
      color:  #4181d0;
      font-size: 1.5rem;
    `}
    @media (min-width: 979px) {
    ${(props) => props.isMobile && 'display:none;'}
  }
    
  `,
  Backdrop: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    ${mediaDown('md')`
      position:  absolute;
      height: 100vh;
    `}
  `,

  Sidebar: styled.div`
    position: sticky;
    top: 0;
    float: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 900px;
    width: 300px;
    z-index: 99;
    border-right: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    .logout {
      position: absolute;
      text-align: left;
      font-weight: bold;
      // margin-left: 45px;
      color: black;
      width: 300px;
    }
    ${mediaDown('md')`
    display: none;
    .BurgerIcon {
      position: sticky;
      display: flex;
    }
  `}
  `,

  SidebarUserInfo: styled.div`
    border-bottom: 1px solid lightgrey;
    height: 100px;
    h3 {
      font-weight: bold;
      margin-top: 5px;
    }
    border-radius: 5px;
    div {
      margin: 25px 0 0 25px;
      padding-left: 20px;
      border-radius: 5px;
      width: 82%;
      height: 60px;
      &:hover {
        background-color: lightgrey;
        cursor: pointer;
      }
    }
  `,

  SidebarLogoutUser: styled.div`
    border-bottom: 1px solid lightgrey;
    line-height: 4;
    padding: 25px 0 0 45px;
  `,

  SidebarOptions: styled.div`
    padding: 10px 0 0 25px;
    .option {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      padding: 0 10px 0 20px;
      height: fit-content;
      width: 90%;
      &:hover {
        background-color: lightgrey;
        cursor: pointer;
      }
    }
  `,

  SideBarSubMeniu: styled.div`
    padding: 10px 0 0 40px;
    font-size: 14px;
    .option {
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      padding: 0 10px 0 20px;
      height: fit-content;
      width: 90%;
      &:hover {
        background-color: lightgrey;
        cursor: pointer;
      }
    }
  `,

  MobileViewTitle: styled.div`
    margin: 30px 0px 0 25px;
    margin-bottom: -25px;
    div {
      border-radius: 5px;
      width: 80%;
      &:hover {
        background-color: lightgrey;
        cursor: pointer;
      }
    }
    h3 {
      margin-top: 0px;
    }
  `,

  ButtonContainer: styled.div`
  ${mediaDown('md')`
  button {
    background-color: #2D69B3;
    border-color:#2D69B3;
  }
`}
  `
};

export default Styled;
