import React from 'react';
import { isString } from 'lodash';
import { ReactComponent as TickIcon } from '../../../assets/icons/tick.svg';
import Form from '../Form';
import Styled from './Checkbox.styles';

const Checkbox = ({
  children,
  onChange,
  name,
  checked,
  error,
  value,
  boldParagraph,
  defaultChecked,
  showBorder,
  innerRef,
  isGrey,
  disabled,
}) => {
  return (
    <Styled.Checkbox error={!!error} showBorder={showBorder}>
      <label>
        <input
          value={value}
          type="checkbox"
          onChange={onChange}
          name={name}
          checked={checked}
          defaultChecked={defaultChecked}
          ref={innerRef}
          disabled={disabled}
        />

        <Styled.Label boldParagraph={boldParagraph}>
          <Styled.Box isGrey={isGrey}>
            <TickIcon />
          </Styled.Box>

          {children}
        </Styled.Label>
      </label>
      {isString(error) && <Form.Error standAlone>{error}</Form.Error>}
    </Styled.Checkbox>
  );
};

export default Checkbox;
