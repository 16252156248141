import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const NoResults = styled.div`
  text-align: center;
  padding: 200px 0;
  font-size: 26px;
  font-weight: 900;

  ${mediaDown('md')`
    padding: 140px 0;
    font-size: 18px;
  `}
`;

const Styled = {
  NoResults,
};

export default Styled;
