import React from 'react';
import { useEffect } from 'react';

const CartStateContext = React.createContext();
const CartDispatchContext = React.createContext();

const initialState = {
  coupon: null,
  registrationServices: [],
  startedAt: '',
  inActionTimeSlot: '',
  step: null,
  url: null,
  orderUrl: null,
  selectedTimeslot: null,
  fromWidget: null,
};

function cartReducer(state, action) {
  switch (action.type) {
    case 'ADD_TO_CART': {
      return {
        ...state,
        registrationServices: [action.product],
      }
    }

    case 'ADD_TO_CART_COMBINED': {
      return {
        ...state,
        combined: action.product
      }
    }

    case 'ADD_TO_CART_COUPON': {
      return {
        ...state,
        coupon: action.product,
      };
    }

    case 'ADD_TO_CART_PAYMENTURL': {
      return {
        ...state,
        payseraUrl: action.payseraUrl,
      };
    }

    case 'ADD_TO_TEMP_CART': {
      return {
        ...state,
        tempRegistrationServices: [
          ...state.registrationServices,
          action.product,
        ],
      };
    }

    case 'CHANGE_REGISTRATION_SERVICE_ID': {
      return {
        ...state,
        registrationServices: state.registrationServices.map((rs, i) =>
          i === 0 ? { ...rs, serviceId: action.newServiceId } : rs,
        ),
      };
    }
    case 'SET_SERVICE_DURATION': {
      return {
        ...state,
        serviceDuration: action.data.serviceDuration,
        timeSlotDate: action.data.timeSlotDate,
      };
    }
    case 'REMOVE_FROM_CART': {
      return {
        ...state,
        registrationServices: state.registrationServices.filter(
          (product) => product.id !== action.id,
        ),
      };
    }
    case 'SET_TIMER': {
      if (state.startedAt && state.registrationServices.length) {
        return state;
      }

      return {
        ...state,
        startedAt: action.date,
      };
    }
    case 'RESET_TIMER': {
      return {
        ...state,
        startedAt: new Date(),
      };
    }
    case 'REMOVE_TIMER': {
      return {
        ...state,
        startedAt: '',
      };
    }

    case 'RESET_CART': {

      return {
        ...initialState,
        payseraUrl: state.payseraUrl,
        registrationId: state.registrationId,
        leftToPaysera: state.leftToPaysera,
      };
    }

    case 'SET_TEMP_TIME_SLOT': {
      return {
        ...state,
        inActionTimeSlot: action.timeSlotId,
        ...(!state.registrationServices.length && !action.timeSlotId
          ? {
            startedAt: '',
            step: null,
            url: null,
            orderUrl: null,
            selectedTimeslot: null,
            tempRegistrationServices: [],
          }
          : {}),
      };
    }
    case 'SET_STEP': {
      return {
        ...state,
        step: action.step,
      };
    }

    case 'SET_ORDER_URL': {
      return {
        ...state,
        orderUrl: action.url,
      };
    }

    case 'REMOVE_ORDER_URL': {
      return {
        ...state,
        orderUrl: null,
      };
    }

    case 'REMOVE_BACK_URL': {
      return {
        ...state,
        url: null,
      };
    }

    case 'REMOVE_LEFTTOPAYSERA': {
      return {
        ...state,
        leftToPaysera: null,
      };
    }

    case 'SET_BACK_URL': {
      return {
        ...state,
        url: action.url,
      };
    }
    case 'SET_TIMESLOT': {
      return {
        ...state,
        selectedTimeslot: action.selectedTimeslot,
      };
    }

    case 'REMOVE_STEP': {
      return {
        ...state,
        step: undefined,
      };
    }

    case 'SET_REGISTRATION_ID': {
      return {
        ...state,
        registrationId: action.registrationId,
        leftToPaysera: action.redirectedToPaysera,
      };
    }

    case 'RESET_REGISTRATION_ID': {
      return {
        ...state,
        registrationId: null,
        leftToPaysera: null,
      };
    }

    case 'SET_LEFTTOPAYSERA': {
      return {
        ...state,
        leftToPaysera: true,
      };
    }

    case 'RESET_PAYSERA_URL': {
      return {
        ...state,
        payseraUrl: null,
      };
    }

    case 'RESET_ONLY_REGISTRATION_ID': {
      return {
        ...state,
        registrationId: null,
      };
    }

    case 'SET_PATIENT_INFORMATION': {
      return {
        ...state,
        patientName: action.patientName,
        patientSurname: action.patientSurname,
      };
    }

    case 'REGISTRATION_FROM_WIDGET': {
      return {
        ...state,
        fromWidget: action.fromWidget,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CartProvider({ children }) {
  const [state, dispatch] = React.useReducer(cartReducer, getOrder());

  function getOrder() {
    const localOrder = window.sessionStorage.getItem('order');

    if (localOrder) {
      try {
        return JSON.parse(localOrder);
      } catch (error) {
        return initialState;
      }
    }

    return initialState;
  }

  useEffect(() => {
    window.sessionStorage.setItem('order', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (!state.registrationServices.length) {
      // dispatch({ type: 'REMOVE_TIMER' });
    }
  }, [state.registrationServices.length]);

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
}

function useCartState() {
  const context = React.useContext(CartStateContext);

  if (context === undefined) {
    throw new Error('useCartState must be used within a CartProvider');
  }

  return context;
}

function useCartDispatch() {
  const context = React.useContext(CartDispatchContext);

  if (context === undefined) {
    throw new Error('useCartDispatch must be used within a CartProvider');
  }

  return context;
}

export { CartProvider, useCartState, useCartDispatch };
