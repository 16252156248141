import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import useFetch from 'use-http';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as CrossIcon } from '../../../../../../../assets/icons/cross.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../../assets/icons/error-circle.svg';
import { ReactComponent as SuccessIcon } from '../../../../../../../assets/icons/success-circle.svg';
import styles from './Modal.module.scss';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useKey } from 'react-use';
import Button from '../../../../../../shared/Button';
import TextField from '../../../../../../shared/TextField';
import Form from '../../../../../../shared/Form';

const Modal = ({
  children,
  visible,
  title,
  onClose,
  showClose = true,
  loading,
  popUp,
  extraButton,
  link,
  imgSize,
  popUpImg,
  isGrey,
  wide,
  fullScreen,
}) => {
  const modalRoot = document.querySelector('body');
  const ref = useRef(null);
  const [height, setHeight] = useState(450);
  const [buttonSize, setButtonSize] = useState('medium');
  const [minusHeight, setMinusHeight] = useState(55);

  useKey('escape', loading ? undefined : onClose, visible);
  useEffect(() => {
    if (imgSize > 0) {
      // setHeight(imgSize);

      if (extraButton && extraButton.length > 40) {
        setMinusHeight(75);
      } else if (extraButton) {
        setMinusHeight(55);
      }
    }
  }, [height, extraButton, imgSize]);

  function resize() {
    var img = document.getElementById('popUpImg');
    if (img) {
      var height = img.clientHeight;
      setHeight(height);
      if (height < 350) {
        setButtonSize('small');
      } else {
        setButtonSize('medium');
      }
    }
  }

  window.onresize = resize;

  useEffect(() => {
    var img = document.getElementById('popUpImg');
    if (img != null) {
      var height = img.clientHeight;
      setHeight(height);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById('popUpImg')]);

  useEffect(() => {
    if (visible) {
      modalRoot.style.overflow = 'hidden';
    } else {
      modalRoot.removeAttribute('style');
    }
  }, [modalRoot, visible]);

  if (!modalRoot) {
    return null;
  }

  function getModalContent() {
    const contentClass = popUp
      ? 'modal__content-popup'
      : wide
        ? 'modal__content__wide'
        : 'modal__content';

    return (
      <CSSTransition in={visible} classNames="fade" timeout={100} unmountOnExit>
        <div className={styles['modal']}>
          <div
            className={styles['modal__container']}
            style={{
              height: fullScreen ? '100%' : 'auto',
            }}
          >
            <div
              ref={ref}
              className={styles[contentClass]}
              style={{
                height: fullScreen ? '100%' : 'auto',
                width: fullScreen ? '100%' : 'auto',
              }}
            >
              {title && !popUp && (
                <div className={styles['modal__header']}>
                  <div className={styles['modal__header__title']}>{title}</div>
                </div>
              )}

              {showClose && (
                <div
                  className={
                    isGrey
                      ? styles['modal__close-button'] +
                      ' ' +
                      styles['modal__close-button-grey']
                      : styles['modal__close-button']
                  }
                  onClick={onClose}
                >
                  <CrossIcon />
                </div>
              )}
              {popUp && (
                <div
                  style={{
                    display: 'flex',
                    position: 'relative',
                    top: height - minusHeight,
                    height: '0px',
                    justifyContent: 'end',
                    marginRight: '10px',
                  }}
                >
                  {popUp && (
                    <div
                      // className={
                      //   extraButton && link
                      //     ? styles['modal__close-blue-button']
                      //     : styles['modal__close-extra-blue-button']
                      // }
                      onClick={onClose}
                    >
                      <Button
                        size={buttonSize}
                        style={{
                          fontSize: '16px',
                        }}
                      >
                        Uždaryti
                      </Button>
                    </div>
                  )}
                  {popUp && extraButton && link && (
                    <div
                      // className={styles['modal__close-extra-blue-button']}
                      onClick={() => window.open(link)}
                    >
                      <Button
                        size={buttonSize}
                        style={{
                          fontSize: '16px',
                          marginLeft: '10px',
                        }}
                      >
                        {extraButton}
                      </Button>
                    </div>
                  )}
                </div>
              )}

              <div
                className={styles['body']}
                style={{
                  height: fullScreen ? '95%' : 'auto',
                  width: fullScreen ? '100%' : 'auto',
                }}
              >
                {children}
              </div>
            </div>
          </div>
          <div
            className={styles['modal__backdrop']}
            onClick={loading ? undefined : onClose}
          ></div>
        </div>
      </CSSTransition>
    );
  }

  return ReactDOM.createPortal(getModalContent(), modalRoot);
};

const Actions = ({ children }) => {
  return <div className={styles['modal__actions']}>{children}</div>;
};

const Alert = ({ children, type = 'success', isGrey }) => {
  return (
    <div
      className={cx(styles['modal__alert'], {
        [styles['modal__alert--success']]: type === 'success',
        [styles['modal__alert--error']]: type === 'error',
        [styles['modal__text-content-grey']]: isGrey === true,
      })}
    >
      {type === 'error' && <ErrorIcon />}
      {type === 'success' && <SuccessIcon />}
      {children}
    </div>
  );
};

const TextContent = ({ children, subcontent, popUp, subtype, isGrey }) => {
  if (subcontent) {
    return (
      <div
        className={
          isGrey
            ? styles['modal__text-sub-content'] +
            ' ' +
            styles['modal__text-content-grey']
            : styles['modal__text-sub-content']
        }
      >
        {children}
      </div>
    );
  } else if (subtype === 'psdfSuccess') {
    return (
      <div className={styles['modal__text-content-psdfSuccess']}>
        {children}
      </div>
    );
  } else if (subtype === 'psdfWithAdditionalPaymentSuccess') {
    return (
      <div
        className={
          styles['modal__text-content-psdfWithAdditionalPaymentSuccess']
        }
      >
        {children}
      </div>
    );
  } else if (subtype === 'ctxrmSuccess') {
    return (
      <div className={styles['modal__text-content-ctxrmSuccess']}>
        {children}
      </div>
    );
  } else if (popUp) {
    return (
      <div className={styles['modal__text-content-popUp']}>{children}</div>
    );
  } else
    return (
      <div
        className={
          isGrey
            ? styles['modal__text-content'] +
            ' ' +
            styles['modal__text-content-grey']
            : styles['modal__text-content']
        }
      >
        {children}
      </div>
    );
};

const Edit = ({
  link,
  changeReload,
  selectedServiceExternalId,
  setEditModalVisible,
  serviceFullName,
}) => {
  const { t } = useTranslation();
  const formRef = useRef();

  const validationSchema = Yup.object().shape({
    link: Yup.string()
      .matches(
        // /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
        'Įveskite teisingą url',
      )
      .required('Įveskite url'),
  });

  const { post: postLink, response: postLinkResponse } = useFetch(
    `/ServicesDescription/UrlUpdate`,
  );

  const handleLinkSubmit = async (data, { setSubmitting, setErrors }) => {
    let link = formRef.current.values.link;

    const response = await postLink('', {
      link: link,
      serviceExternalId: selectedServiceExternalId,
    });

    if (postLinkResponse.status >= 400) {
    } else if (postLinkResponse.status == 200) {
      changeReload();
      setEditModalVisible(false);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{ link: link }}
        validationSchema={validationSchema}
        onSubmit={handleLinkSubmit}
        innerRef={formRef}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ setFieldValue, errors, isSubmitting, values, setErrors }) => (
          <FormikForm>
            <Form>
              <Form.Group>
                <Form.Row>{serviceFullName}</Form.Row>
                <Form.Row>
                  <Field
                    focus={true}
                    name="link"
                    value={values.link}
                    label={t('userAccount.serviceInfo.newLink')}
                    component={TextField}
                    isGrey={true}
                  />
                </Form.Row>
                <Form.Row>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="large"
                    onClick={() => {
                      handleLinkSubmit();
                    }}
                    type="submit"
                    style={{ width: '50px' }}
                  >
                    Išsaugoti
                  </Button>
                </Form.Row>
              </Form.Group>
            </Form>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

Modal.Actions = Actions;
Modal.TextContent = TextContent;
Modal.Alert = Alert;
Modal.Edit = Edit;

export default Modal;
