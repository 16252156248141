import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Summary = styled.div`
  margin-bottom: ${(props) => (props.isMarginBottom ? '30px' : '80px')};
`;

const Item = styled.div`
  position: relative;
  padding: 36px 0 ${(props) => (props.hasOtherPerson ? '24px' : '36px')};
  display: flex;
  margin-bottom: 40px;

  ${mediaDown('md')`
    padding-top: 15px;
  `}

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 65px);

    height: 100%;
    border-radius: 15px;
    background-color: #f1f5f7;
    z-index: -1;

    ${mediaDown('md')`
      width: 100%;
      border-radius: 8px;
    `}
  }
`;

const MainInfo = styled.div`
  ${mediaDown('md')`
    display: flex;
    flex-wrap: wrap;

    > *:last-child {
      flex-grow: 1;
    }
  `}
`;

const Image = styled.div`
  height: 114px;
  width: 160px;
  background-size: cover;
  background-position: center top;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  ${(props) => (props.mobile ? 'display: none;' : '')}
  ${(props) => (props.mobile ? mediaDown('md')`display: block;` : '')}
  ${(props) => (props.desktop ? mediaDown('md')`display: none;` : '')}

  ${mediaDown('md')`
    width: 60px;
    height: 60px;
    border-radius: 0 8px 8px 0;
    margin-bottom: 10px;
    margin-left: -15px;
    margin-right: 15px;
  `}
`;

const tableGrid = css`
  > div {
    width: calc((55% - 70px) / 2);
    padding-right: 20px;

    ${mediaDown('md')`
      width: 100%;
      padding-right: 0;
    `}

    &:nth-child(1) {
      width: 45%;

      ${mediaDown('md')`
        width: 100%;
      `}
    }

    &:nth-child(4) {
      width: 70px;
      padding-right: 0;

      ${mediaDown('md')`
        width: 100%;
      `}
    }
  }
`;

const ItemContent = styled.div`
  width: calc(100% - 160px);
  display: flex;
  padding-left: 30px;

  ${mediaDown('md')`
    flex-direction: column;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    > div {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `}

  ${tableGrid};
`;

const LabelsList = styled.div`
  display: flex;
  align-items: center;
  color: #9395a9;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding-left: 190px;
  margin-bottom: 15px;

  ${mediaDown('md')`
    display: none;
  `}

  ${tableGrid};
`;

const largeText = css`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 16px;

  ${mediaDown('md')`
    font-size: 16px;
    line-height: 19px;
    width: 100%;
  `}
`;

const mediumText = css`
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 4px;

  ${mediaDown('md')`
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
  `}
`;

const smallText = css`
  font-size: 12px;
  margin-bottom: 2px;
`;

const priceText = css`
  font-size: 16px;
  font-weight: 900;
`;

const otherPersonText = css`
  ${smallText}
  font-style: italic;
  margin-top: 10px;
`;

const Text = styled.div`
  color: #000000;
  font-size: 14px;
  line-height: 1.2;

  ${mediaDown('md')`
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 15px;
    
    &:last-child {
      margin-bottom: 0;
    }

    &[data-label]::before {
      content: attr(data-label);
      color: #9395A9;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      width: 140px;
    }
  `}

  ${(props) => {
    switch (props.size) {
      case 'large':
        return largeText;
      case 'medium':
        return mediumText;
      case 'small':
        return smallText;
      case 'price':
        return priceText;
      case 'other-person':
        return otherPersonText;
      default:
        return ``;
    }
  }}
`;

const RemoveButtonHolder = styled.div`
  position: absolute;
  bottom: -5px;
  right: 20px;

  svg {
    margin-right: 8px;
    width: 8px;
    height: 8px;

    path {
      fill: #fff;
    }
  }
`;

const TotalPrice = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  text-align: right;
  margin: 55px 0 0;

  ${mediaDown('md')`
    font-size: 18px;
    line-height: 19px;
    text-align: left;
  `}
`;

const OldPrice = styled.del`
  margin-left: 10px;
  color: #9395a9;
  .oldPrice {
    text-decoration: line-through;
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
    color: black;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end; /* Aligns children to the bottom */
  height: 100%; /* Ensure the container has a height */
`;

const Notification = styled.div`
  color: #5bb548;
  margin-bottom: -24px;
  font-size: 21px;
  font-weight: 500;
  line-height: 0;
  text-align: center;
  ${mediaDown('sm')`
  line-height: 20px;
  `}
`;

const Styled = {
  Summary,
  MainInfo,
  Item,
  Image,
  ItemContent,
  LabelsList,
  Text,
  RemoveButtonHolder,
  TotalPrice,
  OldPrice,
  TableWrapper,
  Notification,
};

export default Styled;
