import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSettingsState } from '../../context/SettingsContext';
import Button from '../Button';
import Modal from '../Modal';
import Switch from '../Switch';
import Container from '../Container';
import './CookiesConsent.scss';
import Checkbox from '../Checkbox';
import { exponeaCapture } from '../../hooks/exponeaCapture';

const CookiesConsent = () => {
  const { cookies } = useSettingsState();
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [hasConsent, setHasConsent] = useState(
    localStorage.getItem('cookieConsent'),
  );
  const [isCookiesSet, setCookies] = useState(
    !!localStorage.getItem('cookies'),
  );

  const TYPES = {
    mandatory: {
      title: t('cookies.mandatory.title'),
      description: t('cookies.mandatory.description'),
      defaultChecked: true,
      disabled: true,
    },
    functional: {
      title: t('cookies.functional.title'),
      description: t('cookies.functional.description'),
      defaultChecked: false,
      disabled: false,
    },
    analysis: {
      title: t('cookies.analysis.title'),
      description: t('cookies.analysis.description'),
      defaultChecked: false,
      disabled: false,
    },
    marketing: {
      title: t('cookies.marketing.title'),
      description: t('cookies.marketing.description'),
      defaultChecked: false,
      disabled: false,
    },
  };

  const defaultAllowed = Object.keys(TYPES).reduce((results, key) => {
    if (TYPES[key].defaultChecked && cookies.enabled.indexOf(key) > -1) {
      results.push(key);
    }
    return results;
  }, []);

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const [allowed, setAllowed] = useState(defaultAllowed);

  const onClose = () => {
    setModalOpen(false);
  };

  const onChange = (type) => (event) => {
    const list = [...allowed];

    if (event.target.checked && list.indexOf(type) === -1) {
      list.push(type);
    }

    if (!event.target.checked && list.indexOf(type) > -1) {
      list.splice(list.indexOf(type), 1);
    }

    setAllowed(list);
  };

  const saveAllowed = (list) => {
    localStorage.setItem('cookies', list.join(','));
    localStorage.setItem('cookieConsent', true);
    setHasConsent(true);
    setCookies(true);
  };

  const allowAll = () => {
    const allCookieTypes = Object.keys(TYPES);
    const updatedDefaultAllowed = allCookieTypes.filter((key) => {
      return (
        cookies.enabled.indexOf(key) > -1 &&
        (!TYPES[key].disabled || key === 'mandatory')
      );
    });

    localStorage.setItem('cookies', updatedDefaultAllowed);
    localStorage.setItem('cookieConsent', true);
    setHasConsent(true);
    setCookies(true);

    exponeaCapture([], 'consent_cookies');
  };

  const onSave = () => {
    saveAllowed(allowed);
    onClose();
    exponeaCapture([], 'consent_cookies');
  };

  const getAllowed = () => {
    const ls = localStorage.getItem('cookies');
    return ls ? ls.split(',') : [];
  };

  const isAllowed = (type) => {
    return (
      getAllowed().indexOf(type) > -1 && cookies.enabled.indexOf(type) > -1
    );
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          {isAllowed('functional') && (
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-176406110-1"
            ></script>
          )}
          {isAllowed('functional') && (
            <script>
              {`
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'UA-176406110-1');
                `}
            </script>
          )}
        </Helmet>
      </HelmetProvider>
      <Modal
        title={t('cookies.title')}
        visible={isModalOpen}
        onClose={onClose}
        customContentSizeHalf={true}
      >
        <Modal.TextContent>
          <div
            className="cookies-consent__modal"
            style={{
              backgroundColor: '#f1f5f7',
              padding: '25px',
              borderRadius: '10px',
            }}
          >
            {Object.keys(TYPES)
              .filter((item) => cookies.enabled.indexOf(item) > -1)
              .map((key) => {
                const item = TYPES[key];

                return (
                  <div
                    className="cookies-consent__item"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    key={key}
                  >
                    <div>
                      <strong>{item.title}</strong>

                      <p>{item.description}</p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '5px',
                      }}
                    >
                      <Checkbox
                        defaultChecked={item.defaultChecked}
                        disabled={item.disabled}
                        style={{ backgroundColor: 'red' }}
                        onChange={onChange(key)}
                      ></Checkbox>
                    </div>
                  </div>
                );
              })}
          </div>
        </Modal.TextContent>
        <Modal.Actions>
          <Button variant="secondary" size="large" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button size="large" onClick={onSave}>
            {t('common.save')}
          </Button>
        </Modal.Actions>
      </Modal>

      {(!isCookiesSet || !hasConsent) && (
        <div className="cookies-consent">
          <div className="cookies-consent__row">
            <Container>
              <p>
                <Trans
                  i18nKey="cookies.description"
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={cookies.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    ),
                  }}
                />
              </p>
              <Button onClick={allowAll}>{t('cookies.allowAll')}</Button>
              <Button variant="secondary" onClick={onModalOpen}>
                {t('cookies.settings')}
              </Button>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesConsent;
