import { Field, Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as Yup from 'yup';
import { useUserDispatch } from '../../../context/UserContext';
import Button from '../../../shared/Button';
import Container from '../../../shared/Container';
import Form from '../../../shared/Form';
import Page from '../../../shared/Page';
import TextField from '../../../shared/TextField';
import Styled from './PasswordResetPage.styles';

const PasswordResetPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const sessionDispatch = useUserDispatch();
  const { post: managePasswordReset, response: passwordResetResponse } =
    useFetch(`/UserAccount/InitResetPassword`);
  const initialValues = {
    email: '',
    phoneNumber: '',
  };

  const handleSubmit = async (data, { setErrors, setSubmitting }) => {
    await managePasswordReset('', {
      email: data.email,
      phoneNumber: data.phoneNumber,
    });
    var response = await passwordResetResponse.json();
    if (passwordResetResponse.status >= 500) {
    } else if (passwordResetResponse.status >= 400) {
      const errors = {
        email: t('login.credentialsTab.passwordReset.accountNotFound'),
      };
      setErrors(errors);
    } else {
      sessionDispatch({
        type: 'UPDATE',
        data: {
          id: response.userId,
        },
      });
      // history.push('verify/phone', { from: 'passwordReset' });
      history.push('/change-password');
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.requiredField'))
      .trim(),
    phoneNumber: Yup.string()
      .required(t('validation.requiredField'))
      .test('checkFormat', 'Netinkamas telefono formatas, turi būti "+" ir skaičiai', function (val) {
        const phoneRegex = /^\+\d{1,20}$/;
        if (val && phoneRegex.test(val)) {
          return true;
        }
        return false;
      }),
  });

  return (
    <Page loading={false}>
      <Container>
        <Styled.Form>
          <Page.Title backUrl={`/selfservice`} greyTheme={true}>
            {t('forgotPassword.title')}
          </Page.Title>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, errors, isSubmitting }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Form.Row>
                      <Field
                        focus={true}
                        name="email"
                        placeholder={t(
                          'login.credentialsTab.passwordReset.email',
                        )}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Field
                        name="phoneNumber"
                        placeholder={t(
                          'login.credentialsTab.passwordReset.phoneNumber',
                        )}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>

                <Styled.Buttons>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    style={{ width: '50%' }}
                    size="mega"
                    type="submit"
                    onClick={() => handleSubmit}
                  >
                    {t('forgotPassword.submit')}
                  </Button>
                </Styled.Buttons>
              </FormikForm>
            )}
          </Formik>
        </Styled.Form>
      </Container>
    </Page>
  );
};

export default PasswordResetPage;
