import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import { useSettingsState } from '../../context/SettingsContext';
import './ErrorModal.scss';

const ErrorModal = ({
  showError,
  errorModalMessage,
  onClose = () => (window.location.href = window.location.origin),
}) => {
  const { phoneNumber } = useSettingsState();

  return (
    <Modal visible={showError} onClose={onClose}>
      <Modal.Alert type="error">Atsiprašome, įvyko klaida</Modal.Alert>
      <Modal.TextContent>
        {errorModalMessage && (<p> {errorModalMessage}</p>)}
        <p>
          Pabandykite pakartoti iš naujo arba skambinkite telefonu{' '}
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>.
        </p>
      </Modal.TextContent>
      <Modal.Actions>
        <Button size="large" onClick={onClose}>
          Uždaryti
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorModal;
