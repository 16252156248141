import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Download = styled.span`
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-transform: uppercase;
`;

const Styled = { Download };

export default Styled;
