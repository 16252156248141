import { EncryptStorage } from 'encrypt-storage';

class SessionStorage {
  static storageName = 'session';
  static isDevEnv = process.env.REACT_APP_API_BASE.includes('localhost');
  static storage = SessionStorage.isDevEnv
    ? sessionStorage
    : EncryptStorage(process.env.REACT_APP_CLIENT_ID, {
      storageType: 'sessionStorage',
    });

  static defaultUser = {
    authenticationType: null,
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    phoneNumberConfirmed: null,
    personalCode: null,
    countryCode: null,
    password: null,
    scheduledToDeleteDate: null,
    initializedFromLogin: null,
    isLoggedIn: null,
    discountCard: false,
    role: null,
    containsPersonalCode: false,
    subscriptionInfo: this.subscriptionInfo,
    contacts: this.contacts,
    childEspbiEsi: null,
    insurances: this.insurances,
  };

  static subscriptionInfo = {
    subscriptionPractitionerName: null,
    subscriptionPractitionerSurname: null,
    subscriptionInstitutionName: null,
    isSubscriptionInstitutionLocal: null,
    isInsured: null,
  };

  static initialState = {
    sessionId: null,
    token: null,
    refreshToken: null,
    emailToken: null,
    couponSum: null,
    emailTokenConfirmed: null,
    user: SessionStorage.defaultUser,
    left: false,
  };

  static insurances = {
    id: null,
    insuranceCompanyId: null,
    number: null,
    validFrom: null,
    validTo: null,
  };

  static getContent() {
    try {
      if (SessionStorage.isDevEnv) {
        const stored = SessionStorage.storage.getItem(
          SessionStorage.storageName,
        );
        if (stored) {
          return JSON.parse(stored);
        }
        return SessionStorage.initialState;
      } else {
        const stored = SessionStorage.storage.getItem(
          SessionStorage.storageName,
        );
        return stored || SessionStorage.initialState;
      }
    } catch {
      return SessionStorage.initialState;
    }
  }

  static setContent(data) {
    const stored = SessionStorage.getContent();
    const dataToStore = { ...stored, ...data };

    SessionStorage.storage.setItem(
      SessionStorage.storageName,
      SessionStorage.isDevEnv ? JSON.stringify(dataToStore) : dataToStore,
    );
  }
}

export default SessionStorage;
