import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Styled from './DateRangePicker.styles';
import _ from 'lodash';
import lt from 'date-fns/locale/lt';

const DateRangePicker = (props) => {
  const [dateRange, setDateRange] = useState(() => {
    if (props.dateRange) {
      return [
        props.dateRange[0] ? new Date(props.dateRange[0]) : null,
        props.dateRange[1] ? new Date(props.dateRange[1]) : null,
      ];
    } else return [null, null];
  });
  const [startDate, endDate] = dateRange;
  const [minDate, setMinDate] = useState(startDate);
  const [maxDate, setMaxDate] = useState(endDate);
  const years = _.range(1995, new Date().getFullYear() + 2, 1);
  const months = [
    'Sausis',
    'Vasaris',
    'Kovas',
    'Balandis',
    'Gegužė',
    'Birželis',
    'Liepa',
    'Rugpjūtis',
    'Rugsėjis',
    'Spalis',
    'Lapkritis',
    'Gruodis',
  ];

  return (
    <Styled.DatePicker>
      <span>Filtras: </span>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={{ color: '#52585F' }}
            >
              {'<'}
            </button>
            <select
              value={new Date(date).getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{ color: '#52585F' }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[new Date(date).getMonth()]}
              onChange={({ target: { value } }) => {
                changeMonth(months.indexOf(value));
              }}
              style={{ color: '#52585F' }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={{ color: '#52585F' }}
            >
              {'>'}
            </button>
          </div>
        )}
        selectsRange={false}
        dateFormat="yyyy-MM-dd"
        locale={lt}
        selected={startDate}
        maxDate={maxDate}
        placeholderText="Data nuo"
        calendarStartDay={1}
        onChange={(update) => {
          setMinDate(update);
          setDateRange([update, maxDate]);
          props.dateChanged([update, maxDate]);
        }}
        isClearable={true}
      />
      <span className="breaker">-</span>
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={{ color: '#52585F' }}
            >
              {'<'}
            </button>
            <select
              value={new Date(date).getFullYear()}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{ color: '#52585F' }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[new Date(date).getMonth()]}
              onChange={({ target: { value } }) => {
                changeMonth(months.indexOf(value));
              }}
              style={{ color: '#52585F' }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={{ color: '#52585F' }}
            >
              {'>'}
            </button>
          </div>
        )}
        selectsRange={false}
        dateFormat="yyyy-MM-dd"
        placeholderText="Data iki"
        locale={lt}
        calendarStartDay={1}
        selected={endDate}
        minDate={minDate}
        onChange={(update) => {
          setMaxDate(update);
          setDateRange([minDate, update]);
          props.dateChanged([minDate, update]);
        }}
        isClearable={true}
      />
    </Styled.DatePicker>
  );
};
export default DateRangePicker;
