import React, { useState, useRef, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import { Formik, Field } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { cloneDeep } from 'lodash';
import Summary from './Summary/Summary';
import NoResults from '../../../shared/NoResults';
import * as Yup from 'yup';
import useFetch from 'use-http';
import { Redirect, useHistory } from 'react-router-dom';
import Styled from './CombinedServicesCompleted.styles';
import Loader from '../../../shared/Loader';
import Modal from '../../../shared/Modal/Modal';
import Button from '../../../shared/Button/Button';
import { useUserState } from '../../../context/UserContext';
import { Link } from 'react-router-dom';
import { useSettingsState } from '../../../context/SettingsContext';
import { exponeaCapture } from '../../../hooks/exponeaCapture';
const CombinedServicesCompleted = () => {
  const successMatchCoupon = useRouteMatch(
    '/kompleksines-paslaugos/completed/:orderId',
  )?.isExact;

  const successMatchCouponParams = useRouteMatch(
    '/kompleksines-paslaugos/completed/:orderId',
  )?.params;

  const { request: complexFileDownloadRequest } = useFetch(
    `/ComplexServiceRegistrationManagment/Download`,
  );

  const { request: complexOrderRequest } = useFetch(
    `/ComplexServiceRegistrationManagment/ComplexRegistartionStatus`,
  );
  const { phoneNumber, shortPhoneNumber } = useSettingsState();

  const userState = useUserState();
  const [cartItem, setCartItem] = useState();
  const [fileGuids, setFileGuids] = useState();
  const [paymentPending, setPaymentPending] = useState();
  const [showEmptyFileModal, setShowEmptyFileModal] = useState(false);
  const history = useHistory();

  const logResultInExponea = (status) => {
    let exponea = JSON.parse(sessionStorage.getItem('exponeaTrack') || '[]');
    exponea.push({ name: 'Registration status', value: status });
    exponea.push({ name: 'booking_stage', value: 'Booked & Paid' });
    exponeaCapture(exponea, 'booking');
    sessionStorage.removeItem('exponeaTrack');
  };

  useEffect(() => {
    setPaymentPending(true);
    if (successMatchCoupon && successMatchCouponParams?.orderId) {
      complexOrderRequest
        .get(`?complexOrderId=${successMatchCouponParams?.orderId}`)
        .then((res) => {
          if (res) {
            setPaymentPending(false);
            logResultInExponea('Success');
            setCartItem(res);
          }
        });
    }
  }, [
    complexOrderRequest,
    successMatchCoupon,
    successMatchCouponParams?.orderId,
  ]);

  async function downloadCoupon(fileGuidsLength, file, idx) {
    await complexFileDownloadRequest.get(`?fileGuid=${file}`).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download =
        fileGuidsLength === 1
          ? 'Affidea kompleksinių paslaugų kuponas.pdf'
          : `Affidea kompleksinių paslaugų ${idx + 1}.pdf`;
      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  }

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  return (
    <>
      <Page>
        <Container style={{ marginTop: '50px' }}>
          {/* {!paymentPending && (
            <h2 style={{ display: 'flex', justifyContent: 'center' }}></h2>
          )} */}

          {cartItem && !paymentPending && <Summary item={cartItem} />}

          {paymentPending && (
            <div
              style={{
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                color: 'grey',
              }}
            >
              Laukiama apmokėjimo informacijos
            </div>
          )}

          {!paymentPending && userState.isLoggedIn && (
            <div
              style={{
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                color: 'grey',
              }}
            >
              <div>Jūsų mokėjimas už paslaugų paketą gautas.</div>
              <div>
                <Styled.Inline>
                  Registracija įsigytai paslaugai vykdoma telefonu&nbsp;
                </Styled.Inline>
                <Styled.Inline>
                  {' '}
                  <Button
                    loading={false}
                    disabled={false}
                    size="medium"
                    style={{
                      backgroundColor: '#04B64F',
                      borderColor: '#04B64F',
                    }}
                    onClick={() =>
                      (window.location.href = `tel:${shortPhoneNumber}`)
                    }
                  >
                    {shortPhoneNumber}
                  </Button>
                </Styled.Inline>
              </div>
              <div>
                Įsigytus paslaugų paketus ir detalesnę informaciją apie juos
                galite peržiūrėti{' '}
                <Link
                  to="/account"
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                  paciento paskyroje
                </Link>
              </div>
            </div>
          )}

          {!paymentPending && !userState.isLoggedIn && (
            <div
              style={{
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
                color: 'grey',
              }}
            >
              <div>Jūsų mokėjimas už paslaugų paketą gautas.</div>
              <div>
                <Styled.Inline>
                  Registracija įsigytai paslaugai vykdoma telefonu&nbsp;
                </Styled.Inline>
                <Styled.Inline>
                  {' '}
                  <Button
                    loading={false}
                    disabled={false}
                    size="medium"
                    style={{
                      backgroundColor: '#04B64F',
                      borderColor: '#04B64F',
                    }}
                    onClick={() =>
                      (window.location.href = `tel:${shortPhoneNumber}`)
                    }
                  >
                    {shortPhoneNumber}
                  </Button>
                </Styled.Inline>
              </div>
              <div>
                Įsigytus paslaugų paketus ir detalesnę informaciją apie juos
                galite peržiūrėti prisijungę prie{' '}
                <Link
                  to="/selfservice"
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                  paciento paskyros
                </Link>
              </div>
            </div>
          )}

          {paymentPending && <Loader></Loader>}
          {!paymentPending && userState.isLoggedIn && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                size="large"
                disabled={false}
                loading={false}
                onClick={() => {
                  history.push('/account/complex-services'); // After page is created redirect to new page
                }}
              >
                Įsigyti paslaugų paketai
              </Button>
            </div>
          )}
          {!paymentPending && !userState.isLoggedIn && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                size="large"
                disabled={false}
                loading={false}
                onClick={() => {
                  history.push('/');
                }}
              >
                Rinktis kitas paslaugas
              </Button>
            </div>
          )}
        </Container>

        <Modal
          visible={showEmptyFileModal}
          showClose={false}
          onClose={() => {
            setShowEmptyFileModal(false);
          }}
        >
          <Modal.Alert type="error">
            Įvyko klaidą generuojant kompleksinių paslaugų failą
          </Modal.Alert>
          <Modal.TextContent>
            Prašome pranešti apie problemą paskambinę telefonu {phoneNumber}.
          </Modal.TextContent>
          <Modal.Actions>
            <Button
              size="large"
              onClick={() => {
                setShowEmptyFileModal(false);
              }}
            >
              Uždaryti
            </Button>
          </Modal.Actions>
        </Modal>
      </Page>
    </>
  );
};
export default CombinedServicesCompleted;
