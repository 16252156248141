import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import Button from '../../../shared/Button';
import Styled from './IdentificationConfirmPage.styles';
import { useHistory } from 'react-router-dom';
import { useUserState, useUserDispatch } from '../../../context/UserContext';
import useRedirection from '../../../hooks/useRedirection';

const IdentificationConfirmPage = () => {
  const [clearCartState] = useRedirection();
  const history = useHistory();
  const { t } = useTranslation();
  const [retry, setRetry] = useState(0);
  const [stopRetry, setStopRetry] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState();
  const [error, setError] = useState(null);
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const { request } = useFetch(
    `/UserAccount/MarkID/Login/${userState.authenticationType}/Status`,
  );

  const { post: postAgreementsRequest, response: postAgreementsResponse } =
    useFetch(`/UserAccount/UpdateAgreement`);

  const { post: createUserPost, response: createUserResponse } =
    useFetch(`/UserAccount/Create`);
  const { post: updateUserPost, response: updateUserResponse } =
    useFetch(`/UserAccount/Update`);
  const validationFailed = history.location.state?.error;
  const isMobileIdAuth = history.location.state?.mobileid;

  useEffect(() => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);

  const isFromAuthorizedPersons = history.location.state?.origin;
  const AuthorizedPersons = history.location.state?.data?.value;
  const AuthorizedPersonsId = history.location.state?.data?.id;
  const consentId = history.location.state?.data?.consentId;

  useEffect(() => {
    if (stopRetry && redirectUrl) {
      if (redirectUrl.url && redirectUrl.state) {
        history.push(redirectUrl.url, redirectUrl.state);
      } else {
        history.push(redirectUrl);
      }
    }
  }, [history, redirectUrl, stopRetry]);

  const pushHistory = async () => {
    const response = await postAgreementsRequest(' ', [
      {
        medConsultPersonGuid: userState.id,
        patientConsentId: AuthorizedPersonsId,
        consentId: consentId,
        value: AuthorizedPersons.authorizedPersons,
        espbiEsi: userState.espbiEsi,
      },
    ]);

    if (postAgreementsResponse.status > 199) {
      if (response.error) {
        const tmp = {
          url: '/account/manage-agreements',
          state: {
            success: false,
            errorMessage: response.error,
          },
        };
        setRedirectUrl(tmp);
      } else {
        const tmp = {
          url: '/account/manage-agreements',
          state: {
            success: true,
          },
        };
        setRedirectUrl(tmp);
      }
    }
  };

  useEffect(() => {
    !validationFailed &&
      !stopRetry &&
      setTimeout(() => {
        request
          .get(`?token=${userState.phoneNumberVerifyOptions.token}`)
          .then(async (response) => {
            const status = response?.status;
            let user = response?.user;

            if (status === 'blocked') {
              setStopRetry(true);
              setError(t('validation.blockedAccount'));
            } else if (status === 'ok') {
              setStopRetry(true);
              // When trying to login but account doesnt exist
              if (
                history.location.state?.from === 'login' &&
                isFromAuthorizedPersons === 'authorizedPersons'
              ) {
                pushHistory();
              } else if (history.location.state?.from === 'login' && !user.id) {
                sessionDispatch({
                  type: 'UPDATE',
                  data: {
                    phoneNumber: userState.phoneNumber,
                    personalCode: user.personalCode,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    countryCode: userState.countryCode,
                    phoneNumberConfirmed: user.phoneNumberConfirmed,
                    containsPersonalCode: user.containsPersonalCode,
                    subscriptionInfo: user.subscriptionInfo,
                    role: user.role,
                    contacts: user.contacts,
                    insurances: user.insurances,
                  },
                });
                history.push('/terms');
              } else {
                if (history.location.state?.from !== 'login') {
                  // When registering but account doesnt exist
                  if (!user.id) {
                    const createUserResult = await createUserPost('', {
                      email: userState.email,
                      password: userState.password,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      personalCode: user.personalCode,
                      phoneNumber: userState.phoneNumber,
                    });

                    user = createUserResult.user;
                    sessionDispatch({
                      type: 'UPDATE_TOKENS',
                      data: {
                        token: createUserResult.token,
                        refreshToken: createUserResult.refreshToken,
                      },
                    });
                  } else {
                    // When registering but account exist
                    user = await updateUserPost('', {
                      id: user.id,
                      email: userState.email,
                      password: userState.password,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      phoneNumber: userState.phoneNumber,
                      scheduledToDeleteDate: user.scheduledToDeleteDate,
                      containsPersonalCode: user.containsPersonalCode,
                      role: user.role,
                    });
                  }
                }
                sessionDispatch({
                  type: 'UPDATE',
                  data: {
                    id: user.id,
                    email: user.email,
                    newEmail: user.newEmail,
                    phoneNumber: user.phoneNumber,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    countryCode: userState.countryCode,
                    phoneNumberConfirmed: user.phoneNumberConfirmed,
                    scheduledToDeleteDate: user.scheduledToDeleteDate,
                    discountCard: user.discountCard,
                    cardNumber: user.cardNumber,
                    cardType: user.cardType,
                    cardValidFrom: user.cardValidFrom,
                    cardValidTo: user.cardValidTo,
                    role: user.role,
                    containsPersonalCode: user.containsPersonalCode,
                    subscriptionInfo: user.subscriptionInfo,
                    contacts: user.contacts,
                    insurances: user.insurances,
                  },
                });

                if (
                  user.phoneNumberConfirmed ||
                  history.location.state?.from === 'login'
                ) {
                  sessionDispatch({
                    type: 'UPDATE',
                    data: {
                      isLoggedIn: true,
                      discountCard: user.discountCard,
                      cardNumber: user.cardNumber,
                      cardType: user.cardType,
                      cardValidFrom: user.cardValidFrom,
                      cardValidTo: user.cardValidTo,
                      containsPersonalCode: user.containsPersonalCode,
                      subscriptionInfo: user.subscriptionInfo,
                      role: user.role,
                      contacts: user.contacts,
                      insurances: user.insurances,
                    },
                  });

                  sessionDispatch({
                    type: 'UPDATE_TOKENS',
                    data: {
                      token: response.token,
                      refreshToken: response.refreshToken,
                    },
                  });
                  setRedirectUrl('/account');
                } else {
                  // sessionDispatch({
                  //   type: 'UPDATE_TOKENS',
                  //   data: {
                  //     token: response.token,
                  //     refreshToken: response.refreshToken,
                  //   },
                  // });
                  setRedirectUrl('/verify/phone');
                }
              }
            } else if (status === 'error') {
              setError(response.message);
              setStopRetry(true);
            }
          })

          .finally(() => setRetry(retry + 1));
      }, 1000);
  }, [
    request,
    history,
    retry,
    stopRetry,
    createUserPost,
    createUserResponse,
    updateUserPost,
    updateUserResponse,
    t,
    userState.phoneNumberVerifyOptions?.token,
    userState.phoneNumber,
    userState.countryCode,
    userState.password,
    userState.email,
    userState.useremail,
    sessionDispatch,
    validationFailed,
    setStopRetry,
    redirectUrl,
    setRedirectUrl,
    isFromAuthorizedPersons,
    AuthorizedPersons,
  ]);

  useEffect(() => {
    if (stopRetry) {
      history.push(redirectUrl);
    }
  }, [history, redirectUrl, stopRetry]);

  return (
    <Page loading={false}>
      <Container>
        <Styled.Form>
          <Styled.Title>{t('identityConfirm.title')}</Styled.Title>
          {!validationFailed && (
            <>
              <Styled.Information>
                {t('identityConfirm.information')}
              </Styled.Information>
              <Styled.ControlCode>
                {t('identityConfirm.controlCode')}{' '}
                <strong>
                  {userState.phoneNumberVerifyOptions.controlCode}
                </strong>
              </Styled.ControlCode>
            </>
          )}
          {validationFailed && (
            <>
              <Styled.Error>
                Įvyko klaida bandant nustatyti Jūsų tapatybę!
              </Styled.Error>
              {!isMobileIdAuth && (
                <>
                  <Styled.Information style={{ marginTop: '25px' }}>
                    Norėdami naudoti „Smart-ID“ asmens tapatybės patvirtinimui
                    būtina naudoti aukštesnį nei „Smart-ID Basic“ paskyros lygį.
                  </Styled.Information>

                  <Styled.Information>
                    Pasitikrinti savo „Smart-ID“ paskyros lygį galite Smart-ID
                    programėlėje, savo mobiliame įrenginyje pasirinkę meniu
                    punktą "Naudotojo informacija".
                  </Styled.Information>

                  <Styled.Information>
                    Daugiau informacijos{' '}
                    <a href="https://www.smart-id.com/lt/pagalba/duk/registracija/smart-id-registracijos-budai/ ">
                      https://www.smart-id.com/lt/pagalba/duk/registracija/smart-id-registracijos-budai/{' '}
                    </a>
                  </Styled.Information>

                  <Styled.Information style={{ marginTop: '50px' }}>
                    Arba galite atvykti į artimiausią Affidea ar Endemik
                    kliniką, kur paskyrą Jums padės sukurti mūsų darbuotojai.
                    Nepamirškite turėti asmens tapatybę patvirtinančio
                    dokumento.
                  </Styled.Information>
                </>
              )}
            </>
          )}
          {error && <Styled.Error>{error}</Styled.Error>}
          <Styled.Buttons>
            {error || validationFailed ? (
              <Button
                size="large"
                onClick={async () => {
                  validationFailed
                    ? history.push('/register')
                    : history.goBack();
                }}
              >
                {t('identityConfirm.back')}{' '}
              </Button>
            ) : (
              <Button
                size="large"
                variant="secondary"
                onClick={async () => {
                  history.goBack();
                }}
                style={{ color: '#52585F' }}
              >
                {t('identityConfirm.cancel')}{' '}
              </Button>
            )}
          </Styled.Buttons>
        </Styled.Form>
      </Container>
    </Page>
  );
};

export default IdentificationConfirmPage;
