import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import useFetch from 'use-http';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const ConsentLogDetails = () => {
  const history = useHistory();
  const log = history.location.state?.data; 
  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>      
      </Styled.BackArrow>
      <pre style={{ whiteSpace: 'pre-wrap' }}>        
      {JSON.stringify(log, null, 2)}       
      </pre>
    </Styled.DataContainer>
  );
};
export default ConsentLogDetails;
