import React, { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { useEffectOnce } from 'react-use';
import Page from '../../../../shared/Page';
import Styled from '../../UserAccountPage/UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import Loader from '../../../../shared/Loader';
import { useHistory } from 'react-router-dom';

const LocalRegistrationList = () => {
  const [Data, setData] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { get: getData, response: getDataResponse } = useFetch(
    '/LocalRegistration/GetLocalRegistrations',
  );

  const handleSelection = async (data) => {
    setLoading(true);
    history.push('/registration/local/details', { registration: data });
    setLoading(false);
  };

  useEffectOnce(async () => {
    setLoading(true);
    const response = await getData();
    if (getDataResponse.status >= 400) {
    } else {
      setData(response);
    }
    setLoading(false);
  });

  return (
    <Styled.DataContainer>
      <Styled.TabTitleWrapper>
        <Styled.TabTitle>Kreipimųsi istorija</Styled.TabTitle>
      </Styled.TabTitleWrapper>

      {loading && (
        <Page>
          <Loader />
        </Page>
      )}
      {!loading &&
        Data &&
        Data.length !== 0 &&
        _.orderBy(Data, ['createdAt'], ['desc']).map((obj) => {
          return (
            <div key={obj.id}>
              <Styled.Visit>
                <Styled.VisitTitleDateTime>
                  <a
                    onClick={() => {
                      handleSelection(obj);
                    }}
                  >
                    {obj.topic}
                  </a>
                  <Moment
                    utc={true}
                    onClick={() => {
                      handleSelection(obj);
                    }}
                    className="time"
                    format="YYYY-MM-DD"
                  >
                    {obj.createdAt}
                  </Moment>
                </Styled.VisitTitleDateTime>
              </Styled.Visit>
            </div>
          );
        })}

      {Data && Data.length === 0 && !loading && (
        <>
          <div
            style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}
          >
            Įrašų nėra
          </div>
        </>
      )}
    </Styled.DataContainer>
  );
};
export default LocalRegistrationList;
