const WorkplaceServices = {
  serviceTypeId: 123,
  serviceTypeTitle:
    'Šeimos gydytojų konsultacijos teikiamos diagnostikos centre/klinikoje',
  services: [
    {
      serviceTypeTitle: 'LOR',
      specialistId: 1,
      workplaceId: 1,
      serviceId: 1,
      specialty: 'LOR',
      specialistName: 'Vardenis Pavardenis',
      departmentTitle: 'LOR',
      isServiceRemote: true,
      price: 43,
      upcomingVisit: new Date('2021-02-03 12:00'),
    },
    {
      serviceTypeTitle: 'LOR',
      specialistId: 2,
      workplaceId: 2,
      serviceId: 2,
      specialty: 'LOR',
      specialistName: 'Vardenis Pavardenis #2',
      departmentTitle: 'LOR',
      isServiceRemote: true,
      price: 43,
      upcomingVisit: new Date('2021-02-03 12:00'),
    },
  ],
};

export default WorkplaceServices;
