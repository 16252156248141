/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import moment from 'moment';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
const ReferralTabComponent = () => {
  const history = useHistory();
  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');
  const referral = history.location?.state?.data;

  const handleFileDownload = async () => {
    await getFile(
      `?fileType=${referral.file.type}&fileGuid=${
        referral.file.guid
      }&category=${'Referral'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download =
        referral.practitioner?.qualification +
        '-' +
        referral.practitioner?.name +
        ' ' +
        referral.practitioner?.surname +
        ' ' +
        moment(new Date(referral.occurrenceDateTime)).format('YYYY-MM-DD') +
        '.pdf';

      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>Siuntimai</Styled.VisitTitle>
      </Styled.BackArrow>
      <Styled.InfoDetailsRow>
        <span>Data:</span>
        <div>
          {(new Date(Date.parse(referral.occurrenceDateTime)).getUTCHours() ===
            0 && (
            <Moment utc={true} className="time" format="YYYY-MM-DD">
              {Date.parse(referral.occurrenceDateTime)}
            </Moment>
          )) || (
            <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
              {Date.parse(referral.occurrenceDateTime)}
            </Moment>
          )}
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Gydytojas:</span>
        <div>
          {referral.practitioner?.qualification} - {referral.practitioner?.name}{' '}
          {referral.practitioner?.surname}
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Diagnozė:</span>
        <div>{referral.reason}</div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Kokiam specialistui siuntimas parašytas:</span>
        <div>{referral.specialty}</div>
      </Styled.InfoDetailsRow>
      <Styled.VisitDetailsRow>
        <span>Siuntimo tikslas:</span>
        {referral.intent &&
          referral.intent.split(/\r?\n|<br\/>/).map((text, index) => {
            return <div key={index}> {text} </div>;
          })}
      </Styled.VisitDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Galioja iki:</span>
        <div>
          <Moment utc={false} className="time" format="YYYY-MM-DD">
            {Date.parse(referral.validUntil)}
          </Moment>
        </div>
      </Styled.InfoDetailsRow>
      {referral.file && referral.file.guid && (
        <Styled.InfoDetailsRow>
          <a onClick={handleFileDownload}>ATSISIŲSTI PDF</a>
        </Styled.InfoDetailsRow>
      )}
    </Styled.DataContainer>
  );
};
export default ReferralTabComponent;
