const WorkplaceService = {
  serviceTypeTitle: 'LOR',
  serviceTitle: 'LOR',
  specialistId: 1,
  workplaceId: 1,
  serviceId: 1,
  specialty: 'LOR',
  specialistName: 'Vardenis Pavardenis',
  departmentTitle: 'LOR',
  isServiceRemote: true,
  price: 43,
  isFileRequired: true,
  upcomingVisit: new Date(),
  orderData: {
    otherPerson: true,
  },
};

export default WorkplaceService;
