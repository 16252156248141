import React from 'react';
import Styled from './InfoTooltip.styles';
import { FiInfo } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
const InfoTooltip = ({
  link,
  onHover,
  onLeave,
  tooltiptext,
  text,
  Icon,
  color,
  size = 35,
}) => {
  return (
    <Styled.TitleBlock>
      <p>
        <div
          style={{
            width: `${size}px`,
            border: 0,
            backgroundColor: '#f1f5f7',
          }}
          className="tooltip"
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
        >
          <span>
            <a href={link} target="_blank" rel="noreferrer">
              {Icon && <Icon size={size} color={color} />}
              <span class="tooltiptext">{tooltiptext}</span>
            </a>
          </span>
        </div>
        {text}
      </p>
    </Styled.TitleBlock>
  );
};

export default InfoTooltip;
