import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CaretIcon } from '../../../assets/icons/caret.svg';
import { ReactComponent as FlagLtIcon } from '../../../assets/icons/flag-lt.svg';
import { ReactComponent as FlagEnIcon } from '../../../assets/icons/flag-en.svg';
import { ReactComponent as FlagRuIcon } from '../../../assets/icons/flag-ru.svg';
import Styled from './LangSelect.styles';

const LangSelect = ({ disabled }) => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();

  const languages = [
    {
      value: 'lt',
      icon: FlagLtIcon,
    },
    {
      value: 'en',
      icon: FlagEnIcon,
    },
    {
      value: 'ru',
      icon: FlagRuIcon,
    },
  ];

  function getActive() {
    const { icon: Icon } =
      languages.find((l) => l.value === i18n.language) || languages[0];
    return <Icon />;
  }

  return (
    <Styled.LangSelect isDisabled={disabled}>
      <Styled.Toggler
        onClick={(event) => {
          setOpen(true);
        }}
      >
        <Styled.Option>{getActive()}</Styled.Option>
        <CaretIcon />
      </Styled.Toggler>

      {open && (
        <>
          <Styled.Dropdown>
            {languages
              .filter((l) => l.value !== i18n.language)
              .map(({ value, icon: Icon }) => (
                <Styled.Option
                  key={value}
                  onClick={() => {
                    i18n.changeLanguage(value);
                    setOpen(false);
                  }}
                >
                  <Icon />
                </Styled.Option>
              ))}
          </Styled.Dropdown>

          <Styled.Backdrop onClick={() => setOpen(false)}></Styled.Backdrop>
        </>
      )}
    </Styled.LangSelect>
  );
};

LangSelect.propTypes = {
  disabled: PropTypes.bool,
};

export default LangSelect;
