import React from 'react';
import defaultCategoryImage from '../../../assets/images/cat-default.png';
import defaultSpecialistImage from '../../../assets/images/specialist-default.png';
import clients from '../../../client.config';

const SettingsStateContext = React.createContext();
const SettingsDispatchContext = React.createContext();

function themeReducer(state, action) {
  switch (action.type) {
    case 'SET_THEME': {
      return {
        ...state,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SettingsProvider({ children }) {
  const id = process.env.REACT_APP_CLIENT_ID;
  const clientConfig = clients.find((client) => client.id === id);
  
  const [state, dispatch] = React.useReducer(themeReducer, {
    theme: clientConfig.theme,
    cookies: clientConfig.cookies,
    header: clientConfig.header,
    footer: clientConfig.footer,
    agreements: clientConfig.agreements,
    phoneNumber: clientConfig.phoneNumber,
    shortPhoneNumber: clientConfig.shortPhoneNumber,
    getServiceImage,
  });

  function getServiceImage(service) {
    const url = service.specialistId
      ? `${process.env.REACT_APP_API_BASE}/Specialists/${service.specialistId}/picture`
      : state.theme.categories[service.categoryId] || defaultCategoryImage;

    return `url(${url}), url(${defaultSpecialistImage})`;
  }

  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
}

function useSettingsState() {
  const context = React.useContext(SettingsStateContext);

  if (context === undefined) {
    throw new Error('useSettingsState must be used within a SettingsProvider');
  }

  return context;
}

function useSettingsDispatch() {
  const context = React.useContext(SettingsDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useSettingsDispatch must be used within a SettingsProvider'
    );
  }

  return context;
}

export { SettingsProvider, useSettingsState, useSettingsDispatch };
