import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

const LineChart = ({
  dataName,
  dataSet,
  xValues,
  borderColor = '#2196F3',
  backgroundColor = '#2196F3',
}) => {
  const data = {
    labels: xValues,
    datasets: [
      {
        label: dataName,
        data: dataSet,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      },
    ],
  };

  return (
    <div style={{ maxWidth: 600, maxHeight: 300 }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChart;
