import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import { useUserState, useUserDispatch } from '../context/UserContext';
import SessionStorage from '../constants/sessionStorage';

const useEmailToken = () => {
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const [emailChangeMessage, setEmailChangeMessage] = useState('');
  const { post: sendTokenRequest, response: sendTokenResponse } = useFetch(
    `/UserAccount/CheckEmailChange`,
  );

  const { get: sendDeleteEmailRequest, response: sendDeleteEmailResponse } =
    useFetch(`/UserAccount/DeleteNewEmail`);

  const [previewTokenConfirmModalVisible, setPreviewTokenConfirmModalVisible] =
    useState(false);

  useEffect(() => {
    if (emailChangeMessage !== '') {
      setPreviewTokenConfirmModalVisible(true);
    }
  }, [emailChangeMessage]);

  const checkEmailToken = () => {
    const emailToken = SessionStorage.getContent().emailToken;
    if (emailToken) {
      return { emailTokenExists: true, emailToken: emailToken };
    } else {
      return { emailTokenExists: false, emailToken: null };
    }
  };

  const resetNewEmail = async (emailToken) => {
    const response = await sendTokenRequest(' ', {
      confirmToken: emailToken,
      action: 'reset_newEmail',
    });
    if (sendTokenResponse.status > 199) {
      if (response.status === 'error') {
      } else {
        sessionDispatch({
          type: 'UPDATE',
          data: {
            email: response.email,
            newEmail: response.newEmail,
          },
        });
        sessionDispatch({
          type: 'UPDATE_EMAIL_TOKEN',
          data: {
            emailToken: null,
          },
        });
      }
    }
  };

  const confirmToken = async (emailToken) => {
    const response = await sendTokenRequest(' ', {
      confirmToken: emailToken,
      action: 'confirm_token',
    });
    sessionDispatch({
      type: 'UPDATE_EMAIL_TOKEN_CONFIRMED',
      data: {
        emailTokenConfirmed: true,
      },
    });
    if (sendTokenResponse.status > 199) {
      if (response.status === 'error') {
        if (response.message === 'Token has already been used') {
          setEmailChangeMessage(
            'El.pašto adreso keitimo nuoroda nebegalioja! Galite pakeisti el.pašto adresą savo paskyroje.',
          );
        } else if (response.message === 'Token has expired') {
          setEmailChangeMessage(
            'El.pašto adreso keitimo nuoroda nebegalioja! Prisijunkite prie paskyros jei norite pakeisti el.pašto adresą.',
          );
        } else if (response.message === 'Link is not valid') {
          setEmailChangeMessage(
            'El.pašto adreso keitimo nuoroda nebegalioja! Prisijunkite prie paskyros jei norite pakeisti el.pašto adresą.',
          );
        }
        sessionDispatch({
          type: 'UPDATE_EMAIL_TOKEN',
          data: {
            emailToken: null,
          },
        });
      } else {
        sessionDispatch({
          type: 'UPDATE_EMAIL_TOKEN',
          data: {
            emailToken: emailToken,
          },
        });
      }
    }
  };

  const sendUpdate = async (emailToken) => {
    if (emailToken) {
      const response = await sendTokenRequest(' ', {
        confirmToken: emailToken,
        newEmail: userState.newEmail,
        email: userState.email,
      });
      if (sendTokenResponse.status > 199) {
        if (response.status === 'error') {
        } else {
          sessionDispatch({
            type: 'UPDATE',
            data: {
              email: response.email,
              newEmail: response.newEmail,
            },
          });
          sessionDispatch({
            type: 'UPDATE_EMAIL_TOKEN',
            data: {
              emailToken: null,
            },
          });
        }
      }
    }
  };

  const deleteNewEmail = async () => {
    const response = await sendDeleteEmailRequest();

    if (sendDeleteEmailResponse.status > 199) {
      if (response.status === 'error') {
      } else {
        sessionDispatch({
          type: 'UPDATE',
          data: {
            email: response.email,
            newEmail: response.newEmail,
          },
        });
      }
    }
  };

  return [
    checkEmailToken,
    sendUpdate,
    resetNewEmail,
    confirmToken,
    emailChangeMessage,
    previewTokenConfirmModalVisible,
    setPreviewTokenConfirmModalVisible,
    deleteNewEmail,
  ];
};

export default useEmailToken;
