import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Coupon = styled.div``;
const CouponHeaderImage = styled.div`
  height: 180px;
  width: 270px;
  background-size: cover;
  background-position: center top;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0 0;
`;
const CouponContent = styled.div`
  height: 211px;
  width: 270px;
  background-color: rgb(241, 245, 247);
  padding: 20px 20px;
  border-radius: 0 0 20px 20px;
  position: relative;
`;
const CouponTitle = styled.h2`
  font-size: 1.2rem;
  margin: 0;
  text-align: left;
  ${(props) => props.comment.length === 0 && 'margin-bottom:3.4rem;'};
`;
const CouponDescription = styled.p`
  color: grey;
  margin: 0;
  font-size: 0.8rem;
`;
const CouponComment = styled.p`
  font-size: 11px;
  font-weight: 500l;
`;

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  margin: 50px 0;

  ${mediaDown('md')`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    grid-gap: 20px;
    align-items:center;
  `}
`;

const Actions = styled.div`
  position: absolute;
  margin-bottom: 15px;
  bottom: 0;
  margin-top: 18px;
  ${mediaDown('md')`
    display: flex;
    justify-content: center;
  `}
`;

const Description = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 70px;
`;

const Styled = {
  Coupon,
  CouponHeaderImage,
  CouponContent,
  CouponTitle,
  CouponDescription,
  Description,
  List,
  CouponComment,
  Actions,
};

export default Styled;
