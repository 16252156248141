/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import useFetch from 'use-http';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../../../../shared/DateRangePicker/DateRangePicker';
import Loader from '../../../../../shared/Loader';
import Page from '../../../../../shared/Page';
import { useHistory } from 'react-router-dom';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import { getQueryString } from '../../../../../constants/visitsQueryStringBuilder';
import Button from '../../../../../shared/Button/Button';
import SelectField from '../../../../../shared/SelectField/SelectField';
import { useEffectOnce } from 'react-use';
const VaccinationsList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [Vaccinations, setVaccinations] = useState();
  const [loading, setLoading] = useState(false);
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const [Filters, setFilters] = useState();
  const [SelectedFilter, setSelectedFilter] = useState();
  const [search, setSearch] = useState();
  const [dateRange, setDateRange] = useState(userState.dateFilter);
  const { get: getVaccinations } = useFetch('/UserAccount/VaccinationsList');

  const { get: getVaccination, response: getVaccinationResponse } = useFetch(
    '/UserAccount/Vaccination',
  );

  const applyChanges = async (date, search, filter) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getVaccinations(
      getQueryString(date, search, null, filter, userState.espbiEsi),
    );
    if (getVaccinationResponse.status >= 400) {
    } else {
      setVaccinations(response.vaccinations);
      const tmpFilterArray = [];
      // eslint-disable-next-line array-callback-return
      response?.filters?.map((filter) => {
        tmpFilterArray.push({
          value: filter,
          label: capitalizeFirstLetter(filter),
        });
      });
      setFilters(tmpFilterArray);
    }
    setLoading(false);
  };

  useEffectOnce(() => {
    applyChanges(dateRange, search, SelectedFilter);
  });

  function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  const handleSelection = async (obj) => {
    setLoading(true);
    let query = userState.espbiEsi ?
    `?id=${obj.id}&espbiEsi=${userState.espbiEsi}`
    :
    `?id=${obj.id}`
    
    await getVaccination(query).then(
      (res) => {
        setLoading(false);
        history.push('/account/vaccination', {
          data: res,
          status: obj.e063Status,
        });
      },
    );
  };

  return (
    <Styled.DataContainer>
      <Styled.TabTitleWrapper select={true}>
        <Styled.TabTitle>Skiepai</Styled.TabTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearch(e.target.search.value);
            applyChanges(dateRange, e.target.search.value, SelectedFilter);
          }}
          onChange={(e) => {
            if (
              e.target.value === undefined ||
              e.target.value === null ||
              e.target.value === ''
            ) {
              setSearch(e.target.value);
              applyChanges(dateRange, e.target.value, SelectedFilter);
            }
          }}
          style={{ display: 'flex' }}
        >
          <input type="text" id="search" placeholder="Paieška" name="search" />
          <Button
            style={{ marginRight: '15px', fontSize: '16px' }}
            size="small"
            type="submit"
          >
            Ieškoti
          </Button>
        </form>
        <Styled.SelectField>
          <SelectField
            options={Filters}
            placeHolder={'Būsena'}
            isClearable={true}
            onChange={(val) => {
              setSelectedFilter(val);
              applyChanges(dateRange, search, val);
            }}
          ></SelectField>
        </Styled.SelectField>
        <DateRangePicker
          dateChanged={(date) => {
            setDateRange(date);
            applyChanges(date, search, SelectedFilter);
          }}
          dateRange={dateRange}
        />
      </Styled.TabTitleWrapper>
      {loading && (
        <Page>
          <Loader />
        </Page>
      )}
      {!loading &&
        Vaccinations &&
        Vaccinations.length !== 0 &&
        _.orderBy(Vaccinations, ['occurrenceDateTime'], ['desc']).map((obj) => {
          return (
            <div key={obj.id}>
              <Styled.Visit>
                <Styled.VisitTitleDateTime finished={obj.e063Status}>
                  <a
                    onClick={() => {
                      handleSelection(obj);
                    }}
                  >
                    {obj.name}
                  </a>
                  {(new Date(
                    Date.parse(obj.occurrenceDateTime),
                  ).getUTCHours() === 0 && (
                    <Moment
                      utc={true}
                      onClick={() => {
                        handleSelection(obj);
                      }}
                      className="time"
                      format="YYYY-MM-DD"
                    >
                      {Date.parse(obj.occurrenceDateTime)}
                    </Moment>
                  )) || (
                    <Moment
                      utc={true}
                      onClick={() => {
                        handleSelection(obj);
                      }}
                      className="time"
                      format="YYYY-MM-DD HH:mm"
                    >
                      {Date.parse(obj.occurrenceDateTime)}
                    </Moment>
                  )}
                </Styled.VisitTitleDateTime>
                <div>
                  <span>{obj.manufacturer}</span>
                  <span>
                    {obj.e063StatusDisplay?.toLowerCase() === 'darbinis'
                      ? 'Nepasirašytas'
                      : capitalizeFirstLetter(obj.e063StatusDisplay)}
                  </span>
                </div>
              </Styled.Visit>
            </div>
          );
        })}
      {Vaccinations && Vaccinations.length !== 0 && !loading && (
        <div style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}>
          {t('userAccount.visitHistoryTab.dateInfo')}
        </div>
      )}
      {Vaccinations && Vaccinations.length === 0 && !loading && (
        <>
          <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
          <div
            style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}
          >
            {t('userAccount.visitHistoryTab.dateInfo')}
          </div>
        </>
      )}
    </Styled.DataContainer>
  );
};
export default VaccinationsList;
