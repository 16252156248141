import ReactGA from 'react-ga';

const useGAEventsTracker = (category = 'Event Category') => {

  if (!getAllowed().includes('analysis')) {
    return;
  }

  const trackEvent = (action = 'action', label = 'label') => {
    ReactGA.event({ category, action, label });
  };
  return trackEvent;
};

const getAllowed = () => {
  const ls = localStorage.getItem('cookies');
  return ls ? ls.split(',') : [];
};

export default useGAEventsTracker;
