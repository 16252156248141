import _, { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { useSettingsState } from '../../context/SettingsContext';
import useGAEventsTracker from '../../hooks/useGAEventsTracker';
import Container from '../Container';
import Loader from '../Loader';
import Styled from './Hero.styles';

function highlightText(text, search) {
  const re = new RegExp(`(${_.escapeRegExp(search)})`, 'gi');
  const newResults = text.replace(re, '<mark>$&</mark>');
  return newResults;
}

const Hero = ({ onScroll }) => {
  const { t } = useTranslation();
  const { theme } = useSettingsState();

  const history = useHistory();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({});
  const { request } = useFetch('/Services/ServicesBySearch');
  const { request: complexServiceRequest } = useFetch(
    '/Services/CombinedServices',
  );
  const GAEventsTracker = useGAEventsTracker('Pagrindinis puslapis');

  const isUltrasoundServiceType = (service) =>
    service.serviceTypeExternalId == process.env.REACT_APP_ULTRASOUNDEXTERNALID;

  const getAllowed = () => {
    const ls = localStorage.getItem('cookies');
    return ls ? ls.split(',') : [];
  };

  const redirectToServicePage = (service) => {
    complexServiceRequest
      .get(`?serviceId=${service.serviceId}&holter=${service.isHolter}`)
      .then((r) => {
        if (r?.length !== 1) {
          return;
        }
        var complexService = r[0];

        history.push('/kompleksines-paslaugos/checkout', {
          service: complexService.service,
          prices: complexService.prices,
          selectedDepartment: undefined,
        });
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (query) {
        const replacedQuery = query.replace(/['"]+/g, '');
        if (getAllowed().includes('analysis')) {
          replacedQuery && GAEventsTracker('Paieška', replacedQuery);
        }

        request.get(`?Search=${replacedQuery}`).then((r) => {
          setResults(replacedQuery ? groupBy(r, 'serviceTypeTitle') : {});
        });
      } else {
        setResults({});
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [/*GAEventsTracker,*/ query, request]);

  const getSearchSelectedTitleForGA = (service) => {
    return !service.specialistId
      ? service?.serviceTitle
      : service?.specialistName + ' - ' + service?.serviceTitle;
  };

  return (
    <Styled.Hero
      primaryColor={theme.primaryColor}
      // style={{
      //   backgroundImage: `url(${heroImg})`,
      // }}
    >
      <Container wide={true}>
        <Styled.ContentContainer>
          <Styled.Title>{t('landing.hero.title')}</Styled.Title>
          <Styled.Notification>
            {t('landing.hero.notification')}
          </Styled.Notification>
          <Styled.Autocomplete>
            <Styled.InputHolder hasResults={!!Object.keys(results).length}>
              <SearchIcon />
              <Styled.Input
                type="text"
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                style={{ color: '#52585F' }}
                placeholder={t('landing.hero.inputText')}
              ></Styled.Input>
              <Styled.Loader isLoading={request.loading}>
                <Loader size={22} color="#c5c5c5" inline />
              </Styled.Loader>
            </Styled.InputHolder>

            {!!Object.keys(results).length && (
              <Styled.Results>
                <Scrollbars>
                  <div style={{ padding: '12px 0' }}>
                    {Object.keys(results).map((key) => (
                      <div key={key}>
                        <Styled.ResultsGroupName>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: highlightText(key, query),
                            }}
                          ></div>
                        </Styled.ResultsGroupName>
                        {results[key].map((service, i) => (
                          <Styled.ResultsGroupItem
                            key={i}
                            primaryColor={theme.primaryColor}
                            onClick={() => {
                              if (getAllowed().includes('analysis')) {
                                GAEventsTracker(
                                  'Paieškoje pasirinkta',
                                  getSearchSelectedTitleForGA(service),
                                );
                              }

                              if (service.type === 'ServiceComplex') {
                                redirectToServicePage(service);
                              } else if (!service.hasUpcomingVisits) {
                              } else if (
                                service.serviceTypeExternalId !=
                                process.env.REACT_APP_FAMILYDOCTOREXTERNALID
                              ) {
                                history.push(
                                  `/order/${service.workplaceId}/${service.serviceId}`,
                                );
                              } else {
                                if (service.specialistId) {
                                  history.push(
                                    `/services/${service.categoryId}/${service.serviceTypeId}?specialistId=${service.specialistId}`,
                                  );
                                } else {
                                  history.push(
                                    `/services/${service.categoryId}/${service.serviceTypeId}`,
                                  );
                                }
                              }
                            }}
                          >
                            {service.specialistName !== null && (
                              <div>
                                <div>
                                  <strong
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        service.specialistName,
                                        query,
                                      ),
                                    }}
                                  ></strong>
                                  <strong
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        ` - ${service.serviceTitle}`,
                                        query,
                                      ),
                                    }}
                                  ></strong>
                                </div>
                                <div style={{ display: 'flex' }}>
                                  {service.specialty && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          `${service.specialty} - `,
                                          query,
                                        ),
                                      }}
                                    />
                                  )}

                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      marginLeft: '2px',
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        ` ${service.departmentName} `,
                                        query,
                                      ),
                                    }}
                                  />
                                  {service.departmentAddress && (
                                    <div
                                      style={{}}
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          `, ${service.departmentAddress} `,
                                          query,
                                        ),
                                      }}
                                    />
                                  )}
                                  {!service.hasUpcomingVisits && (
                                    <div
                                      style={{}}
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          isUltrasoundServiceType(service) &&
                                            service.workplaceTypeId ===
                                              'Tyrimas'
                                            ? '&nbsp;- Pagal poreikį atliekamas konsultacijos metu'
                                            : '&nbsp;- Nėra laisvų vizitų ',
                                          query,
                                        ),
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            {service.specialistName === null && (
                              <div>
                                <div>
                                  <strong
                                    dangerouslySetInnerHTML={{
                                      __html: highlightText(
                                        service.serviceTitle,
                                        query,
                                      ),
                                    }}
                                  ></strong>
                                </div>
                                {service.type !== 'ServiceComplex' && (
                                  <div style={{ display: 'flex' }}>
                                    <div
                                      style={{
                                        fontWeight: 'bold',
                                        marginLeft: '2px',
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          ` ${service.departmentName} `,
                                          query,
                                        ),
                                      }}
                                    />
                                    {service.departmentAddress && (
                                      <div
                                        style={{}}
                                        dangerouslySetInnerHTML={{
                                          __html: highlightText(
                                            `, ${service.departmentAddress} `,
                                            query,
                                          ),
                                        }}
                                      />
                                    )}
                                    {!service.hasUpcomingVisits && (
                                      <div
                                        style={{}}
                                        dangerouslySetInnerHTML={{
                                          __html: highlightText(
                                            '&nbsp;- Nėra laisvų vizitų',
                                            query,
                                          ),
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </Styled.ResultsGroupItem>
                        ))}
                      </div>
                    ))}
                  </div>
                </Scrollbars>
              </Styled.Results>
            )}
          </Styled.Autocomplete>
        </Styled.ContentContainer>
      </Container>
    </Styled.Hero>
  );
};

export default Hero;
