import { useEffect } from 'react';
import { useCallback } from 'react';

const useKey = (key, callback, visible) => {
  const match = useCallback(
    (eventKey) => {
      return key?.toLowerCase() === eventKey?.toLowerCase();
    },
    [key],
  );

  const onDown = useCallback(
    ({ key: eventKey }) => {
      match(eventKey) && callback();
    },
    [callback, match],
  );

  useEffect(() => {
    if (visible) {
      window.addEventListener('keydown', onDown);
    }
    return () => {
      window.removeEventListener('keydown', onDown);
    };
  }, [key, onDown, visible]);
};

export default useKey;
