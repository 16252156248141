import React, { useCallback } from 'react';
import { parseISO, format as dateFnsFormat } from 'date-fns';
import Button from '../../../../shared/Button';
import { ReactComponent as CrossIcon } from '../../../../../assets/icons/cross.svg';
import { useSettingsState } from '../../../../context/SettingsContext';
import Styled from './Summary.styles';
import { Money, formatCurrency } from '../../../../shared/Money';
import defaultCategoryImage from '../../../../../assets/images/cat-default.png';
const Summary = ({ item }) => {
  return (
    <Styled.Summary>
      <Styled.LabelsList>
        <div>Paslauga</div>
        <div></div>
        <div>Galiojimo laikas (d.)</div>
        <div>Kaina</div>
      </Styled.LabelsList>

      <Styled.Item style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Styled.Image
            desktop={true}
            style={{
              backgroundImage: `url(${defaultCategoryImage})`,
            }}
          />
          <Styled.ItemContent>
            <Styled.MainInfo>
              <Styled.Text size="large">{item.serviceTitle}</Styled.Text>

              <Styled.ImageMobile
                mobile={true}
                style={{
                  backgroundImage: `url(${defaultCategoryImage})`,
                  width: '60px',
                }}
              />
            </Styled.MainInfo>
            <div></div>
            <div>
              <Styled.Text data-label="Galiojimo laikas">
                {item.validationTimeInDays}
              </Styled.Text>
            </div>
            <div>
              <Styled.Text data-label="Kaina" size="price">
                <Money amount={item.paidPrice} />
              </Styled.Text>
            </div>
          </Styled.ItemContent>
        </div>
        <Styled.WrapperDiv>
          <Styled.ServicePartsDiv>
            <span>
              {item?.serviceParts?.map((s) => {
                return (
                  <div style={{ color: 'grey' }} key={s}>
                    {s}
                  </div>
                );
              })}
            </span>
          </Styled.ServicePartsDiv>
        </Styled.WrapperDiv>
      </Styled.Item>
      <Styled.TotalPrice>
        Iš viso su PVM: {formatCurrency(item.paidPrice)}
      </Styled.TotalPrice>
    </Styled.Summary>
  );
};

export default Summary;
