import React from 'react';
import { Styled } from './Loader.styles';
import { useSettingsState } from '../../context/SettingsContext';

const Loader = ({ inline, color, size = 40, light = false }) => {
  const { theme } = useSettingsState();

  return (
    <Styled.Loader
      inline={inline}
      color={color || theme.primaryColor}
      size={size}
    >
      <svg viewBox="0 0 50 50">
        <circle
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth={light ? '3' : '5'}
        />
      </svg>
    </Styled.Loader>
  );
};

export default Loader;
