/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Styled from '../UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import Loader from '../../../../shared/Loader';
import Page from '../../../../shared/Page';
import DateRangePicker from '../../../../shared/DateRangePicker/DateRangePicker';
import { useHistory } from 'react-router-dom';
import { useUserState, useUserDispatch } from '../../../../context/UserContext';
import { getQueryString } from '../../../../constants/visitsQueryStringBuilder';
import Button from '../../../../shared/Button/Button';
const FailedRegistrations = () => {
  const history = useHistory();
  const [Visits, setVisits] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const { get: getVisits, response: getVisitsResponse } = useFetch(
    '/UserAccount/Visits',
  );
  const [dateRange, setDateRange] = useState(userState.dateFilter);

  const status = `failed`;

  const applyChanges = async (date, search) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getVisits(
      getQueryString(date, search, status, undefined, userState.espbiEsi),
    );
    if (getVisitsResponse.status >= 400) {
    } else {
      setVisits(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    applyChanges(dateRange, search);
  }, [dateRange]);

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Neįvykusių vizitų sąrašas</Styled.TabTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
              applyChanges(dateRange, e.target.search.value);
            }}
            onChange={(e) => {
              if (
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === ''
              ) {
                setSearch(e.target.value);
                applyChanges(dateRange, e.target.value);
              }
            }}
            style={{ display: 'flex' }}
          >
            <input
              type="text"
              id="search"
              placeholder="Paieška"
              name="search"
            />
            <Button
              style={{ marginRight: '15px', fontSize: '16px' }}
              size="small"
              type="submit"
            >
              {' '}
              Ieškoti
            </Button>
          </form>
          <DateRangePicker
            dateChanged={(date) => {
              setDateRange(date);
              applyChanges(date, search);
            }}
            dateRange={dateRange}
          />
        </Styled.TabTitleWrapper>

        {loading && (
          <Page>
            <Loader />
          </Page>
        )}

        {Visits &&
          Visits.length !== 0 &&
          !loading &&
          _.orderBy(Visits, ['date'], ['desc']).map((obj, index) => {
            return (
              <div key={index}>
                <Styled.Visit>
                  <Styled.VisitTitleDateTime>
                    <a
                      onClick={() => {
                        history.push('/account/visit-details', { visit: obj });
                      }}
                    >
                      {obj.visitTitle}
                    </a>
                    <Moment
                      onClick={() => {
                        history.push('/account/visit-details', {
                          visit: obj,
                        });
                      }}
                      utc={true}
                      className="time"
                      format="YYYY-MM-DD HH:mm"
                    >
                      {obj.date}
                    </Moment>
                  </Styled.VisitTitleDateTime>
                  <div>
                    <span>
                      {obj.department}
                      {obj.departmentAddress && ', ' + obj.departmentAddress}
                    </span>
                    <span>{obj.paymentStatus}</span>
                  </div>
                </Styled.Visit>
              </div>
            );
          })}
        {Visits && Visits.length === 0 && !loading && (
          <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
        )}
      </Styled.DataContainer>
    </Page>
  );
};
export default FailedRegistrations;
