import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const TimeSlotSession = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 8;
  user-select: none;
  text-align: right;
  margin: 0 0 -40px 0;

  ${mediaDown('md')`
    margin: 0;
  `}
`;

const blockMessageCss = css`
  border-radius: 8px;
  font-size: 16px;
  line-height: 20px;
  display: block;

  ${mediaDown('md')`
    width: 100%;
    margin: 0;
  `}
`;

const Message = styled.div`
  border-radius: 0 0 8px 8px;
  background-color: #fff8cb;
  padding: 12px 14px;
  font-size: 13px;
  color: #000;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  margin-right: 0;
  margin-left: auto;

  ${mediaDown('md')`
    width: calc(100% + 40px);
    margin: 0 -20px;
  `}

  ${(props) => props.block && blockMessageCss}

  strong {
    font-weight: 800;
  }
`;

const Styled = {
  TimeSlotSession,
  Message,
};

export default Styled;
