import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';

const getAllowed = () => {
  const ls = localStorage.getItem('cookies');
  return ls ? ls.split(',') : [];
};

if (getAllowed().includes('analysis')) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID_2);
}

const GoogleAnalyticsTag = ({ children }) => {
  useEffect(() => {
    if (getAllowed().includes('analysis')) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });

  return <>{children}</>;
};
export default withRouter(GoogleAnalyticsTag);
