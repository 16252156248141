import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import Form from '../../../../../shared/Form';
import { useTranslation } from 'react-i18next';
import Page from '../../../../../shared/Page';
import Styled from './AuthorizedPersons.styles';
import { useHistory } from 'react-router-dom';
import TextField from '../../../../../shared/TextField';
import Button from '../../../../../shared/Button';
import * as Yup from 'yup';

const AuthorizedPersons = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [AuthorizedPersons, setAuthorizedPersons] = useState(
    history.location.state?.data.value,
  );

  const initialValues = {
    authorizedPersons: AuthorizedPersons,
  };

  const validationSchema = Yup.object().shape({
    authorizedPersons: Yup.lazy((value) => {
      switch (typeof value) {
        case 'array':
          return Yup.array();

        case 'string':
          return Yup.string()
            .required(t('validation.requiredField'))
            .test(
              'authorisedPerson',
              'Invalid format', // kazkoki custom texta reiktu kai netinkamas formatas
              function (value) {
                const textExpr = /^[A-Ząčęėįšųū, ]*$/i;
                let isValid = textExpr.test(value);
                if (!isValid) {
                  return false;
                }
                return true;
              },
            );
        default:
          return Yup.mixed();
      }
    }),
  });

  const handleAuthorizedPersons = async (
    data,
    { setSubmitting, setErrors },
  ) => {
    history.push('/account/authorized-persons-confirmation', {
      data: {
        value: data,
        id: history.location.state.data.id,
        consentId: history.location.state.data.consentId,
      },
    });
    setSubmitting(false);
  };

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.BackArrow>
          <RightArrow onClick={() => history.goBack()}></RightArrow>
          <span>
            {t('userAccount.authorizedPersonsEdit.authorizedPersonsTitle')}
          </span>
        </Styled.BackArrow>
        <Styled.EditForm>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleAuthorizedPersons}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Form.Row>
                      <Field
                        name="authorizedPersons"
                        label={t(
                          'userAccount.authorizedPersonsEdit.authorizedPersons',
                        )}
                        component={TextField}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>
                <Styled.ButtonContainer>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="large"
                    variant={'secondary'}
                    onClick={() => history.push('/account/manage-agreements')}
                  >
                    {t('userAccount.authorizedPersonsEdit.back')}
                  </Button>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    onClick={() => handleAuthorizedPersons}
                  >
                    {t('userAccount.authorizedPersonsEdit.confirmData')}
                  </Button>
                </Styled.ButtonContainer>
              </FormikForm>
            )}
          </Formik>
        </Styled.EditForm>
      </Styled.DataContainer>
    </Page>
  );
};

export default AuthorizedPersons;
