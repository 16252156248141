import React from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTransferState } from '../../context/TransferContext';
import Container from '../../shared/Container';
import Page from '../../shared/Page';
import Alert from '../../shared/Alert';
import './TransferSuccessPage.scss';

const TransferSuccessPage = () => {
  const { t } = useTranslation();
  const transferState = useTransferState();

  if (Object.keys(transferState).length === 0) {
    return <Redirect to="/transfer-request" />;
  }

  return (
    <Page>
      <Container className="transfer-success-page" narrow={true}>
        {transferState.formType === 1 ? 
        (<h1>{t('transfer.title')}</h1>)
        :
        (<h1>{t('transfer.secondaryTitle')}</h1>)
        }        
        <Alert type="success">{t('transfer.success')}
        <p style={{marginTop:'60px'}}>{t('transfer.additionalInfo')}</p>
        </Alert>
        
      </Container>
    </Page>
  );
};

export default TransferSuccessPage;
