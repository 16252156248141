import React from 'react';
import Styled from './Form.styled';
import { Trans, useTranslation } from 'react-i18next';
const Form = ({ children, indent, mw, noBorderRadiusTop }) => {
  return (
    <Styled.Form mw indent={indent} noBorderRadiusTop={noBorderRadiusTop}>
      {children}
    </Styled.Form>
  );
};

const Item = ({ children }) => {
  return <div>{children}</div>;
};

const Title = ({ children, error }) => {
  return (
    <Styled.Title>
      {children}
      {error && (
        <Styled.Error standAlone>
          <div>{error}</div>
        </Styled.Error>
      )}
    </Styled.Title>
  );
};

const Row = ({ children, positionRelative, marginTop }) => {
  return (
    <Styled.Row positionRelative={positionRelative} marginTop={marginTop}>
      {children}
    </Styled.Row>
  );
};

const Error = ({ children, standAlone, styleFormat }) => {
  const { t } = useTranslation();

  if (children) {
    if (
      children === t('coupons.couponNotFound') ||
      children === t('coupons.couponExpired') ||
      children === t('coupons.couponEmpty') ||
      children === t('patientInfo.specialistEmpty') ||
      children === t('patientInfo.healthCenterSpecialistEmpty')
    ) {
      return (
        <Styled.Error standAlone={standAlone} positionAbs={true}>
          <div style={styleFormat}>{children}</div>        
        </Styled.Error>
      );
    }
    return (
      <Styled.Error standAlone={standAlone}>
        <div style={styleFormat}>{children}</div>
      </Styled.Error>
    );
  }

  return null;
};

const Label = ({ children, styleFormat, greyTheme }) => {
  return <Styled.Label style={styleFormat} greyTheme={greyTheme}>{children}</Styled.Label>;
};

const Actions = ({ children }) => {
  return <Styled.Actions>{children}</Styled.Actions>;
};

const Group = ({ children, title, error }) => {
  return (
    <Styled.Group>
      {!!title && <Title error={error}>{title}</Title>}
      {children}
    </Styled.Group>
  );
};

Form.Item = Item;
Form.Title = Title;
Form.Row = Row;
Form.Error = Error;

Form.Label = Label;
Form.Actions = Actions;
Form.Group = Group;

export default Form;
