import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSettingsState } from '../../../context/SettingsContext';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg';
import Loader from '../../../shared/Loader';
import Styled from './Categories.styles';
import Container from '../../../shared/Container/Container';

const Categories = forwardRef(({ list = {}, loading }, ref) => {
  const { theme } = useSettingsState();
  const myRefs = useRef([]);
  const [height, setHeight] = useState(null);
  let isMobile = true;
  const [width, setWidth] = useState(window.innerWidth); // check width size of the window
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    isMobile = window.innerWidth < 700 ? true : false;
    let maxHeight = 0;
    myRefs.current.forEach((ref) => {
      if (ref.clientHeight > maxHeight) {
        maxHeight = ref.clientHeight;
      }
    });
    setHeight(maxHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  if (loading) {
    return (
      <Styled.LoaderBox>
        <Loader />
      </Styled.LoaderBox>
    );
  }

  function moveObjectToEndByExternalId(arr, targetExternalId) {
    const targetIndex = arr.findIndex(
      (item) => item.externalId == targetExternalId,
    );

    if (targetIndex !== -1) {
      const targetObject = arr.splice(targetIndex, 1)[0]; // Remove the object
      arr.push(targetObject); // Push it to the end
    }
  }

  return (
    <Container wide={true}>
      <Styled.Categories ref={ref}>
        {/* <Container>
        <Styled.Title>{t('landing.categories.title')}</Styled.Title>
      </Container> */}

        {Object.keys(list).map((key, i) => {
          let category = list[key];
          const categoryId = category[0]?.categoryId;
          moveObjectToEndByExternalId(
            category,
            process.env.REACT_APP_OTHER_SERVICE_SERVICE_TYPE_ID,
          );
          // if(categoryId !== 1){
          //   category = category.sort((a, b) => (a.serviceTypeTitle < b.serviceTypeTitle ? -1 : 1));
          // }

          // if(categoryId === 3){
          //   const element = category.find(el=>el.serviceTypeTitle === "Kiti tyrimai" )
          // }

          return (
            <Styled.Item key={key}>
              <Styled.ItemTitle
                ref={(el) => (myRefs.current[i] = el)}
                height={height}
                color={theme.brandColor}
              >
                {key}
              </Styled.ItemTitle>

              <Styled.Content>
                <ul>
                  {category.map((service, idx) => {
                    // eslint-disable-next-line no-lone-blocks
                    {
                      return service.serviceTypeId === 7481994 ? (
                        <li
                          key={service.serviceTypeId}
                          style={{ color: '#52585F' }}
                        >
                          <Link
                            to={`/dovanu-kuponai`}
                            style={{ color: '#52585F' }}
                          >
                            {service.serviceTypeTitle}
                          </Link>

                          <ArrowDownIcon />
                        </li>
                      ) : service.serviceTypeId === 7481995 ? (
                        <li
                          key={service.serviceTypeId}
                          style={{ color: '#52585F' }}
                        >
                          <Link
                            to={`/kompleksines-paslaugos`}
                            style={{ color: '#52585F' }}
                          >
                            {service.serviceTypeTitle}
                          </Link>

                          <ArrowDownIcon />
                        </li>
                      ) : (
                        <li
                          key={service.serviceTypeId}
                          style={{ color: '#52585F' }}
                        >
                          <Link
                            to={`/services/${service.categoryId}/${service.serviceTypeId}`}
                            style={{ color: '#52585F' }}
                          >
                            {service.serviceTypeTitle.substring(
                              0,
                              service.serviceTypeTitle.lastIndexOf(' '),
                            )}{' '}
                            {
                              <span
                                style={{
                                  textDecoration: 'inherit',
                                  display: 'inline-block',
                                  color: '#52585F',
                                }}
                              >
                                {service.serviceTypeTitle.split(' ').pop()}
                                <ArrowDownIcon
                                  style={{
                                    position: 'relative',
                                    fill: '#52585F',
                                  }}
                                />
                              </span>
                            }
                          </Link>
                        </li>
                      );
                    }
                  })}
                </ul>
              </Styled.Content>

              <Styled.ItemImage
                style={{
                  backgroundImage: `url(${theme.categories[categoryId]})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                }}
              ></Styled.ItemImage>
            </Styled.Item>
          );
        })}
      </Styled.Categories>
    </Container>
  );
});

export default Categories;
