import React, { useState, useRef, useEffect } from 'react';
import Page from '../../../../../shared/Page';
import Styled from '../../UserAccountPage.styles';
import { useTranslation } from 'react-i18next';
import {
  Formik,
  Form as FormikForm,
  Field,
  yupToFormErrors,
  validateYupSchema,
} from 'formik';
import { FieldArray } from 'formik';
import Form from '../../../../../shared/Form';
import TextField from '../../../../../shared/TextField';
import SelectField from '../../../../../shared/SelectField';
import Button from '../../../../../shared/Button';
import * as Yup from 'yup';
import useFetch from 'use-http';

const PatientInfo = () => {
  const { t } = useTranslation();
  const [specialistList, setSpecialistList] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSpecialistIndex, setSelectedSpecialistIndex] = useState(null);
  const [centersList, setCentersList] = useState(null);
  const [triggerSpecialistUpdate, setTriggerSpecialistUpdate] = useState({
    index: 0,
    change: false,
  });
  const [addSpecialistError, setAddSpecialistError] = useState();
  const [addCenterSpecialistError, setAddCenterSpecialistError] = useState();
  const [triggerCenterSpecialistUpdate, setTriggerCenterSpecialistUpdate] =
    useState({ index: 0, change: false });
  const [selectedCenterSpecialist, setSelectedCenterSpecialist] =
    useState(null);
  const [toggleSaveButton, setToggleSaveButton] = useState(false);
  const [centerSpecialistList, setCenterSpecialistList] = useState(null);
  const [addSpecialistFieldVisible, setAddSpecialistFieldVisible] =
    useState(false);
  const [addCenterSpecialistFieldVisible, setAddCenterSpecialistFieldVisible] =
    useState(false);
  const [selectedCenter, setSelectedCenter] = useState(null);
  // const [tInitialValues, setTInitialValues] = useState();

  const inputRef = useRef(null);

  const tInitialValues = {
    companyId: '',
    centerId: '',
    specialistList: [],
    centerSpecialistList: [],
    addSpecialist: '',
    addCenterSpecialist: '',
  };

  const validationSchema = Yup.object({
    specialistList: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Privalomas laukas').trim(),
      }),
    ),
    centerSpecialistList: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Privalomas laukas').trim(),
      }),
    ),
  });

  const formRefSpecialists = useRef();
  const centersSelectRef = useRef();
  const initialLoad = useRef(true);
  const initialLoadCenterSpecialist = useRef(true);

  const { request: companiesRequest } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/companies`,
  );

  const { request: companySpecialistsRequest } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/companies/specialists`,
  );

  const { request: healthCareSpecialists } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/companies/healtCare/specialists`,
  );

  const { request: addSpecialistRequest } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/specialists/AddSpecialist`,
  );

  const {
    request: removeSpecialistRequest,
    response: removeSpecialistResponse,
  } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/specialists/RemoveSpecialist`,
  );

  const { request: updateSpecialistRequest } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/specialists/UpdateSpecialist`,
  );

  const { request: addCenterSpecialistRequest } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/healthCenterSpecialists/AddHealthCenterSpecialist`,
  );

  const {
    request: removeCenterSpecialistRequest,
    response: removeCenterSpecialistResponse,
  } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/healthCenterSpecialists/RemoveHealthCenterSpecialist`,
  );

  const { request: updateCenterSpecialistRequest } = useFetch(
    `${process.env.REACT_APP_SIGN_API_BASE}/healthCenterSpecialists/UpdateHealthCenterSpecialist`,
  );

  const [focus, setFocus] = useState(0);

  useEffect(() => {
    if (inputRef) {
      inputRef.current?.children[0]?.lastChild?.focus();
    }
  }, [focus]);

  useEffect(() => {
    let isMounted = true;
    companiesRequest
      .get()
      .then((companies) => {
        if (isMounted) {
          let comp = companies;
          let list = [];
          comp.forEach((item) => {
            let healthCenters = [];
            let items = [];
            item.healthCenters.forEach((healthCenter) => {
              let healthCenterSpecialists = [];
              healthCenter.healthCenterSpecialists.forEach((hcs) => {
                healthCenterSpecialists.push({
                  value: hcs.healthCenterSpecialistId,
                  label: hcs.name,
                  disabled: true,
                  tsb: false,
                });
              });
              healthCenters.push({
                value: healthCenter.healthCenterId,
                label: healthCenter.title,
                healthCenterSpecialists: healthCenterSpecialists,
              });
            });
            item.items.forEach((specialist) => {
              items.push({
                value: specialist.id,
                label: specialist.title,
                disabled: true,
                tsb: false,
              });
            });
            list.push({
              value: item.id,
              label: item.title,
              healthCenters: healthCenters,
              items: items,
            });
          });
          setCompanyList(list);
        }
      })
      .catch(() => {});
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
      return;
    }

    handleUpdateSpecialist(
      formRefSpecialists.current.values.specialistList[
        triggerSpecialistUpdate.index
      ].label,
      formRefSpecialists.current.values.specialistList[
        triggerSpecialistUpdate.index
      ].value,
    );
  }, [triggerSpecialistUpdate]);

  useEffect(() => {
    if (initialLoadCenterSpecialist.current) {
      initialLoadCenterSpecialist.current = false;
      return;
    }

    handleUpdateCenterSpecialist(
      formRefSpecialists.current.values.centerSpecialistList[
        triggerCenterSpecialistUpdate.index
      ]?.label,
      formRefSpecialists.current.values.centerSpecialistList[
        triggerCenterSpecialistUpdate.index
      ]?.value,
    );
  }, [triggerCenterSpecialistUpdate]);

  const handleAddSpecialist = async (specialist) => {
    const startIndex = specialist.indexOf(' ');
    const specialistFirstName = specialist.slice(0, startIndex);
    const specialistLastName = specialist.slice(startIndex);

    const response = await addSpecialistRequest.post('', {
      specialistFirstName: specialistFirstName,
      specialistLastName: specialistLastName,
      companyId: selectedCompany,
    });
    return response;
  };

  const handleAddCenterSpecialist = async (centerSpecialist) => {
    const response = await addCenterSpecialistRequest.post('', {
      healthCenterSpecialistFullName: centerSpecialist,
      centerId: selectedCenter,
    });
    return response;
  };

  const handleUpdateSpecialist = async (specialist, specialistValue) => {
    const startIndex = specialist.indexOf(' ');
    const specialistFirstName = specialist.slice(0, startIndex);
    const specialistLastName = specialist.slice(startIndex);

    const response = await updateSpecialistRequest.post('', {
      specialistFirstName: specialistFirstName,
      specialistLastName: specialistLastName,
      specialistId: specialistValue,
    });
    return response;
  };

  const handleUpdateCenterSpecialist = async (
    centerSpecialist,
    centerSpecialistValue,
  ) => {
    await updateCenterSpecialistRequest.post('', {
      healthCenterSpecialistFullName: centerSpecialist,
      healthCenterSpecialistId: centerSpecialistValue,
    });
  };

  const handleDeleteSpecialist = async (specialist) => {
    await removeSpecialistRequest.post('', {
      specialistId: specialist.value,
    });

    if (removeSpecialistResponse.ok) {
      return true;
    }
    return false;
  };

  const handleDeleteCenterSpecialist = async (specialist) => {
    await removeCenterSpecialistRequest.post('', {
      healthCenterSpecialistId: specialist.value,
    });

    if (removeCenterSpecialistResponse.ok) {
      return true;
    }
    return false;
  };

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Tapk pacientu informacija</Styled.TabTitle>
        </Styled.TabTitleWrapper>
        <Formik
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={tInitialValues}
          validationSchema={validationSchema}
          // enableReinitialize
          innerRef={formRefSpecialists}
          onSubmit={() => {}}
        >
          {({
            values,
            handleChange,
            setFieldError,
            setFieldValue,
            errors,
            handleSubmit,
          }) => (
            <FormikForm>
              <Styled.FormContainer>
                <Form>
                  <Form.Group>
                    <Form.Row>
                      <SelectField
                        error={errors.companyId}
                        name="companyId"
                        label={'Pasirinkite PASPĮ kliniką'}
                        options={companyList}
                        greyTheme={true}
                        onChange={async (value) => {
                          const items = [];
                          const specialists =
                            await companySpecialistsRequest.get(
                              `?companyId=${value}`,
                            );
                          specialists.forEach((spec) => {
                            items.push({
                              value: spec.id,
                              label: spec.title,
                              disabled: true,
                              tsb: false,
                            });
                          });

                          setSpecialistList(items);

                          formRefSpecialists.current.values.specialistList =
                            items;

                          setSelectedCompany(value);

                          centersSelectRef.current.select.setValue(null);

                          setFieldValue('centerId', undefined);

                          let company = companyList.find(
                            (item) => item.value === value,
                          );
                          setCentersList(
                            company.healthCenters?.length !== 0 &&
                              company.healthCenters,
                          );
                          formRefSpecialists.current.values.centerSpecialistList =
                            [];
                          setSelectedCenterSpecialist(null);
                          setCenterSpecialistList(null);
                          setSelectedCenter(null);
                        }}
                      />
                    </Form.Row>
                    <FieldArray
                      name="specialistList"
                      render={(arrayHelpers) => (
                        <div>
                          {values.specialistList.map((specialist, index) => {
                            return (
                              <Form.Row key={index}>
                                <Styled.SpecialistFieldsContainer
                                  fourthDistribution={true}
                                >
                                  <Styled.SpecialistParagraphContainer
                                    ref={inputRef}
                                  >
                                    <Field
                                      type={'text'}
                                      component={TextField}
                                      isGrey={true}
                                      disabled={specialist.disabled}
                                      label={t('patientInfo.specialistLabel')}
                                      name={`specialistList.${index}.label`}
                                      value={
                                        values.specialistList[index].label || ''
                                      }
                                      onChange={() => {
                                        handleChange();
                                      }}
                                    />
                                  </Styled.SpecialistParagraphContainer>
                                  <Styled.SpecialistButtonContainer>
                                    <Button
                                      size="large"
                                      type="button"
                                      style={{
                                        backgroundColor: '#fff',
                                        color: '#52585F',
                                      }}
                                      onClick={async () => {
                                        let response =
                                          await handleDeleteSpecialist(
                                            specialist,
                                          );
                                        if (response)
                                          arrayHelpers.remove(index);
                                      }}
                                    >
                                      Pašalinti
                                    </Button>
                                  </Styled.SpecialistButtonContainer>
                                  <Styled.SpecialistButtonContainer>
                                    <Button
                                      size="large"
                                      type="secondary"
                                      style={{
                                        backgroundColor: '#fff',
                                        color: '#52585F',
                                      }}
                                      onClick={() => {
                                        if (
                                          formRefSpecialists.current.isValid
                                        ) {
                                          values.specialistList[
                                            index
                                          ].disabled =
                                            !values.specialistList[index]
                                              .disabled;

                                          values.specialistList[index].tsb =
                                            !values.specialistList[index].tsb;
                                          setFocus((p) => p + 1);
                                          // if (inputRef) {
                                          //   inputRef.current.children[0].lastChild.focus();
                                          //   console.log(
                                          //     inputRef.current.children[0]
                                          //       .lastChild,
                                          //   );
                                          // }

                                          if (
                                            !values.specialistList[index].tsb
                                          ) {
                                            setTriggerSpecialistUpdate(
                                              (prev) => ({
                                                ...prev,
                                                index: index,
                                                change: !prev.change,
                                              }),
                                            );
                                          }
                                        }
                                      }}
                                    >
                                      {values.specialistList[index].tsb
                                        ? 'Išsaugoti'
                                        : 'Redaguoti'}
                                    </Button>
                                  </Styled.SpecialistButtonContainer>
                                </Styled.SpecialistFieldsContainer>
                              </Form.Row>
                            );
                          })}
                          {addSpecialistFieldVisible && (
                            <Styled.SecondaryRowContainer>
                              <Styled.RowContainer>
                                <Form.Row>
                                  <Styled.SpecialistFieldsContainer
                                    fourthDistribution={true}
                                  >
                                    <Styled.SpecialistParagraphContainer>
                                      <Field
                                        focus={true}
                                        type={'text'}
                                        error={addSpecialistError}
                                        component={TextField}
                                        label={t('patientInfo.specialistLabel')}
                                        name={`addSpecialist`}
                                        onChange={handleChange}
                                      />
                                    </Styled.SpecialistParagraphContainer>
                                    <Styled.SpecialistButtonContainer>
                                      <Button
                                        size="large"
                                        type="submit"
                                        onClick={async (value) => {
                                          if (
                                            values.addSpecialist.length === 0
                                          ) {
                                            setAddSpecialistError(
                                              'Privalomas laukas',
                                            );
                                          } else if (
                                            values.addSpecialist.length > 0
                                          ) {
                                            setAddSpecialistError('');
                                            const newValue =
                                              await handleAddSpecialist(
                                                values.addSpecialist,
                                              );

                                            arrayHelpers.insert(
                                              values.specialistList.length + 1,
                                              {
                                                value: newValue,
                                                label: values.addSpecialist,
                                                disabled: true,
                                                tsb: false,
                                              },
                                            );
                                            setFieldValue(
                                              'addSpecialist',
                                              null,
                                            );
                                            setAddSpecialistFieldVisible(false);
                                          }
                                        }}
                                      >
                                        Išsaugoti
                                      </Button>
                                    </Styled.SpecialistButtonContainer>
                                    <Styled.SpecialistButtonContainer>
                                      <Button
                                        size="large"
                                        onClick={() => {
                                          setAddSpecialistFieldVisible(false);
                                          setFieldValue('addSpecialist', null);
                                        }}
                                      >
                                        Atšaukti
                                      </Button>
                                    </Styled.SpecialistButtonContainer>
                                  </Styled.SpecialistFieldsContainer>
                                </Form.Row>
                              </Styled.RowContainer>
                            </Styled.SecondaryRowContainer>
                          )}
                          {selectedCompany !== null && (
                            <Styled.RowContainer>
                              <Form.Row>
                                <Button
                                  size="large"
                                  type="secondary"
                                  onClick={() => {
                                    setAddSpecialistFieldVisible(true);
                                  }}
                                >
                                  Pridėti dar vieną gydytoją
                                </Button>
                              </Form.Row>
                            </Styled.RowContainer>
                          )}
                        </div>
                      )}
                    />
                    <Form.Row marginTop={true}>
                      <SelectField
                        innerRef={centersSelectRef}
                        disabled={!centersList}
                        defaultValue={selectedCenter}
                        error={errors.centerId}
                        label={t('transfer.fields.center')}
                        name="centerId"
                        options={centersList}
                        greyTheme={true}
                        onChange={async (value) => {
                          if (value) {
                            setFieldValue('centerId', value);
                            // let list = null;
                            // if (centersList[0].healthCenterSpecialists) {
                            //   list = centersList[0].healthCenterSpecialists;
                            // }

                            const items = [];

                            const centerSpecialists =
                              await healthCareSpecialists.get(
                                `?centerId=${value}`,
                              );
                            centerSpecialists.forEach((spec) => {
                              items.push({
                                value: spec.id,
                                label: spec.title,
                                disabled: true,
                                tsb: false,
                              });
                            });

                            formRefSpecialists.current.values.centerSpecialistList =
                              items;

                            setSelectedCenter(value);
                            setSelectedCenterSpecialist(items);
                          }
                        }}
                      />
                    </Form.Row>
                    <FieldArray
                      name="centerSpecialistList"
                      render={(arrayHelpers) => (
                        <div>
                          {values.centerSpecialistList.map(
                            (specialist, index) => {
                              return (
                                <Form.Row key={index}>
                                  <Styled.SpecialistFieldsContainer
                                    fourthDistribution={true}
                                  >
                                    <Styled.SpecialistParagraphContainer>
                                      <Field
                                        type={'text'}
                                        component={TextField}
                                        disabled={specialist.disabled}
                                        label={t(
                                          'patientInfo.healthCenterSpecialistLabel',
                                        )}
                                        name={`centerSpecialistList.${index}.label`}
                                        errors={`centerSpecialistList.${index}.label`}
                                        value={
                                          values.centerSpecialistList[index]
                                            .label || ''
                                        }
                                        onChange={() => {
                                          handleChange();
                                        }}
                                      />
                                    </Styled.SpecialistParagraphContainer>
                                    <Styled.SpecialistButtonContainer>
                                      <Button
                                        size="large"
                                        type="secondary"
                                        style={{
                                          backgroundColor: '#fff',
                                          color: '#000',
                                        }}
                                        onClick={async () => {
                                          let response =
                                            await handleDeleteCenterSpecialist(
                                              specialist,
                                            );
                                          if (response) {
                                            arrayHelpers.remove(index);
                                          }
                                        }}
                                      >
                                        Pašalinti
                                      </Button>
                                    </Styled.SpecialistButtonContainer>
                                    <Styled.SpecialistButtonContainer>
                                      <Button
                                        size="large"
                                        type="secondary"
                                        style={{
                                          backgroundColor: '#fff',
                                          color: '#000',
                                        }}
                                        onClick={() => {
                                          if (
                                            formRefSpecialists.current.isValid
                                          ) {
                                            values.centerSpecialistList[
                                              index
                                            ].disabled =
                                              !values.centerSpecialistList[
                                                index
                                              ].disabled;

                                            values.centerSpecialistList[
                                              index
                                            ].tsb =
                                              !values.centerSpecialistList[
                                                index
                                              ].tsb;

                                            if (
                                              !values.centerSpecialistList[
                                                index
                                              ].tsb
                                            ) {
                                              setTriggerCenterSpecialistUpdate(
                                                (prev) => ({
                                                  ...prev,
                                                  index: index,
                                                  change: !prev.change,
                                                }),
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        {values.centerSpecialistList[index].tsb
                                          ? 'Išsaugoti'
                                          : 'Redaguoti'}
                                      </Button>
                                    </Styled.SpecialistButtonContainer>
                                  </Styled.SpecialistFieldsContainer>
                                </Form.Row>
                              );
                            },
                          )}

                          {addCenterSpecialistFieldVisible && (
                            <Styled.SecondaryRowContainer>
                              <Styled.RowContainer>
                                <Form.Row>
                                  <Styled.SpecialistFieldsContainer
                                    fourthDistribution={true}
                                  >
                                    <Field
                                      focus={true}
                                      type={'text'}
                                      component={TextField}
                                      label={t('patientInfo.specialistLabel')}
                                      name={`addCenterSpecialist`}
                                      error={addCenterSpecialistError}
                                      onChange={handleChange}
                                      isGrey={true}
                                    />
                                    <Styled.SpecialistButtonContainer>
                                      <Button
                                        size="large"
                                        onClick={async (value) => {
                                          if (
                                            values.addCenterSpecialist
                                              .length === 0
                                          ) {
                                            setAddCenterSpecialistError(
                                              'Privalomas laukas',
                                            );
                                          } else if (
                                            values.addCenterSpecialist.length >
                                            0
                                          ) {
                                            setAddCenterSpecialistError('');
                                            const newValue =
                                              await handleAddCenterSpecialist(
                                                values.addCenterSpecialist,
                                              );
                                            arrayHelpers.insert(
                                              values.centerSpecialistList
                                                .length + 1,
                                              {
                                                value: newValue,
                                                label:
                                                  values.addCenterSpecialist,
                                                disabled: true,
                                                tsb: false,
                                              },
                                            );
                                            setAddCenterSpecialistFieldVisible(
                                              false,
                                            );
                                            setFieldValue(
                                              'addCenterSpecialist',
                                              null,
                                            );
                                          }
                                        }}
                                      >
                                        Išsaugoti
                                      </Button>
                                    </Styled.SpecialistButtonContainer>
                                    <Styled.SpecialistButtonContainer>
                                      <Button
                                        size="large"
                                        onClick={() => {
                                          setAddCenterSpecialistFieldVisible(
                                            false,
                                          );
                                          setFieldValue(
                                            'addCenterSpecialist',
                                            null,
                                          );
                                        }}
                                      >
                                        Atšaukti
                                      </Button>
                                    </Styled.SpecialistButtonContainer>
                                  </Styled.SpecialistFieldsContainer>
                                </Form.Row>
                              </Styled.RowContainer>
                            </Styled.SecondaryRowContainer>
                          )}

                          {selectedCenter !== null && (
                            <Styled.RowContainer>
                              <Form.Row>
                                <Button
                                  size="large"
                                  type="secondary"
                                  onClick={() => {
                                    setAddCenterSpecialistFieldVisible(true);
                                  }}
                                >
                                  Pridėti dar vieną gydytoją
                                </Button>
                              </Form.Row>
                            </Styled.RowContainer>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Form>
              </Styled.FormContainer>
              <div className="mt-4 mt-md-2 d-flex align-center justify-center"></div>
            </FormikForm>
          )}
        </Formik>
      </Styled.DataContainer>
    </Page>
  );
};

export default PatientInfo;
