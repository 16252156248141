/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../../../../../shared/DateRangePicker/DateRangePicker';
import Loader from '../../../../../shared/Loader';
import Page from '../../../../../shared/Page';
import { useHistory } from 'react-router-dom';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';
import { getQueryString } from '../../../../../constants/visitsQueryStringBuilder';
import Button from '../../../../../shared/Button/Button';
const OtherVisits = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [OtherVisits, setOtherVisits] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState();
  const sessionDispatch = useUserDispatch();
  const userState = useUserState();
  const { get: getVisits, response: getVisitsResponse } = useFetch(
    '/UserAccount/Visits',
  );
  const [dateRange, setDateRange] = useState(userState.dateFilter);
  const status = `other`;

  const applyChanges = async (date, search) => {
    setLoading(true);
    sessionDispatch({
      type: 'UPDATE',
      data: {
        dateFilter: date,
      },
    });
    const response = await getVisits(
      getQueryString(date, search, status, undefined, userState.espbiEsi),
    );
    if (getVisitsResponse.status >= 400) {
    } else {
      setOtherVisits(response);
    }
    setLoading(false);
  };

  useEffect(() => {
    applyChanges(dateRange, search);
  }, [dateRange]);

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Kitos paslaugos</Styled.TabTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
              applyChanges(dateRange, e.target.search.value);
            }}
            onChange={(e) => {
              if (
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === ''
              ) {
                setSearch(e.target.value);
                applyChanges(dateRange, e.target.value);
              }
            }}
            style={{ display: 'flex' }}
          >
            <input
              type="text"
              id="search"
              placeholder="Paieška"
              name="search"
            />
            <Button
              style={{ marginRight: '15px', fontSize: '16px' }}
              size="small"
              type="submit"
            >
              {' '}
              Ieškoti
            </Button>
          </form>
          <DateRangePicker
            dateChanged={(date) => {
              setDateRange(date);
              applyChanges(date, search);
            }}
            dateRange={dateRange}
          />
        </Styled.TabTitleWrapper>
        {loading && (
          <Page>
            <Loader />
          </Page>
        )}
        {!loading &&
          OtherVisits &&
          OtherVisits.length !== 0 &&
          _.orderBy(OtherVisits, ['date'], ['desc']).map((obj, idx) => {
            return (
              <div key={idx}>
                <Styled.Visit key={idx}>
                  <Styled.VisitTitleDateTime>
                    <a
                      onClick={() => {
                        history.push('/account/visit-details', {
                          visit: obj,
                          fromOtherVisits: true,
                        });
                      }}
                    >
                      {obj.registrationService
                        ? obj.registrationService
                        : obj.visitTitle}
                    </a>
                    {(new Date(Date.parse(obj.date)).getUTCHours() === 0 && (
                      <Moment
                        utc={true}
                        onClick={() => {
                          history.push('/account/visit-details', {
                            visit: obj,
                            fromOtherVisits: true,
                          });
                        }}
                        className="time"
                        format="YYYY-MM-DD"
                      >
                        {Date.parse(obj.date)}
                      </Moment>
                    )) || (
                      <Moment
                        utc={true}
                        onClick={() => {
                          history.push('/account/visit-details', {
                            visit: obj,
                            fromOtherVisits: true,
                          });
                        }}
                        className="time"
                        format="YYYY-MM-DD HH:mm"
                      >
                        {Date.parse(obj.date)}
                      </Moment>
                    )}
                  </Styled.VisitTitleDateTime>
                  <div>
                    <span>
                      {obj.department}
                      {obj.departmentAddress && ', ' + obj.departmentAddress}
                    </span>
                    <span>{obj.paymentStatus}</span>
                  </div>
                </Styled.Visit>
              </div>
            );
          })}
        {OtherVisits && OtherVisits.length !== 0 && !loading && (
          <div
            style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}
          >
            {t('userAccount.visitHistoryTab.dateInfo')}
          </div>
        )}
        {OtherVisits && OtherVisits.length === 0 && !loading && (
          <>
            <Styled.EmptyList> {t('validation.emptyList')} </Styled.EmptyList>
            <div
              style={{ padding: '1.2rem', paddingLeft: '0', fontSize: '14px' }}
            >
              {t('userAccount.visitHistoryTab.dateInfo')}
            </div>
          </>
        )}
      </Styled.DataContainer>
    </Page>
  );
};
export default OtherVisits;
