import React, { useEffect, useState, useRef } from 'react';
import Container from '../../../shared/Container';
import SelectField from '../../../shared/SelectField';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross.svg';
import Styled from './Filter.styles';
import { useTranslation } from 'react-i18next';
import Button from '../../../shared/Button';
import { ReactComponent as DropDownArrowIcon } from '../../../../assets/icons/user-settings-arrow-down.svg';

import { useSettingsState } from '../../../context/SettingsContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';



function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

const Filter = ({
  value,
  onChange,
  isLoading,
  filters,
  onClear,
  category,
  sortData,
  isGrid,
  enableSorting,
  disabledFiltersById,
  shouldFilterBeExpanded = false,
}) => {
  const location = useLocation();
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const { theme } = useSettingsState();
  const [openOrderingDown, setOpenOrderingDown] = useState(true);
  const { t } = useTranslation();
  const orderingIconRef = useRef();
  const orderingMenuRef = useRef();
  const [filterVisibility, setFilterVisibility] = useState(!!location?.search || shouldFilterBeExpanded);
  const firstUpdate = useRef(true);

  function getTypeOptions(type) {
    return filters[type].map((sc) => ({
      label: sc.name,
      value: sc.id + '',
    }));
  }
  const size = useWindowSize();
  useEffect(() => {
    if (userSettingsOpen && filterVisibility) {
      if (
        window.innerHeight -
        orderingIconRef?.current?.getBoundingClientRect()?.bottom -
        orderingMenuRef?.current?.getBoundingClientRect()?.height -
        10 <
        0
      ) {
        setOpenOrderingDown(false);
      } else {
        setOpenOrderingDown(true);
      }
    }
  }, [userSettingsOpen]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setUserSettingsOpen(true);
  }, [openOrderingDown]);

  useEffect(() => {
    if (!filterVisibility && userSettingsOpen) {
      setUserSettingsOpen(false);
    }
  }, [filterVisibility]);

  useEffect(() => {
    if (size.width > 767) {
      setFilterVisibility(true);
    }
  }, [size.width]);

  const filtersMap = Object.keys(filters).reduce((results, filter) => {
    const options = getTypeOptions(filter);

    if (options.length > 0) {
      results.push({
        label: t(`filters.${filter}.label`),
        options: [
          {
            value: '',
            label: t(`filters.${filter}.emptyValue`),
          },
          ...options,
        ],
        key: `${filter}Id`,
      });
    }

    return results;
  }, []);

  const closeMenu = () => {
    setOpen(false);
  };

  const userSettingsMenu = () => {
    setUserSettingsOpen(false);
  };

  return (
    <Styled.Filter greyTheme={true}>
      <Container>
        <Styled.Ordering isMobile={open} isMain={true}>
          <Button
            size="medium"
            style={{
              fontSize: '14px',
              padding: '0px',
              display: size.width < 767 ? 'inline-block' : 'none',
              marginBottom: '20px',
              color: '#52585F',
              height: '20px'
            }}
            onClick={() => {
              setFilterVisibility(!filterVisibility);
            }}
          >
            Filtravimas
            <Styled.OrderingIcon isMobile={open} greyTheme={true}>
              <DropDownArrowIcon
                className={filterVisibility ? 'upsideDown' : {}}
                style={{
                  display: size.width < 767 ? 'inline-block' : 'none',
                }}
              />
            </Styled.OrderingIcon>
          </Button>

          {filterVisibility && (
            <>
              <Styled.List isGrid={isGrid} greyTheme={true}>
                {filtersMap.map((filter, i) => (
                  <Styled.Item key={i} isGrid={isGrid} isLast={i === 3}>
                    <SelectField
                      greyTheme={true}
                      defaultMenuIsOpen={true}
                      label={filter.label}
                      disabled={
                        isLoading ||
                        disabledFiltersById?.some(
                          (disbledFilterId) => filter.key === disbledFilterId,
                        )
                      }
                      value={
                        filter.options.find(
                          (f) => f.value === value[filter.key],
                        ) || filter.options[0]
                      }
                      onChange={(value) => {
                        onChange({
                          value,
                          name: filter.key,
                        });
                      }}
                      options={filter.options}
                    />
                  </Styled.Item>
                ))}
              </Styled.List>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '-4px',
                  color: '#52585F',
                }}
              >
                <Styled.Actions>
                  <Styled.ButtonClear onClick={onClear} greyTheme={true}>
                    <CrossIcon />
                    {t('filters.clear')}
                  </Styled.ButtonClear>
                </Styled.Actions>
                {enableSorting && (
                  <Styled.Ordering
                    isMobile={open}
                    onClick={() => setUserSettingsOpen(!userSettingsOpen)}
                  >
                    <Button
                      size="medium"
                      style={{
                        fontSize: '14px',
                        padding: '0px',
                        color: '#52585F',
                      }}
                    >
                      Rikiuoti
                      <Styled.OrderingIcon
                        isMobile={open}
                        ref={orderingIconRef}
                        onClick={userSettingsMenu}
                        greyTheme={true}
                      >
                        <DropDownArrowIcon
                          className={userSettingsOpen ? 'upsideDown' : {}}
                        />
                      </Styled.OrderingIcon>
                    </Button>

                    {userSettingsOpen && (
                      <Styled.OrderingMenu
                        openDown={openOrderingDown}
                        ref={orderingMenuRef}
                      >
                        <Styled.Option hoverColor={theme.primaryColor}>
                          <p
                            onClick={() => {
                              sortData('Default');
                              closeMenu();
                            }}
                            style={{ color: '#52585F' }}
                          >
                            Numatytasis
                          </p>
                        </Styled.Option>
                        <Styled.Option hoverColor={theme.primaryColor}>
                          <p
                            onClick={() => {
                              sortData('Name');
                              closeMenu();
                            }}
                            style={{ color: '#52585F' }}
                          >
                            Vardas ir pavardė
                          </p>
                        </Styled.Option>
                        <Styled.Option hoverColor={theme.primaryColor}>
                          <p
                            onClick={() => {
                              sortData('Service');
                              closeMenu();
                            }}
                            style={{ color: '#52585F' }}
                          >
                            Specialybė ar paslauga
                          </p>
                        </Styled.Option>
                        <Styled.Option hoverColor={theme.primaryColor}>
                          <p
                            onClick={() => {
                              sortData('Nearest visit');
                              closeMenu();
                            }}
                            style={{ color: '#52585F' }}
                          >
                            Anksčiausio vizito data
                          </p>
                        </Styled.Option>
                      </Styled.OrderingMenu>
                    )}
                    {userSettingsOpen && (
                      <Styled.Backdrop
                        onClick={() => setUserSettingsOpen(false)}
                      ></Styled.Backdrop>
                    )}
                  </Styled.Ordering>
                )}
              </div>
            </>
          )}
        </Styled.Ordering>
      </Container>
    </Styled.Filter>
  );
};

export default Filter;
