import { format as dateFnsFormat, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { useSettingsState } from '../../../context/SettingsContext';
import { useUserState, u } from '../../../context/UserContext';
import { formatCurrency, Money } from '../../../shared/Money';
import Styled from './Summary.styles';
import { useHistory } from 'react-router-dom';
import MultipleLaboratoryServicesSummary from '../../../shared/MultipleLaboratoryServicesSummary';
import { useTranslation } from 'react-i18next';

const Summary = ({
  items,
  onRemove,
  couponSum,
  totalPrice,
  totalInitialPrice,
  isMarginBottom,
  onDataFromChild,
}) => {
  const { t } = useTranslation();
  const [initialPrice, setInitialPrice] = useState();
  const [isDiscountCodeFound, setIsDiscountCodeFound] = useState(false);
  const [selectedTimeslot, setSelectedTimeslot] = useState([]);
  const [isLaboratoryServices, setIsLaboratoryServices] = useState(false);
  const history = useHistory();
  const userState = useUserState();
  const isDiscount = userState.discountCard;
  const { getServiceImage } = useSettingsState();
  const [correctChild, setCorrectChild] = useState(true);

  const externalServiceTypeIdForGroupedService =
    process.env.REACT_APP_LABEXTERNALID;

  const gastroenterologyServiceTypeExternalId =
    process.env.REACT_APP_GASTROENTEROLOGY_SERVICE_TYPE_EXTERNAL_ID;

  function getAdditionalPrice() {
    let sum = 0;

    items[0].additionalServices.forEach((as) => {
      sum += as.price;
    });
    return sum;
  }

  function getAdditionalPriceWithDiscount() {
    let sum = 0;

    items[0].additionalServices.forEach((as) => {
      sum +=
        !!as.priceWithDiscountCode && as.priceWithDiscountCode < as.price
          ? as.priceWithDiscountCode
          : as.price;
    });
    return sum;
  }

  const getInitialSum = (correctChild) => {
    return items.reduce((a, b) => {
      return a + determinePrice(b, correctChild);
    }, 0);
  };

  const getInitialSumWithDiscountCode = (correctChild) => {
    return items.reduce((a, b) => {
      let selectedPrice = determinePrice(b, correctChild);
      if (
        b.isDiscountFound &&
        b.priceWithDiscountCode &&
        b.priceWithDiscountCode < selectedPrice
      ) {
        return a + b.priceWithDiscountCode;
      } else {
        return a + selectedPrice;
      }
    }, 0);
  };

  const determinePrice = (item, correctChild) => {
    if (item.orderData.timeSlot.isPaid && !item.orderData.timeSlot.isExtra) {
      if (item.orderData.primaryVisit) {
        return !correctChild
          ? item.priceWithDiscountWithoutCard
          : item.priceWithDiscount;
      } else {
        return item.price
          ? !correctChild
            ? item.priceWithDiscountWithoutCardSecondary
            : item.secondaryDiscountEur
          : item.secondaryPrice;
      }
    } else if (item.orderData.timeSlot.isExtra) {
      return !correctChild
        ? item.priceWithDiscountWithoutCardExtra
        : item.extraPrice;
    } else {
      return 0;
    }
  };

  const getTotalInitialPrice = (correctChild) => {
    let initialSum = getInitialSum(correctChild);
    if (!items[0].orderData.timeSlot.isPaid) {
      initialSum = getAdditionalPrice();
    }
    setInitialPrice(initialSum);
    return initialSum;
  };

  const getDiscountCodePrice = (correctChild) => {
    if (!items[0].orderData.timeSlot.isPaid) {
      return getAdditionalPriceWithDiscount();
    }
    return (
      getInitialSumWithDiscountCode(correctChild) +
      getAdditionalPriceWithDiscount()
    );
  };

  const getTotalPrice = useCallback(
    (correctChild) => {
      let finalSum = isDiscountCodeFound
        ? getInitialSumWithDiscountCode(correctChild)
        : getInitialSum(correctChild);
      if (!items[0].orderData.timeSlot.isPaid) {
        finalSum = getAdditionalPrice();
      }

      if (!couponSum) {
        return finalSum;
      }

      if (finalSum - couponSum <= 0) {
        finalSum = 0;
      } else {
        finalSum = finalSum - couponSum;
      }

      return finalSum;
    },
    [couponSum, isDiscount, isDiscountCodeFound, items, totalPrice],
  );

  useEffect(() => {
    if (items.some((item) => item.isDiscountFound)) {
      setIsDiscountCodeFound(true);
    }

    if (userState?.contacts) {
      if (items[0]?.orderData?.childIdNumber) {
        if (
          !userState?.contacts.some(
            (x) => x.personalCode === items[0]?.orderData?.childIdNumber,
          )
        ) {
          setCorrectChild(false);
        }
      }
    }
    totalInitialPrice(getTotalInitialPrice(correctChild));

    setIsLaboratoryServices(
      items[0].serviceTypeExternalId == externalServiceTypeIdForGroupedService,
    );
  }, []);

  useEffect(() => {
    if (items.some((item) => item.isDiscountFound)) {
      setIsDiscountCodeFound(true);
    }

    setSelectedTimeslot(items[0]?.orderData?.timeSlot);
  }, [items]);

  useEffect(() => {
    if (userState?.contacts) {
      if (items[0]?.orderData?.childIdNumber) {
        if (
          !userState?.contacts.some(
            (x) => x.personalCode === items[0]?.orderData?.childIdNumber,
          )
        ) {
          setCorrectChild(false);
        }
      }
    }

    totalPrice(getTotalPrice(correctChild));
  }, [getTotalPrice, totalPrice]);

  useEffect(() => {
    // Simulate some data being calculated in the child component
    const calculatedData = getTotalPrice(correctChild);

    // Invoke the callback function passed from the parent with the calculated data
    onDataFromChild(calculatedData);
  }, []);
  const shouldShowMultipleLaboratoryServicesSummary = () => {
    const payRule = selectedTimeslot?.isPaid ? 2 : 1;
    return (
      isLaboratoryServices &&
      (items?.length > 0 ||
        (items[0].additionalServices?.length > 0 &&
          items[0].automationRuleCodes?.some((rule) => rule === payRule)))
    );
  };


  const shouldShowAdditionalServices = () => {
    if (shouldShowMultipleLaboratoryServicesSummary()) { return true }
    if (!items[0] || !items[0].serviceTypeExternalId) { return false }
    return items[0].additionalServices?.length > 0 && gastroenterologyServiceTypeExternalId == items[0].serviceTypeExternalId
  }

  return (
    <>
      {(shouldShowAdditionalServices() && (
        <MultipleLaboratoryServicesSummary
          serviceImage={getServiceImage(items[0])}
          allServices={items}
          isExtraVisit={items[0].orderData?.timeSlot?.isExtra}
          isPrimaryVisit={items[0].orderData?.primaryVisit}
          isPaid={items[0].orderData?.timeSlot?.isPaid}
          correctChild={correctChild}
          couponSum={couponSum}
        />
      )) || (
          <Styled.Summary isMarginBottom={isMarginBottom}>
            <Styled.LabelsList>
              <div>Paslauga</div>
              <div>Paslaugos vieta</div>
              <div>Laikas</div>
              <div>Kaina</div>
            </Styled.LabelsList>

            {items.map((service, i) => {
              return (
                <Styled.Item
                  key={i}
                  hasOtherPerson={service.orderData.otherPerson}
                >
                  <Styled.Image
                    desktop={true}
                    style={{
                      backgroundImage: getServiceImage(service),
                    }}
                  />
                  <Styled.ItemContent>
                    <Styled.MainInfo>
                      <Styled.Text size="large">
                        {service.serviceTitle}
                      </Styled.Text>
                      <Styled.Image
                        mobile={true}
                        style={{
                          backgroundImage: getServiceImage(service),
                        }}
                      />
                      <div>
                        <Styled.Text size="medium">
                          {service.specialistName}
                        </Styled.Text>
                        <Styled.Text size="small">
                          {service.specialty}
                        </Styled.Text>

                        {service.orderData.otherPerson && (
                          <Styled.Text size="other-person">
                            Registruojamas vaikas iki 16 m.
                          </Styled.Text>
                        )}
                      </div>
                    </Styled.MainInfo>
                    <div>
                      <Styled.Text data-label="Paslaugos vieta">
                        {service.isServiceRemote
                          ? 'Nuotolinė konsultacija'
                          : service.departmentTitle}
                      </Styled.Text>
                    </div>
                    <div>
                      <Styled.Text data-label="Laikas">
                        <div>
                          {dateFnsFormat(
                            parseISO(service?.orderData?.timeSlot?.startDate),
                            'yyyy-MM-dd',
                          )}
                          <div>
                            {dateFnsFormat(
                              parseISO(service.orderData?.timeSlot?.startDate),
                              'HH:mm',
                            )}
                          </div>
                        </div>
                      </Styled.Text>
                    </div>
                    <div>
                      {!service.orderData?.timeSlot?.isExtra && (
                        <Styled.Text data-label="Kaina" size="price">
                          <Money
                            amount={
                              service.orderData.timeSlot.isPaid
                                ? service.orderData.primaryVisit
                                  ? !correctChild
                                    ? service.priceWithDiscountWithoutCard
                                    : service.priceWithDiscount
                                  : service.price
                                    ? !correctChild
                                      ? service.priceWithDiscountWithoutCardSecondary
                                      : service.secondaryDiscountEur
                                    : service.secondaryPrice
                                : getAdditionalPrice()
                            }
                          />
                        </Styled.Text>
                      )}

                      {service.orderData?.timeSlot?.isExtra && (
                        <Styled.Text data-label="Kaina" size="price">
                          <Money
                            amount={
                              !correctChild
                                ? service.priceWithDiscountWithoutCardExtra
                                : service.extraPrice
                            }
                          />
                        </Styled.Text>
                      )}
                    </div>
                  </Styled.ItemContent>
                </Styled.Item>
              );
            })}
            <Styled.Notification>
              {t('landing.hero.notification')}
            </Styled.Notification>
            {getDiscountCodePrice() > 0 &&
              getDiscountCodePrice() < initialPrice &&
              couponSum > 0 && (
                <Styled.TotalPrice>
                  {`Iš viso su PVM (pritaikius nuolaidą): ${formatCurrency(
                    getDiscountCodePrice(correctChild),
                  )}`}
                </Styled.TotalPrice>
              )}
            <Styled.TotalPrice>
              {couponSum > 0
                ? `Iš viso su PVM (pritaikius dovanų kuponus): ${formatCurrency(
                  getTotalPrice(correctChild),
                )}`
                : `Iš viso su PVM : ${formatCurrency(
                  getTotalPrice(correctChild),
                )}`}
            </Styled.TotalPrice>
          </Styled.Summary>
        )}
    </>
  );
};

export default Summary;
