import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Hero = styled.div`
  position: relative;
  padding: 20px 0 20px;
  background-size: cover;
  background-position: center center;
  background-color: #ffffff;
  z-index: 1;

  ${mediaDown('sm')`
    padding: 40px 0 10px;
  `}

  mark {
    color: ${(props) => props.primaryColor};
    background: transparent;
  }
`;

const Title = styled.h1`
  font-size: 46px;
  font-weight: 900;
  line-height: 76px;
  margin: 0 0 24px;
  text-align: center;
  color: #52585f;
  ${mediaDown('md')`
      font-size: 30px;
      line-height: 33px;
  `}
`;

const Description = styled.div`
  margin: 24px 0 28px;
  font-size: 21px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  ${mediaDown('sm')`
      display:none;
  `}
`;

const Notification = styled.div`
  color: #5bb548;
  margin-top: 24px;
  margin-bottom: 36px;
  font-size: 21px;
  font-weight: 500;
  line-height: 0;
  text-align: center;
  ${mediaDown('sm')`
  line-height: 20px;
  margin-bottom: 24px;
  `}
`;

const ContentContainer = styled.div`
  max-width: 1225px;
  margin: 0 auto;

  ${mediaDown('sm')`
  padding: 0 10px;
  
`}
`;

const Autocomplete = styled.div`
  position: relative;
  margin: 0 auto;

  box-shadow: 0 0 20px 0 rgba(23, 46, 64, 0.25);
  border-radius: 11px;
  max-width: 850px;
  ${mediaDown('md')`
    border-radius: 8px;
    box-shadow: 0 0 46px 0 rgba(23,46,64,0.06);
    width:85%;
  `}
  ${mediaDown('sm')`
  min-width: 85%; 
`}
`;

const Loader = styled.div`
  position: absolute;
  right: 28px;
  top: 50%;
  pointer-events: none;
  transform: translate(0, -50%);
  opacity: ${(props) => (props.isLoading ? 1 : 0)};
  transition: opacity 0.15s;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 86px;
  border-radius: 11px;
  background-color: #ffffff;
  border: none;
  outline: none;
  color: #52585f;
  font-size: 20px;
  line-height: 25px;
  padding: 0 20px 0 86px;

  ::placeholder {
    color: #52585f;
  }

  :-ms-input-placeholder {
    color: #52585f;
  }

  ::-ms-input-placeholder {
    color: #52585f;
  }

  ${mediaDown('md')`
    height: 50px;
    font-size: 17px;
    line-height: 16px;
    padding-left: 42px;
  `}
`;

const InputHolder = styled.div`
  position: relative;
  z-index: 90;

  > svg {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    pointer-events: none;
    transform: translate(36px, -50%);

    ${mediaDown('md')`
      width: 14px;
      transform: translate(15px, -50%);
    `}
  }

  ${(props) =>
    props.hasResults &&
    `
    ${Input} {
      border-radius: 11px 11px 0 0;
    }
  `}
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  > div {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    ${mediaDown('md')`
      font-size: 12px;
    `}
  }
`;

const Results = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 0 0 11px 11px;
  background-color: #fff;
  overflow: auto;
  z-index: 89;
  border-top: 1px solid #e0e2e3;
  box-shadow: 0 0 113px 0 rgba(23, 46, 64, 0.25);

  > div {
    height: 400px !important;

    ${mediaDown('md')`
      height: 200px !important;
    `}
  }
`;
const ResultsGroupName = styled.div`
  > div {
    display: inline-block;
    border-radius: 0 10px 10px 0;
    background-color: #f0f5f7;
    color: #27282a;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 14px 8px 36px;

    ${mediaDown('md')`
      font-size: 12px;
    `}
  }
`;

const ResultsGroupItem = styled.div`
  padding: 0 36px;
  margin: 12px 0;
  color: #27282a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  cursor: pointer;
  transition: color 0.15s;

  &:hover {
    color: ${(props) => props.primaryColor};
  }

  strong {
    font-size: 18px;
    font-weight: 800;

    ${mediaDown('md')`
      font-size: 14px;
    `}
  }
`;

const Styled = {
  Hero,
  Title,
  Description,
  Notification,
  ContentContainer,
  Autocomplete,
  Input,
  InputHolder,
  Loader,
  Actions,
  Results,
  ResultsGroupName,
  ResultsGroupItem,
};

export default Styled;
