import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSettingsState } from '../../context/SettingsContext';
import Styled from './Footer.styles';
import pdfCookieFile from '../../../assets/pdf/Slapuku_privatumo_pranesimas_V1.pdf';

const Footer = (props) => {
  const { t } = useTranslation();
  const { footer, agreements } = useSettingsState();
  const versionNumber = process.env.REACT_APP_VERSION;
  const [show, setShow] = useState(true);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <>
      {show && (
        <Styled.Footer>
          <Styled.FooterItem>
            <Trans
              i18nKey="footer.cookiePolicyLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={pdfCookieFile} target="_blank" rel="noopener noreferrer" />
                ),
              }}
            />
          </Styled.FooterItem>
          <Styled.FooterItem>
            <Trans
              i18nKey="footer.privacyPolicyLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={footer.privacyPolicy.url} target="_parent" />
                ),
              }}
            />
          </Styled.FooterItem>
          <Styled.FooterItem>
            <Trans
              i18nKey="footer.termsOfUseLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={agreements.termsOfUse.url} target="_parent" />
                ),
              }}
            />
          </Styled.FooterItem>
          <Styled.FooterItem>|</Styled.FooterItem>
          <Styled.FooterItem>Autorinės teisės  {currentYear}</Styled.FooterItem>
          <Styled.FooterItem>|</Styled.FooterItem>
          <Styled.FooterItem>{versionNumber}</Styled.FooterItem>
        </Styled.Footer>
      )}
    </>
  );
};

export default Footer;
