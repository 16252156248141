import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Form = styled.div`
  display: grid;
  justify-content: center;
  width: 100%;
  ${mediaDown('md')`
    min-width: 200px;
  `}
`;
const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: inherit;
`;
const Title = styled.h1`
  margin-top: 55px;
  text-align: center;
  ${mediaDown('md')`
    font-size:24px;
  `}
`;
const SmartIdTitle = styled.h3`
  margin-top: 0px;
  text-align: center;
  ${(props)=>props.greyTheme && 'color: #52585F'};
  ${mediaDown('md')`
    
  `}
`;
const LineThrough = styled.h1`
  overflow: hidden;
  text-align: center;
  margin: 15px 0 0 0;
  &:before,
  &:after {
    background-color: grey;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 0.5em;
    opacity: 0.4;
    margin-left: -50%;
  }
  &:after {
    opacity: 0.4;
    left: 0.5em;
    margin-right: -50%;
  }
  ${mediaDown('md')`
    font-size:24px;
  `}
`;

const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  margin: 0 0 5px;
`;

const RegisterHere = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  a {
    margin-left: 10px;
  }
`;

const Styled = {
  Form,
  Title,
  LineThrough,
  SmartIdTitle,
  ForgotPassword,
  Buttons,
  RegisterHere,
};

export default Styled;
