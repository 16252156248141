import React, { useEffect } from 'react';
import Styled from './Coupon.styles';
import CouponImg from '../../../assets/images/Dovanu_kuponas.jpg';
import { useCartState, useCartDispatch } from '../../context/CartContext';
import uniqid from 'uniqid';
import { useParams, useHistory } from 'react-router-dom';
import Button from '../Button';

const Coupon = ({ coupon }) => {
  const dispatch = useCartDispatch();
  const history = useHistory();

  function addToCart() {
    const productCopy = {
      price: coupon.price,
      validTo: coupon.validTo,
      comment: coupon.note,
      title: `DOVANŲ KUPONAS ${coupon.price}€`,
      couponId: coupon.couponId,
      priceId: coupon.priceId,
    };
    dispatch({
      type: 'ADD_TO_CART_COUPON',
      product: {
        ...productCopy,
        id: uniqid(),
      },
    });

    history.push('/couponcheckout', { backPath: window.location.href });
  }

  function formatNote(note) {
    if (note && note.length >= 121) {
      return note.substring(0, 121) + '...';
    }
    return note;
  }

  return (
    <Styled.Coupon>
      <Styled.CouponHeaderImage
        style={{
          backgroundImage: `url(${CouponImg})`,
        }}
      ></Styled.CouponHeaderImage>
      <Styled.CouponContent>
        <Styled.CouponTitle comment={false}>
          DOVANŲ KUPONAS {coupon.price} €
        </Styled.CouponTitle>
        <Styled.Description>
          <Styled.CouponDescription mb>
            Kaina: {coupon.price} €
          </Styled.CouponDescription>
          <Styled.CouponDescription>
            Galioja iki: {coupon.validTo}
          </Styled.CouponDescription>
        </Styled.Description>

        <Styled.Actions>
          <Button color="disabled" onClick={() => addToCart()}>
            Užsakyti
          </Button>
        </Styled.Actions>

        {/* <Styled.CouponButton onClick={() => addToCart()}></Styled.CouponButton> */}
      </Styled.CouponContent>
    </Styled.Coupon>
  );
};

const List = ({ children }) => {
  return <Styled.List>{children}</Styled.List>;
};

Coupon.List = List;

export default Coupon;
