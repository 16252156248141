import React, { useState } from 'react';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';
import { isEdge } from 'react-device-detect';

const usePasswordToggle = () => {
    const[visiblity, setVisibility] = useState(false);
   
    const icon = visiblity ? <AiOutlineEye onClick={()=>setVisibility(visibility => !visibility)} /> : <AiOutlineEyeInvisible onClick={()=>setVisibility(visibility => !visibility)} /> ;
  
    const InputType = visiblity ? "text" : "password";

    if(isEdge){
        return["password", null]
    } else {
        return [InputType, icon];     
    }
    
}

export default usePasswordToggle;