import React, { useState, useEffect } from 'react';
import useFetch from 'use-http';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import Button from '../Button';
import Loader from '../Loader';
import heroImg from '../../../assets/images/background.jpg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg';
import Styled from './HeroError.styles';
import { useSettingsState } from '../../context/SettingsContext';

function highlightText(text, search) {
  const re = new RegExp(search, 'gi');
  const newResults = text.replace(re, '<mark>$&</mark>');
  return newResults;
}

const HeroError = ({ onScroll }) => {
  const { t } = useTranslation();
  const { theme } = useSettingsState();

  const history = useHistory();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({});
  const { request } = useFetch('/Services/WorkplaceServices');

  useEffect(() => {
    request.get(`?Search=${query}`).then((r) => {
      setResults(query ? groupBy(r, 'serviceTypeTitle') : {});
    });
  }, [query, request]);

  return (
    <Styled.Hero
      primaryColor={theme.primaryColor}
      style={{
        backgroundImage: `url(${heroImg})`,
      }}
    >
      <Container>
        <Styled.ContentContainer>
          <Styled.Title>{t('landing.heroError.title')}</Styled.Title>
          <Styled.Description>
            {t('landing.heroError.description')}
          </Styled.Description>        
        </Styled.ContentContainer>
      </Container>
    </Styled.Hero>
  );
};

export default HeroError;
