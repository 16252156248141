import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as Yup from 'yup';

import { useUserState } from '../../../../context/UserContext';
import personalCodeValidation from '../../../../hooks/personalCodeValidation';
import Button from '../../../../shared/Button';
import Checkbox from '../../../../shared/Checkbox';
import Form from '../../../../shared/Form';
import Page from '../../../../shared/Page';
import Modal from '../../../../shared/Modal/Modal';
import TextField from '../../../../shared/TextField';
import Styled from '../../UserAccountPage/UserAccountPage.styles';
import FileUploadAsBase64 from '../../../../shared/FileUploadAsBase64/FileUploadAsBase64';
import SelectField from '../../../../shared/SelectField';
import { ReactComponent as ErrorIcon } from '../../../../../assets/icons/error-circle.svg';
import { ReactComponent as SuccessIcon } from '../../../../../assets/icons/success-circle.svg';

const LocalRegistration = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userState = useUserState();
  const checkboxRef = useRef();
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalType, setModalType] = useState('success');
  const isTestEnv = process.env.REACT_APP_ISTESTENV === 'true';
  const [modalTitle, setModalTitle] = useState('Dėkojame, Jūsų užklausa gauta');
  const [modalText, setModalText] = useState('');
  const { request: fileRequest } = useFetch(`/Files`);
  const { post: createRegistration, response: createRegistrationResponse } =
    useFetch(`/LocalRegistration/RegisterDoctorOnCall`);

  const topicSelectRef = useRef();
  const [topicList, setTopicList] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTopicLabel, setSelectedTopicLabel] = useState(null);
  const [validatePersonalCode] = personalCodeValidation();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation.requiredField'))

      .trim(),
    surname: Yup.string()
      .required(t('validation.requiredField'))

      .trim(),
    personalCode: Yup.number()
      .required(t('validation.requiredField'))
      .test('len', t('validation.invalidFormat'), (val) => {
        return validatePersonalCode(val);
      }),
    phone: Yup.string()
      .required(t('validation.requiredField'))
      .test('checkFormat', t('validation.number'), function (val) {
        const phoneRegex = /^[+]{1}(370)(\d{8})+$/;
        if (val && phoneRegex.test(val)) {
          return true;
        }
        return false;
      }),
    email: Yup.string()
      .email(t('validation.invalidEmail'))
      .required(t('validation.requiredField'))
      .trim(),

    personAgreement: Yup.bool().oneOf([true], t('validation.requiredField')),
    topicId: Yup.number().required(t('validation.requiredField')),
  });

  const initialValues = {
    name: userState.firstName || '',
    surname: userState.lastName || '',
    email: userState.email || '',
    phone: userState.phoneNumber || '',
    personalCode: userState.containsPersonalCode ? userState.personalCode : '',
    personAgreement: false,
    reason: '',
    files: [],
  };

  const sendForm = async (data, { setSubmitting, setErrors, resetForm }) => {
    const response = await createRegistration('', {
      name: data.name,
      surname: data.surname,
      email: data.email,
      topic: selectedTopicLabel,
      phone: data.phone,
      personalCode: data.personalCode,
      reason: data.reason,
      files: data.files,
    });

    if (response) {
      setModalText(
        'Jūsų užklausa gauta. Budintis gydytojas su Jumis susisieks kaip įmanoma skubiau, bet ne vėliau nei per 1 darbo dieną',
      );
      setVisibleModal(true);
    } else {
      setModalText('Įvyko klaida, prašome pakartoti vėliau');
      setVisibleModal(true);
      setModalType('error');
    }
    checkboxRef.current.checked = false;
    setSelectedTopic(null);
    resetForm({ values: '' });
  };

  // isTestEnv || userState.subscriptionInfo?.isSubscriptionInstitutionLocal;

  useEffect(() => {
    setTopicList([
      { value: 1, label: 'Nedarbingumo išdavimas' },
      { value: 2, label: 'Vaisto ar MPP pratęsimas' },
      { value: 3, label: 'Ūmi liga ar lėtinės ligos paūmėjimas' },
      { value: 4, label: 'Kiti skubūs klausimai' },
    ]);
  }, []);

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.TabTitle style={{ display: 'flex', justifyContent: 'center' }}>
          Susisiekti su budinčiu gydytoju
        </Styled.TabTitle>

        <Styled.EditForm
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            color: '#52585F',
          }}
        >
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={sendForm}
          >
            {({
              setFieldValue,
              errors,
              isSubmitting,
              values,
              handleSubmit,
              handleChange,
              setFieldError,
            }) => (
              <FormikForm>
                <Styled.EditFormCenter>
                  <Form>
                    {isTestEnv ||
                    userState.subscriptionInfo
                      ?.isSubscriptionInstitutionLocal ? (
                      <Form.Group>
                        <Form.Row>
                          <Field
                            focus={true}
                            name="name"
                            label={'Vardas'}
                            disabled={true}
                            component={TextField}
                            isGrey={true}
                          />
                        </Form.Row>
                        <Form.Row>
                          <Field
                            name="surname"
                            label={'Pavardė'}
                            disabled={true}
                            component={TextField}
                            isGrey={true}
                          />
                        </Form.Row>

                        <Form.Row>
                          <Field
                            name="personalCode"
                            disabled={userState.containsPersonalCode}
                            label={'Asmens kodas'}
                            component={TextField}
                            isGrey={true}
                          />
                        </Form.Row>

                        <Form.Row>
                          <Field
                            name="phone"
                            label={'Telefono numeris'}
                            component={TextField}
                            isGrey={true}
                          />
                        </Form.Row>

                        <Form.Row>
                          <Field
                            name="email"
                            label={'El. pašto adresas'}
                            component={TextField}
                            isGrey={true}
                          />
                        </Form.Row>

                        <Form.Row>
                          <SelectField
                            innerRef={topicSelectRef}
                            disabled={
                              topicList === null || topicList === undefined
                            }
                            error={errors.topicId}
                            value={selectedTopic}
                            label={t('registration.form.dropDownTitle')}
                            name="topicId"
                            options={topicList}
                            greyTheme={true}
                            onChange={(value) => {
                              setFieldValue('topicId', value);
                              if (topicList) {
                                setSelectedTopic(
                                  topicList.find(
                                    (topic) => topic.value === value,
                                  ),
                                );
                                setSelectedTopicLabel(
                                  topicList.find(
                                    (topic) => topic.value === value,
                                  ).label,
                                );
                                setFieldValue('topicId', value);
                              }
                            }}
                          />
                        </Form.Row>
                        <Form.Row>
                          <Field
                            name="reason"
                            label={'Problema'}
                            textarea
                            component={TextField}
                            isGrey={true}
                          />
                        </Form.Row>
                        <Form.Row>
                          <Checkbox
                            innerRef={checkboxRef}
                            name="personAgreement"
                            value={!values.personAgreement}
                            error={errors.personAgreement}
                            onChange={handleChange}
                          >
                            Sutinku, kad mano asmeniniai duomenys būtų panaudoti
                            registracijos tikslais
                          </Checkbox>
                        </Form.Row>
                        <Form.Row>
                          <FileUploadAsBase64
                            request={fileRequest}
                            error={errors.fileIds}
                            onChange={(value) => {
                              if (value) {
                                setFieldError('fileIds', false);
                              }

                              setFieldValue('files', value);
                              setFieldValue(
                                'fileIds',
                                value.map((f) => f.fileUid),
                              );
                            }}
                            value={values.files}
                            label={t('registration.form.fieldFileLabel')}
                            isGrey={true}
                          />
                        </Form.Row>
                      </Form.Group>
                    ) : (
                      <Form.Row style={{ marginTop: '25px' }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t('registration.form.formTitle'),
                          }}
                        ></p>
                      </Form.Row>
                    )}
                  </Form>
                  {(isTestEnv ||
                    userState.subscriptionInfo
                      ?.isSubscriptionInstitutionLocal) && (
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      style={{ width: '250px', alignSelf: 'center' }}
                      onClick={handleSubmit}
                    >
                      Siųsti prašymą
                    </Button>
                  )}
                </Styled.EditFormCenter>
              </FormikForm>
            )}
          </Formik>
        </Styled.EditForm>
      </Styled.DataContainer>

      <Modal
        visible={visibleModal}
        isGrey={true}
        onClose={() => {
          setVisibleModal(false);
        }}
      >
        <Modal.Alert type={modalType}>
          {modalType === 'error' && <ErrorIcon />}
          {modalType === 'success' && <SuccessIcon />}
          {modalTitle}
        </Modal.Alert>
        <Modal.TextContent isGrey={true}>{modalText}</Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setVisibleModal(false);
              history.push('/registration/local/list');
            }}
          >
            Gerai
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default LocalRegistration;
