import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

export const inputBase = css`
  height: 60px;
  padding: 0 19px;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
  color: #000; 
  box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  transition: all 0.15s;

  ${(props) => props.isGrey && `
    color:#52585f;
    &:-webkit-autofill {
      -webkit-text-fill-color: #52585f;
    }  
    ::-webkit-input-placeholder { 
      color: #52585f;
      }
      ::-moz-placeholder {
        color: #52585f;
      }
      :-ms-input-placeholder {
        color: #52585f;
      }
      :-moz-placeholder { 
        color: #52585f;
      }
    
      ::placeholder {
        color:#52585f;
      }
  `}

  -moz-appearance: none;
  -webkit-appearance: none;

  ${mediaDown('md')`
    font-size: 16px;
    line-height: 16px;
    padding: 0 15px;
    height: 45px;
  `}

  &:focus {
    border-color: ${(props) => props.primaryColor};
  }
`;

export const inputBaseLargerFont = css`
  height: 60px;
  padding: 0 19px;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
  color: #000;
  box-shadow: 0 0 30px 0 rgba(23, 46, 64, 0.03);
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  transition: all 0.15s;
  
  ${(props) => props.isGrey && `
  color:#52585f;
  &:-webkit-autofill {
    -webkit-text-fill-color: #52585f;
  }    

  ::-webkit-input-placeholder { 
  color: #52585f;
  }
  ::-moz-placeholder {
    color: #52585f;
  }
  :-ms-input-placeholder {
    color: #52585f;
  }
  :-moz-placeholder { 
    color: #52585f;
  }

  ::placeholder {
    color:#52585f;
  }

`}

  ${mediaDown('md')`
    font-size: 17px;
    line-height: 14px;
    padding: 0 15px;
    height: 45px;
  `}

  &:focus {
    border-color: ${(props) => props.primaryColor};
  }
`;

const Input = styled.input`
  ${inputBase};
`;



const InputLargerFont = styled.input`
  ${inputBaseLargerFont};

`;

const Hint = styled.span`
  font-size: 13px;
  font-style: italic;
  color: ${(props)=>props.isGrey ? '#52585F' : '#9395a9'};
`;

const Textarea = styled.textarea`
  ${inputBase};
  padding-top: 19px;
  padding-bottom: 19px;
  height: auto;
  resize: none;
  display: block;
  ${mediaDown('md')`
    padding-top: 15px;
    padding-bottom: 15px;
  `}
 
`;



const errorCss = css`
  ${Textarea},
  ${Input} {
    border-color: #f35b69;
    border-bottom-left-radius: 0;
  }
`;

const TextField = styled.div`
  ${(props) => props.error && errorCss}
 
`;

const Styled = {
  TextField,
  Input,
  Textarea,
  InputLargerFont,
  Hint,
};

export default Styled;
