import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

export const currencies = {
  NO: '',
  EUR: '€',
  LTL: 'LTL',
  USD: '$',
  AUD: '$',
  GBP: '£',
};

const formatCurrency = (value, currency = 'EUR') => {
  if (!value && value !== 0) {
    return `${currencies[currency]} --`;
  }
  // TODO CONFIG BY LOCALE
  return accounting.formatMoney(value, {
    symbol: currencies[currency],
    precision: 2,
    thousand: ' ',
    format: {
      pos: ' %v %s',
      neg: ' -%v %s',
      zero: ' %v %s',
    },
  });
};

const Money = ({ amount, currency }) => {
  return <>{formatCurrency(amount, currency.toUpperCase())}</>;
};

Money.defaultProps = {
  amount: 0,
  currency: 'EUR',
};

Money.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  className: PropTypes.string,
  showColors: PropTypes.bool,
};

export { Money, formatCurrency };
