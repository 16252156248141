import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
  margin: auto;
  height: fit-content;
  ${mediaDown('md')`
  width: 450px;
  min-width: 200px;
  `}
`;

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: inherit;
`;
const Title = styled.h1`
  top: 30px;
  margin-top: 55px;
  text-align: center;
  ${mediaDown('md')`
    font-size:24px;
  `}
`;
const PasswordExplanation = styled.div`
  display: flex;
  font-size: 16px;

  svg {
    width: 40px;
    margin-top: 15px;
  }
`;
const Styled = {
  Form,
  Title,
  Buttons,
  PasswordExplanation,
};

export default Styled;
