const personalCodeValidation = () => {
  function daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  const validatePersonalCode = (val) => {
    if (val === undefined) {
      return false;
    }
    if (val?.toString().length !== 11) {
      return false;
    }
    const personalCode = val?.toString();
    if (personalCode[0] === 9) {
      return true;
    }

    if (1 <= Number(personalCode[0]) && Number(personalCode[0]) <= 6) {
      const monthNr = Number(personalCode.slice(3, 5));
      const monthDays = daysInMonth(new Date().getFullYear(), monthNr);

      const month = Number(personalCode.slice(3, 5));
      if (0 <= month && month <= 12) {
        const days = Number(personalCode.slice(5, 7)) - 1;
        if (0 <= days && days <= monthDays) {
          const firstMultipliers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1];
          const secondMultipliers = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3];
          let total = 0;
          for (let index = 0; index < 10; index++) {
            total += Number(personalCode[index] * firstMultipliers[index]);
          }
          const controlNumber = personalCode[10];
          let mod = total % 11;
          total = 0;
          if (mod === 10) {
            for (let index = 0; index < 10; index++) {
              total += Number(personalCode[index] * secondMultipliers[index]);

              mod = total % 11;
              if (mod === 10) {
                mod = 0;
              }
            }
          }
          return mod == controlNumber;
        }
      }
    }

    return false;
  };
  const getAge = (birthDate) =>
    Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10);

  const birthDateFromPersonalCode = (val) => {
    if (val === undefined || val == null) {
      return false;
    }
    const personalCode = val.toString();
    const personYear = Number(personalCode.slice(1, 3));

    if (personalCode[0] == 5 || personalCode[0] == 6) {
      var personYearFormatted = null;
      if (personYear.toString().length == 1) {
        personYearFormatted = `200${personYear}`;
      } else {
        personYearFormatted = `20${personYear}`;
      }

      const childBirthDate = new Date(
        Number(personYearFormatted),
        Number(personalCode.slice(3, 5)) - 1,
        Number(personalCode.slice(5, 7)),
      );
      return childBirthDate;
    } else if (personalCode[0] == 3 || personalCode[0] == 4) {
      var personYearFormatted = null;
      if (personYear.toString().length == 1) {
        personYearFormatted = `1999${personYear}`;
      } else {
        personYearFormatted = `19${personYear}`;
      }

      const childBirthDate = new Date(
        Number(personYearFormatted),
        Number(personalCode.slice(3, 5)) - 1,
        Number(personalCode.slice(5, 7)),
      );
      return childBirthDate;
    }
  };

  const maxAgeFromPersonalCode = (val, validationAge) => {
    if (val === undefined || val == null) {
      return false;
    }

    const childBirthDate = birthDateFromPersonalCode(val);

    const age = getAge(childBirthDate);
    if (age < validationAge) {
      return false;
    }

    return true;
  };

  const minAgeFromPersonalCode = (val, validationAge) => {
    if (val === undefined || val == null) {
      return false;
    }

    const childBirthDate = birthDateFromPersonalCode(val);

    const age = getAge(childBirthDate);
    if (age >= validationAge) {
      return false;
    }

    return true;
  };

  return [
    validatePersonalCode,
    maxAgeFromPersonalCode,
    minAgeFromPersonalCode,
    birthDateFromPersonalCode,
  ];
};
export default personalCodeValidation;
