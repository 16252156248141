/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import moment from 'moment';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';
import Page from '../../../../../shared/Page';

const VisitE025Details = () => {
  const history = useHistory();
  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');
  const e025 = history.location.state?.data;

  const handleFileDownload = async () => {
    await getFile(
      `?fileType=${e025.file.type}&fileGuid=${
        e025.file.guid
      }&category=${'E025'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download =
        e025.practitioner.qualification +
        '-' +
        e025.practitioner.name +
        ' ' +
        e025.practitioner.surname +
        ' ' +
        moment(new Date(e025.date)).format('YYYY-MM-DD') +
        '.pdf';

      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.BackArrow>
          <RightArrow onClick={() => history.goBack()}></RightArrow>
          <Styled.VisitTitle>Gydytojų konsultacijos</Styled.VisitTitle>
        </Styled.BackArrow>
        <Styled.InfoDetailsRow>
          <span>Data:</span>
          <div>
            {(new Date(Date.parse(e025.date)).getUTCHours() === 0 && (
              <Moment utc={true} className="time" format="YYYY-MM-DD">
                {Date.parse(e025.date)}
              </Moment>
            )) || (
              <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
                {Date.parse(e025.date)}
              </Moment>
            )}
          </div>
        </Styled.InfoDetailsRow>
        <Styled.InfoDetailsRow>
          <span>Laikas:</span>
          <Moment className="time" parse="HH:mm" format="HH:mm">
            {e025.time}
          </Moment>
        </Styled.InfoDetailsRow>
        <Styled.InfoDetailsRow>
          <span>Gydytojas:</span>
          <div>
            {e025.doctorSpeciality} - {e025.doctorName} {e025.doctorSurname}
          </div>
        </Styled.InfoDetailsRow>

        <Styled.VisitDetailsRow>
          <span>Nusiskundimai ir anamnezė:</span>
          {e025.complaints &&
            e025.complaints.split(/\r?\n|<br\/>/).map((text, index) => {
              return <div key={index}> {text} </div>;
            })}
        </Styled.VisitDetailsRow>

        <Styled.VisitDetailsRow>
          <span>Objektyvi būklė:</span>
          {e025.objectiveCondition &&
            e025.objectiveCondition.split(/\r?\n|<br\/>/).map((text, index) => {
              return <div key={index}> {text} </div>;
            })}
        </Styled.VisitDetailsRow>

        <Styled.VisitDetailsRow>
          <span>Atlikti tyrimai ir konsultacijos:</span>
          {e025.finishedResearches &&
            e025.finishedResearches
              .split(/;|\r?\n|<br\/>/)
              .map((text, index) => {
                return <div key={index}> {text} </div>;
              })}
        </Styled.VisitDetailsRow>

        <Styled.VisitDetailsRow>
          <span>Tyrimų ir konsultacijų planas:</span>
          {e025.plannedResearches &&
            e025.plannedResearches.split(/\r?\n|<br\/>/).map((text, index) => {
              return <div key={index}> {text} </div>;
            })}
        </Styled.VisitDetailsRow>
        <Styled.VisitDetailsRow>
          <span>Rekomendacijos:</span>
          {e025.recommendations &&
            e025.recommendations.split(/\r?\n|<br\/>/).map((text, index) => {
              return <div key={index}> {text} </div>;
            })}
        </Styled.VisitDetailsRow>
        {e025.file && e025.file.fileGuid && (
          <Styled.InfoDetailsRow>
            <a onClick={handleFileDownload}>ATSISIŲSTI PDF</a>
          </Styled.InfoDetailsRow>
        )}
      </Styled.DataContainer>
    </Page>
  );
};
export default VisitE025Details;
