/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useFetch from 'use-http';
const LabaratoryResearchesTab = (props) => {
  const history = useHistory();
  const research = history.location.state?.data;

  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');

  const handleFileDownload = async (filename, fileType, fileGuid) => {
    await getFile(
      `?fileType=${fileType}&fileGuid=${fileGuid}&category=${'LabaratoryResearch'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${trimFileName(filename)}.pdf`;
      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  const trimFileName = (fileName) => {
    if (fileName.length > 190) {
      return fileName.substring(0, fileName.length - 18) + '...';
    } else return fileName;
  };
  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>Laboratoriniai tyrimai</Styled.VisitTitle>
      </Styled.BackArrow>
      <Styled.InfoDetailsRow>
        <span>Data:</span>

        <div>
          {(new Date(Date.parse(research.orderDate)).getUTCHours() === 0 && (
            <Moment utc={true} className="time" format="YYYY-MM-DD">
              {Date.parse(research.orderDate)}
            </Moment>
          )) || (
            <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
              {Date.parse(research.orderDate)}
            </Moment>
          )}
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Pavadinimas:</span>
        <div>{research.name}</div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Būsena:</span>
        <div>{research.statusDisplay}</div>
      </Styled.InfoDetailsRow>

      {research.observations?.length > 0 && (
        <Styled.List style={{ marginTop: '15px' }}>Rezultatai:</Styled.List>
      )}

      {research.observations?.length > 0 &&
        research.observations.map((result, index) => {
          return (
            <div key={index} style={{ marginTop: '15px' }}>
              <Styled.ListItemRow>
                <span>Pavadinimas:</span>
                <div>{result.name}</div>
              </Styled.ListItemRow>
              {result.referenceRange && (
                <Styled.ListItemRow>
                  <span>Norma:</span>
                  <div>{result.referenceRange}</div>
                </Styled.ListItemRow>
              )}
              <Styled.ListItemRow>
                <span>Rezultatas:</span>
                <div>{result.value}</div>
              </Styled.ListItemRow>
              <Styled.ListItemRow>
                <span>Matavimo vienetas:</span>
                <div>{result.unit}</div>
              </Styled.ListItemRow>
            </div>
          );
        })}

      {research.file && research.file.guid && (
        <Styled.InfoDetailsRow>
          <a
            onClick={() => {
              const fileName =
                research.name +
                ' ' +
                moment(new Date(research.date)).format('YYYY-MM-DD');
              handleFileDownload(
                fileName,
                research.file.type,
                research.file.guid,
              );
            }}
          >
            ATSISIŲSTI PDF
          </a>
        </Styled.InfoDetailsRow>
      )}
    </Styled.DataContainer>
  );
};
export default LabaratoryResearchesTab;
