import React, { useCallback } from 'react';
import { parseISO, format as dateFnsFormat } from 'date-fns';
import Button from '../../../../shared/Button';
import { ReactComponent as CrossIcon } from '../../../../../assets/icons/cross.svg';
import { useSettingsState } from '../../../../context/SettingsContext';
import Styled from './Summary.styles';
import { Money, formatCurrency } from '../../../../shared/Money';
import CouponImg from '../../../../../assets/images/Dovanu_kuponas.jpg';
const Summary = ({ item }) => {
  return (
    <Styled.Summary>
      <Styled.LabelsList>
        <div>Paslauga</div>
        <div>Pastaba</div>
        <div>Galioja iki</div>
        <div>Kaina</div>
      </Styled.LabelsList>
      <Styled.Item>
        <Styled.Image
          desktop={true}
          style={{
            backgroundImage: `url(${CouponImg})`,
          }}
        />
        <Styled.ItemContent>
          <Styled.MainInfo>
            <Styled.Text size="large">{item.title}</Styled.Text>
            <Styled.ImageMobile
              mobile={true}
              style={{
                backgroundImage: `url(${CouponImg})`,
                width: '60px',
              }}
            />
          </Styled.MainInfo>
          <div>
          </div>
          <div>
            <Styled.Text data-label="Galioja iki">{item.validTo}</Styled.Text>
          </div>
          <div>
            <Styled.Text data-label="Kaina" size="price">
              <Money amount={item.price} />
            </Styled.Text>
          </div>
        </Styled.ItemContent>
        <Styled.Number>
          <div>
            <label>Kiekis:</label>
            <span>{item.orderedCount}</span>
          </div>
        </Styled.Number>
      </Styled.Item>

      <Styled.TotalPrice>
        Iš viso su PVM: {formatCurrency(item.price)}
      </Styled.TotalPrice>
    </Styled.Summary>
  );
};

export default Summary;
