import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const largeButtonCss = css`
  font-size: 16px;
  height: 45px;
  min-width: 145px;

  ${mediaDown('md')`
    padding: 12px 24px;
    height: auto;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 1.6px;
  `}
`;
const hugeButtonCss = css`
  padding: 18px 32px;
  font-size: 18px;
  letter-spacing: 2.4px;
  line-height: 23px;

  ${mediaDown('md')`
    padding: 12px 24px;
    height: auto;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 1.6px;
  `}
`;

const megaButtonCss = css`
  padding: 18px 32px;
  font-size: 24px;
  letter-spacing: 2.4px;
  line-height: 23px;

  ${mediaDown('md')`
    padding: 12px 24px;
    height: auto;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 1.6px;
  `}
`;

const smallButtonCss = css`
  font-size: 12px;
  height: auto;
  line-height: 1;
  min-width: 100px;
  padding: 7px 14px;
`;

const secondaryTypeButton = css`
  background-color: #fff;
  color: #000;
`;

const loadingCss = css`
  color: transparent;

  svg {
    max-height: 100%;
  }
`;

const openedButtonCss = css`
  background-color: white;
  border:0;
  border-radius:0;
  color:#4181d0;
  border-top:1px solid #4181d0;
  margin:0 20px;
  height:43px;
  &:hover {
    color:#4181d0 !important; 
  }
`

const Button = styled.button`
  display: flex;
  user-select: none;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  padding: 0 24px;
  border-radius: 5px;
  color: #fff;
  border: 0;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  border-radius: 6px;
  position: relative;
  background-color: ${(props) => props.primaryColor};
  border: 1px solid ${(props) => props.primaryColor};

  ${(props) => props.size === 'mega' && megaButtonCss}
  ${(props) => props.size === 'huge' && hugeButtonCss}
  ${(props) => props.size === 'large' && largeButtonCss}
  ${(props) => props.size === 'small' && smallButtonCss}
  ${(props) => props.variant === 'secondary' && secondaryTypeButton}
  ${(props) => props.isLoading && loadingCss}
  ${(props) => props.isCenter && 'text-align:center;'}
  ${(props) => props.isGrey && 'color:#52585F;'}
  ${(props) => props.isOpened && openedButtonCss}
  ${(props) => props.isWide && 'padding 0 20px !important;'}
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
    border: 0;
    background-color: ${(props) => handleBackgroundColorType(props.color)};
  }
`;

const handleBackgroundColorType = color => {
  switch (color) {
    case "disabled":
      return "#909090";
    default:
      return "#0087F7";
  }
};

const IconHolder = styled.div`
  margin-left: 10px;

  svg {
    display: block;
  }
`;

const Styled = {
  Button,
  IconHolder,
};

export default Styled;
