import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
   100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
   0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Loader = styled.div`
  font-size: 0;
  line-height: 0;
  ${(props) =>
    props.inline
      ? ``
      : `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  `}

  svg {
    animation: ${rotate} 2s linear infinite;
    z-index: 2;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;

    circle {
      stroke: ${(props) => (props.color ? props.color : '#3F9AF7')};
      stroke-linecap: round;
      animation: ${dash} 1s ease-in-out infinite;
    }
  }
`;

export const Styled = {
  Loader,
};
