import styled from 'styled-components/macro';
// import { mediaDown } from '../../../utils/styles/media';

const Option = styled.div`
  font-size: 0;
  cursor: pointer;

  svg {
    height: 18px;
  }
`;

const Toggler = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${Option} {
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 0;
  left: -12px;
  top: 30px;
  padding: 12px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.08),
    0px 2px 8px rgba(37, 26, 26, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.1);
  z-index: 29;

  ${Option} {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const LangSelect = styled.div`
  position: relative;

  ${(props) =>
    props.isDisabled &&
    `
    pointer-events: none;
    
    ${Toggler} > svg {
      display: none;
    }
  `}
`;

const Styled = {
  LangSelect,
  Option,
  Toggler,
  Dropdown,
  Backdrop,
};

export default Styled;
