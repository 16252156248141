/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import Styled from '../UserAccountPage.styles';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RightArrow } from '../../../../../assets/icons/arrow.svg';
import useFetch from 'use-http';
import Loader from '../../../../shared/Loader';
import Page from '../../../../shared/Page';
import { useEffectOnce } from 'react-use';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useUserState } from '../../../../context/UserContext';

const VisitDetailsComponent = (props) => {
  const history = useHistory();
  const vaccinationRef = useRef(null);
  const referralsRef = useRef(null);
  const instrumentalResearchesRef = useRef(null);
  const laboratoryResearchesRef = useRef(null);
  const prescriptionsRef = useRef(null);
  const e025Ref = useRef(null);
  const [VisitDetails, setVisitDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const executeScroll = (ref) => scrollToRef(ref);
  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop + 145);
  };

  const visitDetails = history.location.state?.visit;
  const otherVisit = history.location.state?.fromOtherVisits;
  const fromE025 = history.location.state?.fromE025;
  const userState = useUserState();
  const { get: getVisitDetails } = useFetch('/UserAccount/VisitDetails');

  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');
  const handleFileDownload = async (category, filename, fileType, fileGuid) => {
    await getFile(
      `?fileType=${fileType}&fileGuid=${fileGuid}&category=${category}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${filename}.pdf`;
      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const getUserVisitDetailsInformation = async (visitId) => {
    setLoading(true);
    let queryString = userState.espbiEsi
      ? `?visitId=${visitId}&espbiEsi=${userState.espbiEsi}`
      : `?visitId=${visitId}`;

    getVisitDetails(queryString).then((res) => {
      setVisitDetails(res);
      setLoading(false);
    });
  };

  useEffectOnce(() => {
    getUserVisitDetailsInformation(visitDetails.visitId);
  });

  useEffect(() => {
    scrollToTitle(props);
  }, [props]);

  function scrollToTitle(props) {
    switch (props.scrollTo) {
      case 'prescriptionsRef':
        executeScroll(prescriptionsRef);
        break;
      case 'vaccinationRef':
        executeScroll(vaccinationRef);
        break;
      case 'instrumentalResearchesRef':
        executeScroll(instrumentalResearchesRef);
        break;
      case 'e025Ref':
        executeScroll(e025Ref);
        break;
      case 'referralsRef':
        executeScroll(referralsRef);
        break;
    }
  }

  function calculateRemainingPaymentPrice(service) {
    if (
      Array.isArray(service.payments) &&
      service.payments.some((x) => x.paymentType !== null)
    ) {
      let price = service.price;
      service.payments.forEach((payment, index) => {
        if (payment.paymentType != null) {
          price -= payment.price;
        }
      });
      return price > 0 ? price.toFixed(2) : null;
    }
  }
  if (loading) {
    return (
      <Page>
        <Loader />
      </Page>
    );
  }
  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.BackArrow>
          <RightArrow onClick={() => history.goBack()}></RightArrow>
          <Styled.VisitTitle>
            {!fromE025 && !otherVisit && 'Vizitų istorija'}
            {fromE025 && 'Gydytojų konsultacijos'}
            {otherVisit && 'Kitos paslaugos'}
          </Styled.VisitTitle>
        </Styled.BackArrow>
        {/* Service information */}
        <Styled.ServiceTitle>VIZITO INFORMACIJA</Styled.ServiceTitle>
        {(Array.isArray(visitDetails.services) &&
          visitDetails.services?.length > 0 &&
          visitDetails.services.map((service, index) => {
            return (
              <div key={index}>
                <Styled.ServiceInformation>
                  <Styled.InfoDetailsRow>
                    <span>{t('userAccount.visitTab.service')}</span>
                    <div>{service.name}</div>
                  </Styled.InfoDetailsRow>
                  <Styled.InfoDetailsRow>
                    <span>{t('userAccount.visitTab.medicalCenter')}</span>
                    <div>
                      {visitDetails.department} {visitDetails.departmentAddress}
                    </div>
                  </Styled.InfoDetailsRow>

                  <Styled.InfoDetailsRow>
                    <span>{t('userAccount.visitTab.date')}</span>
                    <div>
                      {(new Date(
                        Date.parse(visitDetails.date),
                      ).getUTCHours() === 0 && (
                        <Moment utc={true} className="time" format="YYYY-MM-DD">
                          {visitDetails.date}
                        </Moment>
                      )) || (
                        <Moment
                          utc={true}
                          className="time"
                          format="YYYY-MM-DD HH:mm"
                        >
                          {visitDetails.date}
                        </Moment>
                      )}
                    </div>
                  </Styled.InfoDetailsRow>

                  {Array.isArray(service.payments) &&
                    service.payments.some(
                      (x) => x.paymentType !== 'VLK (PSDF)',
                    ) && (
                      <Styled.InfoDetailsRow>
                        <span>{t('userAccount.visitTab.price')}</span>
                        <div>{service.price.toFixed(2)}</div>
                      </Styled.InfoDetailsRow>
                    )}

                  {Array.isArray(service.payments) &&
                    service.payments.some(
                      (x) =>
                        x.paymentType?.toLowerCase() !== 'draudimas' &&
                        x.paymentType !== 'VLK (PSDF)',
                    ) &&
                    service.status && (
                      <Styled.InfoDetailsRow>
                        <span>{t('userAccount.visitTab.state')}</span>
                        <div>
                          {service.status}{' '}
                          {calculateRemainingPaymentPrice(service)}
                        </div>
                      </Styled.InfoDetailsRow>
                    )}

                  <Styled.InfoDetailsRow>
                    {Array.isArray(service.payments) &&
                      service.payments.some((x) => x.paymentType !== null) && (
                        <span>{t('userAccount.visitTab.paymentType')}</span>
                      )}
                  </Styled.InfoDetailsRow>
                  <Styled.PaymentDetailsRow>
                    {Array.isArray(service.payments) &&
                      service.payments.map((payment, index) => {
                        return (
                          <div key={index}>
                            {payment.paymentType !== null && (
                              <Styled.PaymentDetails>
                                <span>{payment.paymentType} </span>
                                {payment.paymentType !== 'VLK (PSDF)' && (
                                  <span> - {payment.price.toFixed(2)} </span>
                                )}
                              </Styled.PaymentDetails>
                            )}
                          </div>
                        );
                      })}
                  </Styled.PaymentDetailsRow>
                </Styled.ServiceInformation>
              </div>
            );
          })) || (
          <div>
            <Styled.ServiceInformation>
              {visitDetails && (
                <Styled.InfoDetailsRow>
                  <span>{t('userAccount.visitTab.service')}</span>
                  <div>{visitDetails.visitTitle.toUpperCase()}</div>
                </Styled.InfoDetailsRow>
              )}

              {visitDetails.doctorName &&
                visitDetails.doctorSpeciality &&
                visitDetails.doctorSurname && (
                  <Styled.InfoDetailsRow>
                    <span> {t('userAccount.registrationsTab.doctor')}</span>
                    <div>
                      {visitDetails.doctorSpeciality.toUpperCase()} -{' '}
                      {visitDetails.doctorName.toUpperCase()}{' '}
                      {visitDetails.doctorSurname.toUpperCase()}
                    </div>
                  </Styled.InfoDetailsRow>
                )}

              {visitDetails && (
                <Styled.InfoDetailsRow>
                  <span>{t('userAccount.visitTab.medicalCenter')}</span>
                  <div>
                    {visitDetails.department} {visitDetails.departmentAddress}
                  </div>
                </Styled.InfoDetailsRow>
              )}

              {visitDetails && (
                <Styled.InfoDetailsRow>
                  <span>{t('userAccount.visitTab.date')}</span>
                  <div>
                    {(new Date(Date.parse(visitDetails.date)).getUTCHours() ===
                      0 && (
                      <Moment utc={true} className="time" format="YYYY-MM-DD">
                        {visitDetails.date}
                      </Moment>
                    )) || (
                      <Moment
                        utc={true}
                        className="time"
                        format="YYYY-MM-DD HH:mm"
                      >
                        {visitDetails.date}
                      </Moment>
                    )}
                  </div>
                </Styled.InfoDetailsRow>
              )}
              {visitDetails && (
                <Styled.InfoDetailsRow>
                  <span>Būsena</span>
                  <div>{visitDetails.statusDisplay}</div>
                </Styled.InfoDetailsRow>
              )}
            </Styled.ServiceInformation>
          </div>
        )}
        {VisitDetails && VisitDetails.appointmentServiceRequest && (
          <div>
            <Styled.TabTitle>
              {t('userAccount.registrationsTab.siuntimas')}
            </Styled.TabTitle>
            <Styled.InfoDetails>
              <Styled.InfoDetailsRow>
                <span>
                  {t('userAccount.registrationsTab.referralData.date')}
                </span>
                <div>
                  {(new Date(
                    Date.parse(VisitDetails.appointmentServiceRequest.date),
                  ).getUTCHours() === 0 && (
                    <Moment utc={true} className="time" format="YYYY-MM-DD">
                      {Date.parse(VisitDetails.appointmentServiceRequest.date)}
                    </Moment>
                  )) || (
                    <Moment
                      utc={true}
                      className="time"
                      format="YYYY-MM-DD HH:mm"
                    >
                      {Date.parse(VisitDetails.appointmentServiceRequest.date)}
                    </Moment>
                  )}
                </div>
              </Styled.InfoDetailsRow>
              <Styled.InfoDetailsRow>
                <span>
                  {t('userAccount.registrationsTab.referralData.institution')}
                </span>
                <div>{VisitDetails.appointmentServiceRequest.institution}</div>
              </Styled.InfoDetailsRow>
              {VisitDetails.appointmentServiceRequest.practitioner && (
                <Styled.InfoDetailsRow>
                  <span>
                    {t('userAccount.registrationsTab.referralData.specialist')}
                  </span>
                  <div>{VisitDetails.appointmentServiceRequest.specialist}</div>
                </Styled.InfoDetailsRow>
              )}
              {VisitDetails.appointmentServiceRequest.file &&
                VisitDetails.appointmentServiceRequest.file.guid && (
                  <Styled.InfoDetailsRow>
                    <a
                      onClick={() => {
                        const fileName =
                          VisitDetails.appointmentServiceRequest.institution +
                          ' ' +
                          moment(
                            new Date(
                              VisitDetails.appointmentServiceRequest.date,
                            ),
                          ).format('YYYY-MM-DD');
                        handleFileDownload(
                          fileName,
                          VisitDetails.appointmentServiceRequest.file.type,
                          VisitDetails.appointmentServiceRequest.file.guid,
                        );
                      }}
                    >
                      ATSISIŲSTI PDF
                    </a>
                  </Styled.InfoDetailsRow>
                )}
            </Styled.InfoDetails>
          </div>
        )}
        {VisitDetails &&
          Array.isArray(VisitDetails.e025) &&
          VisitDetails.e025.length > 0 && (
            <div>
              <Styled.TabTitle ref={e025Ref}>
                Ambulatorinio apsilankymo aprašymas
              </Styled.TabTitle>
              <Styled.InfoDetails>
                {VisitDetails.e025.map((e025, index) => {
                  return (
                    <div key={index}>
                      <Styled.InfoDetailsRow>
                        <span>Data:</span>
                        <div>
                          {(new Date(Date.parse(e025.date)).getUTCHours() ===
                            0 && (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD"
                            >
                              {Date.parse(e025.date)}
                            </Moment>
                          )) || (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD HH:mm"
                            >
                              {Date.parse(e025.date)}
                            </Moment>
                          )}
                        </div>
                      </Styled.InfoDetailsRow>
                      <Styled.InfoDetailsRow>
                        <span>Laikas:</span>
                        <Moment
                          utc={true}
                          className="time"
                          parse="HH:mm"
                          format="HH:mm"
                        >
                          {e025.time}
                        </Moment>
                      </Styled.InfoDetailsRow>
                      <Styled.InfoDetailsRow>
                        <span>Gydytojas:</span>
                        <div>
                          {e025.practitioner.qualification} -{' '}
                          {e025.practitioner.name} {e025.practitioner.surname}
                        </div>
                      </Styled.InfoDetailsRow>

                      <Styled.VisitDetailsRow>
                        <span>Nusiskundimai ir anamnezė:</span>
                        {e025.complaints &&
                          e025.complaints
                            .split(/\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                      </Styled.VisitDetailsRow>

                      <Styled.VisitDetailsRow>
                        <span>Objektyvi būklė:</span>
                        {e025.objectiveCondition &&
                          e025.objectiveCondition
                            .split(/\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                      </Styled.VisitDetailsRow>

                      <Styled.VisitDetailsRow>
                        <span>Atlikti tyrimai ir konsultacijos:</span>
                        {e025.finishedDiagnosticReports &&
                          e025.finishedDiagnosticReports
                            .split(/;|\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                      </Styled.VisitDetailsRow>

                      <Styled.VisitDetailsRow>
                        <span>Tyrimų ir konsultacijų planas:</span>
                        {e025.plannedDiagnosticReports &&
                          e025.plannedDiagnosticReports
                            .split(/\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                      </Styled.VisitDetailsRow>
                      <Styled.VisitDetailsRow>
                        <span>Taikytas gydymas:</span>
                        {e025.carePlan &&
                          e025.carePlan
                            .split(/\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                        {!e025.carePlan && <br />}
                      </Styled.VisitDetailsRow>
                      <Styled.VisitDetailsRow>
                        <span>Taikytas medikamentinis gydymas:</span>
                        {e025.medicationStatement &&
                          e025.medicationStatement
                            .split(/\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                        {!e025.medicationStatement && <br />}
                      </Styled.VisitDetailsRow>
                      <Styled.VisitDetailsRow>
                        <span>Rekomendacijos:</span>
                        {e025.recommendations &&
                          e025.recommendations
                            .split(/\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                      </Styled.VisitDetailsRow>
                      {e025.file && e025.file.guid && (
                        <Styled.InfoDetailsRow>
                          <a
                            onClick={() => {
                              const fileName =
                                e025.practitioner.qualification +
                                '-' +
                                e025.practitioner.name +
                                ' ' +
                                e025.practitioner.surname +
                                ' ' +
                                moment(new Date(e025.date)).format(
                                  'YYYY-MM-DD',
                                );
                              handleFileDownload(
                                'e025',
                                fileName,
                                e025.file.type,
                                e025.file.guid,
                              );
                            }}
                          >
                            ATSISIŲSTI PDF
                          </a>
                        </Styled.InfoDetailsRow>
                      )}
                    </div>
                  );
                })}
              </Styled.InfoDetails>
            </div>
          )}
        {VisitDetails &&
          Array.isArray(VisitDetails.medicationStatements) &&
          VisitDetails.medicationStatements.length > 0 && (
            <div>
              <Styled.TabTitle ref={prescriptionsRef}>Receptai</Styled.TabTitle>
              <Styled.InfoDetails>
                {VisitDetails.medicationStatements.map(
                  (prescription, index) => {
                    return (
                      <div key={index}>
                        <Styled.InfoDetailsRow>
                          <span>Data:</span>
                          <div>
                            {(new Date(
                              Date.parse(prescription.dateAsserted),
                            ).getUTCHours() === 0 && (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD"
                              >
                                {Date.parse(prescription.dateAsserted)}
                              </Moment>
                            )) || (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD HH:mm"
                              >
                                {Date.parse(prescription.dateAsserted)}
                              </Moment>
                            )}
                          </div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Pavadinimas:</span>
                          <div>{prescription.name}</div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Diagnozė:</span>
                          <div>{prescription.Reason}</div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Kompensacijos rūšis:</span>
                          <div>
                            {prescription.compensation === null
                              ? 'Nekompensuojama'
                              : prescription.compensation}
                          </div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Dozavimo rekomendacijos:</span>
                          {(prescription.dosage && (
                            <div>{prescription.dosage}</div>
                          )) || <div>-</div>}
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Galioja nuo:</span>
                          <div>
                            {(new Date(
                              Date.parse(prescription.effectiveStartDate),
                            ).getUTCHours() === 0 && (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD"
                              >
                                {Date.parse(prescription.effectiveStartDate)}
                              </Moment>
                            )) || (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD HH:mm"
                              >
                                {Date.parse(prescription.effectiveStartDate)}
                              </Moment>
                            )}
                          </div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Galioja iki:</span>
                          <div>
                            {(new Date(
                              Date.parse(prescription.effectiveEndDate),
                            ).getUTCHours() === 0 && (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD"
                              >
                                {Date.parse(prescription.effectiveEndDate)}
                              </Moment>
                            )) || (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD HH:mm"
                              >
                                {Date.parse(prescription.effectiveEndDate)}
                              </Moment>
                            )}
                          </div>
                        </Styled.InfoDetailsRow>
                        {prescription.file && prescription.file.guid && (
                          <Styled.InfoDetailsRow>
                            <a
                              onClick={() => {
                                const fileName =
                                  prescription.name +
                                  ' ' +
                                  moment(new Date(prescription.date)).format(
                                    'YYYY-MM-DD',
                                  );
                                handleFileDownload(
                                  'Prescription',
                                  fileName,
                                  prescription.file.type,
                                  prescription.file.guid,
                                );
                              }}
                            >
                              ATSISIŲSTI PDF
                            </a>
                          </Styled.InfoDetailsRow>
                        )}
                      </div>
                    );
                  },
                )}
              </Styled.InfoDetails>
            </div>
          )}
        {VisitDetails &&
          Array.isArray(VisitDetails.serviceRequests) &&
          VisitDetails.serviceRequests.length > 0 && (
            <div>
              <Styled.TabTitle ref={referralsRef}>Siuntimai</Styled.TabTitle>
              <Styled.InfoDetails>
                {VisitDetails.serviceRequests.map((referral, index) => {
                  return (
                    <div key={index}>
                      <Styled.InfoDetailsRow>
                        <span>Data:</span>
                        <div>
                          {(new Date(
                            Date.parse(referral.date),
                          ).getUTCHours() === 0 && (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD"
                            >
                              {Date.parse(referral.date)}
                            </Moment>
                          )) || (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD HH:mm"
                            >
                              {Date.parse(referral.date)}
                            </Moment>
                          )}
                        </div>
                      </Styled.InfoDetailsRow>
                      <Styled.InfoDetailsRow>
                        <span>Gydytojas:</span>
                        <div>
                          {referral.doctorSpeciality} - {referral.doctorName}{' '}
                          {referral.doctorSurname}
                        </div>
                      </Styled.InfoDetailsRow>
                      <Styled.InfoDetailsRow>
                        <span>Diagnozė:</span>
                        <div>{referral.diagnosis}</div>
                      </Styled.InfoDetailsRow>
                      <Styled.InfoDetailsRow>
                        <span>Kokiam specialistui siuntimas parašytas:</span>
                        <div>{referral.specialist}</div>
                      </Styled.InfoDetailsRow>
                      <Styled.VisitDetailsRow>
                        <span>Siuntimo tikslas:</span>
                        {referral.purpose &&
                          referral.purpose
                            .split(/\r?\n|<br\/>/)
                            .map((text, index) => {
                              return <div key={index}> {text} </div>;
                            })}
                      </Styled.VisitDetailsRow>
                      <Styled.InfoDetailsRow>
                        <span>Galioja iki:</span>
                        <div>
                          {(new Date(
                            Date.parse(referral.validUntil),
                          ).getUTCHours() === 0 && (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD"
                            >
                              {Date.parse(referral.validUntil)}
                            </Moment>
                          )) || (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD HH:mm"
                            >
                              {Date.parse(referral.validUntil)}
                            </Moment>
                          )}
                        </div>
                      </Styled.InfoDetailsRow>
                      {referral.file && referral.file.guid && (
                        <Styled.InfoDetailsRow>
                          <a
                            onClick={() => {
                              const fileName =
                                referral.doctorSpeciality +
                                '-' +
                                referral.doctorName +
                                ' ' +
                                referral.doctorSurname +
                                ' ' +
                                moment(new Date(referral.date)).format(
                                  'YYYY-MM-DD',
                                );
                              handleFileDownload(
                                'Referral',
                                fileName,
                                referral.file.type,
                                referral.file.guid,
                              );
                            }}
                          >
                            ATSISIŲSTI PDF
                          </a>
                        </Styled.InfoDetailsRow>
                      )}
                    </div>
                  );
                })}
              </Styled.InfoDetails>
            </div>
          )}
        {VisitDetails &&
          Array.isArray(VisitDetails.instrumentalDiagnosticReports) &&
          VisitDetails.instrumentalDiagnosticReports.length > 0 && (
            <div>
              <Styled.TabTitle ref={instrumentalResearchesRef}>
                Instrumentiniai tyrimai:
              </Styled.TabTitle>
              <Styled.InfoDetails>
                {VisitDetails.instrumentalDiagnosticReports.map(
                  (instrumentalResearch, index) => {
                    return (
                      <div key={index}>
                        <Styled.InfoDetailsRow>
                          <span>Data:</span>
                          <div>
                            {(new Date(
                              Date.parse(instrumentalResearch.date),
                            ).getUTCHours() === 0 && (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD"
                              >
                                {Date.parse(instrumentalResearch.date)}
                              </Moment>
                            )) || (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD HH:mm"
                              >
                                {Date.parse(instrumentalResearch.date)}
                              </Moment>
                            )}
                          </div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Pavadinimas:</span>
                          <div>{instrumentalResearch.name}</div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Įranga:</span>
                          <div>{instrumentalResearch.equipment}</div>
                        </Styled.InfoDetailsRow>
                        <Styled.List style={{ marginTop: '10px' }}>
                          <span> Instrumentinio tyrimo kontrastas:</span>
                        </Styled.List>
                        {(instrumentalResearch.instrumentalResearchContrast && (
                          <div>
                            <Styled.ListItemRow>
                              <span>Pavadinimas:</span>
                              <div>{instrumentalResearch.contrast.name}</div>
                            </Styled.ListItemRow>
                            <Styled.ListItemRow>
                              <span>Tipas:</span>
                              <div>{instrumentalResearch.contrast.type}</div>
                            </Styled.ListItemRow>
                            <Styled.ListItemRow>
                              <span>Serijinis numeris:</span>
                              <div>
                                {instrumentalResearch.contrast.serialNumber}
                              </div>
                            </Styled.ListItemRow>
                            <Styled.ListItemRow>
                              <span>Kiekis:</span>
                              <div>{instrumentalResearch.contrast.value}</div>
                            </Styled.ListItemRow>
                            <Styled.ListItemRow>
                              <span>Matavimo vienetas:</span>
                              <div>{instrumentalResearch.contrast.unit}</div>
                            </Styled.ListItemRow>
                          </div>
                        )) || (
                          <Styled.ListItemRow>
                            <div>Netaikytas</div>
                          </Styled.ListItemRow>
                        )}
                        {instrumentalResearch.observations?.length > 0 &&
                          instrumentalResearch.observations.map(
                            (result, idx) => {
                              return (
                                <div key={idx}>
                                  <Styled.ServiceTitle
                                    style={{ marginTop: '25px' }}
                                  >
                                    Instrumentinių tyrimu vertinimai:
                                  </Styled.ServiceTitle>
                                  <Styled.InfoDetailsRow>
                                    <span>Data:</span>
                                    <div>
                                      {(new Date(
                                        Date.parse(result.date),
                                      ).getUTCHours() === 0 && (
                                        <Moment
                                          utc={true}
                                          className="time"
                                          format="YYYY-MM-DD"
                                        >
                                          {Date.parse(result.date)}
                                        </Moment>
                                      )) || (
                                        <Moment
                                          utc={true}
                                          className="time"
                                          format="YYYY-MM-DD HH:mm"
                                        >
                                          {Date.parse(result.date)}
                                        </Moment>
                                      )}
                                    </div>
                                  </Styled.InfoDetailsRow>
                                  <Styled.InfoDetailsRow>
                                    <span>Dokumento numeris:</span>
                                    <div>{result.documentNumber}</div>
                                  </Styled.InfoDetailsRow>
                                  <Styled.InfoDetailsRow>
                                    <span>Radiologo vardas:</span>
                                    <div>{result.radiologist.name}</div>
                                  </Styled.InfoDetailsRow>
                                  <Styled.InfoDetailsRow>
                                    <span>Radiologo pavardė:</span>
                                    <div>{result.radiologist.surname}</div>
                                  </Styled.InfoDetailsRow>
                                  <Styled.InfoDetailsRow>
                                    <span>Radiologo tecchnologo vardas:</span>
                                    <div>
                                      {result.radiologyTechnologist.name}
                                    </div>
                                  </Styled.InfoDetailsRow>
                                  <Styled.InfoDetailsRow>
                                    <span>Radiologo tecchnologo pavardė:</span>
                                    <div>
                                      {result.radiologyTechnologist.surname}
                                    </div>
                                  </Styled.InfoDetailsRow>
                                  <Styled.InfoDetailsRow>
                                    <span>Išvados:</span>
                                    <div>{result.value}</div>
                                  </Styled.InfoDetailsRow>
                                  <Styled.InfoDetailsRow>
                                    <span>Aprašymas:</span>
                                    <div>{result.description}</div>
                                  </Styled.InfoDetailsRow>
                                  {result.file && result.file.guid && (
                                    <Styled.InfoDetailsRow>
                                      <a
                                        onClick={() => {
                                          const fileName =
                                            'Insttrumentinis tyrimas ' +
                                            result.docNumber +
                                            ' ' +
                                            moment(
                                              new Date(result.date),
                                            ).format('YYYY-MM-DD');
                                          handleFileDownload(
                                            'InstrumentalResearches',
                                            fileName,
                                            result.file.type,
                                            result.file.guid,
                                          );
                                        }}
                                      >
                                        ATSISIŲSTI PDF
                                      </a>
                                    </Styled.InfoDetailsRow>
                                  )}
                                </div>
                              );
                            },
                          )}
                      </div>
                    );
                  },
                )}
              </Styled.InfoDetails>
            </div>
          )}
        {VisitDetails &&
          Array.isArray(VisitDetails.immunizations) &&
          VisitDetails.immunizations.length > 0 && (
            <div>
              <Styled.TabTitle ref={vaccinationRef}>Skiepai: </Styled.TabTitle>
              <Styled.InfoDetails>
                {VisitDetails.immunizations.map((vaccination, index) => {
                  return (
                    <div key={index}>
                      <Styled.InfoDetailsRow>
                        <span>Data:</span>
                        <div>
                          {(new Date(
                            Date.parse(vaccination.date),
                          ).getUTCHours() === 0 && (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD"
                            >
                              {Date.parse(vaccination.date)}
                            </Moment>
                          )) || (
                            <Moment
                              utc={true}
                              className="time"
                              format="YYYY-MM-DD HH:mm"
                            >
                              {Date.parse(vaccination.date)}
                            </Moment>
                          )}
                        </div>
                      </Styled.InfoDetailsRow>
                      {vaccination.practitioner && (
                        <Styled.InfoDetailsRow>
                          <span>Gydytojas:</span>
                          <div>
                            {vaccination.practitioner.qualification} -{' '}
                            {vaccination.practitioner.name}{' '}
                            {vaccination.practitioner.surname}
                          </div>
                        </Styled.InfoDetailsRow>
                      )}
                      {vaccination.diagnosis && (
                        <Styled.InfoDetailsRow>
                          <span>Pagrindinė diagnozė:</span>
                          <div>{vaccination.targetDisease}</div>
                        </Styled.InfoDetailsRow>
                      )}

                      {vaccination.e063Status.toLowerCase() !==
                        'pasirasytas' && (
                        <Styled.InfoDetailsRow>
                          <span>LAUKIAMA REZULTATŲ</span>
                        </Styled.InfoDetailsRow>
                      )}

                      {vaccination.doseQuantity && (
                        <Styled.InfoDetailsRow>
                          <span>Dozė:</span>
                          <div>{vaccination.doseQuantity}</div>
                        </Styled.InfoDetailsRow>
                      )}
                      {vaccination.vaccineCode && (
                        <Styled.InfoDetailsRow>
                          <span>Serijos numeris:</span>
                          <div>{vaccination.vaccineCode}</div>
                        </Styled.InfoDetailsRow>
                      )}
                      {vaccination.name && (
                        <Styled.InfoDetailsRow>
                          <span>Vakcinos pavadinimas:</span>
                          <div>{vaccination.name}</div>
                        </Styled.InfoDetailsRow>
                      )}
                      {vaccination.manufacturer && (
                        <Styled.InfoDetailsRow>
                          <span>Gamintojas:</span>
                          <div>{vaccination.manufacturer}</div>
                        </Styled.InfoDetailsRow>
                      )}

                      {vaccination.nextImmunizationDate && (
                        <Styled.InfoDetailsRow>
                          <span>Kitos vakcinos data:</span>
                          <div>
                            {(new Date(
                              Date.parse(vaccination.nextImmunizationDate),
                            ).getUTCHours() === 0 && (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD"
                              >
                                {Date.parse(vaccination.nextImmunizationDate)}
                              </Moment>
                            )) || (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD HH:mm"
                              >
                                {Date.parse(vaccination.nextImmunizationDate)}
                              </Moment>
                            )}
                          </div>
                        </Styled.InfoDetailsRow>
                      )}
                      {vaccination.file && vaccination.file.guid && (
                        <Styled.InfoDetailsRow>
                          <a
                            onClick={() => {
                              const fileName =
                                vaccination.vaccine +
                                ' ' +
                                moment(new Date(vaccination.date)).format(
                                  'YYYY-MM-DD',
                                );
                              handleFileDownload(
                                'Vaccination',
                                fileName,
                                vaccination.file.type,
                                vaccination.file.guid,
                              );
                            }}
                          >
                            ATSISIŲSTI PDF
                          </a>
                        </Styled.InfoDetailsRow>
                      )}
                    </div>
                  );
                })}
              </Styled.InfoDetails>
            </div>
          )}
        {VisitDetails &&
          Array.isArray(VisitDetails.diagnosticReports) &&
          VisitDetails.diagnosticReports.length > 0 && (
            <div>
              <Styled.TabTitle ref={laboratoryResearchesRef}>
                Laboratoriniai tyrimai:
              </Styled.TabTitle>
              <Styled.InfoDetails>
                {VisitDetails.diagnosticReports.map(
                  (labaratoryResearch, index) => {
                    return (
                      <div key={index}>
                        <Styled.InfoDetailsRow>
                          <span>Data:</span>
                          <div>
                            {(new Date(
                              Date.parse(labaratoryResearch.orderDate),
                            ).getUTCHours() === 0 && (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD"
                              >
                                {Date.parse(labaratoryResearch.orderDate)}
                              </Moment>
                            )) || (
                              <Moment
                                utc={true}
                                className="time"
                                format="YYYY-MM-DD HH:mm"
                              >
                                {Date.parse(labaratoryResearch.orderDate)}
                              </Moment>
                            )}
                          </div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Pavadinimas:</span>
                          <div>{labaratoryResearch.name}</div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Būsena:</span>
                          <div>{labaratoryResearch.statusDisplay}</div>
                        </Styled.InfoDetailsRow>
                        <Styled.InfoDetailsRow>
                          <span>Gydytojas:</span>
                          <div>
                            {labaratoryResearch.requestedDoctorNameSurname}
                          </div>
                        </Styled.InfoDetailsRow>
                        <Styled.List style={{ marginTop: '15px' }}>
                          Rezultatai:
                        </Styled.List>
                        {!labaratoryResearch.observations ||
                          (labaratoryResearch.observations?.length === 0 && (
                            <div>-</div>
                          ))}
                        {labaratoryResearch.observations?.length > 0 &&
                          labaratoryResearch.observations.map(
                            (result, index) => {
                              return (
                                <div key={index} style={{ marginTop: '15px' }}>
                                  <Styled.ListItemRow>
                                    <span>Pavadinimas:</span>
                                    <div>{result.name}</div>
                                  </Styled.ListItemRow>
                                  {result.referenceRange && (
                                    <Styled.ListItemRow>
                                      <span>Norma:</span>
                                      <div>{result.referenceRange}</div>
                                    </Styled.ListItemRow>
                                  )}
                                  <Styled.ListItemRow>
                                    <span>Rezultatas:</span>
                                    <div>{result.value}</div>
                                  </Styled.ListItemRow>
                                  <Styled.ListItemRow>
                                    <span>Matavimo vienetas:</span>
                                    <div>{result.unit}</div>
                                  </Styled.ListItemRow>
                                </div>
                              );
                            },
                          )}
                        {labaratoryResearch.file &&
                          labaratoryResearch.file.guid && (
                            <Styled.InfoDetailsRow>
                              <a
                                onClick={() => {
                                  const fileName =
                                    labaratoryResearch.name +
                                    ' ' +
                                    moment(
                                      new Date(labaratoryResearch.date),
                                    ).format('YYYY-MM-DD');
                                  handleFileDownload(
                                    'LabaratoryResearch',
                                    fileName,
                                    labaratoryResearch.file.type,
                                    labaratoryResearch.file.guid,
                                  );
                                }}
                              >
                                ATSISIŲSTI PDF
                              </a>
                            </Styled.InfoDetailsRow>
                          )}
                      </div>
                    );
                  },
                )}
              </Styled.InfoDetails>
            </div>
          )}
      </Styled.DataContainer>
    </Page>
  );
};
export default VisitDetailsComponent;
