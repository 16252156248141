/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import useFetch from 'use-http';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const VaccinationDetails = () => {
  const history = useHistory();
  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');
  const vaccination = history.location.state?.data;
  const status = history.location?.state?.status;

  const handleFileDownload = async () => {
    const res = await getFile(
      `?fileType=${vaccination.file.type}&fileGuid=${
        vaccination.file.guid
      }&category=${'Vaccination'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download =
        vaccination.name +
        ' ' +
        moment(new Date(vaccination.occurrenceDateTime)).format('YYYY-MM-DD') +
        '.pdf';

      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>Skiepai</Styled.VisitTitle>
      </Styled.BackArrow>
      <Styled.InfoDetailsRow>
        <span>Data:</span>
        <div>
          {(new Date(
            Date.parse(vaccination.occurrenceDateTime),
          ).getUTCHours() === 0 && (
            <Moment utc={true} className="time" format="YYYY-MM-DD">
              {Date.parse(vaccination.occurrenceDateTime)}
            </Moment>
          )) || (
            <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
              {Date.parse(vaccination.occurrenceDateTime)}
            </Moment>
          )}
        </div>
      </Styled.InfoDetailsRow>
      {vaccination.practitioner?.name && (
        <Styled.InfoDetailsRow>
          <span>Gydytojas:</span>
          <div>
            {vaccination.practitioner?.qualification} -{' '}
            {vaccination.practitioner?.name} {vaccination.practitioner?.surname}
          </div>
        </Styled.InfoDetailsRow>
      )}
      {vaccination.diagnosis && (
        <Styled.InfoDetailsRow>
          <span>Pagrindinė diagnozė:</span>
          <div>{vaccination.diagnosis}</div>
        </Styled.InfoDetailsRow>
      )}

      {vaccination.targetDisease && (
        <Styled.InfoDetailsRow>
          <span>Liga:</span>
          <div>{vaccination.targetDisease}</div>
        </Styled.InfoDetailsRow>
      )}

      {vaccination.e063StatusDisplay.toLowerCase() !== 'pasirašytas' && (
        <Styled.InfoDetailsRow>
          <span>LAUKIAMA REZULTATŲ</span>
        </Styled.InfoDetailsRow>
      )}

      {vaccination.doseQuantity && (
        <Styled.InfoDetailsRow>
          <span>Dozė:</span>
          <div>{vaccination.doseQuantity}</div>
        </Styled.InfoDetailsRow>
      )}
      {vaccination.lotNumber && (
        <Styled.InfoDetailsRow>
          <span>Serijos numeris:</span>
          <div>{vaccination.lotNumber}</div>
        </Styled.InfoDetailsRow>
      )}
      {vaccination.vaccine && (
        <Styled.InfoDetailsRow>
          <span>Vakcinos pavadinimas:</span>
          <div>{vaccination.vaccine}</div>
        </Styled.InfoDetailsRow>
      )}
      {vaccination.manufacturer && (
        <Styled.InfoDetailsRow>
          <span>Gamintojas:</span>
          <div>{vaccination.manufacturer}</div>
        </Styled.InfoDetailsRow>
      )}

      {vaccination.nextImmunizationDate && (
        <Styled.InfoDetailsRow>
          <span>Kitos vakcinos data:</span>
          <div>
            {(new Date(
              Date.parse(vaccination.nextVaccinationDate),
            ).getUTCHours() === 0 && (
              <Moment utc={true} className="time" format="YYYY-MM-DD">
                {Date.parse(vaccination.nextImmunizationDate)}
              </Moment>
            )) || (
              <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
                {Date.parse(vaccination.nextImmunizationDate)}
              </Moment>
            )}
          </div>
        </Styled.InfoDetailsRow>
      )}

      {vaccination.file && vaccination.file.guid && (
        <Styled.InfoDetailsRow>
          <a onClick={handleFileDownload}>ATSISIŲSTI PDF</a>
        </Styled.InfoDetailsRow>
      )}
    </Styled.DataContainer>
  );
};
export default VaccinationDetails;
