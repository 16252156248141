import React, { useState, useEffect } from 'react';
import { Document, Page as PDFPage, pdfjs } from 'react-pdf';
import { useHistory, Redirect } from 'react-router-dom';
import useFetch from 'use-http';
import { useTranslation } from 'react-i18next';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useTransferState } from '../../context/TransferContext';
import Container from '../../shared/Container';
import Loader from '../../shared/Loader';
import Page from '../../shared/Page';
import Button from '../../shared/Button';
import './TransferPreviewPage.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let statusTimeout = null;

const TransferPreviewPage = () => {
  const history = useHistory();
  const transferState = useTransferState();
  const { t } = useTranslation();

  const {
    post: signIn,
    get: requestStatus,
    response: signInResponse,
  } = useFetch(`${process.env.REACT_APP_SIGN_API_BASE}/Sign`);

  const {
    post: mobileSignIn,
    get: mobileRequestStatus,
    response: mobileSignInResponse,
  } = useFetch(`${process.env.REACT_APP_SIGN_API_BASE}/Mobile/Sign`);

  const [smartIdVisible, setSmartIdVisible] = useState(null);
  const [mobileIdVisible, setMobileIdVisible] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [controlCode, setControlCode] = useState(null);
  const [errors, setErrors] = useState(null);
  const [numPages, setNumPages] = useState(1);

  useEffect(
    () => () => {
      if (statusTimeout) {
        clearTimeout(statusTimeout);
      }
    },
    [],
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleBackClick = () => {
    history.push('/transfer-request', { from: 'preview' });
  };
  const formType = history.location?.state?.type;

  const getStatus = async (token) => {
    const params = {
      token,
      phoneNumber: transferState.phoneNumber,
      companyId: transferState.companyId,
      email: transferState.email,
      specialistId: transferState.specialistId,
      centerSpecialistId: transferState.centerSpecialistId,
      centerId: transferState.centerId,
      formType: transferState.formType,
      surname: transferState.lastName,
    };

    let qsk = Object.keys(params).filter((key) => params[key]);
    const qs = qsk
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    await requestStatus(`?${qs}`);

    const response = await signInResponse.json();
    if (signInResponse.status === 200) {
      if (response.status === 'waiting') {
        return new Promise(async (resolve) => {
          statusTimeout = setTimeout(async () => {
            resolve(await getStatus(token));
          }, 1000);
        });
      }

      if (response.status === 'ok') {
        return response;
      }

      return response;
    }
  };

  const handleSmartIdSignIn = async () => {
    setLoading(true);

    const reader = new FileReader();

    if (!transferState.pdf) {
      return null;
    }

    reader.readAsDataURL(transferState.pdf);
    reader.onloadend = async () => {
      await signIn('', {
        file: reader.result.split(',')[1],
        personalCode: transferState.personalCode,
      });

      const response = await signInResponse.json();

      if (signInResponse.status === 200 && response.hasSucceeded) {
        setSmartIdVisible(true);
        setControlCode(response.controlCode);
        const status = await getStatus(response.token);

        if (status.hasSucceeded && status.status === 'ok') {
          history.push('/transfer-request/success');
        } else {
          setSmartIdVisible(false);
          setErrors({
            main: status.message,
          });
          setControlCode(null);
          setLoading(false);
        }
      } else {
        let rerrors = {};

        if (response.message) {
          rerrors.main = response.message;
        } else {
          rerrors = response.errors;
        }

        setLoading(false);
        setErrors(rerrors);
      }
    };
  };

  const mobileGetStatus = async (token) => {
    const params = {
      token,
      phoneNumber: transferState.phoneNumber,
      companyId: transferState.companyId,
      email: transferState.email,
      specialistId: transferState.specialistId,
      centerSpecialistId: transferState.centerSpecialistId,
      centerId: transferState.centerId,
      formType: transferState.formType,
      surname: transferState.lastName,
    };

    let qsk = Object.keys(params).filter((key) => params[key]);
    const qs = qsk
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');

    await mobileRequestStatus(`?${qs}`);

    const response = await mobileSignInResponse.json();

    if (mobileSignInResponse.status === 200) {
      if (response.status === 'waiting') {
        return new Promise(async (resolve) => {
          statusTimeout = setTimeout(async () => {
            resolve(await mobileGetStatus(token));
          }, 1000);
        });
      }

      if (response.status === 'ok') {
        return response;
      }

      return response;
    }
  };

  const handleMobileIdSignIn = async () => {
    setLoading(true);

    const reader = new FileReader();

    if (!transferState.pdf) {
      return null;
    }

    reader.readAsDataURL(transferState.pdf);
    reader.onloadend = async () => {
      await mobileSignIn('', {
        file: reader.result.split(',')[1],
        personalCode: transferState.personalCode,
        phoneNumber: transferState.phoneNumber,
      });

      const response = await mobileSignInResponse.json();

      if (mobileSignInResponse.status === 200 && response.hasSucceeded) {
        setMobileIdVisible(true);
        setControlCode(response.controlCode);

        const status = await mobileGetStatus(response.token);
        if (status.hasSucceeded && status.status === 'ok') {
          history.push('/transfer-request/success');
        } else {
          setMobileIdVisible(false);
          setErrors({
            main: status.message,
          });
          setControlCode(null);
          setLoading(false);
        }
      } else {
        let rerrors = {};

        if (response.message) {
          rerrors.main = response.message;
        } else {
          rerrors = response.errors;
        }

        setLoading(false);
        setErrors(rerrors);
      }
    };
  };

  if (Object.keys(transferState).length === 0) {
    return <Redirect to="/transfer-request" />;
  }

  return (
    <Page loading={false}>
      <Container className="transfer-preview-page" narrow={true}>
        {formType && formType == 1 ? (
          <h1>{t('transfer.title')}</h1>
        ) : (
          <h1>{t('transfer.secondaryTitle')}</h1>
        )}

        <div className="transfer-preview-page--pdf mb-4">
          <div className="tac mb-2">
            <strong>{t('transfer.fileTitle')}</strong>
          </div>

          <Document
            file={transferState.pdf}
            loading={<Loader />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <PDFPage
              scale={1}
              width={660}
              loading={<Loader />}
              pageNumber={numPages}
            />
          </Document>
        </div>

        {errors && (
          <div className="mb-4 tac error">
            {Object.keys(errors).map((errorKey) => {
              return (
                <div key={`error_${errorKey}`}>
                  {Array.isArray(errors[errorKey])
                    ? errors[errorKey].join(' ')
                    : errors[errorKey]}
                </div>
              );
            })}
          </div>
        )}

        {smartIdVisible && (
          <div className="transfer-preview-page--signin">
            <div className="tac mb-2">
              <strong>{t('transfer.smartIdTitle')}</strong>
            </div>

            <div className="transfer-preview-page--signin-content">
              <div>
                {t('transfer.controlCode')}
                <div className="transfer-preview-page--signin-control-number">
                  {controlCode}
                </div>
              </div>

              <p>{t('transfer.smartIdDescription')}</p>
            </div>
            <div className="d-flex justify-center">
              <Button
                onClick={handleBackClick}
                size="large"
                variant="secondary"
              >
                {t('transfer.cancelSignIn')}
              </Button>
            </div>
          </div>
        )}
        {mobileIdVisible && (
          <div className="transfer-preview-page--signin">
            <div className="tac mb-2">
              <strong>{t('transfer.smartIdTitle')}</strong>
            </div>

            <div className="transfer-preview-page--signin-content">
              <div>
                {t('transfer.controlCode')}
                <div className="transfer-preview-page--signin-control-number">
                  {controlCode}
                </div>
              </div>

              <p>{t('transfer.smartIdDescription')}</p>
            </div>
            <div className="d-flex justify-center">
              <Button
                onClick={handleBackClick}
                size="large"
                variant="secondary"
              >
                {t('transfer.cancelSignIn')}
              </Button>
            </div>
          </div>
        )}
        {!smartIdVisible && !mobileIdVisible && (
          <>
            <div className="d-flex justify-center mb-35">
              <Button
                loading={isLoading}
                onClick={handleSmartIdSignIn}
                size="huge"
              >
                {t('transfer.singWithSmartId')}
              </Button>
            </div>

            <div className="d-flex justify-center mb-35">
              <Button
                loading={isLoading}
                onClick={handleMobileIdSignIn}
                size="huge"
              >
                {t('transfer.singWithMobileId')}
              </Button>
            </div>
            <div className="d-flex justify-center">
              <Button
                onClick={handleBackClick}
                size="large"
                variant="secondary"
              >
                {t('transfer.backToForm')}
              </Button>
            </div>
          </>
        )}
      </Container>
    </Page>
  );
};

export default TransferPreviewPage;
