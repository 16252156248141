/* eslint-disable jsx-a11y/anchor-is-valid */
import useFetch from 'use-http';
import React, { useEffect, useState, useRef } from 'react';
import Page from '../../../../../shared/Page';
import Styled from '../../UserAccountPage.styles';
import Loader from '../../../../../shared/Loader';
import Button from '../../../../../shared/Button/Button';
import { AiOutlineEdit } from 'react-icons/ai';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { margin, padding } from 'polished';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext/UserContext';

const InsuranceCardList = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [data, setData] = useState();
  const [fullData, setFullData] = useState();
  const [reload, setReload] = useState(false);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
  const [insurances, setInsurances] = useState([]);
  const [serviceDescriptionLength, setInsurancesLength] = useState(0);
  const { get: getInsurances } = useFetch(`/UserInsurance`);
  const [search, setSearch] = useState('');
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();

  const updateReload = useRef();

  useEffect(() => {
    setSearch('');
    setReload((prev) => !prev);
  }, []);

  useEffect(() => {
    setInsurances(data?.slice(0, serviceDescriptionLength));
  }, [data, serviceDescriptionLength]);

  useEffect(() => {
    const fetchData = async () => {
      await getInsurances().then((res) => {
        let sortedRes = res?.sort((a, b) =>
          a.insuranceCompanyDisplayName < b.insuranceCompanyDisplayName
            ? -1
            : 1,
        );
        setFullData(sortedRes ?? []);
        setData(sortedRes ?? []);
        if (updateReload.current) {
          setInsurances(sortedRes?.slice(0, serviceDescriptionLength));
          updateReload.current = false;
          setLoading(false);
          return;
        }
        setInsurances(sortedRes?.slice(0, 50));
        setInsurancesLength(50);
        setLoading(false);
        sessionDispatch({
          type: 'UPDATE',
          data: {
            insurances: sortedRes,
          },
        });
      });
    };
    fetchData();
  }, [reload]);

  useEffect(() => {
    if (!search) {
      setData(fullData);
    }
    const filteredData = fullData?.filter((item) => {
      return item.insuranceCompanyDisplayName
        .toLowerCase()
        .includes(search.toLowerCase());
    });
    setData(filteredData);
  }, [search]);

  const clearSearchField = () => {
    setSearch('');
  };

  return (
    <Page>
      <Styled.DataContainer>
        <Styled.TabTitleWrapper>
          <Styled.TabTitle>Privataus draudimo kortelės</Styled.TabTitle>
          <form
            autocomplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              setSearch(e.target.search.value);
            }}
            onChange={(e) => {
              if (
                e.target.value === undefined ||
                e.target.value === null ||
                e.target.value === ''
              ) {
                setSearch(e.target.value);
              }
            }}
            style={{ display: 'flex' }}
          >
            <input
              type="text"
              id="search"
              placeholder="Paieška"
              name="search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
            <Button
              style={{ marginRight: '15px', fontSize: '16px' }}
              size="small"
              type="submit"
              onClick={clearSearchField}
            >
              Išvalyti
            </Button>
          </form>
        </Styled.TabTitleWrapper>

        {loading && (
          <Page>
            <Loader />
          </Page>
        )}
        <Styled.HeaderLegend>
          <Styled.Visit>
            <Styled.DoctorInfoListRecord>
              <Styled.DoctorName style={{ marginTop: '5px' }}>
                Įmonės pavadinimas
              </Styled.DoctorName>
              <Styled.DoctorName>Kortelės numeris</Styled.DoctorName>
              <Styled.DoctorName>Galioja nuo</Styled.DoctorName>
              <Styled.DoctorName>Galioja iki</Styled.DoctorName>
            </Styled.DoctorInfoListRecord>
          </Styled.Visit>
        </Styled.HeaderLegend>
        {insurances &&
          insurances.length !== 0 &&
          insurances.map((sp, index) => {
            return (
              <div key={index}>
                <Styled.Visit>
                  <Styled.DoctorInfoListRecord>
                    <Styled.HeaderMini>Imonės pavadinimas:</Styled.HeaderMini>
                    <Styled.DoctorName>
                      <AiOutlineEdit
                        onClick={() => {
                          history.push('/account/insurance-card-edit', {
                            data: sp,
                            allInsurances: insurances,
                          });
                        }}
                        style={{ marginRight: '5px', marginTop: '0' }}
                      />
                      {sp.insuranceCompanyDisplayName.toString().toUpperCase()}
                    </Styled.DoctorName>
                    <Styled.HeaderMini>Kortelės numeris:</Styled.HeaderMini>
                    <Styled.DoctorName>
                      {sp.insuranceCardNumber}
                    </Styled.DoctorName>
                    {sp.validFrom && (
                      <Styled.HeaderMini>Galioja nuo:</Styled.HeaderMini>
                    )}
                    <Styled.DoctorName>
                      {sp.validFrom ? sp.validFrom.substring(0, 10) : ''}
                    </Styled.DoctorName>
                    {sp.validUntil && (
                      <Styled.HeaderMini>Galioja iki:</Styled.HeaderMini>
                    )}
                    <Styled.DoctorName>
                      {sp.validUntil ? sp.validUntil.substring(0, 10) : ''}
                    </Styled.DoctorName>
                  </Styled.DoctorInfoListRecord>
                </Styled.Visit>
              </div>
            );
          })}

        <Button
          style={{ marginTop: '10px', fontSize: '16px' }}
          size="small"
          type="submit"
          onClick={() => {
            history.push('/account/insurance-card-edit', {
              allInsurances: insurances,
            });
          }}
        >
          Pridėti naują
        </Button>
        {data &&
          data.length !== 0 &&
          insurances?.length < data?.length &&
          showLoadMoreButton && (
            <Styled.LoadMoreBtn>
              <Button
                loading={loading}
                onClick={() => setInsurancesLength((prev) => prev + 10)}
              >
                Rodyti daugiau
              </Button>
            </Styled.LoadMoreBtn>
          )}
      </Styled.DataContainer>
    </Page>
  );
};
export default InsuranceCardList;
