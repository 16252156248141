import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  margin: 50px 0;

  ${mediaDown('md')`
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    grid-gap: 20px;
    align-items:center;
  `}
`;

const CouponCheckbox = styled.div`
  line-height: 28px;
  text-align: right;
  font-size: 24px;
  ${mediaDown('md')`    
    line-height: 19px;
    text-align: left;
  `}
`;

const CouponFieldsParent = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row;
`;

const CouponFields = styled.div`
  width: calc(100% - 485px);
  display: ${(props) => (props.active ? 'block' : 'none')};
  ${mediaDown('md')`
  width: 100%;
`};
`;

const CouponFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 10px;
`;

const CouponButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  align-self: end;
  ${mediaDown('md')`
    height:45px;
  `};
`;

const CouponParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  align-self: end;
  ${mediaDown('md')`
    height:45px;
  `};
`;

const MainContainerDiv = styled.div`
  margin-bottom: ${(props) => (props.message ? '0px' : '30px')};
`;

const FormButtons = styled.div`
  display: flex;
  ${mediaDown('md')`
    display: grid;
    flex: auto;
  button:not(:first-child) {
    margin-top: 20px;
  }
  `};
`;

const Trans = styled.div`
  font-size: 24px;
`;

const Styled = {
  List,
  CouponFieldsParent,
  CouponFields,
  MainContainerDiv,
  CouponFieldsContainer,
  CouponButtonContainer,
  CouponParagraphContainer,
  CouponCheckbox,
  Trans,
};

export default Styled;
