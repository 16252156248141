import styled, { css } from 'styled-components/macro';

const Alert = styled.div`
  border-radius: 15px;
  padding: 30px;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  svg {
    width: auto;
    height: 38px;
    margin-right: 17px;
  }

  ${(props) => props.type === 'success' && success}
`;

const success = css`
  background-color: #dbffed;
`;

const Styled = {
  Alert,
};

export default Styled;
