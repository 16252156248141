import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../assets/icons/back-arrow.svg';
import Loader from '../Loader';
import Styled from './Page.styles';

const Page = ({ children, loading, error, styles }) => {
  return (
    <Styled.Page isLoading={loading} style={styles}>
      {loading && <Loader />}

      {!loading && !error && children}

      {error && (
        <Styled.Error>
          Atsiprašome įvyko nenumatyta klaida, bandykite dar kartą.{' '}
          <a href="/">Grįžti.</a>
        </Styled.Error>
      )}
    </Styled.Page>
  );
};

const Title = ({
  children,
  backUrl,
  backData,
  goBack,
  smallHeight,
  greyTheme,
}) => {
  const history = useHistory();
  
  return (
    <Styled.Title smallHeight={smallHeight} greyTheme={greyTheme}>
      {backUrl && (
        <Styled.BackButton
          onClick={() => history.push(backUrl, backData)}
          greyTheme={greyTheme}
        >
          <BackIcon />
        </Styled.BackButton>
      )}
      {goBack && (
        <Styled.BackButton
          greyTheme={greyTheme}
          onClick={() => history.goBack()}
        >
          <BackIcon />
        </Styled.BackButton>
      )}
      <h1 style={{ padding: '8px 0 10px 0' }}>{children}</h1>
    </Styled.Title>
  );
};

Page.Title = Title;

export default Page;
