/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useCartState } from '../../context/CartContext';
import { useSettingsState } from '../../context/SettingsContext';
import { ReactComponent as CartIcon } from '../../../assets/icons/cart.svg';
import { ReactComponent as BurgerIcon } from '../../../assets/icons/list.svg';
import { ReactComponent as DropDownArrowIcon } from '../../../assets/icons/user-settings-arrow-down.svg';
import { ReactComponent as MobileNavCloseIcon } from '../../../assets/icons/x-lg.svg';
import LangSelect from '../LangSelect';
import Styled from './Header.styles.js';
import { useUserState, useUserDispatch } from '../../context/UserContext';
import Button from '../../shared/Button';
import { ReactComponent as RightArrow } from '../../../assets/icons/arrow.svg';

const Header = (props) => {
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const [open, setOpen] = useState(false);
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const { registrationServices: products } = useCartState();
  const { theme, header } = useSettingsState();
  const [show, setShow] = useState(true);
  const [children, setChildren] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();
  const isTestEnv = process.env.REACT_APP_ISTESTENV === 'true';
  const showLocalRegistrationTab =
    process.env.REACT_HIDE_REGISTRATION_PROD === 'true';
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userAccountEnabled =
    process.env.REACT_APP_USER_ACCOUNT_ENABLED === 'true';

  const closeMenu = () => {
    window.scrollTo(0, 0);
    setOpen(false);
  };

  const handleWindowSizeChange = () => {
    if (window.innerWidth > 978) {
      closeMenu();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const accountRoutes = [
    '/account',
    '/syncLog/details',
    '/syncLogs',
    '/consentLog/details',
    '/log/details',
    '/sync',
    '/consentLogs',
    '/logs',
    '/',
  ];

  useEffect(() => {
    if (
      userState.isLoggedIn &&
      (accountRoutes.includes(history?.location?.pathname) ||
        accountRoutes.some((route) =>
          history?.location?.pathname?.startsWith(route),
        ))
    ) {
      setShowUserMenu(true);
    } else {
      setShowUserMenu(false);
    }
  }, [accountRoutes, history]);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  useEffect(() => {
    let children = userState.contacts?.filter(
      (ch) =>
        ch.relationship === 'Dukra' ||
        ch.relationship === 'Sunus' ||
        ch.relationship === 'Isunis' ||
        ch.relationship === 'Idukra',
    );

    let childrens = children?.map((ch) => ({
      name: ch.name,
      surname: ch.surname,
      espbiEsi: ch.espbiEsi,
    }));

    if (userState.espbiEsi) {
      childrens.unshift({
        name: userState.firstName,
        surname: userState.lastName,
        espbiEsi: null,
      });
    }

    setChildren(childrens);
  }, [userState.contacts, userState.espbiEsi]);

  const onChildSelect = (ch) => {
    if (ch.espbiEsi) {
      sessionDispatch({
        type: 'SET_CHILD',
        data: {
          espbiEsi: ch.espbiEsi,
        },
      });
    } else {
      sessionDispatch({
        type: 'SET_CHILD',
        data: {
          espbiEsi: null,
        },
      });
    }
  };

  const userSettingsMenu = () => {
    setUserSettingsOpen(false);
  };

  const handleLogout = async () => {
    sessionDispatch({ type: 'LOGOUT' });
  };

  function renderSubMenu() {
    return (
      <div>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/E025');
            }}
          >
            Gydytojų konsultacijos
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/prescriptions');
            }}
          >
            Receptai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/referrals');
            }}
          >
            Siuntimai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/instrumental-researches');
            }}
          >
            Instrumentiniai tyrimai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/vaccinations');
            }}
          >
            Skiepai
          </span>
        </Styled.SideBarSubMeniu>
        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/labaratory-researches');
            }}
          >
            Laboratoriniai tyrimai
          </span>
        </Styled.SideBarSubMeniu>

        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/complex-services');
            }}
          >
            Abonementai ir paslaugų paketai
          </span>
        </Styled.SideBarSubMeniu>

        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/pathology-researches');
            }}
          >
            Patologiniai tyrimai
          </span>
        </Styled.SideBarSubMeniu>

        <Styled.SideBarSubMeniu>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/other-visits');
            }}
          >
            Kitos paslaugos
          </span>
        </Styled.SideBarSubMeniu>
      </div>
    );
  }

  function renderAdminSubMenu() {
    return (
      <div>
        {(userState.role.toLowerCase() === 'popupadmin' ||
          userState.role.toLowerCase() === 'affidea') && (
            <>
              <Styled.SideBarSubMeniu>
                <span
                  className={'option'}
                  onClick={() => {
                    closeMenu();
                    history.push('/popup');
                  }}
                >
                  Popup lango parametrai
                </span>
              </Styled.SideBarSubMeniu>

              <Styled.SideBarSubMeniu>
                <span
                  className={'option'}
                  onClick={() => {
                    closeMenu();
                    history.push('/doctorInformation');
                  }}
                >
                  Gydytojų informacija
                </span>
              </Styled.SideBarSubMeniu>

              <Styled.SideBarSubMeniu>
                <span
                  className={'option'}
                  onClick={() => {
                    closeMenu();
                    history.push('/serviceInformation');
                  }}
                >
                  Paslaugų informacija
                </span>
              </Styled.SideBarSubMeniu>

              <Styled.SideBarSubMeniu>
                <span
                  className={'option'}
                  onClick={() => {
                    closeMenu();
                    history.push('/admin/restrictions');
                  }}
                >
                  Vaikų gydytojų nustatymai
                </span>
              </Styled.SideBarSubMeniu>
            </>
          )}

        {userState.role.toLowerCase() === 'affidea' && (
          <>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/logs');
                }}
              >
                AMIS kreipinių žurnalas
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/sync');
                }}
              >
                AMIS sinchronizacija
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/syncLogs');
                }}
              >
                Įvykių žurnalas
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/consentLogs');
                }}
              >
                Sutikimų pakeitimai
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/amis/requests');
                }}
              >
                Amis užklausų klaidos
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/doctorInformation');
                }}
              >
                Gydytojų informacija
              </span>
            </Styled.SideBarSubMeniu>
            <Styled.SideBarSubMeniu>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  history.push('/serviceInformation');
                }}
              >
                Paslaugų informacija
              </span>
            </Styled.SideBarSubMeniu>
          </>
        )}
      </div>
    );
  }

  function renderMobileSideBar() {
    return (
      <div
        style={{
          marginLeft: '2%',
          fontSize: '1.8rem',
          minHeight: 'fit-content',
        }}
      >
        <Styled.MobileViewTitle
          onClick={() => {
            history.push('/account');
            closeMenu();
          }}
        ></Styled.MobileViewTitle>

        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              history.push('/account/visits');
              closeMenu();
            }}
          >
            {t('userAccount.sidebar.visits')}
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        {renderSubMenu()}
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/registrations');
            }}
          >
            Suplanuotos registracijos
            <RightArrow />
          </span>
        </Styled.SidebarOptions>
        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/failed-registrations');
            }}
          >
            Neįvykę vizitai
            <RightArrow />
          </span>
        </Styled.SidebarOptions>

        <Styled.SidebarOptions>
          <span
            className={'option'}
            onClick={() => {
              closeMenu();
              history.push('/account/user-coupons');
            }}
          >
            Kuponai
            <RightArrow />
          </span>
        </Styled.SidebarOptions>

        <Styled.SidebarOptions>
          <span
            className={'option'}
            style={{ height: '45px' }}
            onClick={() => {
              closeMenu();
              history.push('/registration/local');
            }}
          >
            Susisiekti su budinčiu gydytoju
            <RightArrow />
          </span>

          <Styled.SideBarSubMeniu>
            <span
              className={'option'}
              onClick={() => {
                closeMenu();
                history.push('/registration/local');
              }}
            >
              Kreipimosi forma
            </span>
          </Styled.SideBarSubMeniu>

          <Styled.SideBarSubMeniu>
            <span
              className={'option'}
              onClick={() => {
                closeMenu();
                history.push('/registration/local/list');
              }}
            >
              Kreipimųsi istorija
            </span>
          </Styled.SideBarSubMeniu>
        </Styled.SidebarOptions>

        {userState.role !== null &&
          userState.role !== undefined &&
          (userState.role.toLowerCase() === 'affidea' ||
            userState.role.toLowerCase() === 'popupadmin') && (
            <Styled.SidebarOptions>
              <span
                className={'option'}
                onClick={() => {
                  closeMenu();
                  if (userState.role.toLowerCase() === 'affidea') {
                    history.push('/logs');
                  }
                }}
              >
                Administravimas
                <RightArrow />
              </span>
              {renderAdminSubMenu()}
            </Styled.SidebarOptions>
          )}
        <Link
          to={'/'}
          className={'logout'}
          onClick={() => handleLogout()}
          style={{ color: '#fff' }}
        >
          {t('userAccount.sidebar.logout')}
        </Link>
      </div>
    );
  }

  function renderNav() {
    return (
      <nav>
        <ul>
          {userAccountEnabled && open && (
            <li style={{ display: 'flex', justifyContent: 'end' }}>
              {userState.id && userState.isLoggedIn ? (
                <Styled.SelfService
                  isMobile={open}
                  onClick={() => setUserSettingsOpen(!userSettingsOpen)}
                >
                  <Styled.ButtonContainer>
                    <Button
                      size="medium"
                      style={{
                        fontSize: '16px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        marginLeft: '20px',
                      }}
                    >
                      {userState.firstName}
                      <Styled.SelfServiceIcon isMobile={open}>
                        <DropDownArrowIcon
                          className={userSettingsOpen ? 'upsideDown' : {}}
                        />
                      </Styled.SelfServiceIcon>
                    </Button>
                  </Styled.ButtonContainer>

                  {userSettingsOpen && (
                    <Styled.UserSettingsMenu
                      onClick={userSettingsMenu}
                      style={{ right: '15px' }}
                    >
                      <Styled.Option>
                        <Link
                          className={'login'}
                          to={'/account'}
                          onClick={closeMenu}
                        >
                          {t('header.selfService.account')}
                        </Link>
                      </Styled.Option>
                      <Styled.Option>
                        <Link
                          className={'login'}
                          to={'/'}
                          onClick={() => {
                            sessionDispatch({ type: 'LOGOUT' });
                            closeMenu();
                          }}
                        >
                          {t('header.selfService.logout')}
                        </Link>
                      </Styled.Option>
                      {children?.map((ch, index) => {
                        return (
                          <Styled.Option
                            isFirst={index === 0}
                            isMobile={true}
                            style={{ width: '250px' }}
                          >
                            <Link
                              style={{ fontSize: '20px' }}
                              onClick={() => {
                                onChildSelect(ch);
                                closeMenu();
                              }}
                            >
                              {ch.name} {ch.surname}
                            </Link>
                          </Styled.Option>
                        );
                      })}
                    </Styled.UserSettingsMenu>
                  )}
                  {userSettingsOpen && (
                    <Styled.Backdrop
                      onClick={() => setUserSettingsOpen(false)}
                    ></Styled.Backdrop>
                  )}
                </Styled.SelfService>
              ) : (
                <Styled.SelfService>
                  <Link
                    className={'login'}
                    to="/selfservice"
                    onClick={closeMenu}
                  >
                    {open ? (
                      <Button
                        className={'login'}
                        size="medium"
                        variant="secondary"
                        style={{
                          fontSize: '24px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                          backgroundColor: '#2D69B3',
                          color: '#fff',
                        }}
                      >
                        {t('header.selfService.login')}
                      </Button>
                    ) : (
                      <Button
                        className={'login'}
                        size="small"
                        style={{
                          fontSize: '16px',
                          // paddingTop: '0px',
                          // paddingBottom: '0px',
                          marginLeft: '20px',
                          backgroundColor: '#2D69B3',
                          color: '#fff',
                        }}
                      >
                        {t('header.selfService.login')}
                      </Button>
                    )}
                  </Link>
                </Styled.SelfService>
              )}
            </li>
          )}
          <li className={showUserMenu ? `userMenuOption` : ``}>
            <Trans
              i18nKey="header.aboutLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={header.about.url}
                    target="_parent"
                    onClick={closeMenu}
                  />
                ),
              }}
            />
          </li>
          <li className={showUserMenu ? `userMenuOption` : ``}>
            <Trans
              i18nKey="header.servicesLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={header.services.url}
                    target="_parent"
                    onClick={closeMenu}
                  />
                ),
              }}
            />
          </li>
          <li className={showUserMenu ? `userMenuOption` : ``}>
            <Trans
              i18nKey="header.findUsLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={header.findUs.url}
                    target="_parent"
                    onClick={closeMenu}
                  />
                ),
              }}
            />
          </li>
          <li className={showUserMenu ? `userMenuOption` : ``}>
            <Trans
              i18nKey="header.ourDoctorsLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={header.ourDoctors.url}
                    target="_parent"
                    onClick={closeMenu}
                  />
                ),
              }}
            />
          </li>
          <li className={showUserMenu ? `userMenuOption` : ``}>
            <Trans
              i18nKey="header.pricesLabel"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={header.prices.url}
                    target="_parent"
                    onClick={closeMenu}
                  />
                ),
              }}
            />
          </li>
          <li className={showUserMenu ? `userMenuOption` : ``}>
            <Link to="/transfer-request" onClick={closeMenu}>
              {t('header.becomePatientLabel')}
            </Link>
          </li>
          <li className={showUserMenu ? `userMenuOption` : ``}>
            <Link to={'/'} onClick={closeMenu}>
              {t('header.registration')}
            </Link>
          </li>
          {!open && (
            <Styled.LangSelect>
              <LangSelect disabled />
            </Styled.LangSelect>
          )}
          {userAccountEnabled && !open && (
            <li>
              {userState.id && userState.isLoggedIn ? (
                <Styled.SelfService
                  isMobile={open}
                  onClick={() => setUserSettingsOpen(!userSettingsOpen)}
                >
                  <Button
                    size="medium"
                    style={{
                      fontSize: '16px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      marginLeft: '20px',
                    }}
                  >
                    {userState.firstName}
                    <Styled.SelfServiceIcon isMobile={open}>
                      <DropDownArrowIcon
                        className={userSettingsOpen ? 'upsideDown' : {}}
                      />
                    </Styled.SelfServiceIcon>
                  </Button>

                  {userSettingsOpen && (
                    <Styled.UserSettingsMenu onClick={userSettingsMenu}>
                      <Styled.Option>
                        <Link
                          className={'login'}
                          to={'/account'}
                          onClick={closeMenu}
                        >
                          {t('header.selfService.account')}
                        </Link>
                      </Styled.Option>
                      <Styled.Option>
                        <Link
                          className={'login'}
                          to={'/'}
                          onClick={() => {
                            sessionDispatch({ type: 'LOGOUT' });
                            closeMenu();
                          }}
                        >
                          {t('header.selfService.logout')}
                        </Link>
                      </Styled.Option>
                    </Styled.UserSettingsMenu>
                  )}
                  {userSettingsOpen && (
                    <Styled.Backdrop
                      onClick={() => setUserSettingsOpen(false)}
                    ></Styled.Backdrop>
                  )}
                </Styled.SelfService>
              ) : (
                <Styled.SelfService>
                  <Link
                    className={'login'}
                    to="/selfservice"
                    onClick={closeMenu}
                  >
                    {open ? (
                      <Button
                        className={'login'}
                        size="medium"
                        variant="secondary"
                        style={{
                          fontSize: '24px',
                          paddingTop: '0px',
                          paddingBottom: '0px',
                        }}
                      >
                        {t('header.selfService.login')}
                      </Button>
                    ) : (
                      <Button
                        className={'login'}
                        size="small"
                        style={{
                          fontSize: '16px',
                          // paddingTop: '0px',
                          // paddingBottom: '0px',
                          marginLeft: '20px',
                        }}
                      >
                        {t('header.selfService.login')}
                      </Button>
                    )}
                  </Link>
                </Styled.SelfService>
              )}
            </li>
          )}
        </ul>
      </nav>
    );
  }

  return (
    <>
      {show && (
        <Styled.Header>
          <Styled.Container>
            <Styled.Content>
              <Styled.Logo>
                <Link to="/">
                  <img src={theme.logo} alt="" />
                  <span>Lietuva</span>
                </Link>
              </Styled.Logo>

              <Styled.Middle>{renderNav()}</Styled.Middle>

              <Styled.RightSide>
                <Styled.Cart>
                  <Link to="/checkout" />
                  <CartIcon />
                  {!!products.length && (
                    <Styled.CartCounter>{products.length}</Styled.CartCounter>
                  )}
                </Styled.Cart>

                <Styled.MobileNavLangSelect>
                  <LangSelect disabled />
                </Styled.MobileNavLangSelect>

                <Styled.Burger onClick={(click) => setOpen(!open)}>
                  <BurgerIcon />
                </Styled.Burger>

                {open && (
                  <Styled.MobileNav open>
                    <a onClick={closeMenu} className="mobileNavClose">
                      <MobileNavCloseIcon />
                    </a>
                    {renderNav()}
                    {showUserMenu && renderMobileSideBar()}
                  </Styled.MobileNav>
                )}
              </Styled.RightSide>
            </Styled.Content>
          </Styled.Container>
        </Styled.Header>
      )}
    </>
  );
};

export default Header;
