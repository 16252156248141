const usePhoneValidation = () => {
  const validatePhoneNumber = (val) => {
    if (val === undefined) {
      return false;
    }

    // 00 doesnt matter which, change to +
    const orgVal = val;
    val = val.replace(/[^\d]/g, '');

    if (orgVal[0] === '+') {
      val = '+' + val;
    }
    var containsOnlyNumbersRgx = /[^\d+]/;

    if (val.slice(0, 5) === '+3706' && val.length === 12) {
      // Jeigu raides kazzkas blogai +370(6)
      return {
        lt:true,
        valid: true,
        format: true,
        orgVal: orgVal,
        newVal: val,
        showModal: false,
      };
    } else if (val.slice(0, 5) === '+3706' && val.length !== 12) {
      return {
        valid: false,
        format: false,
      };
    }
    if (
      val.slice(0, 3) === '370' ||
      val.slice(0, 2) === '86' ||
      val.slice(0, 5) === '00370' ||
      val.slice(0, 4) === '0086' || // This is not handled. 008 keist i +3706
      (val.slice(0, 1) === '6' && val.length === 8)
    ) {
      // Removes everything except + and numbers;

      if (val.slice(0, 3) === '370') {
        val = '+' + val;
      } else if (val.slice(0, 5) === '00370') {
        val = val.substring(2);
        val = '+' + val;
      } else if (val.slice(0, 2) === '86') {
        val = val.substring(1);
        val = '+370' + val;
      } else if (val.slice(0, 1) === '6' && val.length === 8) {
        val = '+370' + val;
      } else if (val.slice(0, 4) === '0086') {
        val = val.substring(3);
        val = '+370' + val;
      }

      if (val.length === 12) {
        return {
          lt:true,
          valid: true,
          format: true,
          orgVal: orgVal,
          newVal: val,
          showModal: true,
        };
      } else {
        return { valid: true, format: false };
      }
    }
    if (containsOnlyNumbersRgx.test(val)) {
      return { lt:true, valid: false, format: false };
    }
    if (val.slice(0, 2) === '00') {
      val = val.substring(2);
      val = '+' + val;
      return {
        lt:true,
        valid: true,
        format: true,
        orgVal: orgVal,
        newVal: val,
        showModal: true,
      };
    }
    // If above IF fails, return true; means not LT phone was entered;
    return {
      lt: false,
      valid: true,
      format: false,
      changed: true,
      newValue: val,
      oldVal: orgVal,
    };
  };

  return validatePhoneNumber;
};
export default usePhoneValidation;
