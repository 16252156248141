import styled, { css } from 'styled-components/macro';
import { mediaDown, mediaUp } from '../../../../utils/styles/media';

const Description = styled.div`
  position: relative;
  display: flex;
  ${(props) => props.isWidget && 'overflow: auto;'}
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 60px);
    height: 100%;
    border-radius: 15px;
    background-color: #f1f5f7;
    z-index: -1;

    ${mediaDown('lg')`
      width: 100%;
    `}
  }

  .tooltip {
    color: black;
    width: 400px;
    background-color: white;
    border: 1px solid black;
    ${mediaDown('lg')`
      width: 250px;
    `}
  }
`;

const Image = styled.div`
  height: 200px;
  width: 285px;
  border-radius: 15px;
  background-size: cover;
  background-position: center top;
  margin: 40px 0;
  background-color: rgba(0, 0, 0, 0.1);
  ${mediaDown('lg')`
    position: absolute;
    left: 0;
    top: 0;    
    width: 130px;
    height: 87px;
    margin: 0;
    border-radius: 8px 0 8px 0;
  `}
`;

const WidgetImageResponsive = styled.div`
  height: 250px;
  width: 240px;
  border-radius: 15px;
  background-size: cover;
  background-position: center top;
  // margin: 40px 0;
  background-color: rgba(0, 0, 0, 0.1);

  ${mediaUp('xs')`
  flex-shrink: 0;
`}

  ${mediaDown('xs')`
  width: 350px; 
  `}
`;

const WidgetImage = styled.div`
  height: 250px;
  width: 240px;
  border-radius: 15px;
  background-size: cover;
  background-position: center top;
  // margin: 40px 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

const WidgetPrice = styled.div`
  font-size: 16px;
  span {
    font-size: 24px;
    font-weight: 900;
  }
  ${mediaDown('lg')`
     font-size: 16px;
     span {
    font-size: 16px;
  }
  `}
`;

const Content = styled.div`
  padding: 40px 20px 40px 45px;
  ${mediaDown('lg')`
    padding: 15px 15px 25px;
  `}
`;

const ContentResponsive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 20px 0px 45px;
  overflow: auto;
  ${mediaDown('lg')`
    padding: 15px 15px 0px;
    overflow: auto;    
  `}
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 900;
  ${mediaDown('lg')`
    font-size: 16px;
    line-height: 19px;
    padding-left: 140px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    min-height: 47px;
    margin-bottom: 10px;
  `}
`;

const SpaceDiv = styled.div`
  height: 14px;
  padding: 5px 7px;
`;

const Badge = styled.div`
  background-color: ${(props) => (props.isService ? '#6A32E6' : '#0535AD')};
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
  padding: 5px 7px;
  border-radius: 6px;
  display: inline-block;

  ${mediaDown('lg')`
    position: relative;
    z-index: 1;
    margin-left: -15px;
    padding-left: 15px;
  `}
`;

const Properties = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-top: 30px;

  ${mediaDown('lg')`
    margin-top: 10px;
    font-size: 12px;
    line-height: 15px;
  `}

  > div {
    column-count: 2;

    ${mediaDown('lg')`
      column-count: 1;
      display: flex;
      flex-direction: column;
    `}

    span {
      display: block;
      color: #9395a9;
      margin-right: 34px;

      &:nth-of-type(1) {
        order: 1;
      }
      &:nth-of-type(2) {
        order: 3;
      }
      &:nth-of-type(3) {
        order: 5;
      }
      &:nth-of-type(4) {
        order: 7;
        margin-top: 38px;
        ${mediaDown('lg')`
        margin-top:0px;
      `}
      }
    }

    > div {
      &:nth-of-type(1) {
        order: 2;
      }
      &:nth-of-type(2) {
        order: 4;
      }
      &:nth-of-type(3) {
        order: 6;
      }
      &:nth-of-type(4) {
        order: 8;
      }
    }

    .blank {
      min-height: 24px;

      ${mediaDown('lg')`
        display: none;
      `}
    }
  }

  > div > span {
    margin-bottom: 12px;
  }

  > div > div {
    margin-bottom: 12px;

    ${mediaDown('lg')`
      margin-bottom: 8px;
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Flags = styled.div`
  display: flex;
  font-size: 0;

  svg {
    display: block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
const PrimaryLink = styled.a`
  color: #000;
  display: block;
  padding: 2px 0px;
`;

const SecondaryLink = styled.a`
  color: blue;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;
const UnorderedList = styled.ul`
  height: auto;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  top: 35px;
  width: 90%;
`;

const PrimaryListItem = styled.span`
  // :hover {
  //   ul {
  //     height: auto;
  //     opacity: 1;
  //     transform: translateY(0);
  //   }
  // }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  ${mediaDown('lg')`
      position:  absolute;
      height: 100vh;
`}
`;

const TitleBlock = styled.div`
  p {
    display: flex;
    flex-direction: row;
    margin: 0;
    svg {
      margin-right: 4px;
      margin-top: 3.5px;
      color: rgb(95, 157, 244);
      ${mediaDown('lg')`
       margin-top:0px;      
       height:18px;
       margin-right: -4px;
  `}
    }
  }
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  color: #9395a9;
  p {
    display: flex;
    flex-direction: row;
    margin: 0;
    svg {
      margin-right: 2px;
      margin-top: 5px;
      color: rgb(95, 157, 244);
      ${mediaDown('lg')`
         margin-top: 3px;
    `}
    }
  }
  span {
    margin-top: 5px;
    color: black;

    ${mediaDown('lg')`
    width: 300px;
         margin-top: 3px;
         word-wrap: break-word;
    `}
  }
  .price {
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
    color: black;
  }
  .oldPrice {
    text-decoration: line-through;
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
    color: black;
  }
`;

const AdditionalInformation = styled.div`
  color: #9395a9;
  margin-top: 25px;
`;

const AdditionalServices = styled.div`
  color: #9395a9;
  margin-top: 10px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  ${InfoBlock}:first-child {
    width: ${(props) => (props.psdf ? '600px' : '600px')};   atkeist
  }
  ${InfoBlock}:not(:first-child) {
    ${mediaDown('lg')`
      margin-top: 25px;
      
    `};
  }
  ${InfoBlock}:not(:last-child) {
    padding-right: 25px;
    margin-right: 25px;
    border-right: 1px solid lightgrey;
    ${mediaDown('lg')`
        border-right: none;
    `}
  }
  ${mediaDown('lg')`
       flex-direction: column;
       width: 250px;
       font-size: 12px;
      line-height: 15px;
    `}
`;

const TlkIconSvg = styled.div`
  position: absolute;
  right: -10px;
  height: 29px;
  z-index: 0;
  ${mediaDown('lg')`
  top: 10%;
    `}
  svg {
    height: 45px;
    width: 45px;
    fill: rgba(0, 0, 0, 0.1);
  }
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-weight: bold;
  font-size: 20px;
  color: black;
  ${mediaDown('lg')`
       margin-left: 0;
    `}
`;

const OldPrice = styled.del`
  margin-left: 10px;
  color: #9395a9;
`;

const TlkPrice = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-weight: bold;
  font-size: 20px;
  margin-left: 15px;
  color: black;
  ${mediaDown('lg')`
       margin-left: 0;
    `}
`;

const WidgetTitle = styled.div`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 12px;
  ${mediaDown('lg')`
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    min-height: 47px;
    margin-bottom: 10px;
  `}
`;

const slotBase = css`
  background-color: #f5f9fa;
  border-radius: 5px;
  height: 30px;
`;

const slotBaseResponsive = css`
  background-color: #f5f9fa;
  border-radius: 5px;
  height: 21px;
`;
const Slot = styled.div`
  ${slotBaseResponsive}
  background-color: #CCF9E5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.selected
      ? '#000'
      : props.isTaken
      ? '#c7c7c7'
      : props.isPaid
      ? '#519c77'
      : '#3655d7'};
  background-color: ${(props) =>
    props.isTaken ? '#f5f9fa' : props.isPaid ? '#CCF9E5' : '#a2cbff'};
  font-size: 13px;
  cursor: ${(props) =>
    props.legend ? 'default' : props.isTaken ? 'default' : 'pointer'};

  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
  border: 1px solid black;
  transition: opacity 0.15s;
  position: relative;
  overflow: hidden;
  ${mediaDown('lg')`
    font-size: 12px;
  `}
`;

const TimeSlotInfo = styled.div`
  display: flex;
`;
const TimeSlotLegend = styled.div`
  width: 100%;
  ${TimeSlotInfo} {
    margin-top: 15px;
  }
`;

const TimeSlot = styled.div`
  width: 50px;
`;

const TimeSlotResponsive = styled.div`
  width: 30px;
  ${mediaDown('xxs')`
  width: 45px; 
  `}
`;

const LegendTitle = styled.div`
  width: 400px;
  margin-left: 5px;
  align-self: center;
  color: ${(props) => (props.isPaid ? '#519c77' : 'black')};
`;

const LegendTitleResponsive = styled.div`
  width: 400px;
  margin-left: 5px;
  align-self: center;
  font-size: 0.95rem;
  color: ${(props) => (props.isPaid ? '#519c77' : 'black')};
  ${mediaDown('xxs')`
  font-size:0.85rem;
 `}
`;

const InfoName = styled.span`
  width: 600px;
  padding-right: 10px; /* Spacing between columns */
  border-right: 1px solid lightgrey;
  // display: flex;
  // flex-direction: row;
  // margin-top: 5px;
  // font-weight: bold;
  // font-size: 20px;
  // color: black;
  ${mediaDown('lg')`
  width: 0;
  border-right: none;
    margin-left: 0;
  `}
`;

const InfoMainName = styled.span`
  width: 600px;
  padding-top: 10px;
  font-size: 18px;
  border-right: 1px solid lightgrey;
  ${mediaDown('lg')`
  border-right: none;
       margin-left: 0;
    `}
`;

const InfoPrice = styled.span`
  width: 20%;
  padding-left: 24px;
`;

const InfoContainer = styled.div`
  width: 100%;
  ${mediaDown('lg')`
  width: 80vw;
       margin-left: 0;
    `}
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Aligns items at the bottom */
`;

const TotalPrice = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  text-align: right;
  margin: 55px 0 0;

  ${mediaDown('lg')`
    font-size: 18px;
    line-height: 19px;
    text-align: left;
  `}
`;

const MainTitle = styled.div`
  width: 600px;
  color: black;
  font-weight: Bold;
  font-size: 20px;
  padding-top: 10px;
  border-right: 1px solid lightgrey;
  ${mediaDown('lg')`
  width: 300px;
  height: 50px;
  border-right: none;
      margin-left: 0;
  `}
`;

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  color: #9395a9;
  p {
    display: flex;
    flex-direction: row;
    margin: 0;
    svg {
      margin-right: 2px;
      margin-top: 5px;
      color: rgb(95, 157, 244);
      ${mediaDown('lg')`
         margin-top: 3px;
    `}
    }
  }
  ${mediaDown('lg')`
    font-size: 12px;
    line-height: 19px;
    padding-left: 140px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    min-height: 47px;
    margin-bottom: 10px;
    width:80vw;
  `}
`;

const Styled = {
  Description,
  Image,
  Content,
  Title,
  Badge,
  Properties,
  Flags,
  DescriptionWrapper,
  InfoBlock,
  TlkIconSvg,
  AdditionalInformation,
  Price,
  OldPrice,
  TlkPrice,
  SpaceDiv,
  WidgetImage,
  WidgetPrice,
  WidgetTitle,
  slotBase,
  LegendTitle,
  TimeSlot,
  TimeSlotLegend,
  TimeSlotInfo,
  Slot,
  WidgetImageResponsive,
  LegendTitleResponsive,
  TimeSlotResponsive,
  slotBaseResponsive,
  ContentResponsive,
  TitleBlock,
  AdditionalServices,
  PrimaryLink,
  SecondaryLink,
  UnorderedList,
  PrimaryListItem,
  Backdrop,
  TotalPrice,
  HeaderArea,
  InfoName,
  InfoMainName,
  InfoContainer,
  InfoRow,
  InfoPrice,
  MainTitle,
};

export default Styled;
