import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Page from '../../../../../shared/Page';
import Styled from '../../UserAccountPage.styles';
import Button from '../../../../../shared/Button';
import * as Yup from 'yup';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import { Formik, Form as FormikForm, Field } from 'formik';
import Form from '../../../../../shared/Form';
import TextField from '../../../../../shared/TextField';
import Modal from '../../../../../shared/Modal/Modal';
import useFetch from 'use-http';
import { ReactComponent as Danger } from '../../../../../../assets/icons/danger.svg';
import { useHistory } from 'react-router-dom';
import {
  useUserState,
  useUserDispatch,
} from '../../../../../context/UserContext';

const AccountCredentialsEdit = (props) => {
  const userState = useUserState();
  const sessionDispatch = useUserDispatch();
  const [PreviewModalVisible, setPreviewModalVisible] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const {
    post: updateUserAccountRequest,
    response: updateUserAccountResponse,
  } = useFetch(`/UserAccount/Update`);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.requiredField'))
      .matches(
        /(?=[A-Za-z0-9@$!%*#?&^`~+-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&^`~+-])(?=.{8,}).*$/,
        t('validation.password'),
      ),
    confirmPassword: Yup.string()
      .required(t('validation.requiredField'))
      .trim()
      .oneOf([Yup.ref('password'), null], t('validation.notMatchingPassword')),
  });

  const handleAccountCredentialsEdit = async (
    data,
    { setSubmitting, setErrors },
  ) => {
    setSubmitting(true);
    const response = await updateUserAccountRequest('', {
      id: userState.id,
      email: userState.email,
      firstName: userState.firstName,
      lastName: userState.lastName,
      phoneNumber: userState.phoneNumber,
      password: data.password,
    });

    if (updateUserAccountResponse.ok) {
      sessionDispatch({
        type: 'UPDATE',
        data: {
          id: response.id,
          email: response.email,
          phoneNumber: response.phoneNumber,
          personalCode: userState.personalCode,
          firstName: response.firstName,
          lastName: response.lastName,
          countryCode: userState.countryCode,
          phoneNumberConfirmed: response.phoneNumberConfirmed,
          contacts: response.contacts
        },
      });
      setPreviewModalVisible(true);
    }

    setSubmitting(false);
    // history.push('/account');
  };

  return (
    <Page loading={false}>
      <Styled.DataContainer>
        <Styled.BackArrow>
          <RightArrow onClick={() => history.goBack()}></RightArrow>
          <span>{t('userAccount.userInfoTab.loginInfoTitle')}</span>
        </Styled.BackArrow>
        <Styled.EditForm>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleAccountCredentialsEdit}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    {/* <Form.Row>
                      <Field
                        name="email"
                        label="El. pašto adresas"
                        value={userAccountState.email}
                        component={TextField}
                      />
                    </Form.Row> */}
                    <Form.Row>
                      <Styled.PasswordExplanation>
                        <Styled.DangerContainer>
                          <Danger></Danger>
                        </Styled.DangerContainer>
                        <div>
                          <Trans
                            i18nKey="validation.passwordStrongSymbols"
                          />
                        </div>

                      </Styled.PasswordExplanation>
                    </Form.Row>

                    <Form.Row>
                      <Field
                        focus={true}
                        name="password"
                        label={t('userAccount.userCredentialsEdit.newPassword')}
                        password
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Field
                        name="confirmPassword"
                        label={t(
                          'userAccount.userCredentialsEdit.confirmPassword',
                        )}
                        password
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  onClick={() => handleAccountCredentialsEdit}
                >
                  {t('userAccount.userInfoTab.updateData')}
                </Button>
              </FormikForm>
            )}
          </Formik>
        </Styled.EditForm>
      </Styled.DataContainer>
      <Modal
        visible={PreviewModalVisible}
        onClose={() => setPreviewModalVisible(false)}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          {t('userAccount.userCredentialsEdit.changedPassword')}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setPreviewModalVisible(false);
              history.push('/account');
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};
export default AccountCredentialsEdit;
