import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const RouterFbClick = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('fbclid') || queryParams.has('FBCLID')) {
      if (queryParams.has('fbclid')) {
        queryParams.delete('fbclid');
      }

      if (queryParams.has('FBCLID')) {
        queryParams.delete('FBCLID');
      }

      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history, location.search]);

  return null;
};
export default RouterFbClick;
