/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import moment from 'moment';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';

const PrescriptionTabComponent = () => {
  const history = useHistory();
  const { get: getFile } = useFetch('/UserAccount/DownloadAmisFile');
  const prescription = history.location?.state?.data;
  const handleFileDownload = async () => {
    await getFile(
      `?fileType=${prescription.file.type}&fileGuid=${
        prescription.file.guid
      }&category=${'Prescription'}`,
    ).then((res) => {
      const base64Data = res;
      const blob = base64toBlob(base64Data, 'application/pdf');
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download =
        trimFileName(prescription.name) +
        ' ' +
        moment(new Date(prescription.dateAsserted)).format('YYYY-MM-DD') +
        '.pdf';

      document.body.appendChild(downloadLink);
      setTimeout(() => {
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }, 120);
    });
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
  const trimFileName = (fileName) => {
    if (fileName.length > 190) {
      return fileName.substring(0, fileName.length - 18) + '...';
    } else return fileName;
  };
  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>Receptai</Styled.VisitTitle>
      </Styled.BackArrow>
      <Styled.InfoDetailsRow>
        <span>Data:</span>
        <div>
          {(new Date(Date.parse(prescription.date)).getUTCHours() === 0 && (
            <Moment utc={true} className="time" format="YYYY-MM-DD">
              {Date.parse(prescription.dateAsserted)}
            </Moment>
          )) || (
            <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
              {Date.parse(prescription.dateAsserted)}
            </Moment>
          )}
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Pavadinimas:</span>
        <div>{prescription.name}</div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Diagnozė:</span>
        <div>{prescription.reason}</div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Kompensacijos rūšis:</span>
        <div>
          {prescription.coverage === null || prescription.coverage === ''
            ? 'Nekompensuojama'
            : prescription.coverage}
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Dozavimo rekomendacijos:</span>
        {(prescription.dosage && <div>{prescription.dosage}</div>) || (
          <div>-</div>
        )}
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Galioja nuo:</span>
        <div>
          <Moment className="time" format="YYYY-MM-DD">
            {Date.parse(prescription.effectiveStartDate)}
          </Moment>
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Galioja iki:</span>
        <div>
          {(new Date(
            Date.parse(prescription.effectiveEndDate),
          ).getUTCHours() === 0 && (
            <Moment utc={true} className="time" format="YYYY-MM-DD">
              {Date.parse(prescription.effectiveEndDate)}
            </Moment>
          )) || (
            <Moment utc={true} className="time" format="YYYY-MM-DD HH:mm">
              {Date.parse(prescription.effectiveEndDate)}
            </Moment>
          )}
        </div>
      </Styled.InfoDetailsRow>
      {prescription.file && prescription.file.guid && (
        <Styled.InfoDetailsRow>
          <a onClick={handleFileDownload}>ATSISIŲSTI PDF</a>
        </Styled.InfoDetailsRow>
      )}
    </Styled.DataContainer>
  );
};
export default PrescriptionTabComponent;
