import React from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './ServicePreparation.styles';

const ServicePreparation = ({ service }) => {
  const { t } = useTranslation();

  return (
    <Styled.Description>
      <Styled.Preparation>
        <Styled.PreparationTitle>
          <span>{t('servicePreparations.title')}</span>
        </Styled.PreparationTitle>
        {service.servicePreparations &&
          service.servicePreparations.map((sp) => {
            return <li key={sp}> {sp}</li>;
          })}
      </Styled.Preparation>
    </Styled.Description>
  );
};

export default ServicePreparation;
