import styled from 'styled-components/macro';
import { mediaDown } from '../../../../utils/styles/media';
import { mediaUp } from '../../../../utils/styles/media';

const Sidebar = styled.div`
  position: sticky;
  top: 0;
  float: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 900px;
  width: 300px;
  z-index: 2;
  border-right: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  .logout {
    position: absolute;
    text-align: left;
    font-weight: bold;
    // margin-left: 45px;
    color: black;
    width: 300px;
  }
  ${mediaDown('md')`
    display: none;
    .BurgerIcon {
      position: sticky;
      display: flex;
    }
  `}
`;

const SidebarUserInfo = styled.div`
  border-bottom: 1px solid lightgrey;
  height: 100px;
  h3 {
    font-weight: bold;
    margin-top: 5px;
  }
  border-radius: 5px;
  div {
    margin: 0;
    padding-left: 15px;
    border-radius: 5px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const SidebarLogoutUser = styled.div`
  border-bottom: 1px solid lightgrey;
  line-height: 4;
  padding: 25px 0 0 45px;
`;

const SidebarOptions = styled.div`
  padding: 20px 0 0 25px;
  .option {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px 0 20px;
    height: 25px;
    width: 90%;
    &:hover {
      background-color: lightgrey;
      cursor: pointer;
    }
  }
  svg path {
    fill: #52585f;
  }
`;
const InfoTitle = styled.div`
  padding: 30px 0 30px 0;
  border-top: 1px solid black;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  a {
    color: black;
  }
  span {
    cursor: pointer;
    text-decoration: underline;
  }

  ${({ noTopBorder }) =>
    noTopBorder &&
    `
  border-top:0px !important ;
`}

  ${({ noBottomBorder }) =>
    noBottomBorder &&
    `
  border-bottom:0px !important ;
`}
`;

const InfoEmail = styled.div`
  margin-top: 10px;

  .emailHead {
    font-weight: bold;
  }
  display: flex;
  justify-content: space-between;
  a {
    color: black;
  }
  .deleteEmail {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }

  ${({ noTopBorder }) =>
    noTopBorder &&
    `
  border-top:0px !important ;
`}
`;

const DeleteWarning = styled.div`
  padding: 13px;
  margin-top: 17px;
  font-weight: bold;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  background-color: rgb(255, 0, 0);
  opacity: 75%;
  color: white;
  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const BurgerIcon = styled.div`
  display: none;
  z-index: ${(props) => (props.isOpen ? '0' : '100')};
  ${mediaDown('md')`
  
    position: fixed;
    display: flex;
    margin: 20px 0 0 25px;
    width: 10%;
    cursor: pointer;
    svg {
      height: 50px;
      width: 50px;
    }
  `}
`;
const InfoDetailsRow = styled.div`
  display: flex;
  margin-top: 10px;
  > span {
    font-weight: bold;
    margin-right: 5px;
  }
  > div {
    margin-left: 0px;
  }
  &:first-child {
    margin-top: 25px;
  }
  a {
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    text-transform: uppercase;
  }
  ${mediaDown('md')`
    flex-direction: column;
    a {
      margin-top: 0px;
    }
    margin-top:0px;
  `}
`;
const InfoDetails = styled.div`
  margin: 15px 0 15px 0;
`;

const ServiceInformation = styled.div`
  margin: 0 0 5px 0;
  border-bottom: 1px solid lightgrey;
`;

const DangerContainer = styled.div`
  svg path {
    fill: #52585f;
  }
`;

const SelfService = styled.div`
  font-size: 1rem;
  display: flex;
  cursor: pointer;
  user-select: none;
  button {
    svg {
      fill: white;
    }
  }
  button:hover {
    color: #28a745;
    svg {
      fill: #28a745;
    }
  }
  :hover {
    svg {
      fill: #28a745;
    }
  }
  svg {
    align-self: center;
    margin-right: 5px;
  }
  ${mediaDown('md')`
font-size: 24px;
`}
`;

const BackArrow = styled.div`
  display: flex;
  margin-bottom: 25px;
  padding-top: 50px;
  svg {
    cursor: pointer;
    min-height: 30px;
    min-width: 30px;
    transform: rotate(180deg);
  }
  svg path {
    fill: #52585f;
  }
  span {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  ${mediaDown('md')`
  padding-top: 30px;
`}
`;
const EditForm = styled.div`
  width: 650px;
  ${mediaDown('md')`
  width: 100%;
`}
  button {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
`;
const MobileView = styled.div`
  position: fixed;
  color: rgb(241, 239, 237);
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #4181d0;
  overflow: auto;
  z-index: 9;
  font-size: 24px;
  ${mediaUp('md')`
      display: none;
    `}
  .mobileNavClose {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;

    svg {
      transform: scale(2);
    }
  }
  .logout {
    color: rgb(241, 239, 237);
    display: flex;
    margin: 40px 0 100px 25px;
  }
`;
const MobileViewTitle = styled.div`
  margin: 30px 0px 0 25px;
  margin-bottom: -25px;
  div {
    border-radius: 5px;
    width: 80%;
    &:hover {
      background-color: lightgrey;
      cursor: pointer;
    }
  }
  h3 {
    margin-top: 0px;
  }
`;
const PasswordExplanation = styled.div`
  display: flex;
  font-size: 16px;
  height: auto;
  ${mediaDown('md')`
  margin-bottom: 35px;
`}
  svg {
    height: auto;
    width: 40px;
    margin-top: 5px;
  }
`;
const PhoneNumberNotConfirmed = styled.div`
  color: red;
  display: flex;

  svg {
    height: auto;
    width: 15px;
    margin-right: 5px;
  }
`;
const PhoneNumberInfoRow = styled.div`
  display: flex;
  margin-top: 10px;
  > span {
    font-weight: bold;
  }
  > div {
    margin-left: 5px;
  }

  @media (max-width: 650px) {
    div:last-child {
      display: flex;
    }
  }
`;
const DatePicker = styled.div`
  span {
    font-weight: normal;
    margin-right: 5px;
    font-size: 18px;
  }

  display: flex;
  width: 330px;
  float: right;
  font-size: 18px;
`;
const Visit = styled.div`
  a {
    margin-top: 5px;
    ${(props) =>
      props.finished !== 'Uzbaigtas' ? 'cursor: pointer' : 'cursor: default'};
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  div {
    display: flex;
    justify-content: space-between;
    ${mediaDown('md')`
    flex-direction: column;
`}
  }

  div:not(:first-child) {
    margin-top: 5px;
  }
  div:last-child {
    border-bottom: 1px solid lightgrey;
    // padding-top: 10px;
    padding-bottom: 10px;
  }
  span {
    font-size: 16px;
  }
`;

const DescriptionUrl = styled.div`
  display: inline-block;
  width: 800px;
  color: #4181d0;
  word-wrap: break-word;
  border: 0 !important;
`;
const DoctorName = styled.div`
  display: inline-block !important;
  width: 500px;
  word-wrap: break-word;
  vertical-align: top;
  padding-bottom: 10px;
  margin-left: 10px;
  svg {
    position: relative;
    margin-top: 14px;
    display: inline-block;
  }
  border-bottom: none !important;
  ${mediaDown('md')`
    margin-left:0;
  `}
`;

const TabTitleWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid lightgrey;
  align-items: center;

  input {
    margin-right: 10px;
  }
  span {
  }

  ${(props) =>
    props.select
      ? `@media (max-width: 1400px) {
        flex-flow:wrap;
        align-self:center;
    padding-bottom: 15px;
    input {
      width: 355px;
    }
    form {
      margin-bottom: 10px;
    }
  }`
      : `@media (max-width: 1295px) {
      flex-flow:wrap;
    padding-bottom: 15px;
    input {
      width: 355px;
    }
    form {
      margin-bottom: 10px;
    }
  }`};

  @media (max-width: 585px) {
    flex-direction: column;
    align-items: start;
    padding-bottom: 15px;
    input {
      width: 255px;
    }
    form {
      diplay: flex;
      flex-direction: column;

      button {
        margin-top: 5px;
        width: 140px;
        align-self: center;
      }
    }
  }
`;

const SelectField = styled.div`
  min-width: 170px;
  margin-right: 15px;
  .rs {
    &__control {
      height: 30px;
      margin-bottom: 5px;
    }
  }
  @media (max-width: 1395px) {
    width: 355px;
    margin-bottom: 15px;
  }

  ${mediaDown('md')`
     width: 355px;
  `}

  @media (max-width: 585px) {
    width: 255px;
  }
`;

const TabTitle = styled.h2`
  margin-top: 0;
  padding-top: 20px;
  width: 100%;
  span {
    font-size: 16px;
    font-weight: normal;
  }
`;

const TabSubTitle = styled.div`
  width:100%;
  margin-bottom:20px; 

  span {
    font-size:initial;
    font-weight:initial;
  }
}
`;

const VisitTitleDateTime = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;
  .time {
    padding-top: 5px;
    display: flex;
    justify-content: end;
  }
  .calendar {
    padding-left: 50px;
  }
  time {
    ${(props) =>
      props.finished !== 'Pasirasytas' ? 'cursor: pointer' : 'cursor: default'};
    min-width: 170px;
  }
  ${mediaDown('md')`
    flex-direction: column;
`}
`;

const VisitTitle = styled.span`
  text-transform: uppercase;
  font-weight: bold;
`;

const ServiceTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px solid lightgrey;
`;

const Preparations = styled.div`
  li {
    margin-left: 45px;
  }

  li:not(first-child) {
    margin-top: 5px;
  }
`;

const DataContainer = styled.div`
  margin: 0 25px 0 350px;
  color: #52585f;
  ${InfoTitle}:first-child {
    border-bottom: 1px solid black;
    border-top: 0px solid black;
  }
  ${InfoTitle}:not(:first-child):not(:last-child) {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  ${mediaDown('md')`
    margin: 0 25px 0 40px;
    display: flex;
    flex-direction: column;
  `}
`;

const Reminder = styled.span`
  margin-top: 25px;
  font-size: 24px;
`;

const PaymentDetails = styled.div`
  margin-left: 5px;
  display: flex;
`;
const PaymentDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 90px;

  position: relative;
  top: -22px;

  div:not(:first-child) {
    margin-top: 5px;
  }
  span {
    margin-left: 3px;
  }
`;

const VisitDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > span {
    font-weight: bold;
    margin-right: 5px;
  }
  > div {
    margin-left: 0px;
  }
  &:first-child {
    margin-top: 25px;
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
    text-transform: uppercase;
  }
  ${mediaDown('md')`
    flex-direction: column;
  `}
`;

const SideBarSubMeniu = styled.div`
  padding: 15px 0 0 40px;
  font-size: 14px;
  .option {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px 0 20px;
    height: 30px;
    width: 90%;
    &:hover {
      background-color: lightgrey;
      cursor: pointer;
    }
  }
`;
const List = styled.div`
  font-weight: bold;
`;
const ListItemRow = styled.div`
  margin-left: 35px;
  display: flex;
  span {
    font-weight: bold;
  }
  div {
    margin-left: 5px;
  }
`;
const ListItemBottom = styled.div`
  margin-bottom: 15px;
`;
const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
`;

const DiscountCard = styled.div`
  border-top: 1px solid black;
  margin-bottom: 25px;
  }
`;

const DiscountCardTitle = styled.div`
  font-weight: bold;
  margin-top: 25px;
`;

const DoctorInfoListRecord = styled.div`
  // display: flex;
  // justify-content: space-around;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;

  svg {
    margin-top: 10px;
    cursor: pointer;
  }

  ${mediaDown('md')`
    flex-direction: column;
`}
`;

const LogListRecord = styled.div`
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  text-decoration: none;
  font-size: 20px;
  span {
    margin-left: 20px;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: underline;
    align-self: start;
  }
  .time {
    padding-top: 5px;
    display: flex;
    justify-content: end;
  }
  time {
    ${(props) =>
      props.finished !== 'Pasirasytas' ? 'cursor: pointer' : 'cursor: default'};
    min-width: 170px;
  }
  ${mediaDown('md')`
    flex-direction: column;
`}
`;

const LoadMoreBtn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const ImageContainer = styled.div`
  width: 500px;
  height: auto;
  margin-top: 15px;
  ${mediaDown('sm')`
  width: 310px;
`}
`;

const Input = styled.input`
  width: 310px;
  margin-right: 15px;
  border: 0;
`;

const SelfServiceIcon = styled.div`
  cursor: pointer;
  display: inline;
  margin: 2px 0 0 5px;
  padding-left: 10px !important;
  user-select: none;
  z-index: 29;
  ${(props) => (props.isMobile ? 'padding-top: 5px;' : 'padding-top: 0px')}
  svg {
    fill: white;
  }
  svg:hover {
    fill: #28a745;
  }
  .upsideDown {
    svg:hover {
      fill: #28a745;
    }
    transform: rotate(180deg);
  }
  ${mediaDown('md')`
margin-top: 0px;
svg {
  width: 20px;
  height: 20px;
  fill: rgb(241, 239, 237);
}
`}
`;

const SecondaryRowContainer = styled.div`
  ${mediaDown('sm')`    
  margin-bottom:15px;
`}
`;
const InputContainer = styled.div`
  form input {
    color: #52585f;

    ::-webkit-input-placeholder {
      color: #52585f;
    }
    ::-moz-placeholder {
      color: #52585f;
    }
    :-ms-input-placeholder {
      color: #52585f;
    }
    :-moz-placeholder {
      color: #52585f;
    }
    ::placeholder {
      color: #52585f;
    }
  }
`;

const Label = styled.label`
  display: inline-block;
`;

const ButtonContainer = styled.div`
  ${mediaDown('md')`
button {
  background-color: #2D69B3;
  border-color:#2D69B3;
}
`}
`;

const RowDiv = styled.div`
  margin-top: 25px;
  width: 390px;
  ${mediaDown('sm')`
  width: 310px;
`}
`;
const GreyLabel = styled.label`
  color: #52585f;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 6px;
  display: block;
`;

const FormContainer = styled.div`
  width: 60%;
  min-width: 600px;
  ${mediaDown('md')`    
  min-width:unset;
  width:600px;
`}
  ${mediaDown('sm')`    
min-width:unset;
width:unset;
`}
`;

const RowContainer = styled.div`
  margin-top: 30px;
  ${mediaDown('sm')`    
    margin-top:unset;
  `}
`;
const SpecialistFieldsParent = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  ${mediaDown('md')`
  position:  absolute;
  height: 100vh;
`}
`;

const UserSettingsMenu = styled.div`
    position: absolute;
    //width:228px;
    top: 70px;
    left: 30px;
    padding: 0 12px;    
    border-radius: 6px;
    background-color: #fff;
     z-index: 29;
    ${mediaDown('md')`
      top:auto;
      bottom: auto;
      margin-top: 6vh;
      background-color: #72a1db;
    `}
    }
  `;

const SpecialistFields = styled.div`
  width: calc(100% - 485px);
  display: ${(props) => (props.active ? 'block' : 'none')};
  ${mediaDown('md')`
  width: 100%;
`};
`;

const SpecialistFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.secondaryDistribution ? '3fr 2fr' : '3fr 1fr'};
  grid-template-columns: ${(props) =>
    props.fourthDistribution && '6fr 2fr 2fr'};
  grid-template-columns: ${(props) => props.tertiaryDistribution && '1fr 1fr'};

  ${(props) => props.secondaryWidth && 'width: 60%'};
  grid-gap: 10px;

  ${mediaDown('sm')`
    grid-template-columns: 1fr; 

    div:first-child {
      margin-bottom:10px;
    }
  `};
`;

const SpecialistButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  align-self: end;
  ${mediaDown('md')`
    height:45px;
  `};
`;

const SpecialistParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: end;
`;

const Option = styled.div`
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  padding-left: 0px !important;
  color: #4181d0;
  &:hover {
    background-color: lightgrey;
    cursor: pointer;
  }
  ${(props) => props.isFirst && 'margin-top:0px;'}
  // ${(props) => props.selectedChild && 'background-color:red;'}
${mediaDown('md')`
  color:  #4181d0;
  font-size: 1.5rem;
`}
`;

const EditFormCenter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 900px;
  @media (max-width: 1250px) {
    width: 600px;
  }
  @media (max-width: 690px) {
    width: 300px;
  }

  @media (max-width: 370px) {
    width: 200px;
  }
`;

const Badge = styled.div`
  background-color: ${(props) =>
    props.validityStatus === 'Galioja'
      ? 'green'
      : props.validityStatus === 'Negalioja'
      ? 'red'
      : 'lightGrey'};
  color: #ffffff;
  font-size: 14px;
  font-weight: 800;
  line-height: 12px;
  padding: 7px 7px;
  border-radius: 6px;
  display: inline-block;
  width: fit-content;
`;

const SpaceDiv = styled.div`
  height: 14px;
  padding: 5px 7px;
`;

const HeaderLegend = styled.div`
  color: grey;
  ${mediaDown('md')`
    display:none;
  `};
`;

const HeaderMini = styled.div`
  font-size: 16px;
  color: grey;
  display: none !important;

  ${mediaDown('md')`
   display: block !important;
  `}
`;

const Styled = {
  Sidebar,
  SidebarUserInfo,
  SidebarLogoutUser,
  SidebarOptions,
  DeleteWarning,
  InfoTitle,
  InfoEmail,
  DataContainer,
  InfoDetails,
  InfoDetailsRow,
  BackArrow,
  EditForm,
  BurgerIcon,
  MobileView,
  MobileViewTitle,
  PasswordExplanation,
  PhoneNumberNotConfirmed,
  PhoneNumberInfoRow,
  Visit,
  TabTitle,
  TabSubTitle,
  VisitTitleDateTime,
  VisitTitle,
  Preparations,
  Reminder,
  PaymentDetails,
  PaymentDetailsRow,
  VisitDetailsRow,
  SideBarSubMeniu,
  ServiceTitle,
  ServiceInformation,
  DatePicker,
  List,
  ListItemRow,
  ListItemBottom,
  EmptyList,
  TabTitleWrapper,
  DiscountCard,
  DiscountCardTitle,
  SelectField,
  LogListRecord,
  LoadMoreBtn,
  ImageContainer,
  Input,
  Label,
  RowDiv,
  GreyLabel,
  SpecialistFields,
  SpecialistFieldsParent,
  SpecialistFieldsContainer,
  SpecialistButtonContainer,
  SpecialistParagraphContainer,
  FormContainer,
  RowContainer,
  SecondaryRowContainer,
  EditFormCenter,
  DangerContainer,
  InputContainer,
  SelfService,
  ButtonContainer,
  SelfServiceIcon,
  UserSettingsMenu,
  Option,
  Backdrop,
  DoctorInfoListRecord,
  DoctorName,
  DescriptionUrl,
  Badge,
  SpaceDiv,
  HeaderLegend,
  HeaderMini,
};

export default Styled;
