import React, { useEffect} from 'react';
import Styled from '../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../assets/icons/arrow.svg';
import moment from 'moment';
import useFetch from 'use-http';
import { useHistory } from 'react-router-dom';

const LocalRegistrationDetails = () => {
  const handleFileDownload = async (file) => {
    var a = document.createElement('a'); //Create <a>
    a.href = file.base64;
    a.download = file.fileName;
    a.click();
  };

  const history = useHistory();
  const registration = history.location.state.registration;

  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>KREIPIMŲSI ISTORIJA</Styled.VisitTitle>
      </Styled.BackArrow>
      <Styled.InfoDetailsRow>
        <span>Kreipimosi tema:</span>
        <div>{registration.topic}</div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Data:</span>
        <div>
          <Moment utc={true} className="time" format="YYYY-MM-DD">
            {Date.parse(registration.createdAt)}
          </Moment>
        </div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Vardas:</span>
        <div>{registration.name}</div>
      </Styled.InfoDetailsRow>
      <Styled.InfoDetailsRow>
        <span>Pavardė:</span>
        <div>{registration.surname}</div>
      </Styled.InfoDetailsRow>

      <Styled.InfoDetailsRow>
        <span>El. pašto adresas:</span>
        <div>{registration.email}</div>
      </Styled.InfoDetailsRow>

      <Styled.InfoDetailsRow>
        <span>Telefono numeris:</span>
        <div>{registration.phone}</div>
      </Styled.InfoDetailsRow>

      <Styled.InfoDetailsRow>
        <span>Asmens kodas:</span>
        <div>{registration.personalCode}</div>
      </Styled.InfoDetailsRow>

      <Styled.InfoDetailsRow>
        <span>Priežastis:</span>
        <div>{registration.reason}</div>
      </Styled.InfoDetailsRow>

      {registration.files.map((file) => {
        return (
          <Styled.InfoDetailsRow>
            <a onClick={() => handleFileDownload(file)}>
              ATSISIŲSTI PRISEGTĄ FAILĄ
            </a>
          </Styled.InfoDetailsRow>
        );
      })}
    </Styled.DataContainer>
  );
};
export default LocalRegistrationDetails;
