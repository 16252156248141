import useFetch from 'use-http';
import React, { useEffect, useState, useRef } from 'react';
import Page from '../../../../../shared/Page';
import Styled from '../../UserAccountPage.styles';
import { useTranslation } from 'react-i18next';
import Loader from '../../../../../shared/Loader';
import Button from '../../../../../shared/Button/Button';
import { AiOutlineEdit } from 'react-icons/ai';
import SelectField from '../../../../../shared/SelectField/SelectField';
import TextField from '../../../../../shared/TextField';
import * as Yup from 'yup';
import { Formik, Form as FormikForm, Field } from 'formik';
import Form from '../../../../../shared/Form';
import toast, { Toaster } from 'react-hot-toast';
import cross from '../../../../../../assets/icons/remove.png';

const SpecialistRestrictions = () => {
  const { get: getRestrictions } = useFetch(
    `/SpecialistRestriction/getSpecialistRestrictions`,
  );

  const { post: createRestriction } = useFetch(
    `/SpecialistRestriction/createRestriction`,
  );

  const { delete: removeRestriction } = useFetch(
    `/SpecialistRestriction/removeRestriction`,
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [restrictions, setRestrictions] = useState();
  const [specialists, setSpecialists] = useState();
  const [selectedSpecialist, setSelectedSpecialist] = useState();
  const [updateForm, setUpdateForm] = useState(0);
  const [defaultInputValue, setDefaultInputValue] = useState(0);
  const formRef = useRef();
  const initialValues = {
    minAge: defaultInputValue,
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getRestrictions().then((res) => {
        if (res.specialists) {
          let arr = [];
          res.specialists.forEach((element) => {
            arr.push({
              value: element.specialistExternalId,
              label: element.fullName,
            });
            setSpecialists(arr);
          });
        }
        setRestrictions(res.restrictions);

        setLoading(false);
      });
    };
    fetchData();
  }, [getRestrictions]);

  const onSelectedSpecialist = (val) => {
    if (restrictions && formRef && formRef.current) {
      const specialistIndex = restrictions.findIndex(
        (restriction) => restriction.specialistExternalId === val,
      );
      if (specialistIndex !== -1) {
        formRef.current.values.minAge = restrictions[specialistIndex].minAge;
      } else formRef.current.values.minAge = 0;
    }
  };

  useEffect(() => {
    // Check if formRef is available and if there's a selected specialist
    if (formRef.current && selectedSpecialist) {
      const specialistIndex = restrictions.findIndex(
        (restriction) =>
          restriction.specialistExternalId === selectedSpecialist,
      );

      if (specialistIndex !== -1) {
        formRef.current.setFieldValue(
          'minAge',
          restrictions[specialistIndex].minAge,
        );
      } else {
        formRef.current.setFieldValue('minAge', 0);
      }
    }
  }, [formRef, selectedSpecialist, restrictions]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.requiredField'))
      .matches(
        /(?=[A-Za-z0-9@$!%*#?&^`~+-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&^`~+-])(?=.{8,}).*$/,
        t('validation.password'),
      ),
    confirmPassword: Yup.string()
      .required(t('validation.requiredField'))
      .trim()
      .oneOf([Yup.ref('password'), null], t('validation.notMatchingPassword')),
  });

  const handleInfoSubmit = async (data) => {
    if (isNaN(data.minAge)) {
      return;
    }
    if (!(data.minAge >= 1 && data.minAge <= 18)) {
      return;
    }
    var request = {
      minAge: data.minAge,
      specialistExternalId: selectedSpecialist,
    };
    await createRestriction('', request).then((res) => {
      if (res === true) {
        updateRestrictions(selectedSpecialist, data.minAge);
        toast.success('Išsaugota');
      }
    });
  };

  const validateNumber = (value) => {
    const regex = /^[0-9]*$/; // Regular expression to allow only numbers
    if (!regex.test(value)) {
      return 'Galima vesti tik skaičius';
    }

    if (!(value >= 1 && value <= 18)) {
      return 'Amžius gali būti nuo 1 iki 18 metų';
    }

    return undefined; // No validation error
  };

  const updateRestrictions = (specialistExternalId, newMinAge) => {
    const updatedRestrictions = [...restrictions];

    const specialistIndex = updatedRestrictions.findIndex(
      (restriction) =>
        restriction.specialistExternalId === specialistExternalId,
    );

    if (specialistIndex !== -1) {
      updatedRestrictions[specialistIndex].minAge = newMinAge;
    } else {
      updatedRestrictions.push({
        specialistExternalId: specialistExternalId,
        minAge: newMinAge,
        fullName:
          specialists[
            specialists.findIndex(
              (restriction) => restriction.value === specialistExternalId,
            )
          ].label,
      });
    }

    setRestrictions(updatedRestrictions);
  };

  const removeSpecialistRestirction = async (id) => {
    await removeRestriction(`?id=${id}`).then((res) => {
      if (res === true) {
        toast.success('Apribojimas panaikintas');
        setRestrictions(
          restrictions.filter((item) => item.specialistExternalId !== id),
        );
      } else {
        toast.error('Apribojimo ištrinti nepavyko!');
      }
    });
  };

  return (
    <>
      <Page loading={loading}>
        <Toaster />
        <Styled.DataContainer>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '24px',
              paddingTop: '20px',
            }}
          >
            Norint pridėti naują arba pakeisti apribojimą, pasirinkite
            specialistą
          </div>
          <Styled.SelectField
            style={{
              width: '25%',
              backgroundColor: '#f1f5f7',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <SelectField
              options={specialists}
              isSearchable={true}
              isClearable={true}
              placeHolder={'Specialistai'}
              onChange={(val) => {
                onSelectedSpecialist(val);
                setSelectedSpecialist(val);
              }}
            ></SelectField>
          </Styled.SelectField>

          {selectedSpecialist && (
            <>
              <Formik
                innerRef={formRef}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleInfoSubmit}
              >
                {({
                  setFieldValue,
                  errors,
                  isSubmitting,
                  values,
                  handleSubmit,
                }) => (
                  <FormikForm style={{ width: '25%' }}>
                    <Form>
                      <Form.Group>
                        <Form.Row>
                          <Field
                            focus={true}
                            name="minAge"
                            label={'Minimalus amžius'}
                            component={TextField}
                            isGrey={true}
                            validate={validateNumber}
                          />
                        </Form.Row>
                      </Form.Group>
                    </Form>
                    <Button
                      loading={false}
                      disabled={false}
                      size="large"
                      type="submit"
                      onClick={() => {
                        handleInfoSubmit(values);
                      }}
                    >
                      Išsaugoti
                    </Button>
                  </FormikForm>
                )}
              </Formik>
            </>
          )}

          {restrictions && (
            <div style={{ marginTop: '20px' }}>
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: '24px',
                  marginTop: '20px',
                }}
              >
                Vaikų specialistų apribojimai
              </span>
              <div>
                {restrictions.map((r, idx) => {
                  return (
                    <div
                      style={{ fontSize: '18px', display: 'flex' }}
                      key={idx}
                    >
                      {r.fullName} Minimalus amžius {r.minAge}{' '}
                      <img
                        src={cross}
                        style={{
                          height: 'auto',
                          width: '24px',
                          marginLeft: '35px',
                        }}
                        onClick={() =>
                          removeSpecialistRestirction(r.specialistExternalId)
                        }
                      />
                      {/* <Cross
                        style={{
                          marginLeft: '15px',
                          width: '13px',
                          height: 'auto',
                          color: 'red',
                        }}
                      ></Cross> */}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Styled.DataContainer>
      </Page>
    </>
  );
};
export default SpecialistRestrictions;
