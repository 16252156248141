import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import Button from '../../../shared/Button';
import Styled from './LoginOptions.styles';
import Form from '../../../shared/Form';
import TextField from '../../../shared/TextField';
import { useHistory } from 'react-router-dom';
import { useUserDispatch } from '../../../context/UserContext';
import useRedirection from '../../../hooks/useRedirection';
import { useEffectOnce } from 'react-use';
import personalCodeValidation from '../../../hooks/personalCodeValidation';

const MSignatureLogin = () => {
  const { post: formPost, response: formResponse } = useFetch(
    `/UserAccount/MarkID/Login/MobileID`,
  );

  const history = useHistory();

  const isFromAuthorizedPersons = history.location.state?.from;
  const [AuthorizedPersons, setAuthorizedPersons] = useState(
    history.location.state?.data,
  );

  const [validatePersonalCode] = personalCodeValidation();

  const [clearCartState] = useRedirection();
  useEffectOnce(async () => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);

  const sessionDispatch = useUserDispatch();
  const { t } = useTranslation();

  const initialValues = {
    personalCode: '',
    phoneNumber: '+370',
  };

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .required(t('validation.requiredField'))
      .test('checkFormat', t('validation.number'), function (val) {
        const phoneRegex = /^[+]{1}(370)(\d{8})+$/;
        if (val && phoneRegex.test(val)) {
          return true;
        }
        return false;
      }),
    personalCode: Yup.number()
      .typeError(t('validation.personalCodeNumbers'))
      .required(t('validation.requiredField'))
      .test('len', t('validation.personalCode'), (val) => {
        return validatePersonalCode(val);
      }),
  });

  const handleSignatureLogin = async (data, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    await formPost('', {
      personalCode: data.personalCode?.replace(/\s/g, ''),
      phoneNumber: data.phoneNumber,
    });

    if (formResponse.status >= 400) {
      const response = await formResponse.json();
      const errors = {
        personalCode: response.exceptionDetails[0]?.message,
      };
      setErrors(errors);
    } else {
      const response = await formResponse.data;

      sessionDispatch({
        type: 'UPDATE',
        data: {
          authenticationType: 'mobileid',
          personalCode: data.personalCode?.replace(/\s/g, ''),
          phoneNumber: data.phoneNumber,
          initializedFromLogin: true,
          phoneNumberVerifyOptions: {
            token: response.token,
            controlCode: response.controlCode,
          },
        },
      });
      if (isFromAuthorizedPersons === 'AuthorizedPersonsConfirmation') {
        history.push('/confirmIdentity', {
          from: 'login',
          origin: 'authorizedPersons',
          data: AuthorizedPersons,
        });
        return;
      }

      history.push('/confirmIdentity', { from: 'login' });
    }

    setSubmitting(false);
  };

  return (
    <Page loading={false}>
      <Container>
        <Styled.Form>
          {isFromAuthorizedPersons === 'AuthorizedPersonsConfirmation' ? (
            <Page.Title backUrl={`/account/authorized-persons-confirmation`}>
              {t('login.smartIdTab.title')}
            </Page.Title>
          ) : (
            <Page.Title backUrl={`/selfservice`} greyTheme={true}>
              {t('login.smartIdTab.title')}
            </Page.Title>
          )}

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSignatureLogin}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Styled.SmartIdTitle greyTheme={true}>
                      {t('login.msignatureTab.tabName')}
                    </Styled.SmartIdTitle>
                    <Form.Row>
                      <Field
                        focus={true}
                        name="personalCode"
                        value={values.personalCode?.replace(/\s/g, '')}
                        placeholder={t(
                          'login.msignatureTab.personalCodePlaceholder',
                        )}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Field
                        name="phoneNumber"
                        placeholder={t(
                          'login.msignatureTab.phoneNumberPlaceholder',
                        )}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>
                <div className="mt-4 mt-md-2 d-flex align-center justify-center">
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    onClick={() => handleSignatureLogin}
                  >
                    {t('login.msignatureTab.continue')}
                  </Button>
                </div>
              </FormikForm>
            )}
          </Formik>
        </Styled.Form>
      </Container>
    </Page>
  );
};

export default MSignatureLogin;
