import React from 'react';
import Styled from '../../UserAccountPage.styles';
import Moment from 'react-moment';
import { ReactComponent as RightArrow } from '../../../../../../assets/icons/arrow.svg';
import useFetch from 'use-http';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
const AmisSyncLogDetails = () => {
  const history = useHistory();
  const log = history.location.state?.data;

  return (
    <Styled.DataContainer>
      <Styled.BackArrow>
        <RightArrow onClick={() => history.goBack()}></RightArrow>
        <Styled.VisitTitle>{log.event}</Styled.VisitTitle>
      </Styled.BackArrow>

      <Styled.InfoDetailsRow>
        Pavadinimas:
        <span> {log.event}</span>
      </Styled.InfoDetailsRow>

      <Styled.InfoDetailsRow>
        Sinchronizacija pradėta:
        <span>
          <Moment format="YYYY-MM-DD HH:mm:ss" utc={true}>
            {log.startedAt}
          </Moment>
        </span>
      </Styled.InfoDetailsRow>

      <Styled.InfoDetailsRow>
        Sinchronizacija baigta:
        <span>
          <Moment format="YYYY-MM-DD HH:mm:ss" utc={true}>
            {log.finishedAt}
          </Moment>
        </span>
      </Styled.InfoDetailsRow>

      {log.exceptionMessage ? (
        <Styled.InfoDetailsRow>
          Klaida:
          <span>{log.exceptionMessage}</span>
        </Styled.InfoDetailsRow>
      ) : (
        <Styled.InfoDetailsRow>
          Sinchronizacija pavyko sėkmingai
        </Styled.InfoDetailsRow>
      )}
    </Styled.DataContainer>
  );
};
export default AmisSyncLogDetails;
