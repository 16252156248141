import { Field, Form as FormikForm, Formik } from 'formik';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useFetch from 'use-http';
import * as Yup from 'yup';
import Modal from '../../../../shared/Modal/Modal';
import { ReactComponent as Danger } from '../../../../../assets/icons/danger.svg';
import { useUserState } from '../../../../context/UserContext';
import Button from '../../../../shared/Button';
import Container from '../../../../shared/Container';
import Form from '../../../../shared/Form';
import Page from '../../../../shared/Page';
import TextField from '../../../../shared/TextField';
import Styled from './../PasswordResetPage.styles';

const PasswordResetFormPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userState = useUserState();
  const [PreviewModalVisible, setPreviewModalVisible] = useState(false);

  const initialValues = {
    password: '',
    confirmPassword: '',
    smsCode: '',
  };

  const { post: managePasswordReset, response: passwordResetResponse } =
    useFetch(`/UserAccount/ResetPassword`);
  const handleSubmit = async (data, { setErrors, setSubmitting }) => {
    setSubmitting(true);
    const response = await managePasswordReset('', {
      userId: userState.id,
      password: data.password,
      code: data.smsCode,
    });
    setSubmitting(false);
    if (passwordResetResponse.status >= 500) {
    } else if (passwordResetResponse.status >= 400) {
      const errors = {
        password: response.message,
        confirmPassword: response.message,
        smsCode: response.message,
      };
      setErrors(errors);
    } else {
      if (userState?.authenticationType !== 'amis') {
        setPreviewModalVisible(true);
      } else {
        history.push('/selfservice');
      }
    }
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.requiredField'))
      .matches(
        /(?=[A-Za-z0-9@$!%*#?&^`~+-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&^`~+-])(?=.{8,}).*$/,
        t('validation.password'),
      ),
    confirmPassword: Yup.string()
      .required(t('validation.requiredField'))
      .trim()
      .oneOf([Yup.ref('password'), null], t('validation.notMatchingPassword'))
      .matches(
        /(?=[A-Za-z0-9@$!%*#?&^`~+-]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&^`~+-])(?=.{8,}).*$/,
        t('validation.password'),
      ),
    smsCode: Yup.number()
      .required(t('validation.requiredField'))
      .typeError(t('validation.smsFormat'))
      .test('len', t('validation.smsCodeLength'), (val) => {
        if (val) return val.toString().length === 4;
      }),
  });

  return (
    <Page loading={false}>
      <Container>
        <Styled.Form>
          <Page.Title backUrl={`/selfservice`}>
            {t('forgotPassword.title')}
          </Page.Title>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, errors, isSubmitting }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Form.Row>
                      <Styled.PasswordExplanation>
                        <div>
                          <Danger></Danger>
                        </div>
                        <div>
                          <Trans i18nKey="validation.passwordStrongSymbols" />
                        </div>
                      </Styled.PasswordExplanation>
                    </Form.Row>
                    <Form.Row>
                      <Field
                        focus={true}
                        name="password"
                        password
                        label={t('forgotPassword.form.password')}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Field
                        name="confirmPassword"
                        password
                        label={t('forgotPassword.form.confirmPassword')}
                        component={TextField}
                        isGrey={true}
                      />
                    </Form.Row>
                    <Form.Row>
                      <Field
                        name="smsCode"
                        label={t('forgotPassword.form.smsCode')}
                        component={TextField}
                        autoComplete="off"
                        isGrey={true}
                      />
                    </Form.Row>
                  </Form.Group>
                </Form>

                <Styled.Buttons>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    style={{ width: '50%' }}
                    size="mega"
                    type="submit"
                    onClick={() => handleSubmit}
                  >
                    {t('forgotPassword.form.submit')}
                  </Button>
                </Styled.Buttons>
              </FormikForm>
            )}
          </Formik>
        </Styled.Form>
      </Container>
      <Modal
        visible={PreviewModalVisible}
        onClose={() => setPreviewModalVisible(false)}
      >
        <Modal.TextContent>
          {t('userAccount.userCredentialsEdit.changedPassword')}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setPreviewModalVisible(false);
              history.push('/selfservice');
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </Page>
  );
};

export default PasswordResetFormPage;
