import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => (props.wide ? '1400px' : '1150px')};
  ${(props) => (props.narrowHeight && 'height: 300px')};
  padding: 0 20px;
  margin: 0 auto;

  ${mediaDown('md')`
    padding: 0 15px;
  `}

  ${(props) =>
    props.narrow &&
    `
    max-width: 920px;
  `}

  @media (max-width: 767px) {
    ${(props) => (props.marginTop && 'margin-top:0')};
  }
`;

const Styled = {
  Container,
};

export default Styled;
