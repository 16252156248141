import moment from 'moment';
export function getQueryString(date, search, status, filter, espbiEsi) {
  let prevApplied = false;
  let queryString = '';

  if (espbiEsi) {
    queryString = `?espbiEsi=${espbiEsi}`;
    prevApplied = true;
  }
  if (status) {
    queryString +=
      `${prevApplied ? '&' : '?'}` + new URLSearchParams({ status: status });
    prevApplied = true;
  }
  if (date !== undefined) {
    if (date[0] !== null && date[1] != null) {
      queryString += `${prevApplied ? '&' : '?'}from=${moment(
        new Date(date[0]),
      ).format('YYYY-MM-DD')}&to=${moment(new Date(date[1])).format(
        'YYYY-MM-DD',
      )}`;
      prevApplied = true;
    } else if (date[0] !== null) {
      queryString += `${prevApplied ? '&' : '?'}from=${moment(
        new Date(date[0]),
      ).format('YYYY-MM-DD')}`;
      prevApplied = true;
    } else if (date[1] !== null) {
      queryString += `${prevApplied ? '&' : '?'}to=${moment(
        new Date(date[1]),
      ).format('YYYY-MM-DD')}`;
      prevApplied = true;
    }
  }
  if (search) {
    queryString += `${prevApplied ? '&' : '?'}search=${search}`;
    prevApplied = true;
  }

  if (filter) {
    queryString += `${prevApplied ? '&' : '?'}statusFilter=${filter}`;
    prevApplied = true;
  }

  return queryString;
}
