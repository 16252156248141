import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import useFetch from 'use-http';
import Modal from '../shared/Modal';
import Button from '../shared/Button';
const PopUp = () => {
  const [showPopUp, setPopUpModal] = useState(false);
  const [imgHeight, setImgHeight] = useState(0);
  const [popUp, setPopUp] = useState();
  const { get: getPopup, response: getPopUpResponse } = useFetch(
    `/UserAccount/GetFullPopup`,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const popupSetting = localStorage.getItem('popup');
    if (popupSetting) {
      const show = new Date(popupSetting) < new Date();
      if (show) {
        const PopUpObj = await getPopup();
        setPopUp(PopUpObj);
        if (getPopUpResponse.status !== 200) {
        } else {
          if (PopUpObj && PopUpObj.isVisible) {
            // showpopup
            setPopUpModal(true);
            let hours = 24;
            if (PopUpObj.validForHours) {
              hours = PopUpObj.validForHours;
            }
            localStorage.setItem(
              'popup',
              new moment(new Date()).add(Number(hours), 'h').toDate(),
            );
          }
        }
      }
    } else {
      const PopUpObj = await getPopup();
      if (getPopUpResponse.status !== 200) {
      } else {
        setPopUp(PopUpObj);
        if (PopUpObj && PopUpObj.isVisible) {
          // showPopup
          setPopUpModal(true);
          let hours = 24;
          if (PopUpObj.validForHours) {
            hours = PopUpObj.validForHours;
          }
          localStorage.setItem(
            'popup',
            new moment(new Date()).add(Number(hours), 'h').toDate(),
          );
        }
      }
    }
  }, []);

  function calculateSize({ target: img }) {
    setImgHeight(img.offsetHeight);
  }

  return (
    <Modal
      popUp={true}
      visible={showPopUp}    
      showClose={true}
      extraButton={popUp?.buttonTitle}
      link={popUp?.link}
      onClose={() => {
        setPopUpModal(false);
      }}
      imgSize={imgHeight}
      popUpImg={popUp?.popupImage}
      isGrey={true}
    >
      <Modal.TextContent popUp={true}>
        <a href={popUp?.link} target="_blank" rel="noreferrer">
          <img
            id="popUpImg"
            alt="popUpImage"
            src={popUp?.popupImage}
            onLoad={calculateSize}
            width="auto"
            height="auto"
            style={{
              objectFit: 'contain',
              height: '100%',
              width: '100%',
              outline: 'none',
              borderColor: '#fff',
              borderBottomLeftRadius: '15px',
              borderBottomRightRadius: '15px',
              borderTopLeftRadius: '15px',
              borderTopRightRadius: '15px',
            }}
          ></img>
        </a>
      </Modal.TextContent>
    </Modal>
  );
};

export default PopUp;
