import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  ${mediaDown('md')`
  min-width: 200px;
  `}
`;

const FormWrapper = styled.div`
  display: flex;
  color: #52585F;

  
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  ${(props) => props.isMediumWidth && 'width:700px;'}
  ${(props) => props.isMediumWidth && 'margin:0 auto;'}
`;

const Buttons = styled.div`
  margin-top: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: inherit;
`;
const Title = styled.h1`
  top: 30px;
  margin-top: 55px;
  text-align: center;
  ${mediaDown('md')`
    font-size:24px;
  `}
`;

const StyledHr = styled.hr`
  overflow: hidden;
  text-align: center;
  margin: 25px 0 25px 0;
  background-color: grey;
  content: '';
  display: inline-block;
  position: relative;
  vertical-align: middle;
  opacity: 0.4;
  width: 100%;
`;
const LineThrough = styled.span`
  margin: 0 40px 0 40px;
  height: 100%;
  width: 50px;
  display: inline-block;
  text-align: center;
  padding-top: 305px;

  font-weight: bold;
  :before {
    opacity: 0.4;
    margin-left: 20px;
    border-left: 1px solid black;
    content: '';
    display: block;
    height: 195px;
    position: absolute;
    top: 90px;
    width: 1px;
    z-index: 0;
    @media (max-width: 1100px) {
      display: inline-block;
      position: relative;
      height: 1px;
      width: 100%;
      top: 0;
      opacity: 0.4;
      right: 1em;

      margin-left: -50%;
    }
  }

  :after {
    opacity: 0.4;
    margin-left: 20px;
    border-left: 1px solid black;
    content: '';
    display: block;
    height: 195px;
    position: absolute;
    top: 345px;
    width: 1px;
    z-index: 0;
    @media (max-width: 1100px) {
      display: inline-block;
      position: relative;
      height: 1px;
      width: 100%;
      top: 0;
      opacity: 0.4;
      left: 0em;
      margin-right: -53%;
    }
  }
  @media (max-width: 1100px) {
    padding-top: 30px;
    width: auto;
    &:before,
    &:after {
      background-color: grey;
      content: '';
      height: 1px;
      vertical-align: middle;
      width: 50%;
    }
  }
`;

const SmartIdTitle = styled.h3`
  margin-top: 0px;
  text-align: center;
  ${mediaDown('md')`
    
  `}
`;

const ForgotPassword = styled.div`
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  margin: 0 0 5px;
`;

const RegisterHere = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  font-size: 24px;
  justify-content: center;
  text-align: center;
`;

const ToggleIcon = styled.span`
  position: absolute;
  z-index: 10;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
`;

const Styled = {
  Form,
  Title,
  LineThrough,
  ForgotPassword,
  Buttons,
  RegisterHere,
  StyledHr,
  FormWrapper,
  SmartIdTitle,
  ToggleIcon,
};

export default Styled;
