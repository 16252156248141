import styled, { css } from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const AdditionalPayment = styled.div`
  font-size: 11px;
  order: 2;
  color: grey;
  font-weight: bold;

`;

const Calendar = styled.div`
  background-color: #f1f5f7;
  border-radius: 15px;
  padding: 0 20px 20px;
  user-select: none;

  ${mediaDown('md')`
    border-radius: 0;
    maring: ${(props) => (props.widget ? '0' : '0 -20px')}
    // margin: 0 -20px; 
  `}
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 27px;

  ${mediaDown('md')`
    padding: 25px 0;
  `}
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  min-width: 340px;

  ${mediaDown('md')`
    font-size: 16px;
    line-height: 20px;
    min-width: 0;
    flex-grow: 1;
  `}
`;

const Arrow = styled.div`
  height: 27px;
  width: 27px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: -1px 1px 0 0 #0087ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    svg {
      opacity: 0.5;
    }
  }

  svg {
    display: block;
    transform: rotate(${(props) => (props.left ? '180deg' : '0')});
    transition: opacity 0.15s;
  }
`;

const Body = styled.div`
  border-radius: 6px;
  background-color: #fff;
`;
const Header = styled.div`
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  flex-grow: 1;
  padding: 27px 0 15px;
  border-bottom: 1px solid #eff5f7;

  ${mediaDown('md')`
    padding: 17px 0 10px;
    border-bottom: none;
  `}
`;
const Day = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;

  ${mediaDown('md')`
    font-size: 16px;
    line-height: 20px;
  `}

  small {
    color: #9395a9;
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin-top: 4px;
    text-transform: capitalize;

    ${mediaDown('md')`
      font-size: 9px;
      line-height: 12px;
    `}
  }
`;
const Columns = styled.div`
  display: grid;
  grid-gap: 0;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-top: -1px;

  ${mediaDown('md')`
    padding: 2.5px;
    margin-top: 0;
  `}
`;
const Cell = styled.div`
  padding: 2px;
  border-top: 1px solid #eff5f7;

  ${mediaDown('md')`
    padding: 2.5px;
    border: none;
  `}
`;

const Column = styled.div`
  border-right: 1px solid #eff5f7;

  ${mediaDown('md')`
    border: none;
  `}
`;

const slotBase = css`
  background-color: #f5f9fa;
  border-radius: 5px;
  height: 40px;
`;

const SlotSpacer = styled.div`
  ${slotBase}
`;

const Slot = styled.div`
  ${slotBase}
  background-color: #CCF9E5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.selected
      ? '#000'
      : props.isTaken
      ? '#c7c7c7'
      : props.isPaid
      ? '#519c77'
      : props.isAdditional
      ? '#ffffff'
      : '#3655d7'};
  background-color: ${(props) =>
    props.isTaken
      ? '#f5f9fa'
      : props.isPaid
      ? '#CCF9E5'
      : props.isAdditional
      ? '#7447E6'
      : '#a2cbff'};
  font-size: 13px;
  cursor: ${(props) =>
    props.legend ? 'default' : props.isTaken ? 'default' : 'pointer'};

  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
  border: 2px solid
    ${(props) =>
      props.selected
        ? '#000'
        : props.isTaken
        ? '#f5f9fa'
        : props.isPaid
        ? '#CCF9E5'
        : props.isAdditional
        ? '#7447E6'
        : '#a2cbff'};
  transition: opacity 0.15s;
  position: relative;
  overflow: hidden;
  padding-top: ${(props) => (props.price ? '0' : '0')};
  flex-direction: ${(props) => props.is3T && 'column'};
  flex-direction: ${(props) => (props.price ? 'column-reverse' : '0')};
  ${mediaDown('md')`
    font-size: 12px;
  `}
`;

const NoResults = styled.div`
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaDown('md')`
    height: 225px;
  `}
`;

const NoResultsMessage = styled.div`
  position: relative;
  padding: 2px;
  text-align: center;

  color: #9395a9;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  max-width: 215px;
  margin: 0 auto;

  ${mediaDown('md')`
    font-size: 12px;
    line-height: 15px;
  `}

  svg {
    display: block;
    margin: 12px auto;

    ${mediaDown('md')`
      height: 35px;
    `}
  }
`;

const TlkIconSvg = styled.div`
  position: absolute;
  bottom: 0;
  right: -8px;
  height: 29px;
  z-index: 0;
  order: 1;
  svg {
    height: 35px;
    width: 35px;
    fill: rgba(0, 0, 0, 0.1);
  }
`;

const ExtraPrice = styled.div`
  color: ${(props) =>
    props.extra ? 'rgba(255, 255, 255, 0.9)' : 'rgba(128,128,128)'};
  font-size: 10px;
  // position: absolute;
  // bottom: 0px;
  // right: 20%;
  // height: 14px;
  // z-index: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 2px;
  ${mediaDown('md')`
        font-size: 8px;
    `}
`;

const Styled = {
  Calendar,
  Controls,
  Title,
  Arrow,
  Body,
  Header,
  Columns,
  Day,
  Column,
  Slot,
  Cell,
  SlotSpacer,
  NoResults,
  NoResultsMessage,
  TlkIconSvg,
  ExtraPrice,
  AdditionalPayment,
};

export default Styled;
