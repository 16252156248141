import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, Form as FormikForm } from 'formik';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import useFetch from 'use-http';
import Container from '../../../shared/Container';
import Page from '../../../shared/Page';
import * as Yup from 'yup';
import Button from '../../../shared/Button';
import Styled from './RegisterPage.styles';
import Form from '../../../shared/Form';
import Checkbox from '../../../shared/Checkbox';
import SelectField from '../../../shared/SelectField';
import { useSettingsState } from '../../../context/SettingsContext';
import { useUserDispatch } from '../../../context/UserContext';
import useRedirection from '../../../hooks/useRedirection';
import { useEffectOnce } from 'react-use';
import Modal from '../../../shared/Modal'

const RegisterPage = () => {
  const { get: amisStatusRequest, response: amisStatusResponse } = useFetch(
    `/UserAccount/AMIS/Status`,
  );

  const [clearCartState] = useRedirection();
  useEffectOnce(async () => {
    return () => {
      clearCartState();
    };
  }, [clearCartState]);

  const { t } = useTranslation();
  const history = useHistory();
  const { agreements } = useSettingsState();
  const sessionDispatch = useUserDispatch();
  const { search: urlParams } = useLocation();
  const options = queryString.parse(urlParams);
  
  const initialValues = {
    agreedWithDataProcessing: false,
    agreedWithTos: false,
    type: undefined,
    countryCode: 'LT',
  };
  const countries = [
    { value: 'LT', label: 'Lietuva' },
    { value: 'LV', label: 'Latvija' },
    { value: 'ES', label: 'Estija' },
  ];

  const validationSchema = Yup.object().shape({
    agreedWithDataProcessing: Yup.boolean().isTrue(
      t('validation.dataAgreement'),
    ),
    agreedWithTos: Yup.boolean().isTrue(t('validation.patientTosAgreement')),
  });

  const types = {
    smartid: 'smartid',
    msign: 'mobileid',
    amis: 'amis',
  };

  useEffect(() => {
    async function getAmisStatus(token) {
      await amisStatusRequest(`?token=${token}`);

      if (amisStatusResponse.status === 200) {
        const response = await amisStatusResponse.json();

        sessionDispatch({
          type: 'UPDATE',
          data: {
            authenticationType: types.amis,
            id: response.user.id,
            email: response.user.email,
            phoneNumber: response.user.phoneNumber,
            phoneNumberConfirmed: false,
            personalCode: response.user.personalCode,
            firstName: response.user.firstName,
            lastName: response.user.lastName,
          },
        });
        sessionDispatch({
          type: 'UPDATE_TOKENS',
          data: {
            token: response.token,
            refreshToken: response.refreshToken,
          },
        });

        history.push('/terms');
      } else if (amisStatusResponse.status===400)
      {      
        history.push('/', {isAccountTokenExpired:true})
      }
    }

    options?.token && getAmisStatus(options.token);
  }, [
    amisStatusRequest,
    amisStatusResponse,
    history,
    options.token,
    options.type,
    sessionDispatch,
    types.amis,
  ]);

  const handleVerification = async (data, { setSubmitting, setErrors }) => {
    sessionDispatch({
      type: 'UPDATE',
      data: {
        authenticationType: data.type,
        countryCode: data.countryCode,
      },
    });
    history.push('/register/info');
  };

  return (
    <Page loading={options?.token ? true : false}>
      <Container>
        <Styled.Title>{t('userRegistration.initialPage.title')}</Styled.Title>
        <Styled.SubTitle>
          <span>{t('userRegistration.initialPage.rules')}</span>
        </Styled.SubTitle>
        <Styled.Form>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleVerification}
          >
            {({ setFieldValue, errors, isSubmitting, values }) => (
              <FormikForm>
                <Form>
                  <Form.Group>
                    <Form.Row>
                      <Checkbox
                        name="agreedWithDataProcessing"
                        error={errors.agreedWithDataProcessing}
                        onChange={(event) => {
                          setFieldValue(
                            'agreedWithDataProcessing',
                            event.target.checked,
                          );
                        }}
                      >
                        {t('userRegistration.agreements.registerAgeerements')}
                        <a
                          href={agreements.personalData.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {t('userRegistration.agreements.data')}
                        </a>
                      </Checkbox>
                    </Form.Row>
                    <Form.Row>
                      <Checkbox
                        name="agreedWithTos"
                        error={errors.agreedWithTos}
                        onChange={(event) => {
                          setFieldValue('agreedWithTos', event.target.checked);
                        }}
                      >
                        {t('userRegistration.agreements.registerAgeerements')}
                        <a
                          href={agreements.termsOfUse.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {t('userRegistration.agreements.tos')}
                        </a>
                      </Checkbox>
                    </Form.Row>
                  </Form.Group>
                  <Styled.SubTitleInForm>
                    <span>
                      {t(
                        'userRegistration.initialPage.identityVerificationOption',
                      )}
                    </span>
                  </Styled.SubTitleInForm>
                  <Form.Row>
                    <SelectField
                      error={errors.companyId}
                      defaultValue={{ value: 'LT', label: 'Lietuva' }}
                      name="countryCode"
                      label={t('login.smartIdTab.country')}
                      options={countries}
                      onChange={(value) => {
                        setFieldValue('countryCode', value);
                      }}
                    />
                  </Form.Row>
                  <Styled.Buttons>
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      size="mega"
                      type="submit"
                      style={{ width: 'inherit' }}
                      onClick={() => {
                        values.type = types.smartid;
                      }}
                    >
                      {t('userRegistration.identity.smartId')}
                    </Button>
                  </Styled.Buttons>
                  <Styled.Buttons>
                    <Button
                      loading={isSubmitting}
                      disabled={isSubmitting || values.countryCode === 'LV'}
                      size="mega"
                      type="submit"
                      style={{ width: 'inherit' }}
                      onClick={() => {
                        setFieldValue('type', types.msign);
                      }}
                    >
                      {t('userRegistration.identity.msignature')}
                    </Button>
                  </Styled.Buttons>
                </Form>
              </FormikForm>
            )}
          </Formik>

          <Styled.SubTitle>
            <span>
              {t('userRegistration.identity.footer')}
              <br /> {t('userRegistration.identity.visit')}
            </span>
          </Styled.SubTitle>
          <Styled.Buttons>
            <Button
              size="huge"
              variant="secondary"
              onClick={async () => {
                history.goBack();
              }}
            >
              {t('userRegistration.cancel')}
            </Button>
          </Styled.Buttons>
        </Styled.Form>
      </Container>
     
    </Page>
  );
};
export default RegisterPage;
