import React, { useRef, useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import useFetch from 'use-http';
import Hero from '../../shared/Hero';
import Categories from './Categories';
import HeroError from '../../shared/HeroError';
import useCancelationToken from '../../hooks/useCancelationToken';
import Modal from '../../shared/Modal/Modal';
import Button from '../../shared/Button';
import { useCartDispatch, useCartState } from '../../context/CartContext';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSettingsState } from '../../context/SettingsContext';
import { exponeaCapture } from '../../hooks/exponeaCapture';
import { useUserDispatch, useUserState } from '../../context/UserContext';

const CategoriesPage = () => {
  const { loading, error, data = [] } = useFetch('/ServiceTypes', []);
  const cartState = useCartState();
  const servicesRef = useRef();
  const userDispatch = useUserDispatch();
  const [serviceTitle, setServiceTitle] = useState();
  const [registrationDate, setRegistrationDate] = useState();
  const [cancelledRegistration, setCancelledRegistration] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isCancelRegistration, setIsCancelRegistration] = useState(false);
  const [isCancelModalClicked, setIsCancelModalClicked] = useState(false);
  const history = useHistory();
  const { phoneNumber } = useSettingsState();

  const [showLinkExpiryModal, setShowLinkExpiryModal] = useState(
    history.location?.state?.isAccountTokenExpired,
  );

  const {
    get: getRegistrationPaymentStatus,
    response: getRegistrationPaymentStatusResponse,
  } = useFetch('/RegistrationManagement/IsCancelled');

  const {
    post: cancelRegistrationRequestSend,
    response: cancelRegistrationResponse,
  } = useFetch(`/RegistrationManagement/Registrations/Cancel`);

  const [registrationCancelationToken, setRegistrationCancelationToken] =
    useState('');
  const [cantBePaidModalVisible, setCantBePaidModalVisible] = useState(false);
  const [cantBePaidModalText, setCantBePaidModalText] = useState(
    `Apmokėjimas negalimas. Prašome pakartoti registraciją ir susisiekti tel. nr: ${phoneNumber}`,
  );
  const [
    previewRegistrationCancelationModalVisible,
    setPreviewRegistrationCancelationModalVisible,
    cancelRegistration,
    previewRegistrationCancelationFailureModalVisible,
    setPreviewRegistrationCancelationFailureModalVisible,
    manuallyConfirmedRegistrationVisible,
    setManuallyConfirmedRegistrationVisible,
    registrationCancelled,
    setRegistrationCancelled,
  ] = useCancelationToken();
  const dispatch = useCartDispatch();

  useEffect(() => {
    if (showLinkExpiryModal) {
      history.replace({ state: {} });
    }
  }, [showLinkExpiryModal]);

  useEffect(async () => {
    if (cartState.leftToPaysera) {
      setIsCancelModalVisible(true);
    }
  }, [cartState.leftToPaysera]);

  useEffect(async () => {
    if (isCancelRegistration) {
      let exponea = JSON.parse(sessionStorage.getItem('exponeaTrack') || '[]');
      exponea.push({ name: 'Registration status', value: 'Cancelled' });
      exponea.push({ name: 'booking_stage', value: 'Cancelled' });
      exponeaCapture(exponea, 'booking');
      sessionStorage.removeItem('exponeaTrack');
      await cancelRegistrationRequestSend('', {
        cancelationToken: cartState.registrationId,
      });
      dispatch({
        type: 'RESET_ONLY_REGISTRATION_ID',
      });
      history.push('/payment/error');
    }
  }, [isCancelRegistration]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        if (cartState.leftToPaysera) {
          history.go(1);
        }
      }
    };
  }, [history]);

  useEffect(() => {}, []);

  useEffect(() => {
    setPreviewRegistrationCancelationModalVisible(false);
    const cancelToken = new URLSearchParams(window.location.search).get(
      'cancel_token',
    );

    const serviceTitle = new URLSearchParams(window.location.search).get(
      'serviceTitle',
    );

    const registrationDate = new URLSearchParams(window.location.search).get(
      'registration_date',
    );

    const orderId = new URLSearchParams(window.location.search).get('order_id');

    if (cancelToken) {
      setServiceTitle(serviceTitle);
      setRegistrationCancelationToken(cancelToken);
      setRegistrationDate(registrationDate);
      (async function () {
        setPreviewRegistrationCancelationModalVisible(true);
      })();
    }

    if (orderId) {
      getRegistrationPaymentStatus(`?registrationId=${orderId}`).then((res) => {
        if (res.canBePaid) {
          dispatch({
            type: 'SET_REGISTRATION_ID',
            registrationId: orderId,
            redirectedToPaysera: true,
          });

          window.location.replace(res.paymentUrl);
        } else {
          if (res.alreadyPaid) {
            setCantBePaidModalText('Registracija jau apmokėta');
          } else if (res.alreadyCancelled) {
            setCantBePaidModalText('Registracija jau buvo atšaukta');
          } else if (res.outOfTime) {
            setCantBePaidModalText('Registracijos laikas jau praėjo');
          }
          setCantBePaidModalVisible(true);
        }
      });
    }
  }, []);

  if (error) {
    return <HeroError />;
  }

  function clearCart() {
    dispatch({
      type: 'REMOVE_LEFTTOPAYSERA',
    });
  }

  function getGroupedServices() {
    return groupBy(
      data.sort((a, b) => a.categoryId - b.categoryId),
      'categoryTitle',
    );
  }

  const categories = getGroupedServices();

  function scrollToServices() {
    const { top } = servicesRef.current?.getBoundingClientRect() || 0;

    window.scrollBy({
      top,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <>
      <Hero onScroll={scrollToServices} />
      {/* <Container>
        <Benefits onScroll={scrollToServices} />
      </Container> */}

      <Categories list={categories} loading={loading} ref={servicesRef} />

      <Modal
        visible={previewRegistrationCancelationModalVisible}
        onClose={() => {
          setPreviewRegistrationCancelationModalVisible(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          {`Ar tikrai norite atšaukti registraciją ${registrationDate} "${serviceTitle}"?`}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={async () => {
              setPreviewRegistrationCancelationModalVisible(false);
              cancelRegistration(registrationCancelationToken);
              if (manuallyConfirmedRegistrationVisible) {
                setCancelledRegistration(true);
              }
            }}
          >
            Taip
          </Button>
          <Button
            size="large"
            onClick={() => {
              setPreviewRegistrationCancelationModalVisible(false);
            }}
          >
            Ne
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={previewRegistrationCancelationFailureModalVisible}
        onClose={() => {
          setPreviewRegistrationCancelationFailureModalVisible(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          Užsakymas jau apmokėtas, todėl jo negalime atšaukti. Dėl pagalbos
          prašome kreiptis telefonu {phoneNumber}.
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setPreviewRegistrationCancelationFailureModalVisible(false);
            }}
          >
            Gerai
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={manuallyConfirmedRegistrationVisible}
        onClose={() => {
          setManuallyConfirmedRegistrationVisible(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          Atšaukimas negalimas. Registracija patvirtinta padalinyje.
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setManuallyConfirmedRegistrationVisible(false);
            }}
          >
            Gerai
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={cancelledRegistration || registrationCancelled}
        onClose={() => {
          setCancelledRegistration(false);
          setRegistrationCancelled(false);
        }}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          {`Registracija "${serviceTitle}" sėkmingai atšaukta.`}
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setCancelledRegistration(false);
              setRegistrationCancelled(false);
            }}
          >
            Gerai
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={cantBePaidModalVisible}
        onClose={() => {
          setCantBePaidModalVisible(false);
          history.push('/');
        }}
        isGrey={true}
      >
        <Modal.Alert type="error" isGrey={true}>
          Registracijos apmokėjimas
        </Modal.Alert>
        <Modal.TextContent isGrey={true}>
          <div>
            {cantBePaidModalText}
            <p>
              Pabandykite pakartoti iš naujo arba skambinkite telefonu{' '}
              {phoneNumber}
            </p>
          </div>
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setCantBePaidModalVisible(false);
              history.push('/');
            }}
          >
            Gerai
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={showLinkExpiryModal}
        loading={false}
        onClose={() => setShowLinkExpiryModal(false)}
        isGrey={true}
      >
        <Modal.TextContent isGrey={true}>
          Paskyros kūrimo nuoroda nebegalioja! Kreipkitės į Affidea padalinį,
          kad inicijuotų jums paskyros kūrimą iš naujo.
        </Modal.TextContent>
        <Modal.Actions>
          <Button size="large" onClick={() => setShowLinkExpiryModal(false)}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        visible={isCancelModalVisible}
        showClose={false}
        onClose={() => {
          setIsCancelModalVisible(false);
          dispatch({
            type: 'REMOVE_LEFTTOPAYSERA',
          });
          dispatch({
            type: 'RESET_PAYSERA_URL',
          });
          userDispatch({
            type: 'SET_LEFT_CART_PAGE',
            left: false,
          });
        }}
      >
        <Modal.TextContent>
          Ar tikrai norite atšaukti registraciją?
        </Modal.TextContent>
        <Modal.Actions>
          <Button
            size="large"
            onClick={() => {
              setIsCancelModalClicked(true);
            }}
          >
            Grįžti
          </Button>
          <Button
            size="large"
            onClick={() => {
              setIsCancelRegistration(true);
              setIsCancelModalVisible(false);
              dispatch({
                type: 'REMOVE_LEFTTOPAYSERA',
              });
              userDispatch({
                type: 'SET_LEFT_CART_PAGE',
                left: false,
              });
            }}
          >
            Taip
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default CategoriesPage;
