import styled from 'styled-components/macro';
import { mediaDown } from '../../../utils/styles/media';

const Description = styled.div`
  position: relative;
  display: flex;
  ${(props) => props.isWidget && 'overflow: auto;'}
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 60px);
    height: 100%;
    border-radius: 15px;
    background-color: #f1f5f7;
    z-index: -1;

    ${mediaDown('md')`
      width: 100%;
      
    `}
  }

  .tooltip {
    color: black;
    width: 400px;
    background-color: white;
    border: 1px solid black;
    ${mediaDown('md')`
      width: 250px;
    `}
  }
`;

const Image = styled.div`
  height: 200px;
  width: 285px;
  border-radius: 15px;
  background-size: cover;
  background-position: center top;
  margin: 40px 0;
  background-color: rgba(0, 0, 0, 0.1);
  ${mediaDown('md')`
    position: absolute;
    left: 0;
    top: 0;    
    width: 130px;
    height: 87px;
    margin: 0;
    border-radius: 8px 0 8px 0;
  `}
`;

const Content = styled.div`
  padding: 40px 20px 40px 45px;
  ${mediaDown('md')`
    padding: 15px 15px 25px;
  `}
`;

const SpaceDiv = styled.div`
  height: 14px;
  padding: 5px 7px;
`;

const Badge = styled.div`
  background-color: ${(props) => (props.isService ? '#6A32E6' : '#0535AD')};
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
  padding: 5px 7px;
  border-radius: 6px;
  display: inline-block;

  ${mediaDown('md')`
    position: relative;
    z-index: 1;
    margin-left: -15px;
    padding-left: 15px;
  `}
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  color: #9395a9;

  p {
    display: flex;
    flex-direction: row;
    margin: 0;
    svg {
      margin-right: 2px;
      margin-top: 5px;
      color: rgb(95, 157, 244);
      ${mediaDown('md')`
         margin-top: 3px;
    `}
    }
  }
  span {
    margin-top: 5px;
    color: black;

    ${mediaDown('md')`
    width: 300px;
         margin-top: 3px;
         word-wrap: break-word;
    `}
  }
  .price {
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
    color: black;
  }
  .oldPrice {
    text-decoration: line-through;
    margin-top: 5px;
    font-weight: bold;
    font-size: 16px;
    color: black;
  }
`;

const MainPriceHeader = styled.div`
  max-width: 130px;
`;

const AdditionalInformation = styled.div`
  color: #9395a9;
  margin-top: 25px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  ${InfoBlock}:first-child {
    width: ${(props) => (props.psdf ? '600px' : '600px')};   atkeist
  }
  ${InfoBlock}:not(:first-child) {
    ${mediaDown('md')`
      margin-top: 25px;
     
    `};
  }
  ${InfoBlock}:not(:last-child) {
    padding-right: 25px;
    margin-right: 25px;
    border-right: 1px solid lightgrey;
    ${mediaDown('md')`
        border-right: none;
         width: auto;
    `}
  }
  ${mediaDown('md')`
       flex-direction: column;
       width: 250px;
       font-size: 12px;
      line-height: 15px;
    `}
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-weight: bold;
  font-size: 20px;
  color: black;
  ${mediaDown('md')`
       margin-left: 0;
    `}
`;

const InfoName = styled.span`
  width: 600px;
  padding-right: 10px; /* Spacing between columns */
  border-right: 1px solid lightgrey;
  ${mediaDown('md')`
  width: 80vw;
  border-right: none;
    margin-left: 0;
  `}
`;

const InfoMainName = styled.span`
  width: 600px;
  padding-top: 10px;
  font-size: 18px;
  border-right: 1px solid lightgrey;
  ${mediaDown('md')`
  border-right: none;
       margin-left: 0;
    `}
`;

const InfoPrice = styled.div`
  width: 150px;
  padding-left: 24px;
`;

const InfoContainer = styled.div`
  width: 100%;
  ${mediaDown('md')`
  width: 80vw;
       margin-left: 0;
    `}
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Aligns items at the bottom */
`;

const TotalPrice = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 900;
  line-height: 28px;
  text-align: right;
  margin: 55px 0 0;

  ${mediaDown('md')`
    font-size: 18px;
    line-height: 19px;
    text-align: left;
  `}
`;

const MainTitle = styled.div`
  width: 600px;
  color: black;
  font-weight: Bold;
  font-size: 30px;
  padding-top: 10px;
  font-size: 30px,
  border-right: 1px solid lightgrey;
  ${mediaDown('md')`
  width: auto;
  height: 50px;
  margin-top:70px;
  font-size: 18px;
  border-right: none;
      margin-left: 0;
  `}
`;

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  color: #9395a9;
  p {
    display: flex;
    flex-direction: row;
    margin: 0;
    svg {
      margin-right: 2px;
      margin-top: 5px;
      color: rgb(95, 157, 244);
      ${mediaDown('md')`
         margin-top: 3px;
    `}
    }
  }
  ${mediaDown('md')`
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
    min-height: 47px;
    margin-bottom: 10px;
    margin-top:10px;
    span {
      width: 200px;
    }
  `}
  ${mediaDown('sm')`
    
    margin-top:20px;
   
  `}

   ${mediaDown('xs')`
    margin-top:60px;
    
  `}
`;

const Notification = styled.div`
  color: #5bb548;
  margin-top: 36px;
  margin-bottom: -24px;
  font-size: 21px;
  font-weight: 500;
  line-height: 0;
  text-align: center;
  ${mediaDown('sm')`
  line-height: 20px;
  `}
`;

const Styled = {
  Description,
  Image,
  Content,
  Badge,
  DescriptionWrapper,
  InfoBlock,
  MainPriceHeader,
  AdditionalInformation,
  Price,
  SpaceDiv,
  TotalPrice,
  HeaderArea,
  InfoName,
  InfoMainName,
  InfoContainer,
  InfoRow,
  InfoPrice,
  MainTitle,
  Notification,
};

export default Styled;
